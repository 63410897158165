import _ from 'lodash';

import {handleActions, apiActionHandler} from 'store/helpers/actions';
import {readClient, readBank} from 'store/data/client';

import {
    checkClientExistence,
    fetchClient,
    fetchBanks,
    fetchBankAuthMethods,
    fetchBanksForClient,
    deleteBank,
    bankSignIn,
    updateBankCredentials,
    clearError,
    clearAddBankError,
    clearClientBanksError,
    clearCheckExistenceError,
    resetState,
    resetAddBankState,
    setClient, fetchLocalizationTerms,
} from './actions';

const initialState = {
    clientExist: null,
    client: null,
    loading: false,
    error: null,
    addBankError: null,
    addBankLoading: false,
    checkExistenceLoading: false,
    checkExistenceError: null,
    banks: null,
    clientBanks: null,
    clientBanksLoading: false,
    clientBanksError: null,
    bankSignIn: {},
    authMethods: [],
    localizationTerms: [],
};

export const isClientExist = ({clientExist}) => clientExist;
export const getClient = ({client}) => client;
export const isLoading = ({loading}) => loading;
export const getError = ({error}) => error;
export const getBanks = ({banks}) => banks;
export const getClientBanks = ({clientBanks}) => clientBanks;
export const isClientBanksLoading = ({clientBanksLoading}) => clientBanksLoading;
export const getClientBanksError = ({clientBanksError}) => clientBanksError;
export const isAddBankLoading = ({addBankLoading}) => addBankLoading;
export const getAddBankError = ({addBankError}) => addBankError;
export const getLoginMessage = ({bankSignIn: {message}}) => message;
export const getLoginStatus = ({bankSignIn: {status}}) => status;
export const getLoginSubStatuses = ({bankSignIn: {subStatuses}}) => subStatuses;
export const getAgreements = ({bankSignIn: {agreements}}) => agreements;
export const isCheckExistenceLoading = ({checkExistenceLoading}) => checkExistenceLoading;
export const getCheckExistenceError = ({checkExistenceError}) => checkExistenceError;
export const getAuthMethods = ({authMethods}) => authMethods;
export const getLocalizationTerms = ({localizationTerms}) => localizationTerms;

export const bankAuthRequestReducer = (state, action) => apiActionHandler(
    state,
    action,
    {
        loading: null,
        error: 'addBankError',
        result: 'bankSignIn',
        reader: ({message, agreements, status, metadata, subStatuses}) => {
            if (metadata) {
                const helpMessage = Object.entries(metadata).map(entry => entry.join(': ')).join(', ');
                return ({
                    message: `${message.replace(/\.+$/, '')}. ${helpMessage}`,
                    agreements,
                    status,
                });
            }
            return ({message, agreements, status, subStatuses});
        },
    },
);

export default handleActions(
    {
        [checkClientExistence]: (state, action) => apiActionHandler(
            state,
            action,
            {loading: 'checkExistenceLoading', error: 'checkExistenceError', result: 'clientExist'},
        ),

        [fetchClient]: (state, action) => apiActionHandler(
            state,
            action,
            {result: 'client', reader: readClient},
        ),
        [setClient]: (state, action) => ({...state, client: action.payload}),

        [fetchBanks]: (state, action) => apiActionHandler(
            state,
            action,
            {
                loading: 'addBankLoading',
                error: 'addBankError',
                result: 'banks',
                reader: banks => _.map(banks, readBank),
            },
        ),

        [fetchBanksForClient]: (state, action) => apiActionHandler(
            state,
            action,
            {
                loading: 'clientBanksLoading',
                error: 'clientBanksError',
                result: 'clientBanks',
                reader: banks => _.map(banks, readBank),
            },
        ),

        [fetchBankAuthMethods]: (state, action) => apiActionHandler(
            state,
            action,
            {
                loading: 'addBankLoading',
                error: 'addBankError',
                result: 'authMethods',
            },
        ),

        [fetchLocalizationTerms]: (state, action) => apiActionHandler(
            state,
            action,
            {
                loading: 'addBankLoading',
                error: 'localizationTermsError',
                result: 'localizationTerms',
            },
        ),

        [deleteBank]: apiActionHandler,

        [bankSignIn]: bankAuthRequestReducer,
        [updateBankCredentials]: bankAuthRequestReducer,

        [clearError]: state => ({...state, error: null}),
        [clearAddBankError]: state => ({...state, addBankError: null}),
        [clearClientBanksError]: state => ({...state, clientBanksError: null}),
        [clearCheckExistenceError]: state => ({...state, checkExistenceError: null}),
        [resetState]: () => initialState,
        [resetAddBankState]: state => ({
            ...state, addBankError: null, isAddBankLoading: false, bankSignIn: {},
        }),
    },
    initialState,
);

