import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cn from 'classnames';
import moment from 'moment';

import {accountShape, clientShape} from 'store/data/client';
import {assetShape} from 'store/data/assets';

import Moire from 'components/moire/moire';
import Select from 'components/select/select';
import ErrorPanel from 'components/panel/panel';
import Spinner from 'components/loading-spinner/loading-spinner';

import {transactionsTypes, typesWithCustodySetup, typesWithPriceSetup, CURRENCY_LIST} from './transactions-types';

import DatePicker from '../../../components/date-picker';

const typesAsOptions = transactionsTypes.map(t => ({value: t, label: _.startCase(t)}));
const CURRENCY_OPTIONS = CURRENCY_LIST.map(currency => ({value: currency, label: currency}));
const getAccountsAsOptions = accounts => accounts.map(a => ({value: a.id, label: a.name}));
const getAssetsAsOptions = assets => assets.map(a => ({value: a.id, label: a.name}));
const getCustodyAccounts = accounts => accounts.filter(a => a.type === 'deposit');
const isTypeWithCustodyAccount = transactionType => typesWithCustodySetup.includes(transactionType);
const isTypeWithPrice = transactionType => typesWithPriceSetup.includes(transactionType);

export default class TransactionEditDialog extends PureComponent {
    static propTypes = {
        accounts: PropTypes.arrayOf(accountShape).isRequired,
        actionButtonTitle: PropTypes.string,
        assets: PropTypes.arrayOf(assetShape).isRequired,
        client: clientShape,
        error: PropTypes.shape({}),
        fetchAccounts: PropTypes.func.isRequired,
        fetchAssets: PropTypes.func.isRequired,
        loading: PropTypes.bool.isRequired,
        onClearError: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
        onSetTransaction: PropTypes.func.isRequired,
        title: PropTypes.string,
        transaction: PropTypes.shape({
            type: PropTypes.string,
            custodyAccountId: PropTypes.string,
            currency: PropTypes.string,
            accountId: PropTypes.string,
            assetId: PropTypes.string,
            originalValue: PropTypes.number,
            price: PropTypes.number,
            message: PropTypes.string,
            originalId: PropTypes.string,
            tradeDate: PropTypes.string,
            settlementDate: PropTypes.string,
        }),
    };

    static defaultProps = {
        actionButtonTitle: 'Set Details',
        title: 'Edit',
    };

    constructor(props) {
        super(props);
        const {
            type, custodyAccountId, currency, accountId, assetId, originalValue, price, message, tradeDate,
            settlementDate,
        } = props.transaction || {};
        this.state = {
            transactionType: transactionsTypes.includes(type) ? type : null,
            custodyAccountId,
            currency: CURRENCY_LIST.includes(currency) ? currency : null,
            accountId,
            assetId,
            originalValue,
            price,
            message,
            tradeDate: tradeDate ? moment.utc(tradeDate) : null,
            settlementDate: settlementDate ? moment.utc(settlementDate) : null,
            validationError: undefined,
            assets: this._addNoAssetAsset(props.assets),
        };
    }

    componentDidMount() {
        const {client} = this.props;

        this.props.fetchAccounts({id: client.id});
        this.props.fetchAssets({perPage: 999999}); // 999999 to fetch all assets
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.assets !== nextProps.assets) {
            this.setState({assets: this._addNoAssetAsset(nextProps.assets)});
        }
    }

    onChangeTransactionType = transactionType => this.setState((state) => {
        const custodyAccountId = isTypeWithCustodyAccount(transactionType) ? state.custodyAccountId : null;
        const price = isTypeWithPrice(transactionType) ? state.price : null;
        return {transactionType, custodyAccountId, price};
    });

    onChangeCurrency = currency => this.setState({currency});

    onChangeAccount = accountId => this.setState({accountId});

    onChangeAsset = assetId => this.setState({assetId});

    onChangeValue = ({target: {value}}) => this.setState({originalValue: this._parseNumber(value)});

    onChangePrice = ({target: {value}}) => this.setState({price: this._parseNumber(value)});

    onChangeCustodyAccount = custodyAccountId => this.setState({custodyAccountId});

    onMessageChange = ({target: {value: message}}) => this.setState({message});

    onTradeDateChange = tradeDate => {
        this.setState({tradeDate: this._convertToUtcIfNotAlready(tradeDate) || null});
    };

    onSettlementDateChange = settlementDate => {
        this.setState({settlementDate: this._convertToUtcIfNotAlready(settlementDate) || null});
    };

    onClearValidationError = () => this.setState({validationError: undefined});

    onSetTransaction = () => {
        const {
            transactionType,
            custodyAccountId,
            currency,
            accountId,
            assetId,
            originalValue,
            price,
            message,
            tradeDate,
            settlementDate,
        } = this.state;
        const {onSetTransaction} = this.props;

        if (!this._validate()) {
            return;
        }

        onSetTransaction({
            transactionType,
            custodyAccountId,
            currency,
            accountId,
            assetId,
            originalValue,
            price,
            message,
            tradeDate: tradeDate.toISOString(),
            settlementDate: settlementDate.toISOString(),
        });
    };

    _validate = () => {
        const {
            transactionType, custodyAccountId, accountId, price, originalValue, assetId, tradeDate, settlementDate,
        } = this.state;

        if (_.isNil(accountId)) {
            this.setState({validationError: {message: 'Please select Account'}});
            return false;
        }

        if (_.isNil(assetId)) {
            this.setState({validationError: {message: 'Please select Asset'}});
            return false;
        }

        if (isTypeWithPrice(transactionType) && _.isNil(price)) {
            this.setState({validationError: {message: 'Please specify Price'}});
            return false;
        }

        if (_.isNil(originalValue)) {
            this.setState({validationError: {message: 'Please specify Value'}});
            return false;
        }

        if (isTypeWithCustodyAccount(transactionType) && !custodyAccountId) {
            this.setState({validationError: {message: 'Please select Custody Account'}});
            return false;
        }

        if (_.isNil(tradeDate)) {
            this.setState({validationError: {message: 'Please select Trade Date'}});
            return false;
        }

        if (_.isNil(settlementDate)) {
            this.setState({validationError: {message: 'Please select Settlement Date'}});
            return false;
        }

        this.onClearValidationError();

        return true;
    };

    /**
     * Adds an artificial "No Asset" asset. If it is already there, moving it to the beginning of the list.
     * @param {*[]} assets
     * @return {*[]}
     * @private
     */
    _addNoAssetAsset = (assets) => [
        {id: '00000000-0000-0000-0000-000000000000', name: 'No Asset'},
        ...assets.filter(({id}) => id !== '00000000-0000-0000-0000-000000000000'),
    ];

    /**
     * @param {string} value
     * @private
     */
    _parseNumber = (value) => {
        if (!value.trim()) {
            return null;
        }

        return parseFloat(value);
    };

    _convertToUtcIfNotAlready = (date) => {
        const parseDate = moment(date);
        if (!parseDate.isValid()) {
            return null;
        }

        return parseDate.utcOffset() !== 0
            ? parseDate.add(parseDate.utcOffset(), 'minutes').utc()
            : parseDate;
    };

    render() {
        const {onClose, onClearError, error, accounts, loading, actionButtonTitle, title} = this.props;
        const {
            transactionType, custodyAccountId, currency, accountId, assetId, originalValue, price, message,
            tradeDate, settlementDate, validationError, assets,
        } = this.state;
        const custodyAccounts = getCustodyAccounts(accounts);
        const custodyAccountsAsOptions = getAccountsAsOptions(custodyAccounts);
        const allAccountsAsOptions = getAccountsAsOptions(accounts);
        const assetsAsOptions = getAssetsAsOptions(assets);
        const showPrice = isTypeWithPrice(transactionType);

        return (
            <Moire
                onClose={onClose}
                onCancel={onClose}
            >
                <div className="prv-client-transaction-edit-dialog">
                    {loading && <Spinner/>}
                    {validationError &&
                    <ErrorPanel
                        className="prv-client-transaction-edit-dialog__error-panel"
                        error={validationError}
                        onClear={this.onClearValidationError}
                    />}
                    {!validationError && error &&
                    <ErrorPanel
                        className="prv-client-transaction-edit-dialog__error-panel"
                        error={error}
                        onClear={onClearError}
                    />}
                    <div className="prv-client-transaction-edit-dialog__header">
                        <span>{title}</span>
                        <div className="prv-client-transaction-edit-dialog__close-icon" onClick={onClose}/>
                    </div>
                    <div className="prv-client-transaction-edit-dialog__content">
                        <span className="prv-client-transaction-edit-dialog__label">Account</span>
                        <Select
                            className="prv-client-transaction-edit-dialog__select-field"
                            clearable={false}
                            onChange={this.onChangeAccount}
                            options={allAccountsAsOptions}
                            placeholder="Select Account"
                            value={accountId}
                        />
                        <span className="prv-client-transaction-edit-dialog__label">Asset</span>
                        <Select
                            className="prv-client-transaction-edit-dialog__select-field"
                            clearable={false}
                            onChange={this.onChangeAsset}
                            options={assetsAsOptions}
                            placeholder="Select Asset"
                            value={assetId}
                        />
                        <div className="prv-client-transaction-edit-dialog__input-row">
                            {showPrice &&
                            <div>
                                <span className="prv-client-transaction-edit-dialog__label">Price</span>
                                <input
                                    className="prv-client-transaction-edit-dialog__money-field"
                                    name="price"
                                    onChange={this.onChangePrice}
                                    placeholder="Price"
                                    type="number"
                                    value={price === 0 ? 0 : price || ''}
                                />
                            </div>
                            }
                            <div>
                                <span className="prv-client-transaction-edit-dialog__label">Value</span>
                                <input
                                    className="prv-client-transaction-edit-dialog__money-field"
                                    name="value"
                                    onChange={this.onChangeValue}
                                    placeholder="Value"
                                    type="number"
                                    value={originalValue === 0 ? 0 : originalValue || ''}
                                />
                            </div>
                            <div>
                                <span className="prv-client-transaction-edit-dialog__label">Currency</span>
                                <Select
                                    className="prv-client-transaction-edit-dialog__select-field"
                                    clearable
                                    onChange={this.onChangeCurrency}
                                    options={CURRENCY_OPTIONS}
                                    placeholder="Select Currency"
                                    value={currency}
                                />
                            </div>
                        </div>
                        <span className="prv-client-transaction-edit-dialog__label">Type</span>
                        <Select
                            className="prv-client-transaction-edit-dialog__select-field"
                            clearable={false}
                            onChange={this.onChangeTransactionType}
                            options={typesAsOptions}
                            placeholder="Select Transaction Type"
                            value={transactionType}
                        />
                        {
                            isTypeWithCustodyAccount(transactionType) &&
                            <div>
                                <span className="prv-client-transaction-edit-dialog__label">Custody Account</span>
                                <Select
                                    className="prv-client-transaction-edit-dialog__select-field"
                                    clearable={false}
                                    onChange={this.onChangeCustodyAccount}
                                    options={custodyAccountsAsOptions}
                                    placeholder="Select custody account"
                                    value={custodyAccountId}
                                />
                            </div>
                        }
                        <span className="prv-client-transaction-edit-dialog__label">Message</span>
                        <textarea
                            className="prv-client-transaction-edit-dialog__message-field"
                            onChange={this.onMessageChange}
                            placeholder="Message"
                            value={message || ''}
                        />

                        <div
                            className={cn(
                                'prv-client-transaction-edit-dialog__input-row',
                                'prv-client-transaction-edit-dialog__input-row--dates-row',
                            )}
                        >
                            <div>
                                <span className="prv-client-transaction-edit-dialog__label">Trade Date</span>
                                <DatePicker
                                    date={tradeDate}
                                    onChange={this.onTradeDateChange}
                                />
                            </div>
                            <div>
                                <span className="prv-client-transaction-edit-dialog__label">Settlement Date</span>
                                <DatePicker
                                    date={settlementDate}
                                    onChange={this.onSettlementDateChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="prv-client-transaction-edit-dialog__footer">
                        <button
                            className="prv-client-transaction-edit-dialog__save-button"
                            disabled={!transactionType}
                            onClick={this.onSetTransaction}
                        >
                            {actionButtonTitle}
                        </button>
                    </div>
                </div>
            </Moire>
        );
    }
}
