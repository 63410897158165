import React, {PureComponent} from 'react';
import cn from 'classnames';
import {NavLink} from 'react-router-dom';
import FontIcon from 'react-md/lib/FontIcons/FontIcon';
import PropTypes from 'prop-types';

export default class SidebarMenuItem extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
        icon: PropTypes.string.isRequired,
        onCreate: PropTypes.func,
        onCreateNewFlow: PropTypes.func,
        title: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
    };

    render() {
        const {className, icon, onCreate, onCreateNewFlow, title, url} = this.props;

        return (
            <li className={cn('prv-sidebar-menu-item-container', className)}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <NavLink to={url} className="prv-sidebar-menu-item" activeClassName="prv-sidebar-menu-item--active">
                    <span className="prv-sidebar-menu-item__title">
                        <FontIcon
                            className="prv-sidebar-menu-item__icon"
                            inherit
                        >
                            {icon}
                        </FontIcon>
                        {title}
                    </span>
                    {onCreate &&
                        <FontIcon
                            className="prv-sidebar-menu-item__create-button"
                            onClick={onCreate}
                        >
                            add_circle_outline
                        </FontIcon>
                    }
                    {onCreateNewFlow &&
                        <FontIcon
                            className="prv-sidebar-menu-item__create-button"
                            onClick={onCreateNewFlow}
                        >
                            offline_bolt
                        </FontIcon>
                    }
                </NavLink>
            </li>
        );
    }
}
