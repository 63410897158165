import React, {Component} from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Button from 'react-md/lib/Buttons/Button';
import SelectField from 'react-md/lib/SelectFields/SelectField';

import {connect} from 'store';

import {money} from 'helpers/formatters';

import {
    fetchAccounts,
    fetchAssetsManagers,
    fetchCustomerPortfolios,
    deleteAccount,
    updateAccount,
    clearError,
    clearUpdateAccountError,
    resetState,
    recalculateAccount,
} from 'store/accounts/actions';

import {getClientBanks, getPinnedClient} from 'store/clients';
import {
    getAccounts, getAssetsManagers, getPortfolios, isLoading,
    getError, getAccountUpdateError, isAccountUpdating,
} from 'store/accounts';
import {
    accountShape,
    accountTypes,
    assetsManagerShape,
    bankShape,
    clientShape,
    portfolioShape,
} from 'store/data/client';

import Table from 'components/form-table';
import {isCustomer} from '../../../store/auth';
import AddAccountDialog from '../add-account-dialog/add-account-dialog';
import EditAccountDialog from '../edit-account-dialog/edit-account-dialog';
import AccountListHeader from './account-list-header';
import ActionViewOptions from '../../../components/resource-table/action-view-options';
import MessageBox from '../../../components/dialogs/message-box';
import {setClient, updateBankDescription} from '../../../store/new-client/actions';
import AddBankDialog from '../../new-client/add-bank-dialog';
import {showToast} from '../../../store/snackbar/actions';
import {fetchClientBanks} from '../../../store/clients/actions';
import BankColumn from './components/BankColumn';
import * as roles from '../../../config/roles';
import HasRole from '../../../components/has-role/has-role';

const yes = () => true;
const no = () => false;

const VALUE_CHANGE_TYPE_OPTIONS = {
    totalValueChange: 'All',
    totalValueChangeForDay: 'Daily',
    totalValueChangeForMonth: 'MtD',
    totalValueChangeForYear: 'YtD',
};

class ClientAccount extends Component {
    static propTypes = {
        isCustomer: PropTypes.bool,

        accounts: PropTypes.arrayOf(accountShape),
        assetsManagers: PropTypes.arrayOf(assetsManagerShape),
        portfolios: PropTypes.arrayOf(portfolioShape),
        client: clientShape,
        clientBanks: PropTypes.arrayOf(bankShape), // eslint-disable-line react/no-unused-prop-types
        loading: PropTypes.bool,
        fetchAccounts: PropTypes.func.isRequired,
        fetchAssetsManagers: PropTypes.func.isRequired,
        fetchCustomerPortfolios: PropTypes.func.isRequired,
        deleteAccount: PropTypes.func.isRequired,

        updateAccount: PropTypes.func.isRequired,
        accountUpdating: PropTypes.bool.isRequired,
        accountUpdateError: PropTypes.object,

        error: PropTypes.object,
        clearError: PropTypes.func.isRequired,
        clearUpdateAccountError: PropTypes.func.isRequired,
        resetState: PropTypes.func.isRequired,
        recalculateAccount: PropTypes.func.isRequired,

        fetchClientBanks: PropTypes.func.isRequired,
        setClient: PropTypes.func.isRequired,
        showToast: PropTypes.func.isRequired,
        updateBankDescription: PropTypes.func.isRequired,
    };

    static getDerivedStateFromProps(props) {
        return {bankMap: new Map(props.clientBanks.map(bank => [bank.id, bank]))};
    }

    constructor(props) {
        super(props);
        this.state = {
            shouldRenderAccountDialog: false,
            setAccountDialog: null,
            recalculationDialog: null,
            valueChangeType: 'totalValueChange',
            bankMap: new Map(),
            showAddBankDialog: false,
            bankBeingEdited: undefined,
        };
    }

    componentDidMount() {
        this.onRefresh();
    }

    componentDidUpdate() {
        this.props.setClient(this.props.client);
    }

    componentWillUnmount() {
        this.props.resetState();
    }

    onDelete = ({id}) => this.props.deleteAccount({clientId: this.props.client.id, id});

    onFilter = items => this.setState({filteredItemsCount: items && items.length});

    onAddAccount = () => {
        this.setState({shouldRenderAccountDialog: true});
    };

    onSetAccount = (params) => {
        const {setAccountDialog} = this.state;
        const {client} = this.props;
        const {
            accountName,
            selectedAMs,
            selectedCustomAM,
            cashAccountId,
            proveoId,
            benchmarks,
            limits,
            selectedPortfolio,
            selectedCustomPortfolio,
            endDate,
        } = params;
        this.props.updateAccount({
            id: setAccountDialog.id,
            name: accountName,
            clientId: client.id,
            cashAccountId,
            proveoId,
            assetsManagerId: (selectedAMs && selectedAMs.length) ? selectedAMs[0].id : null,
            customAssetsManagerName: selectedCustomAM,
            portfolioId: selectedPortfolio ? selectedPortfolio.id : null,
            customPortfolioName: selectedCustomPortfolio,
            benchmarks: _.map(benchmarks, ({id, weight, startDate, deleted}) => ({id, weight, startDate, deleted})),
            limits: _.map(limits, limit => ({
                typeId: limit.id,
                value: limit.value,
            })),
            endDate,
        })
            .then(() => {
                this.onRefresh();
                this.closeSetAccountDialog();
            });
    };

    onRefresh = () => {
        this.props.fetchAccounts({id: this.props.client.id, limits: true, benchmarks: true});
        this.props.fetchAssetsManagers();
        this.props.fetchCustomerPortfolios({id: this.props.client.id});
        this.props.clearError();
    };

    onSuccessImport = () => {
        this.setState({notification:
                'Import succeeded. Server handle importing accounts from bank, this can take a few minutes'});
        this.onRefresh();
    };

    onClearNotification = () => {
        this.setState({notification: null});
    };

    onRecalculate = async (item) => {
        const {client, recalculateAccount} = this.props;
        try {
            await recalculateAccount({clientId: client.id, accountId: item.id});
            this.setState({recalculationDialog:
                    `Recalculation has started for Account "${item.name}".\nIt will take some time.`});
        } catch (e) {
            this.setState({recalculationDialog:
                    `Failed to start recalculation for Account "${item.name}".\nPlease try again later.`});
        }
    };

    getColumns = () => {
        const {accounts} = this.props;

        const columns = [
            {
                field: 'bank',
                label: 'Bank',
                width: '130px',
                render: this.renderBank,
                className: 'prv-client-account__bank-cell',
            },
            {field: 'name', label: 'Account Name', width: '147px', render: this.renderName},
            {field: 'assetsManagerName', label: 'Asset manager', width: '138px'},
            {field: 'type', label: 'Type', width: '84px', render: this.renderType},
            {field: 'portfolioName', label: 'Portfolio', width: '124px'},
            {field: 'originalId', label: 'OrigID', width: '60px'},
            {field: 'value', label: 'Value', width: '100px', render: money},
            {
                field: 'valueChange',
                label: 'Value Change',
                width: '100px',
                render: (value, row) => this.renderValueChange(row[this.state.valueChangeType]),
            },
        ];

        if (accounts.some(acc => acc.importStatus === 'initializing')) {
            columns.push({field: 'importStatus', label: 'Status', width: '54px', render: v => _.startCase(v)});
        }

        return columns;
    };

    getFiltersConfig = () => {
        const {assetsManagers} = this.props;

        const options = {'00000000-0000-0000-0000-000000000000': 'Own Account'};
        assetsManagers.reduce(
            (prev, cur) => {
                const tmp = prev;
                tmp[cur.id] = cur.name;
                return tmp;
            }, options,
        );

        return {
            assetsManagerId: {
                title: 'Asset Manager',
                options,
            },
        };
    };

    _handleHideRecalculationDialog = () => this.setState({recalculationDialog: undefined});

    hideAccountDialog = () => this.setState({shouldRenderAccountDialog: false});

    showSetAccountDialog = account => this.setState({setAccountDialog: account});
    closeSetAccountDialog = () => this.setState({setAccountDialog: null});

    _handleValueChangeTypeChange = (valueChangeType) => {
        this.setState({valueChangeType});
    };

    _handleAddBankClick = () => this.setState({showAddBankDialog: true});

    _handleCloseAddBankDialog = () => this.setState({showAddBankDialog: false});

    _handleAddBankSuccess = () => {
        this.setState({showAddBankDialog: false});
        this.props.showToast({text: 'Bank added successfully.'});
        this.props.fetchClientBanks({id: this.props.client.id});
    };

    _handleUpdateCredentialsClick = (bank) => this.setState({bankBeingEdited: bank});
    _handleBankCredentialsEditingCancel = () => this.setState({bankBeingEdited: undefined});
    _handleBankCredentialsEditingSuccess = () => {
        this.setState({bankBeingEdited: undefined});
        this.props.showToast({text: 'Bank credentials updated successfully.'});
        this.props.fetchClientBanks({id: this.props.client.id});
        this.onRefresh();
    };

    _handleUpdateBankDescription = async ({id, description}) => {
        const {client, fetchClientBanks, showToast, updateBankDescription} = this.props;
        try {
            await updateBankDescription({bankUserId: id, description});
            showToast({text: 'Description updated'});
            fetchClientBanks({id: client.id});
        } catch (e) {
            showToast({text: 'Unexpected error happened, please try again later'});
        }
    }

    renderBank = (value, row) => {
        const {isCustomer} = this.props;
        const bank = this.state.bankMap.get(row.bankUserId);
        return (
            <BankColumn
                bank={bank}
                bankName={value}
                canEditDescription={!isCustomer}
                id={`bank-column-${row.id}`}
                onUpdateCredentialsClick={this._handleUpdateCredentialsClick}
                onUpdateDescription={this._handleUpdateBankDescription}
            />
        );
    };

    renderValueChange = (v) => {
        let color;
        if (v > 0) color = '#34A793';
        if (v === 0) color = '#E5AE60';
        if (v < 0) color = '#DD796B';
        return (<span style={{color}}>{money(v)}</span>);
    };

    renderName = (value, row) => (
        <div>
            <div>{value}</div>
            {row.lastSync && (
                <div className="prv-client-account__last-sync-date">
                    Last Sync: {moment(row.lastSync).format('LLL')}
                </div>
            )}
        </div>
    );

    renderType = v => accountTypes[v];

    renderCreationButton = () => (
        <div>
            <Button
                disabled={this.props.loading}
                flat
                iconChildren="add_circle"
                id="accounts-add-bank-button"
                onClick={this._handleAddBankClick}
                secondary
            >
                Add Bank
            </Button>
            <HasRole allowedRoles={[roles.SA, roles.RM]}>
                <Button
                    disabled={this.props.loading}
                    flat
                    iconChildren="add_circle"
                    id="accounts-add-account-button"
                    onClick={this.onAddAccount}
                    secondary
                >
                    Add Account
                </Button>
            </HasRole>
        </div>
    );

    renderTitle = () => (
        <AccountListHeader
            filteredItemCount={this.state.filteredItemsCount}
            totalItemCount={(this.props.accounts && this.props.accounts.length) || 0}
        />
    );

    render() {
        const {
            loading,
            error,
            assetsManagers,
            accountUpdating,
            accountUpdateError,
            isCustomer,
            portfolios,
            accounts,
        } = this.props;
        const {
            shouldRenderAccountDialog,
            notification,
            setAccountDialog,
            valueChangeType,
            recalculationDialog,
            showAddBankDialog,
            bankBeingEdited,
        } = this.state;
        const selectedPortfolio = setAccountDialog
            ? portfolios.find(portfolio => portfolio.id === setAccountDialog.portfolioId)
            : null;

        return (
            <div>
                <Table
                    actionView={ActionViewOptions.Icons}
                    className="prv-client-account__list"
                    title={this.renderTitle()}
                    items={accounts}
                    columns={this.getColumns()}
                    displayOptions={[
                        <SelectField
                            className="prv-subheader-filters__option"
                            id="filter-valueChangeType"
                            key="filter-valueChangeType"
                            label="Value Change"
                            menuItems={Object.keys(VALUE_CHANGE_TYPE_OPTIONS)
                                .map(opt => ({value: opt, label: VALUE_CHANGE_TYPE_OPTIONS[opt]}))}
                            onChange={this._handleValueChangeTypeChange}
                            simplifiedMenu={false}
                            value={valueChangeType}
                        />,
                    ]}
                    filters={this.getFiltersConfig()}
                    canDelete={isCustomer ? no : yes}
                    onDelete={this.onDelete}
                    canRecalculate={isCustomer ? no : yes}
                    onRecalculate={this.onRecalculate}
                    canEdit={item => item.type !== 'unknown' && !isCustomer}
                    onEdit={this.showSetAccountDialog}
                    onFilter={this.onFilter}
                    actionButton={this.renderCreationButton()}
                    loading={loading}
                    refresh={this.onRefresh}
                    notification={notification}
                    onClearNotification={this.onClearNotification}
                    error={error}
                    onClearError={this.props.clearError}
                />
                {showAddBankDialog && (
                    <AddBankDialog
                        hideDescription={isCustomer}
                        onCancel={this._handleCloseAddBankDialog}
                        onAddBankSuccess={this._handleAddBankSuccess}
                    />
                )}
                {bankBeingEdited && (
                    <AddBankDialog
                        hideDescription={isCustomer}
                        onCancel={this._handleBankCredentialsEditingCancel}
                        onAddBankSuccess={this._handleBankCredentialsEditingSuccess}
                        selectedBank={bankBeingEdited}
                    />
                )}
                {
                    shouldRenderAccountDialog &&
                    <AddAccountDialog
                        onCancel={this.hideAccountDialog}
                        onSuccess={this.onSuccessImport}
                    />
                }
                {
                    setAccountDialog &&
                    <EditAccountDialog
                        account={setAccountDialog}
                        assetsManagers={assetsManagers}
                        benchmarks={setAccountDialog.benchmarks || []}
                        error={accountUpdateError}
                        portfolios={portfolios || []}
                        limits={setAccountDialog.limits || []}
                        loading={accountUpdating}
                        onClearError={this.props.clearUpdateAccountError}
                        onClose={this.closeSetAccountDialog}
                        onSetAccount={this.onSetAccount}
                        selectedAMs={[{
                            id: setAccountDialog.assetsManagerId,
                            name: setAccountDialog.assetsManagerName,
                        }]}
                        selectedPortfolio={selectedPortfolio}
                        shouldBeRendered={{
                            accountName: true,
                            accountType: true,
                            accountEndDate: true,
                            accountCurrency: true,
                            cashAccount: true,
                            ams: true,
                            portfolio: true,
                            proveoId: true,
                            benchmarks: true,
                            limits: true,
                        }}
                    />
                }
                {recalculationDialog &&
                <MessageBox
                    onHide={this._handleHideRecalculationDialog}
                    title="Recalculation"
                    text={<p>{recalculationDialog}</p>}
                    visible={!!recalculationDialog}
                />}
            </div>
        );
    }
}

export default connect(
    {
        accounts: getAccounts,
        accountUpdateError: getAccountUpdateError,
        accountUpdating: isAccountUpdating,
        assetsManagers: getAssetsManagers,
        portfolios: getPortfolios,
        client: getPinnedClient,
        clientBanks: getClientBanks,
        error: getError,
        loading: isLoading,
        isCustomer,
    },
    {
        fetchAccounts,
        fetchAssetsManagers,
        fetchClientBanks,
        fetchCustomerPortfolios,
        deleteAccount,
        updateAccount,
        clearError,
        clearUpdateAccountError,
        resetState,
        recalculateAccount,
        setClient,
        showToast,
        updateBankDescription,
    },
)(ClientAccount);
