import {apiActionHandler, handleActions} from 'store/helpers/actions';
import {actionHandlers} from 'store/collections/reducer_helpers';
import {readNotification} from 'store/data/notification';

import {
    createNotification,
    clearCreateNotificationError,
} from './actions';

export {
    getCollectionState,
    getFetchParams,
} from 'store/collections/reducer_helpers';

const initialState = {
    isCreatingNotification: false,
    createNotificationError: null,
};

export const isCreatingNotification = ({creatingNotification}) => creatingNotification;

export const getCreateNotificationError = ({createNotificationError}) => createNotificationError;

export default handleActions({
    [createNotification]: (state, action) => apiActionHandler(state, action, {
        loading: 'creatingNotification', error: 'createNotificationError',
    }),
    [clearCreateNotificationError]: state => ({...state, createNotificationError: null}),
    ...actionHandlers('notifications', readNotification),
}, initialState);
