import {
    callApi,
    fromStore,
    dispatch,
    apiCaller,
} from 'store/helpers/sagas';

import {createApiCallResultAction} from 'store/helpers/actions';
import {actionDispatchers} from '../collections/saga_helpers';

import {getFetchParams} from './index';

import {
    fetchTransactions,
    pinTransactionById,
} from './actions';

const transactionsFetched = createApiCallResultAction(fetchTransactions);

function* fetcher({payload}) {
    const params = yield fromStore(getFetchParams, payload);

    try {
        const result = yield callApi('fetchTransactions', {...params});
        yield dispatch(transactionsFetched(result));
    } catch (error) {
        yield dispatch(transactionsFetched(error));
    }
}

export default {
    [fetchTransactions]: fetcher,
    [pinTransactionById]: apiCaller('fetchTransactionById', createApiCallResultAction(pinTransactionById)),
    ...actionDispatchers('transactions'),
};
