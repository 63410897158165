import invariant from 'invariant';
import {Action, ActionCreatorsMapObject, bindActionCreators, Dispatch} from 'redux';
import {
    connect as reduxConnect,
    DefaultRootState,
    DispatchProp,
    InferableComponentEnhancerWithProps,
    MapDispatchToPropsParam,
    MapStateToPropsParam,
    Options,
    ResolveThunks,
} from 'react-redux';

export type GetterType<TState> = (state: TState, ...args: any[]) => any;

export function bindProps<TStateProps, TOwnProps, State = DefaultRootState>(
    props: {[key: string]: GetterType<State>},
): MapStateToPropsParam<TStateProps, TOwnProps, State> {
    invariant(typeof props === 'object', '[connect]: getters should be an object');
    return function bindStateToProps(state: State, ownProps: TOwnProps): TStateProps {
        const boundProps: {[key: string]: GetterType<State>} = {};
        Object.keys(props).forEach((prop: string) => {
            const getter = props[prop];
            invariant(typeof getter === 'function', `[connect]: getter '${prop}' should be function`);
            boundProps[prop] = getter.length > 1
                ? (arg: any, ...args: any[]) => getter(state, arg, ...args)
                : getter(state);
        });
        return boundProps as unknown as TStateProps;
    };
}

export function bindActions<A, TDispatchProps, TOwnProps>(
    actions: ActionCreatorsMapObject<A>,
): MapDispatchToPropsParam<TDispatchProps, TOwnProps> {
    invariant(typeof actions === 'object', '[connect]: actions should be an object');
    return function bindActionsToDispatch(dispatch: Dispatch<Action>, ownProps: TOwnProps): TDispatchProps {
        return bindActionCreators(actions, dispatch) as unknown as TDispatchProps;
    };
}

// eslint-disable-next-line max-len
export function connect<TStateProps = {}, TDispatchProps = {}, TOwnProps = {}, TMergedProps = {}, State = DefaultRootState>(
    props: {[key: string]: GetterType<State>},
    actions?: ActionCreatorsMapObject,
    options?: Options<State, TStateProps, TOwnProps>,
): InferableComponentEnhancerWithProps<TStateProps & ResolveThunks<TDispatchProps>, TOwnProps>
    | InferableComponentEnhancerWithProps<TStateProps & DispatchProp, TOwnProps> {
    if (options) {
        return reduxConnect(
            bindProps(props), actions && bindActions(actions), undefined, options,
        );
    }

    return reduxConnect(bindProps(props), actions && bindActions(actions));
}

export function connectActions<TStateProps = {}, TDispatchProps = {}, TOwnProps = {}>(
    actions: ActionCreatorsMapObject,
    options: Options<{}, TStateProps, TOwnProps>,
): InferableComponentEnhancerWithProps<TDispatchProps, TOwnProps> {
    return reduxConnect(null, bindActions(actions), undefined, options);
}
