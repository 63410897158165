import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import Header from 'components/layout/header/header';
import Snackbar from 'components/snackbar/snackbar';
import Sidebar from './sidebar/sidebar';

class Layout extends PureComponent {
    static propTypes = {
        children: PropTypes.node,
    };

    render() {
        return (
            <div className="prv-layout">
                <Header className="prv-layout__header"/>
                <div className="prv-layout__page">
                    <Sidebar
                        className="prv-layout__sidebar"
                    />
                    <div className="prv-layout__content">
                        {this.props.children}
                    </div>
                </div>
                <Snackbar/>
            </div>
        );
    }
}

export default Layout;
