import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {reduxForm, handleSubmitError} from 'components/forms';

import Moire from 'components/moire/moire';
import Spinner from 'components/loading-spinner/loading-spinner';
import Select from 'components/select/select';
import styles from './rm-dialog.module.css';

@reduxForm({form: 'dialog'})
export default class Dialog extends Component {
    static propTypes = {
        message: PropTypes.string,
        itemToDelete: PropTypes.object,
        items: PropTypes.arrayOf(PropTypes.object),
        selectPlaceholder: PropTypes.string,
        loading: PropTypes.bool,
        onSubmit: PropTypes.func,
        onCancel: PropTypes.func.isRequired,
        renderItem: PropTypes.func,
        renderSubmitButton: PropTypes.func,
        selectOptionsMapper: PropTypes.func.isRequired,

        handleSubmit: PropTypes.func.isRequired,
        submitting: PropTypes.bool,
    };

    constructor(props) {
        super(props);
        this.state = {selectedItem: void 0};
    }

    onSubmit = (event) => {
        event.preventDefault();
        const {onSubmit, handleSubmit} = this.props;
        const {selectedItem} = this.state;

        const submitter = () => {
            const promise = onSubmit({assignTo: selectedItem});
            if (promise && typeof promise.then === 'function') {
                return handleSubmitError(promise);
            }
            return null;
        };
        return handleSubmit(submitter)(event);
    };

    onSelectItem = selectedItem => this.setState({selectedItem});

    render() {
        const {
            onCancel, submitting, onSubmit, selectOptionsMapper, itemToDelete,
            loading, items, message, renderItem, selectPlaceholder, renderSubmitButton,
        } = this.props;

        const {selectedItem} = this.state;

        const buttonProps = {onClick: this.onSubmit, disabled: !selectedItem};

        return (
            <Moire onCancel={this.props.onCancel}>
                {(submitting || loading) && <Spinner/>}
                <div className={styles.container}>
                    <div className={styles.message}>{message || 'Select item to reassign'}</div>
                    <div className={styles.rmContentWrapper}>
                        <div className={styles.deletedRm}>
                            {renderItem ? renderItem(itemToDelete) : selectedItem}
                        </div>
                        <div className={styles.arrowIcon}/>
                        <div className={styles.selectRm}>
                            <Select
                                placeholder={selectPlaceholder || 'Select item to reassign'}
                                onChange={this.onSelectItem}
                                value={selectedItem || ''}
                                options={items.filter(item => item.id !== itemToDelete.id)
                                    .map(selectOptionsMapper)}
                                clearable={false}
                            />
                        </div>
                    </div>

                    <div className={styles.actions}>
                        {
                            onCancel &&
                            <button className={styles.cancelButton} onClick={onCancel}>Cancel</button>
                        }
                        {
                            onSubmit && renderSubmitButton ?
                                renderSubmitButton(buttonProps) :
                                <DeleteButton {...buttonProps} />
                        }
                    </div>
                </div>
            </Moire>
        );
    }
}

export const DeleteButton = props => (
    <button className={styles.deleteButton} {...props}>Delete</button>
);

export const BlockButton = props => (
    <button className={styles.blockButton} {...props}>Block</button>
);
