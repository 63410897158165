import buildActions from '../collections/actions_builder';
import {createApiCallAction, createAction} from '../helpers/actions';

const collection = buildActions('transactions');

export const fetchTransactions = collection.fetchItems;
export const pinTransactionById = collection.pinItemById;

export const updateTransaction = createApiCallAction('transactions/UPDATE_TRANSACTION', 'updateTransaction');
export const deleteTransaction = createApiCallAction('transactions/DELETE_TRANSACTION', 'deleteTransaction');
export const clearUpdateTransactionError = createAction('transactions/CLEAR_UPDATE_TRANSACTION_ERROR');
