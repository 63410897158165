import {handleActions} from 'redux-actions';
import {TOKEN_KEY} from 'config/api.config';
import * as pathnames from 'config/pathnames';

import {internalSetupApi, apiAuthError} from './actions';

export const haveToken = (api) => {
    const token = api && api.getAuthToken();
    return !!(token && token.length);
};

export const onApiAuthError = () => {
    sessionStorage.removeItem(TOKEN_KEY);
    window.location = `${window.location.origin}${pathnames.LOGIN}`;
};

export default handleActions({
    [internalSetupApi]: (_, {payload}) => (payload),
    [apiAuthError]: onApiAuthError,
}, {});
