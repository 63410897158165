import React from 'react';
import cn from 'classnames';
import moment from 'moment';
import Button from 'react-md/lib/Buttons/Button';
import PropTypes, {string} from 'prop-types';
import {Dialog} from '../../../components/dialogs';
import {showToast} from '../../../store/snackbar/actions';
import {connectActions} from '../../../store';
import {refreshPinnedClient} from '../../../store/clients/actions';
import {PowerBiReportDialog} from '../../../components/dialogs/power-bi-report-dialog/power-bi-report-dialog';
import {SyncStatus} from '../../../config/sync';

const STATUS = {
    BLOCKED: 'blocked',
};

const SYNC_RECHECK_TIMEOUT = 5000;

class ActionsBar extends React.Component {
    static propTypes = {
        client: PropTypes.object.isRequired,
        redirect: PropTypes.func.isRequired,
        refresh: PropTypes.func.isRequired,
        deleteClient: PropTypes.func.isRequired,
        blockClient: PropTypes.func.isRequired,
        unblockClient: PropTypes.func.isRequired,
        pinItemById: PropTypes.func.isRequired,
        getDeleteMessage: PropTypes.func,
        getBlockMessage: PropTypes.func,
        onSyncClientStart: PropTypes.func.isRequired,
        onSyncClientCheck: PropTypes.func.isRequired,
        refreshPinnedClient: PropTypes.func.isRequired,
        showToast: PropTypes.func.isRequired,
    };
    state = {
        showBlockDialog: false,
        showDeleteDialog: false,
        showPowerBiDialog: false,
        syncInProgress: false,
        syncId: '',
        syncTimerId: null,
    };

    componentWillUnmount() {
        const {syncTimerId} = this.state;
        if (syncTimerId) {
            clearTimeout(syncTimerId);
        }
    }
    onSuccessDelete = () => this.props.redirect({
        pathname: '/clients',
        state: {deleted: this.getClientName(this.props.client)},
    });
    onPerformDelete = () => this.onDelete();
    onCancelDelete = () => this.setState({
        showDeleteDialog: false,
    });
    onCompletedDelete = () => {
        this.setState({showDeleteDialog: false});
        this.onSuccessDelete();
    };
    onFailedDelete = ({_error: error}) => {
        this.setState({showDeleteDialog: false});
    };
    onAskBlockItem = () => this.setState({showBlockDialog: true});
    onPerformBlock = () => this.onToggleBlock();
    onCompleteBlock = () => {
        this.setState({
            showBlockDialog: false,
        });
        this.refresh();
    };
    onFailedBlock = ({_error: error}) => {
        this.setState({showBlockDialog: false});
    };
    onCancelBlock = () => this.setState({showBlockDialog: false});
    onDelete = () => this.props.deleteClient(this.props.client.id);
    onToggleBlock = () => {
        const promise = this.blocked() ?
            this.props.unblockClient(this.props.client.id) :
            this.props.blockClient(this.props.client.id);
        promise.then(() => this.props.pinItemById({id: this.props.client.id}));
    };
    onAskDeleteItem = () => this.setState({showDeleteDialog: true});
    getClientName = client => `${client.firstName} ${client.lastName}`;
    handleSyncClientCheck = async () => {
        const {client, onSyncClientCheck, refreshPinnedClient, showToast} = this.props;
        const {syncId} = this.state;
        try {
            const response = await onSyncClientCheck(syncId);
            if (response === SyncStatus.IN_PROGRESS) {
                this.setState({syncTimerId: setTimeout(this.handleSyncClientCheck, SYNC_RECHECK_TIMEOUT)});
                return;
            }
            this.setState({syncTimerId: null});

            if (response.hasErrors) {
                // try extracting description from the error
                const match = response.report.match(/description:\s*([^,$]+)(?:,|$)/i);
                showToast({
                    text: match.length > 1 ? match[1] : 'Unknown error. Please try again later.',
                    autohide: false,
                    action: 'Dismiss',
                });
            } else {
                showToast({text: 'Synchronization completed successfully'});
                refreshPinnedClient({id: client.id});
            }
        } catch (e) {
            this.setState({syncInProgress: false});
            const messageText = e.message || 'Unknown error. Please try again later.';
            showToast({text: messageText});
        }
    };
    handleSyncClientClick = async () => {
        const {client, onSyncClientStart, showToast} = this.props;
        try {
            this.setState({syncInProgress: true});
            const response = await onSyncClientStart(client.id);
            this.setState({syncId: response});
            this.setState({syncTimerId: setTimeout(this.handleSyncClientCheck, SYNC_RECHECK_TIMEOUT)});
        } catch (e) {
            this.setState({syncInProgress: false});
            const messageText = e.message || 'Unknown error. Please try again later.';
            showToast({text: messageText});
        }
    };
    refresh = () => {
        this.props.refresh({
            pathname: window.location.pathname,
            state: {update: true},
        });
    };
    blocked = () => this.props.client.status === STATUS.BLOCKED;
    renderDeleteDialog = () => {
        const {
            getDeleteMessage = () => 'Delete item?',
        } = this.props;
        const {showDeleteDialog} = this.state;
        if (!showDeleteDialog) return null;

        return (
            <Dialog
                message={getDeleteMessage()}
                onDelete={this.onPerformDelete}
                onCancel={this.onCancelDelete}
                onSubmitSuccess={this.onCompletedDelete}
                onSubmitFail={this.onFailedDelete}
            />
        );
    };
    renderBlockDialog = () => {
        const {
            getBlockMessage = () => 'Block selected items?',
        } = this.props;
        const {showBlockDialog} = this.state;
        if (!showBlockDialog) return null;

        return (
            <Dialog
                message={getBlockMessage()}
                onBlock={!this.blocked() ? this.onPerformBlock : null}
                onUnblock={this.blocked() ? this.onPerformBlock : null}
                onCancel={this.onCancelBlock}
                onSubmitSuccess={this.onCompleteBlock}
                onSubmitFail={this.onFailedBlock}
            />
        );
    };
    render() {
        const {syncInProgress, showPowerBiDialog} = this.state;
        const {lastSyncDate} = this.props.client;
        return (
            <div className="client-information-actions-bar">
                {this.renderDeleteDialog()}
                {this.renderBlockDialog()}
                <PowerBiReportDialog
                    open={showPowerBiDialog}
                    onClose={() => this.setState({showPowerBiDialog: false})}
                />
                {lastSyncDate && (
                    <div className="md-text--theme-primary">
                        Last Sync: {moment(lastSyncDate).format('LLL')}
                    </div>
                )}
                <Button
                    className={cn(
                        'prv-client-information__sync-button',
                        {'prv-client-information__sync-button--animated': syncInProgress},
                    )}
                    disabled={syncInProgress}
                    flat
                    iconChildren="refresh"
                    onClick={this.handleSyncClientClick}
                    primary
                >
                    Sync
                </Button>
                <Button
                    flat
                    iconChildren="report"
                    onClick={() => this.setState({showPowerBiDialog: true})}
                >
                    Generate Report
                </Button>
                <Button
                    flat
                    iconChildren={this.blocked() ? 'lock_open' : 'lock'}
                    onClick={this.onAskBlockItem}
                >
                    {this.blocked() ? 'Unblock' : 'Block'}
                </Button>
                <Button
                    flat
                    iconChildren="delete"
                    onClick={this.onAskDeleteItem}
                >
                    Delete
                </Button>
            </div>
        );
    }
}

export default connectActions({refreshPinnedClient, showToast})(ActionsBar);
