import React from 'react';
import {Button} from 'react-md';
import styles from './power-bi-report-dialog.module.css';

type Props = {
    onClose: () => void,
    rmEmail: string,
}

export const ReportSentInfo: React.FC<Props> = ({onClose, rmEmail}) => (
    <div>
        <div className={styles.messageContainer}>
            <h2>Report is being generated</h2>
            <p>Report is sent to {rmEmail} when it is ready.</p>
        </div>
        <div className={styles.buttonsContainer}>
            <Button flat primary onClick={onClose}>Close</Button>
        </div>
    </div>
);
