import React, {PureComponent, useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';

import {reduxForm} from 'components/forms';
import validate from 'components/forms/validations/validate';

import EditingTable, {Row} from 'components/editing/editing-table';
import {ErrorsRow, HintRow, InputRow} from 'components/editing/editing-rows';

import Spinner from 'components/loading-spinner/loading-spinner';

import AuthMethods from 'components/bank-components/AuthMethods';
import nemidLogo from 'assets/buttons/nemid-logo.svg';
import styles from './login-form.module.css';
import BankAuthenticationStatus, {BankAuthenticationSubStatus} from './BankAuthenticationStatus';
import {useDefaultBankTerms} from '../../scenes/onboarding/components/BankLoginStep/utils/useBankTerms';
import Language from '../../config/Language';
import getBankTerms from '../../scenes/onboarding/components/BankLoginStep/bankTermsUtils';
import {bankShape} from '../../store/data/client';

const language = window.navigator.language.includes('da') ? Language.DANSKE : Language.ENGLISH;

const fields = {
    username: {
        required: true,
    },
    password: {
        required: true,
    },
    description: {
        required: true,
    },
    confirmationCode: {
        required: true,
    },
};

const getFormFields = (credentialsOnly, hideDescription, status, authMethod) => {
    const formFields = {...fields};
    if (status !== BankAuthenticationStatus.ConfirmationCodeRequired) {
        delete formFields.confirmationCode;
    }
    if (credentialsOnly && authMethod !== AuthMethods.MitId) {
        delete formFields.username;
    }
    if (credentialsOnly || hideDescription) {
        delete formFields.description;
    }
    if (authMethod === AuthMethods.MitId) {
        delete formFields.password;
    }
    return formFields;
};

class RenderLoginForm extends PureComponent {
    static propTypes = {
        formFields: PropTypes.object.isRequired,
        texts: PropTypes.object.isRequired,
        status: PropTypes.string,
        subStatuses: PropTypes.arrayOf(PropTypes.string),
        withAgreements: PropTypes.bool,
        disabled: PropTypes.bool,
        message: PropTypes.string,
        agreements: PropTypes.shape({}),
        onValidateResult: PropTypes.func,
        valid: PropTypes.bool.isRequired,
        submitting: PropTypes.bool.isRequired,
        authMethod: PropTypes.string,
    };

    componentWillReceiveProps(nextProps) {
        const {onValidateResult, valid} = this.props;
        if (valid !== nextProps.valid) {
            onValidateResult(nextProps.valid);
        }
    }

    onTableRef = (table) => {
        this.table = table;
    };

    submit = (event) => {
        if (!this.props.submitting) {
            this.table.onSubmit(event);
        }
    };

    render() {
        const {
            formFields,
            message,
            texts,
            status,
            subStatuses,
            agreements,
            disabled,
            submitting,
            withAgreements,
            authMethod,
        } = this.props;

        const withCode = status === BankAuthenticationStatus.ConfirmationCodeRequired;
        const withNemId = status === BankAuthenticationStatus.NemIdApprovalRequired;
        const withMitId = status === BankAuthenticationStatus.MitIdApprovalRequired;
        const additionalActionNeeded = withCode || withAgreements || withNemId || withMitId;

        const isPersonalCode = subStatuses.includes(BankAuthenticationSubStatus.PersonalCodeRequired);

        // We don't display message from the BE response if it's personal code step and authorization method is MitID
        const messageText = isPersonalCode && authMethod === AuthMethods.MitId ? '' : message;

        const helpText = texts?.help ? texts.help.split('\n').map((item, key) => (
            // eslint-disable-next-line react/no-array-index-key
            <p key={key}>{item}</p>
        )) : null;

        return (
            <div className={styles.loginFormTableWrapper}>
                <EditingTable ref={this.onTableRef} className={styles.loginFormTable} {...this.props}>
                    {formFields.username && (
                        <React.Fragment>
                            <InputRow
                                className={additionalActionNeeded || disabled ? styles.disabled : null}
                                disabled={additionalActionNeeded || disabled}
                                label={authMethod === AuthMethods.MitId
                                    ? texts.mitIdUsername
                                    : texts.username
                                }
                                name="username"
                                placeholder={authMethod === AuthMethods.MitId
                                    ? ''
                                    : texts.usernamePlaceholder}
                                required
                            />
                            <ErrorsRow name="username"/>
                        </React.Fragment>
                    )}

                    {formFields.password && (
                        <React.Fragment>
                            <InputRow
                                className={additionalActionNeeded || disabled ? styles.disabled : null}
                                disabled={additionalActionNeeded || disabled}
                                label={texts.password}
                                name="password"
                                placeholder={texts.passwordPlaceholder}
                                required
                                type="password"
                            />
                            <ErrorsRow name="password"/>
                        </React.Fragment>
                    )}

                    {formFields.description && (
                        <React.Fragment>
                            <InputRow
                                className={additionalActionNeeded || disabled ? styles.disabled : null}
                                disabled={additionalActionNeeded || disabled}
                                label="Description"
                                name="description"
                                required
                            />
                            <ErrorsRow name="description"/>
                        </React.Fragment>
                    )}

                    {withAgreements &&
                    <Row className={styles.radioRow} label="Agreement">
                        {
                            Object.keys(agreements).map(agreementId => (
                                <label htmlFor={agreementId} key={agreementId}>
                                    <Field
                                        name="agreementId"
                                        id={agreementId}
                                        component="input"
                                        type="radio"
                                        value={agreementId}
                                    />
                                    {agreements[agreementId]}
                                </label>
                            ))
                        }
                    </Row>
                    }
                    {withAgreements && <ErrorsRow name="agreementId"/>}

                    {withCode &&
                    <InputRow
                        name="confirmationCode"
                        // eslint-disable-next-line no-nested-ternary
                        label={isPersonalCode
                            ? authMethod === AuthMethods.MitId
                                ? texts.personalCodeMitId
                                : texts.personalCode
                            : authMethod === AuthMethods.MitId
                                ? texts.confirmationCodeMitId
                                : texts.confirmationCode}
                        disabled={disabled}
                        // eslint-disable-next-line no-nested-ternary
                        placeholder={isPersonalCode
                            ? authMethod === AuthMethods.MitId
                                ? texts.personalCodeMitIdPlaceholder
                                : texts.personalCodePlaceholder
                            : authMethod === AuthMethods.MitId
                                ? texts.confirmationCodeMitIdPlaceholder
                                : texts.confirmationCodePlaceholder}
                        required
                    />}
                    {withCode && <ErrorsRow name="confirmationCode"/>}
                    <br/>
                    {authMethod !== AuthMethods.MitId && <HintRow>{helpText}</HintRow>}
                    {messageText && <HintRow>{messageText}</HintRow>}

                    {withNemId &&
                    <Row className={styles['login-form-nemid-block']} name="nemIdApprovalRequest" required>
                        <img src={nemidLogo} alt="NemID"/>
                        <div>
                            {window.navigator.language.includes('da')
                                ? 'Når du trykker fortsæt skal du godkende adgangen i din nemid nøgleapp på mobil/tablet.'
                                : 'Click continue to authorize the access in your code app on your phone/tablet'
                            }
                        </div>
                    </Row>}
                </EditingTable>
                {submitting && <Spinner/>}
            </div>
        );
    }
}

const LoginForm = React.forwardRef((props, ref) => {
    const {
        credentialsOnly,
        fetchLocalizationTerms,
        hideDescription,
        status,
        authMethod,
        selectedBank,
        localizationTerms,
    } = props;
    const formFields = useMemo(
        () => getFormFields(credentialsOnly, hideDescription, status, authMethod),
        [credentialsOnly, hideDescription, status, authMethod],
    );

    const defaultBankTerms = useDefaultBankTerms(language);
    const texts = useMemo(
        () => {
            // When update credentials we need to use 'bankId' instead of 'id'
            const bankId = selectedBank?.bankId || selectedBank?.id;
            return getBankTerms(
                defaultBankTerms,
                selectedBank.name,
                localizationTerms,
                language === Language.DANSKE ? 'da' : 'en',
                bankId,
            );
        },
        [selectedBank, localizationTerms, defaultBankTerms]);

    useEffect(() => {
        fetchLocalizationTerms();
    }, [fetchLocalizationTerms]);

    const ReduxForm = useMemo(
        () => reduxForm({
            form: `bank-login-form-${status}`,
            enableReinitialize: true,
            ...validate(formFields),
        })(RenderLoginForm),
        [formFields, status],
    );
    return <ReduxForm ref={ref} formFields={formFields} texts={texts} {...props} />;
});
LoginForm.propTypes = {
    credentialsOnly: PropTypes.bool,
    fetchLocalizationTerms: PropTypes.func,
    hideDescription: PropTypes.bool,
    localizationTerms: PropTypes.arrayOf(PropTypes.shape({})),
    selectedBank: bankShape,
    status: PropTypes.string,
    authMethod: PropTypes.string,
};

export default LoginForm;
