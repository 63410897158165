import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
// TODO: rewrite that class - separate logic by components
import _ from 'lodash';
import {connect} from 'store';

import {
    fetchBanks, clearAddAccountError,
    importAccounts, fetchBankAccounts, fetchCustomerPortfolios,
} from 'store/accounts/actions';

import {getPinnedClient} from 'store/clients';
import {
    getBanks, getAssetsManagers, getBankAccounts, getPortfolios,
    isAddAccountLoading, getAddAccountError,
} from 'store/accounts';
import {clientShape, bankShape, assetsManagerShape, bankAccountShape, portfolioShape} from 'store/data/client';

import ChooseBank from 'components/bank-components/choose-bank';
import ErrorPanel from 'components/panel/panel';
import Spinner from 'components/loading-spinner/loading-spinner';
import Moire from 'components/moire/moire';

import EditAccountDialog from '../edit-account-dialog/edit-account-dialog';
import AccountItem from './add-account-dialog-account';

class AddAccountDialog extends Component {
    static propTypes = {
        client: clientShape,
        assetsManagers: PropTypes.arrayOf(assetsManagerShape),
        portfolios: PropTypes.arrayOf(portfolioShape),
        banks: PropTypes.arrayOf(bankShape),
        bankAccounts: PropTypes.arrayOf(bankAccountShape),
        addAccountError: PropTypes.shape({message: PropTypes.string}),
        loading: PropTypes.bool,
        onCancel: PropTypes.func.isRequired,
        onSuccess: PropTypes.func,

        fetchBanks: PropTypes.func.isRequired,
        fetchBankAccounts: PropTypes.func.isRequired,
        fetchCustomerPortfolios: PropTypes.func.isRequired,
        importAccounts: PropTypes.func.isRequired,
        clearAddAccountError: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        const {banks} = this.props;
        this.defaultState = {
            selectedAccountsIds: [],
            selectedBank: null,
            step: 1,
            isLoginFormValid: false,
            banks,
        };
        this.state = this.defaultState;
    }

    componentWillMount() {
        this.props.fetchBanks({id: this.props.client.id});
        this.props.fetchCustomerPortfolios({id: this.props.client.id});
    }

    componentWillReceiveProps(nextProps) {
        this.defaultState.banks = nextProps.banks;
        this.setState({banks: nextProps.banks});
    }

    onPrevStep = () => {
        const {step} = this.state;
        if (step >= 2) {
            this.state = {};
            return this.setState({...this.defaultState});
        }

        return this.setState(prevState => ({step: prevState.step - 1}));
    };

    onNextStep = () => {
        const {step, selectedBank} = this.state;

        if (step > 2) return this.props.onCancel();

        if (step === 1) this.props.fetchBankAccounts({id: selectedBank.id});
        if (step === 2) {
            const id = selectedBank.id;
            const {id: customerId} = this.props.client;
            const {selectedAccountsIds} = this.state;
            const accounts = selectedAccountsIds.map((accId) => {
                const {
                    accountName,
                    selectedAMs,
                    selectedCustomAM,
                    cashAccountId,
                    accountType,
                    benchmarks,
                    limits,
                    proveoId,
                    selectedPortfolio,
                    selectedCustomPortfolio,
                    endDate,
                } = this.state[accId];
                return {
                    id: accId,
                    name: accountName,
                    assetsManagerId: (selectedAMs && selectedAMs.length)
                        ? selectedAMs[0].id
                        : null,
                    portfolioId: selectedPortfolio ? selectedPortfolio.id : null,
                    cashAccountId,
                    proveoId,
                    customAssetsManagerName: selectedCustomAM,
                    customPortfolioName: selectedCustomPortfolio,
                    type: accountType,
                    benchmarks: _.map(benchmarks, ({id: benchmarkId, weight}) => ({
                        id: benchmarkId,
                        weight,
                    })),
                    limits: _.map(limits, ({id: typeId, value}) => ({
                        typeId,
                        value,
                    })),
                    endDate,
                };
            });
            return this.props.importAccounts({id, customerId, accounts})
                .then(() => {
                    this.props.onCancel();
                    this.props.onSuccess();
                });
        }

        return this.setState(prevState => ({step: prevState.step + 1}));
    };

    onSelectBank = bank => this.setState({selectedBank: bank});

    onSearch = (e) => {
        const {value: searchTerm} = e.target;
        const {banks} = this.props;
        const filteredItems = _.filter(banks, bank => bank.name.toLowerCase().includes(searchTerm.toLowerCase()));
        this.setState({banks: filteredItems});
    };

    onSetAccount = (params) => {
        const {selectedAccountsIds, accountBeingEditedId} = this.state;
        const index = selectedAccountsIds.indexOf(accountBeingEditedId);
        if (index === -1) {
            selectedAccountsIds.push(accountBeingEditedId);
        }
        this.setState({
            [this.state.accountBeingEditedId]: params,
            accountBeingEditedId: null,
        });
    };
    onDeselectAccount = (id) => {
        const {selectedAccountsIds} = this.state;
        const newIdsList = selectedAccountsIds.filter(item => item !== id);

        this.setState({
            selectedAccountsIds: newIdsList,
            [id]: null,
        });
    };

    showSetAccountDialog = accountId => this.setState({accountBeingEditedId: accountId});
    closeSetAccountDialog = () => {
        this.setState({accountBeingEditedId: null});
    };

    clearDialogError = () => this.props.clearAddAccountError();

    canNext = () => {
        const {step, selectedBank, selectedAccountsIds = []} = this.state;
        const {loading} = this.props;

        switch (step) {
            case 1:
                return !!selectedBank && !loading;
            case 2:
                return selectedAccountsIds.length > 0 && !loading;
            default:
                return false;
        }
    };

    renderStepsHeader = () => (
        <div>
            <div className="prv-add-account-dialog__steps-header">
                <div className="prv-add-account-dialog__close-icon" onClick={this.props.onCancel}/>
                <div className="prv-add-account-dialog__step prv-add-account-dialog__step--active">
                    <div className="prv-add-account-dialog__step-icon prv-add-account-dialog__step-icon--bank"/>
                    <div className="prv-add-account-dialog__step-title">Banks</div>
                </div>
                <div className="prv-add-account-dialog__step prv-add-account-dialog__step--divider"/>
                <div
                    className={cn('prv-add-account-dialog__step', {
                        'prv-add-account-dialog__step--active': this.state.step > 1,
                    })}
                >
                    <div className="prv-add-account-dialog__step-icon prv-add-account-dialog__step-icon--account"/>
                    <div className="prv-add-account-dialog__step-title">Accounts</div>
                </div>
            </div>
            {this.props.addAccountError &&
            <ErrorPanel
                className="prv-add-account-dialog__error-panel"
                error={this.props.addAccountError}
                onClear={this.clearDialogError}
            />}
        </div>
    );

    renderFooter = () => (
        <div className="prv-add-account-dialog__footer">
            {this.state.step > 1 &&
            <button
                className="prv-add-account-dialog__prev-button"
                onClick={this.onPrevStep}
                disabled={this.props.loading}
            >
                Back
            </button>}
            <button
                className="prv-add-account-dialog__next-button"
                onClick={this.onNextStep}
                disabled={!this.canNext()}
            >
                {this.state.step === 2 ? 'Done' : 'Next'}
            </button>
        </div>
    );

    render() {
        const {step, accountBeingEditedId, selectedAccountsIds, selectedBank} = this.state;
        const {assetsManagers, loading, banks, bankAccounts, portfolios} = this.props;
        const bankAccount = bankAccounts && bankAccounts.filter(ac => ac.id === accountBeingEditedId)[0];
        const selectedAcc = this.state[accountBeingEditedId]
            ? {
                id: bankAccount.id,
                name: this.state[accountBeingEditedId].accountName,
                type: bankAccount.type,
                ...this.state[accountBeingEditedId],
            }
            : bankAccount;

        return (
            <Moire onCancel={this.props.onCancel}>
                <div className="prv-add-account-dialog">
                    {this.renderStepsHeader()}
                    {step === 1 &&
                    <ChooseBank
                        banks={banks}
                        loading={loading}
                        onSelectBank={this.onSelectBank}
                        selectedBankId={selectedBank ? selectedBank.id : undefined}
                    />}
                    {step === 2 &&
                    <div className="prv-add-account-dialog__accounts">
                        <div className="prv-add-account-dialog__accounts-title">Add accounts</div>
                        <ul className="prv-add-account-dialog__accounts-list">
                            {this.props.loading && <Spinner className="prv-add-account-dialog__spinner"/>}
                            {_.map(this.props.bankAccounts, (account) => (
                                <AccountItem
                                    key={account.id}
                                    account={account}
                                    accountParams={this.state[account.id]}
                                    className="prv-add-account-dialog__account"
                                    isAccountBeingEdited={accountBeingEditedId === account.id}
                                    isSelected={_.includes(selectedAccountsIds, account.id)}
                                    onEditAssetManagerClick={this.showSetAccountDialog}
                                    onCancelAssetManagerClick={this.onDeselectAccount}
                                />
                            ))}
                        </ul>
                    </div>}
                    {this.renderFooter()}
                    {accountBeingEditedId &&
                    <EditAccountDialog
                        account={selectedAcc}
                        assetsManagers={assetsManagers}
                        bankName={selectedBank.name}
                        benchmarks={[]}
                        confirmationButtonTitle="Set Account"
                        limits={[]}
                        onClose={this.closeSetAccountDialog}
                        onSetAccount={this.onSetAccount}
                        portfolios={portfolios || []}
                        {...this.state[accountBeingEditedId]}
                    />}
                </div>
            </Moire>
        );
    }
}

export default connect(
    {
        client: getPinnedClient,
        assetsManagers: getAssetsManagers,
        portfolios: getPortfolios,
        banks: getBanks,
        bankAccounts: getBankAccounts,
        loading: isAddAccountLoading,
        addAccountError: getAddAccountError,
    },
    {fetchBanks, fetchBankAccounts, fetchCustomerPortfolios, importAccounts, clearAddAccountError},
)(AddAccountDialog);
