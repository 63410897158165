import {handleActions, apiActionHandler} from 'store/helpers/actions';
import {actionHandlers} from 'store/collections/reducer_helpers';

import {readAssetsManager} from 'store/data/client';

import {
    updateAssetsManager,
    clearUpdateAMError,
} from './actions';

const initialState = {
    assetsManagers: [],
    updateAMLoading: false,
    updateAMError: null,
};

export {
    getCollectionState,
    getFetchParams,
} from 'store/collections/reducer_helpers';

export const isUpdateAMLoading = ({updateAMLoading}) => updateAMLoading;
export const getUpdateAMError = ({updateAMError}) => updateAMError;

export default handleActions(
    {
        ...actionHandlers('clientsAMs', readAssetsManager),
        [updateAssetsManager]: (state, action) => apiActionHandler(
            state,
            action,
            {loading: 'isUpdateAMLoading', error: 'updateAMError'},
        ),
        [clearUpdateAMError]: state => ({...state, updateAMError: null}),
    },
    initialState,
);
