import React from 'react';
import isEmpty from 'lodash/isEmpty';
import {SelectField, TextField} from 'react-md';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import uuid from 'uuid';
import {notificationTypes} from 'store/data/notification';
import translations from '../translate';

const notificationTypesAsSelectOptions = Object.keys(notificationTypes).map(
    b => ({value: b, label: notificationTypes[b]}),
);

export class NotificationMessage extends React.Component {
    static propTypes = {
        chooseNotificationType: PropTypes.func.isRequired,
        next: PropTypes.func.isRequired,
        back: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
        notification: PropTypes.object.isRequired,
        errors: PropTypes.array.isRequired,
        loading: PropTypes.bool.isRequired,
    }

    constructor(props) {
        super(props);

        this.ids = [
            uuid.v4(),
            uuid.v4(),
            uuid.v4(),
        ];
    }

    onChange = field => event => {
        this.props.onChange(field)(event);
    }

    canNext = () => !(isEmpty(this.props.notification.type)
        || isEmpty(this.props.notification.subject)
        || isEmpty(this.props.notification.message)
        || this.props.loading)
        && this.props.errors.length === 0

    render() {
        const {back, next, notification, ...props} = this.props;
        const language = window.navigator.language.includes('da') ? 'danske' : 'english';
        return (
            <React.Fragment>
                <div
                    className="prv-notification-edit-dialog__content"
                >
                    <span className="subheader">
                        {translations[language].addMessage.title}
                    </span>
                </div>
                <div
                    className="prv-notification-edit-dialog__content"
                >
                    <SelectField
                        id={this.ids[0]}
                        placeholder="Select notification type"
                        menuItems={notificationTypesAsSelectOptions}
                        position={SelectField.Positions.BELOW}
                        className="prv-notification-edit-dialog__content-select new-notification-select"
                        value={notification.type}
                        onChange={this.onChange('type')}
                    />
                </div>
                <div
                    className="prv-notification-edit-dialog__content"
                >
                    <TextField
                        id={this.ids[1]}
                        placeholder="Subject"
                        className="prv-notification-edit-dialog__content-select"
                        value={notification.subject}
                        onChange={this.onChange('subject')}
                    />
                </div>

                <div
                    className="prv-notification-edit-dialog__content"
                >
                    <TextField
                        id={this.ids[2]}
                        placeholder="Message to users"
                        rows={6}
                        className="prv-notification-edit-dialog__content-select"
                        value={notification.message}
                        onChange={this.onChange('message')}
                    />
                </div>
                <div
                    className="prv-notification-edit-dialog__footer prv-notification-edit-dialog__footer--customers"
                >
                    <button
                        className={classnames(
                            'prv-notification-edit-dialog__save-button',
                            'prv-notification-edit-dialog__save-button--outline',
                        )}
                        onClick={back}
                    >
                        {translations[language].general.back}
                    </button>
                    <button
                        className="prv-notification-edit-dialog__save-button"
                        disabled={!this.canNext()}
                        onClick={next}
                    >
                        {translations[language].general.next}
                    </button>
                </div>
            </React.Fragment>
        );
    }
}

export default NotificationMessage;
