import {apiActionHandler} from '../../helpers/actions';
import {StructureState} from '../reducer';
import {Action, ResultAction} from '../../helpers/types';
import Portfolio from '../../types/Portfolio';
import {readPortfolio} from '../../data/client';

const fetchPortfolioReducer = (
    state: StructureState,
    action: Action<string> | ResultAction<Portfolio | Error>,
) => apiActionHandler(
    state,
    action,
    {loading: 'fetchPortfolioLoading', error: 'fetchPortfolioError', reader: readPortfolio},
);

export default fetchPortfolioReducer;
