import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Button from 'react-md/lib/Buttons/Button';
import BenchmarkRow from './benchmark-row';
import {subBenchmarkShape} from '../../store/data/client';

export default class BenchmarkList extends React.PureComponent {
    static propTypes = {
        availableBenchmarks: PropTypes.arrayOf(PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
        })),
        benchmarks: PropTypes.arrayOf(subBenchmarkShape),
        className: PropTypes.string,
        errors: PropTypes.arrayOf(PropTypes.object),
        onAddBenchmark: PropTypes.func,
        onBenchmarkChange: PropTypes.func,
        onWeightChange: PropTypes.func,
        onDelete: PropTypes.func,
    };

    render() {
        const {
            availableBenchmarks, benchmarks, className, errors, onAddBenchmark, onBenchmarkChange, onWeightChange,
            onDelete,
        } = this.props;

        return (
            <div className={cn('prv-benchmark-list', className)}>
                {benchmarks.map((benchmark, index) => (
                    <BenchmarkRow
                        // index is used because user should be able to add several empty benchmarks
                        // and empty benchmarks don't have an id. Benchmark list will never be long or
                        // complex, so it's ok from the performance perspective
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        availableBenchmarks={availableBenchmarks}
                        benchmark={benchmark}
                        className="prv-benchmark-list__item"
                        errors={errors && errors.length && errors[index]}
                        index={index}
                        onBenchmarkChange={onBenchmarkChange}
                        onWeightChange={onWeightChange}
                        onDelete={onDelete}
                    />
                ))}
                <div className="prv-benchmark-list__buttons">
                    <Button
                        flat
                        iconChildren="add"
                        onClick={onAddBenchmark}
                    >
                        Add benchmark
                    </Button>
                </div>
            </div>
        );
    }
}
