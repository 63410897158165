import {
    callApi,
    fromStore,
    dispatch,
} from 'store/helpers/sagas';

import {createApiCallResultAction} from 'store/helpers/actions';
import {actionDispatchers} from '../collections/saga_helpers';

import {getPinnedClient} from '../clients';
import {getFetchParams} from './index';

import {
    fetchAssetsManagers,
} from './actions';

const assetsManagersFetched = createApiCallResultAction(fetchAssetsManagers);

function* fetcher({payload}) {
    const {id} = yield fromStore(getPinnedClient);
    const params = yield fromStore(getFetchParams, payload);
    try {
        const result = yield callApi('fetchCustomersAssetsManagers', {id, limits: true, benhcmarks: true, ...params});
        yield dispatch(assetsManagersFetched(result));
    } catch (error) {
        yield dispatch(assetsManagersFetched(error));
    }
}

export default {
    [fetchAssetsManagers]: fetcher,
    ...actionDispatchers('clientsAMs'),
};
