import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {connect} from 'store';
import {getTitle} from 'store/breadcrumbs';
import HeaderProfile from './header-profile';

class Header extends Component {
    static propTypes = {
        className: PropTypes.string,
        title: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.arrayOf(PropTypes.string),
        ]),
    };

    renderBreadcrumbs = () => {
        const {title} = this.props;
        if (title instanceof Array) {
            return title.map((val, index, arr) => (
                <span
                    key={val}
                    className={cn('prv-header__breadcrumb', {
                        'prv-header__breadcrumb--with-separator': index < arr.length - 1,
                    })}
                >
                    {val}
                </span>
            ));
        }

        return title;
    };

    render() {
        const {className} = this.props;
        return (
            <div className={cn('prv-header', className)}>
                <div className="prv-header__breadcrumbs">
                    {this.renderBreadcrumbs()}
                </div>
                <HeaderProfile/>
            </div>
        );
    }
}

export default connect({title: getTitle}, {})(Header);
