import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'store';

import {collectionShape} from 'store/collections';
import {isCreatingNotification, getCreateNotificationError} from 'store/notifications';
import {createNotification, clearCreateNotificationError} from 'store/notifications/actions';
import {getCollectionState as getClientsCollectionState} from 'store/clients';
import {
    fetchClients,
    clearFetchClientsError,
    changeClientsPerPage,
    clearClientsCollection,
} from 'store/clients/actions';
import {notificationTypes} from 'store/data/notification';

import Select from 'components/select/select';
import Moire from 'components/moire/moire';
import ErrorPanel from 'components/panel/panel';
import Spinner from 'components/loading-spinner/loading-spinner';
import SideDialog from '../../../components/dialogs/choose-items-side-dialog';

const notificationTypesAsSelectOptions = Object.keys(notificationTypes).map(
    b => ({value: b, label: notificationTypes[b]}),
);

const renderName = item => `${item.firstName} ${item.lastName}`;

class NotificationEditDialog extends Component {
    static propTypes = {
        loading: PropTypes.bool,
        onSubmitSuccess: PropTypes.func.isRequired,
        clientsCollection: collectionShape,
        onCancel: PropTypes.func.isRequired,
        createNotification: PropTypes.func.isRequired,
        fetchClients: PropTypes.func.isRequired,
        createNotificationError: PropTypes.shape({
            message: PropTypes.string,
        }),
        clearFetchClientsError: PropTypes.func.isRequired,
        clearCreateNotificationError: PropTypes.func.isRequired,
        changeClientsPerPage: PropTypes.func.isRequired,
        clearClientsCollection: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            showSelectRecipientsDialog: false,
            selectedRecipients: [],
            type: null,
            subject: '',
            message: '',
        };
    }

    componentDidMount() {
        this.props.changeClientsPerPage(999999);
        this.props.fetchClients();
    }

    componentWillUnmount() {
        this.props.clearClientsCollection();
    }

    onSubmit = () => {
        const {selectedRecipients, type, subject, message} = this.state;

        this.props.createNotification({
            recipientIds: selectedRecipients.map(i => i.id),
            type,
            subject,
            message,
        })
            .then(this.props.onSubmitSuccess);
    };

    onShowSelectRecipientsDialog = () => this.setState({showSelectRecipientsDialog: true});
    onCloseSelectRecipientsDialog = () => this.setState({showSelectRecipientsDialog: false});

    onSelectRecipients = items => this.setState(
        {selectedRecipients: items},
        this.onCloseSelectRecipientsDialog,
    );

    onChangeType = type => this.setState({type});

    onChangeSubject = e => this.setState({subject: e.target.value});

    onChangeMessage = e => this.setState({message: e.target.value});

    canSubmit = () => {
        const {selectedRecipients, type, subject, message} = this.state;

        if (!selectedRecipients.length) return false;
        if (!(type && type.length)) return false;
        if (!(subject && subject.length)) return false;
        if (!(message && message.length)) return false;

        return true;
    };

    renderSelectRecipientsDialog = () => {
        const {clientsCollection} = this.props;
        const {selectedRecipients} = this.state;

        return (
            <SideDialog
                className="prv-notification-edit-dialog__recipients-dialog"
                title="Recipients"
                buttonTitle="Save"
                uniqueKey="id"
                items={clientsCollection.items}
                selectedItems={selectedRecipients}
                renderItem={renderName}
                onConfirm={this.onSelectRecipients}
                onClose={this.onCloseSelectRecipientsDialog}
                multiSelect
            />
        );
    };

    render() {
        const {loading, onCancel, clientsCollection, createNotificationError} = this.props;
        const {showSelectRecipientsDialog, selectedRecipients, type} = this.state;

        return (
            <Moire onCancel={onCancel}>
                <div className="prv-notification-edit-dialog">
                    {(clientsCollection.loading || loading) && <Spinner/>}
                    {
                        clientsCollection.error &&
                        <ErrorPanel
                            className="prv-notification-edit-dialog__error-panel"
                            error={clientsCollection.error}
                            onClear={this.props.clearFetchClientsError}
                        />
                    }
                    {
                        createNotificationError &&
                        <ErrorPanel
                            className="prv-notification-edit-dialog__error-panel"
                            error={createNotificationError}
                            onClear={this.props.clearCreateNotificationError}
                        />
                    }
                    <div className="prv-notification-edit-dialog__header">
                        <span>Create</span>
                        <div className="prv-notification-edit-dialog__close-icon" onClick={onCancel}/>
                    </div>
                    <div className="prv-notification-edit-dialog__content">
                        <div className="prv-notification-edit-dialog__label">Recipients</div>
                        <button
                            className="prv-notification-edit-dialog__select-recepients-button"
                            onClick={this.onShowSelectRecipientsDialog}
                        >
                            {selectedRecipients.length
                                ? selectedRecipients.map(renderName).join(', ')
                                : 'Select Recipients'
                            }
                        </button>
                    </div>
                    <div className="prv-notification-edit-dialog__content">
                        <div className="prv-notification-edit-dialog__label">Type</div>
                        <Select
                            options={notificationTypesAsSelectOptions}
                            onChange={this.onChangeType}
                            value={type}
                            placeholder="Select type"
                            clearable={false}
                            required
                        />
                    </div>
                    <div className="prv-notification-edit-dialog__content">
                        <div className="prv-notification-edit-dialog__label">Subject</div>
                        <input
                            type="text"
                            className="prv-notification-edit-dialog__subject-field"
                            onChange={this.onChangeSubject}
                            value={this.state.subject}
                        />
                    </div>
                    <div className="prv-notification-edit-dialog__content">
                        <div className="prv-notification-edit-dialog__label">Message</div>
                        <textarea
                            className="prv-notification-edit-dialog__message-field"
                            onChange={this.onChangeMessage}
                            value={this.state.message}
                        />
                    </div>
                    <div className="prv-notification-edit-dialog__footer">
                        <button
                            className="prv-notification-edit-dialog__save-button"
                            onClick={this.onSubmit}
                            disabled={!this.canSubmit()}
                        >
                            Create Notification
                        </button>
                    </div>
                    {showSelectRecipientsDialog && this.renderSelectRecipientsDialog()}
                </div>
            </Moire>
        );
    }
}

export default connect(
    {
        clientsCollection: getClientsCollectionState,
        loading: isCreatingNotification,
        createNotificationError: getCreateNotificationError,
    },
    {
        fetchClients,
        clearFetchClientsError,
        changeClientsPerPage,
        clearClientsCollection,
        createNotification,
        clearCreateNotificationError,
    },
)(NotificationEditDialog);

