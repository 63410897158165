export default {
    danske: {
        selectType: {
            title: 'Hvilken type bruger vil du sende notifikation til?',
            proveo: 'proVeo kunder',
            atlaz: 'Atlaz brugere',
        },
        selectUsers: {
            title: 'Hvor mange brugere vil du sende til?',
            single: 'Én bruger',
            multiple: 'Flere brugere',
            searchUsers: 'Søg kundens navn',
        },
        addMessage: {
            title: 'Hvad',
        },
        general: {
            next: 'Næste',
            back: 'Tilbage',
            create: 'Create',
            update: 'Update',
        },
    }, english: {
        selectType: {
            title: 'What type of user do you want to send a notification to?',
            proveo: 'proVeo customer',
            atlaz: 'Atlaz user',
        },
        selectUsers: {
            title: 'How many users will you approach?',
            single: 'One user',
            multiple: 'More users',
            searchUsers: 'Search for customer\'s name',
        },
        addMessage: {
            title: 'What',
        },
        general: {
            next: 'Next',
            back: 'Back',
            create: 'Create',
            update: 'Update',
        },
    },
};
