import React, {Component} from 'react';
import PropTypes from 'prop-types';
import qs from 'query-string';

import * as pathnames from 'config/pathnames';

import {connect} from 'store';
import {parseJson} from 'store/helpers/json';
import {navigate, redirect} from 'store/navigate';

import {haveToken} from 'store/api';
import {getError, isLoading, getProfile, isSA, isRM, isCustomer} from 'store/auth';
import {loggedIn, logout, fetchProfile, signedUp} from 'store/auth/actions';
import {profileShape} from 'store/data/profile';

import {fetchLimits, fetchReferenceData} from 'store/general-data/actions';
import {isLimitsLoading, isReferenceDataLoading, getError as getGeneralDataError} from 'store/general-data';

import InfoMessage from 'components/layout/info-message';
import Spinner from 'components/loading-spinner/loading-spinner';
import {withRouter} from 'react-router-dom';

const ORIGINAL_LOCATION_KEY = '__original_url__';

const isAuthError = error => error && error.status === 401;

export default function loginRequired(Base, {preserveURL} = {}) {
    const displayName = `Authenticated(${Base.displayName || Base.name || 'Component'})`;

    class AuthenticatedComponent extends Component {
        static WrappedComponent = Base; // eslint-disable-line react/sort-comp
        static displayName = displayName;
        static propTypes = {
            location: PropTypes.shape({
                pathname: PropTypes.string,
                hash: PropTypes.string,
            }),
            haveToken: PropTypes.bool,
            loading: PropTypes.bool,
            error: PropTypes.shape({
                message: PropTypes.string,
            }),
            generalDataError: PropTypes.shape({
                message: PropTypes.string,
            }),
            profile: profileShape,
            isSA: PropTypes.bool.isRequired,
            isRM: PropTypes.bool.isRequired,
            isCustomer: PropTypes.bool.isRequired,

            fetchProfile: PropTypes.func.isRequired,
            fetchLimits: PropTypes.func.isRequired,
            fetchReferenceData: PropTypes.func.isRequired,
            loggedIn: PropTypes.func.isRequired,
            signedUp: PropTypes.func.isRequired,
            logout: PropTypes.func.isRequired,
            navigate: PropTypes.func.isRequired,
            redirect: PropTypes.func.isRequired,
        };

        componentWillMount() {
            const {location, profile} = this.props;
            const {id_token: token, state: inviteToken} = qs.parse(location.hash);

            if (this.props.profile && !this.props.isSA && !this.props.isRM && !this.props.isCustomer) {
                this.onLogout();
            }

            if (inviteToken) {
                this.props.signedUp({token, inviteToken});
                return this.props.redirect({...location, hash: null});
            }

            if (token) {
                this.props.loggedIn({token});
                this.props.fetchLimits();
                this.props.fetchReferenceData();
                return this.props.redirect({...location, hash: null});
            }

            if (!this.props.haveToken) return this.navigate(pathnames.LOGIN);
            const {loading, error} = this.props;

            this.props.fetchLimits();
            this.props.fetchReferenceData();
            if (loading) return null;
            if (error) return this.checkError(error);
            if (!profile) {
                this.props.fetchProfile();
            }
            return null;
        }

        componentWillReceiveProps(nextProps) {
            const {loading, location, error, profile} = nextProps;
            if (loading) return null;
            if (error) {
                return this.checkError(error);
            }

            if (profile && nextProps.isRM && nextProps.profile.info.status === 'blocked') {
                this.navigate(pathnames.PROFILE);
            }

            if (profile && !(nextProps.isSA || nextProps.isRM || nextProps.isCustomer)) {
                this.onLogout();
            }
            const pathname = location.pathname;
            const isAfterLogin = pathname === pathnames.LOGIN || pathname === pathnames.ROOT;
            if (isAfterLogin && profile) {
                if (nextProps.isSA) {
                    this.navigate(pathnames.CLIENTS);
                }
                if (nextProps.isCustomer) {
                    this.navigate(`${pathnames.CLIENTS}/${profile.id}/view`);
                }
            }

            return null;
        }

        onLogout = () => this.props.logout();

        checkError = error => isAuthError(error) && this.navigate(pathnames.LOGIN);

        navigate = (path, avaliable = []) => {
            const {location} = this.props;
            if (preserveURL) {
                this.saveOriginalLocation(location);
            }
            const pathname = location.pathname;
            if (pathname !== path && !avaliable.includes(pathname)) {
                this.props.navigate(path);
            }
        };

        saveOriginalLocation = location => (
            sessionStorage.setItem(ORIGINAL_LOCATION_KEY, JSON.stringify(location))
        );

        clearOriginalLocation = () => sessionStorage.removeItem(ORIGINAL_LOCATION_KEY);

        loadOriginalLocation = () => parseJson(sessionStorage.getItem(ORIGINAL_LOCATION_KEY));

        render() {
            const {loading, error, generalDataError} = this.props;
            const inProgress = loading || (preserveURL);

            if (inProgress) {
                return (
                    <Spinner>
                        Verifying your permissions, please wait...
                    </Spinner>
                );
            }
            // TODO: create separate error handlers
            if (error || generalDataError) {
                return (
                    <InfoMessage>
                        Server error occurred. Please retry in several minutes.
                        <br/>
                        If the problem persists, please contact our support team.
                    </InfoMessage>
                );
            }
            if (inProgress) {
                return (
                    <InfoMessage>
                        Loading, please wait...
                    </InfoMessage>
                );
            }

            return Base
                ? <Base {...this.props} />
                : null;
        }
    }

    return withRouter(connect(
        {
            haveToken,
            loading: state => isLoading(state) || isLimitsLoading(state) || isReferenceDataLoading(state),
            error: getError,
            generalDataError: getGeneralDataError,
            profile: getProfile,
            isSA,
            isRM,
            isCustomer,
        },
        {
            loggedIn,
            signedUp,
            logout,
            fetchProfile,
            fetchLimits,
            fetchReferenceData,
            navigate,
            redirect,
        },
    )(AuthenticatedComponent));
}
