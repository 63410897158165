import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import cn from 'classnames';
import uuid from 'uuid';
import Divider from 'react-md/lib/Dividers/Divider';
import {connect} from '../../../../store';
import {
    PortfolioNode,
    StructureArray,
    StructureItem,
    StructureItemId,
    StructureItemState,
    StructureNodeDtoType,
} from '../../../../store/structure/types';
import {
    createPortfolio,
    disablePortfolioRenaming,
    enablePortfolioRenaming,
    expandPortfolio,
    fetchPortfolio,
    movePortfolio,
    removePortfolio,
    renamePortfolio,
    selectPortfolio,
} from '../../../../store/structure/actions';
import {
    getExpandedPortfolioIds,
    getObsoleteAssets,
    getPortfolioBeingRenamedId,
    getSelectedPortfolio,
    getTree,
} from '../../../../store/structure';
import {ActionMenuItems} from '../../../../components/TreeView/tree';
import ConfirmationDialog from '../../../../components/dialogs/ConfirmationDialog';
import useBoolean from '../../../../components/hooks/useBoolean';
import {isNotRootItem, isPortfolio, isRootItem} from '../../../../store/structure/helpers/assertion';
import {
    getChildrenNodes,
    getNextChildrenLevel,
    getParentPortfolios,
    hasChildrenPortfolios,
} from '../../../../store/structure/helpers/structure';
import FlatTreeView from '../../../../components/TreeView/FlatTreeView';
import Portfolio from '../../../../store/types/Portfolio';
import EditAccountDialog from '../../edit-account-dialog/edit-account-dialog';
import {clearUpdatePortfolioError, updatePortfolio} from '../../../../store/client-portfolios/actions';
import {getUpdatePortfolioError, isUpdatePortfolioLoading} from '../../../../store/client-portfolios';
import {getAssetsManagers, isLoading} from '../../../../store/accounts';
import {fetchAssetsManagers} from '../../../../store/accounts/actions';
import AssetManager from '../../../../store/types/AssetManager';
import Benchmark from '../../../../store/types/Benchmark';
import {showToast} from '../../../../store/snackbar/actions';
import Toast from '../../../../store/types/Toast';
import {isChildren, isSameLevel} from '../../../../store/structure/helpers/level';
import SelectPortfolioDialog from './SelectPortfolioDialog';
import MessageBox from '../../../../components/dialogs/message-box';
import {FAKE_ROOT_PORTFOLIO} from '../../../../store/structure/reducers/movePortfolioReducer';

const getName = (item: StructureItem) => (isPortfolio(item) ? item.name : 'Portfolios');
const getNodeId = ({id}: StructureItem) => `${id}`;
const getNodeLevel = ({level}: StructureItem) => level.length;
const canSelect = () => true;

const PORTFOLIO_EDIT_DIALOG_FEATURES = Object.freeze({
    ams: true,
    benchmarks: true,
    reportingBenchmarks: true,
    invoices: true,
});

const processBenchmarks = (benchmarks: Array<Benchmark & {deleted?: boolean}>) => benchmarks
    .map(({id, weight, startDate, deleted}) => ({
        id,
        weight,
        startDate,
        deleted,
    }));

interface StateProps {
    amsLoading: boolean,
    assetsManagers: AssetManager[],
    client?: {id: string},
    expandedPortfolioIds: Set<StructureItemId>,
    obsoleteAssets: {[key in StructureItemId]: StructureItem},
    portfolioBeingRenamedId: StructureItemId | undefined,
    portfolioUpdateError: string | undefined,
    portfolioUpdating: boolean,
    selectedPortfolio: PortfolioNode | undefined,
    tree: StructureArray,
}

interface DispatchProps {
    clearUpdatePortfolioError: () => void,
    createPortfolio: (portfolio: PortfolioNode) => void,
    disablePortfolioRenaming: (cancelled: boolean) => void,
    enablePortfolioRenaming: (payload?: {portfolio: PortfolioNode, deleteOnCancel?: boolean}) => void,
    expandPortfolio: (payload: {node: StructureItem | StructureItem[], expanded: boolean}) => void,
    fetchAssetsManagers: () => void,
    fetchPortfolio: (payload: {clientId: string, portfolioId: string, isLegacy?: boolean}) => Portfolio,
    movePortfolio: (payload: {portfolio: PortfolioNode, targetPortfolio: PortfolioNode}) => void,
    removePortfolio: (portfolio: PortfolioNode) => void,
    renamePortfolio: (portfolio: PortfolioNode) => void,
    selectPortfolio: (portfolio: PortfolioNode | undefined) => void,
    showToast: (toast: Toast) => void,
    updatePortfolio: (portfolio: Partial<Portfolio>) => Promise<Portfolio>,
}

interface Props extends StateProps, DispatchProps {
    className?: string,
}

const PortfolioTree: React.FC<Props> = ({
    amsLoading,
    assetsManagers,
    className,
    clearUpdatePortfolioError,
    client,
    expandedPortfolioIds,
    selectedPortfolio,
    tree,
    createPortfolio,
    disablePortfolioRenaming,
    enablePortfolioRenaming,
    expandPortfolio,
    fetchAssetsManagers,
    fetchPortfolio,
    movePortfolio,
    obsoleteAssets,
    portfolioBeingRenamedId,
    portfolioUpdateError,
    portfolioUpdating,
    removePortfolio,
    renamePortfolio,
    selectPortfolio,
    showToast,
    updatePortfolio,
}) => {
    useEffect(() => { fetchAssetsManagers(); }, []);

    const hasChildren = useCallback(
        (node: StructureItem) => hasChildrenPortfolios(tree, node),
        [hasChildrenPortfolios, tree],
    );

    const getNodeClass = useCallback(
        (node: StructureItem) => {
            if (isRootItem(node)) {
                return 'prv-portfolio-tree__root-node';
            }
            if (isPortfolio(node)) {
                const childrenNodes = getChildrenNodes(tree, node);
                const hasObsoleteNodes = childrenNodes
                    .some(node => obsoleteAssets[node.id] && isSameLevel(obsoleteAssets[node.id].level, node.level));
                if (hasObsoleteNodes) {
                    return 'prv-portfolio-tree__node prv-portfolio-tree__node--has-obsolete';
                }
            }
            return '';
        },
        [obsoleteAssets, tree],
    );

    const handleRename = useCallback(
        (node: PortfolioNode, name: string) => renamePortfolio({...node, name}),
        [renamePortfolio],
    );
    const handleDeselect = useCallback(() => selectPortfolio(undefined), [selectPortfolio]);
    const handleSelect = useCallback(
        (node: StructureItem) => selectPortfolio(isRootItem(node) || !isPortfolio(node) ? undefined : node),
        [selectPortfolio],
    );

    // Handle Add/Expand All/Collapse All/Rename actions
    const handleAddPortfolio = useCallback(
        (node?: StructureItem) => {
            const portfolio: PortfolioNode = {
                custodyAccounts: [],
                level: getNextChildrenLevel(tree, node),
                id: uuid.v4(), // we need to have a unique id, even if it's the fake one
                name: '',
                state: StructureItemState.CREATED,
                $type: StructureNodeDtoType.PORTFOLIO,
            };
            createPortfolio(portfolio);
            enablePortfolioRenaming({portfolio, deleteOnCancel: true});
        },
        [createPortfolio, enablePortfolioRenaming, tree],
    );

    const handleExpandAll = useCallback(
        () => expandPortfolio({node: tree, expanded: true}),
        [expandPortfolio, tree],
    );
    const handleCollapseAll = useCallback(
        () => expandPortfolio({node: tree.filter(isNotRootItem).filter(isPortfolio), expanded: false}),
        [expandPortfolio, tree],
    );
    const handleStartRenaming = useCallback(
        (portfolio) => enablePortfolioRenaming({portfolio}),
        [enablePortfolioRenaming],
    );
    const handleCancelRenaming = useCallback(
        (node, cancelled) => disablePortfolioRenaming(cancelled),
        [disablePortfolioRenaming],
    );

    // Handle Move action
    const [portfolioBeingMoved, setPortfolioBeingMoved] = useState<PortfolioNode | undefined>(undefined);
    const [targetPortfolios, setTargetPortfolios] = useState<PortfolioNode[]>([]);
    const handleHideTargetPortfolioDialog = useCallback(
        () => setPortfolioBeingMoved(undefined),
        [setPortfolioBeingMoved],
    );
    const handleMovePortfolio = useCallback(
        (portfolio: PortfolioNode) => {
            // target node is any portfolio except any children of selected portfolio or portfolio itself
            const targets = tree
                .filter(isPortfolio)
                .filter(node => node.id !== portfolio.id && !isChildren(node.level, portfolio.level));

            // if given portfolio is not a root-level portfolio (i.e. is a node on the 3rd level or deeper)
            // add a fake root portfolio at the top to allow moving portfolios there
            if (portfolio.level.length > 2) {
                targets.unshift(FAKE_ROOT_PORTFOLIO);
            }

            setTargetPortfolios(targets);
            setPortfolioBeingMoved(portfolio);
        },
        [setPortfolioBeingMoved, setTargetPortfolios, tree],
    );
    const handleSelectMoveTarget = useCallback(
        (targetPortfolio: PortfolioNode) => {
            if (portfolioBeingMoved && targetPortfolio) {
                movePortfolio({portfolio: portfolioBeingMoved, targetPortfolio});
                setPortfolioBeingMoved(undefined);
            }
        },
        [movePortfolio, portfolioBeingMoved, setPortfolioBeingMoved],
    );

    // Handle Delete action
    const [isRemovalConfirmationVisible, showRemovalConfirmation, hideRemovalConfirmation] = useBoolean();
    const portfolioToRemove = useRef<PortfolioNode | undefined>(undefined);
    const handleRemovePortfolio = useCallback(
        (portfolio) => {
            portfolioToRemove.current = portfolio;
            showRemovalConfirmation();
        },
        [showRemovalConfirmation],
    );
    const handleConfirmRemoval = useCallback(
        () => {
            portfolioToRemove.current && removePortfolio(portfolioToRemove.current);
            portfolioToRemove.current = undefined;
        },
        [removePortfolio],
    );

    const [portfolioBeingEdited, setPortfolioBeingEdited] = useState<Portfolio | undefined>(undefined);
    const handleEditPortfolioClick = useCallback(
        async (node: StructureItem) => {
            if (isPortfolio(node) && node.state === StructureItemState.CREATED) {
                showToast({text: 'Please save current structure before editing created portfolio.'});
                return;
            }
            const portfolio = await fetchPortfolio({clientId: client!.id, portfolioId: node.id, isLegacy: false});
            setPortfolioBeingEdited(portfolio);
        },
        [client, fetchPortfolio],
    );
    const handlePortfolioUpdate = useCallback(
        async ({benchmarks, reportingBenchmarks, invoices, selectedAMs}) => {
            try {
                clearUpdatePortfolioError();
                await updatePortfolio({
                    clientId: client!.id,
                    portfolioId: portfolioBeingEdited!.id,
                    benchmarks: processBenchmarks(benchmarks),
                    reportingBenchmarks: processBenchmarks(reportingBenchmarks),
                    assetsManagerId: (selectedAMs && selectedAMs.length) ? selectedAMs[0].id : undefined,
                    isLegacy: portfolioBeingEdited!.isLegacy,
                    ...invoices,
                });
                setPortfolioBeingEdited(undefined);
                showToast({text: 'Updated successfully'});
            } catch (e) {
                showToast({text: 'Failed to update'});
            }
        },
        [client, portfolioBeingEdited, setPortfolioBeingEdited],
    );
    const handleEditPortfolioDialogClose = useCallback(
        () => setPortfolioBeingEdited(undefined),
        [setPortfolioBeingEdited],
    );
    const selectedAm = portfolioBeingEdited && assetsManagers
        ? assetsManagers.find(({id}) => id === portfolioBeingEdited.assetsManagerId)
        : undefined;

    const getActions = useCallback(
        (node: StructureItem): ActionMenuItems => (isRootItem(node)
            ? [
                {primaryText: 'Add Portfolio', onClick: () => handleAddPortfolio(node)},
                <Divider key="divider"/>,
                {primaryText: 'Expand All', onClick: handleExpandAll},
                {primaryText: 'Collapse All', onClick: handleCollapseAll},
            ]
            : [
                {primaryText: 'Add Sub Portfolio', onClick: () => handleAddPortfolio(node)},
                <Divider key="divider1"/>,
                ...(client ? [{primaryText: 'Edit', onClick: () => handleEditPortfolioClick(node)}] : []),
                {primaryText: 'Rename', onClick: () => handleStartRenaming(node)},
                ...(isPortfolio(node) ? [{primaryText: 'Move', onClick: () => handleMovePortfolio(node)}] : []),
                <Divider key="divider2"/>,
                {primaryText: 'Delete', onClick: () => handleRemovePortfolio(node)},
            ]
        ),
        [handleAddPortfolio, handleExpandAll, handleCollapseAll, handleStartRenaming, handleRemovePortfolio],
    );
    const isEditing = useCallback((node) => node.id === portfolioBeingRenamedId, [portfolioBeingRenamedId]);
    const isSelected = useCallback((node: StructureItem) => node === selectedPortfolio, [selectedPortfolio]);
    const isExpanded = useCallback(({id}: StructureItem) => expandedPortfolioIds.has(id), [expandedPortfolioIds]);
    const isVisible = useCallback(
        (node: PortfolioNode) => isRootItem(node)
            || getParentPortfolios(tree, node).every(({id}) => expandedPortfolioIds.has(id)),
        [expandedPortfolioIds, tree],
    );
    const handleExpand = useCallback(
        (node: StructureItem, expanded: boolean) => expandPortfolio({node, expanded}),
        [expandPortfolio],
    );

    const portfolioNodes = useMemo(() => tree.filter(node => isPortfolio(node) || isRootItem(node)), [tree]);
    return (
        <div className={cn('prv-portfolio-tree', className)}>
            <FlatTreeView
                className="prv-portfolio-tree__tree"
                canExpand={isNotRootItem}
                canSelect={canSelect}
                getActions={getActions}
                getName={getName}
                getNodeClass={getNodeClass}
                getNodeId={getNodeId}
                getNodeLevel={getNodeLevel}
                hasChildren={hasChildren}
                isEditing={isEditing}
                isExpanded={isExpanded}
                isSelected={isSelected}
                isVisible={isVisible}
                onDeselect={handleDeselect}
                onCancelRename={handleCancelRenaming}
                onExpand={handleExpand}
                onRename={handleRename}
                onSelect={handleSelect}
                tree={portfolioNodes}
            />
            <ConfirmationDialog
                id="portfolio-removal-confirmation"
                focusOnConfirm={false}
                onConfirm={handleConfirmRemoval}
                onHide={hideRemovalConfirmation}
                text={portfolioToRemove.current?.name || ''}
                title="Do you really want to remove portfolio?"
                visible={isRemovalConfirmationVisible}
            />
            {portfolioBeingEdited &&
            <EditAccountDialog
                addCustomAssetManager={false}
                assetsManagers={assetsManagers}
                selectedAMs={selectedAm ? [selectedAm] : []}
                account={{name: portfolioBeingEdited.name}}
                renderHeaderTitle={() => <div>Edit</div>}
                limits={portfolioBeingEdited.limits || []}
                benchmarks={portfolioBeingEdited.benchmarks || []}
                reportingBenchmarks={portfolioBeingEdited.reportingBenchmarks || []}
                invoicingAUMLock={portfolioBeingEdited.invoicingAUMLock}
                invoicingReturnLock={portfolioBeingEdited.invoicingReturnLock}
                invoicingFixedLock={portfolioBeingEdited.invoicingFixedLock}
                reportingLock={portfolioBeingEdited.reportingLock}
                confirmationButtonTitle="Update"
                onSetAccount={handlePortfolioUpdate}
                onClose={handleEditPortfolioDialogClose}

                loading={portfolioUpdating || amsLoading}
                error={portfolioUpdateError}
                onClearError={clearUpdatePortfolioError}

                shouldBeRendered={PORTFOLIO_EDIT_DIALOG_FEATURES}
                invoices={{
                    invoicingAUM: portfolioBeingEdited.invoicingAUM,
                    invoicingReturn: portfolioBeingEdited.invoicingReturn,
                    invoicingFixed: portfolioBeingEdited.invoicingFixed,
                    proveoScoreCalculated: portfolioBeingEdited.proveoScoreCalculated,
                    reporting: portfolioBeingEdited.reporting,
                    createReport: portfolioBeingEdited.createReport,
                }}
            />
            }
            <SelectPortfolioDialog
                id="portfolio-moving"
                onHide={handleHideTargetPortfolioDialog}
                onSelectPortfolio={handleSelectMoveTarget}
                portfolios={targetPortfolios}
                text=""
                title="Select Target Portfolio"
                visible={!!portfolioBeingMoved && targetPortfolios.length > 0}
            />
            <MessageBox
                onHide={handleHideTargetPortfolioDialog}
                title="Can't move"
                text="Can't move this Portfolio because there's no suitable target Portfolio."
                visible={!!portfolioBeingMoved && !targetPortfolios.length}
            />
        </div>
    );
};

// FIXME: figure out how to type the connect function
// @ts-ignore
export default connect(
    {
        amsLoading: isLoading,
        assetsManagers: getAssetsManagers,
        expandedPortfolioIds: getExpandedPortfolioIds,
        obsoleteAssets: getObsoleteAssets,
        portfolioBeingRenamedId: getPortfolioBeingRenamedId,
        portfolioUpdateError: getUpdatePortfolioError,
        portfolioUpdating: isUpdatePortfolioLoading,
        selectedPortfolio: getSelectedPortfolio,
        tree: getTree,
    },
    {
        clearUpdatePortfolioError,
        createPortfolio,
        disablePortfolioRenaming,
        enablePortfolioRenaming,
        expandPortfolio,
        fetchAssetsManagers,
        fetchPortfolio,
        movePortfolio,
        removePortfolio,
        renamePortfolio,
        selectPortfolio,
        showToast,
        updatePortfolio,
    },
)(PortfolioTree);
