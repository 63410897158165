import React, {MouseEvent, SyntheticEvent, useCallback, useMemo} from 'react';
import cn from 'classnames';
import castArray from 'lodash/castArray';
import Button from 'react-md/lib/Buttons/Button';
import MenuButton from 'react-md/lib/Menus/MenuButton';
import MenuComponent from 'react-md/lib/Menus/Menu';
import {ActionMenuItems} from './tree';
import EditableLabel from '../EditableLabel/EditableLabel';

const nonEmptyNameValidator = (label: string) => (label.trim().length ? undefined : "Name can't be empty");

interface Props {
    actions?: ActionMenuItems,
    className?: string,
    canExpand: boolean,
    canSelect: boolean,
    editing: boolean,
    expanded: boolean,
    name: string,
    nodeId: string,
    onCancelRename?: (cancelled: boolean) => void,
    onRename?: (newName: string) => void,
    onSelect?: () => void,
    onToggleExpand?: (expanded: boolean) => void,
}

const TreeElementHeader: React.FC<Props> = ({
    actions: rawActions,
    canExpand,
    className,
    editing,
    expanded,
    name,
    nodeId,
    onCancelRename,
    onRename,
    onSelect,
    onToggleExpand,
}) => {
    const id = nodeId.replace(' ', '_');
    const handleExpand = useCallback(
        (e: SyntheticEvent) => {
            e.stopPropagation();
            onToggleExpand && onToggleExpand(!expanded);
        },
        [onToggleExpand, expanded],
    );
    const handleSelect = useCallback(
        (e: SyntheticEvent) => {
            // process all element events except when they're going from the menu-button
            // NOTE: we can't suppress menu button's bubbling because it's needed to only have one menu opened at a time
            // @ts-ignore
            if (!e?.target?.className?.split(' ').includes('md-icon')) {
                onSelect && onSelect();
            }
        },
        [onSelect],
    );

    const handleStopPropagation = useCallback((e: MouseEvent<HTMLElement>) => {e.stopPropagation();}, []);

    const actions = useMemo(() => [...castArray(rawActions ?? [])], [rawActions]);
    const hasActions = actions.length > 0;

    return (
        <div
            className={cn('prv-tree-view-element-header', className)}
            onClick={handleSelect}
        >
            {canExpand &&
            <Button
                className="prv-tree-view-element-header__expand-button"
                icon
                onClick={handleExpand}
            >
                {expanded ? 'expand_less' : 'expand_more'}
            </Button>}
            <div onClick={editing ? handleStopPropagation : undefined}>
                <EditableLabel
                    className="prv-tree-view-element-header__name"
                    id={`${id}-node-name`}
                    isEditing={editing}
                    label={name}
                    onEdit={onRename}
                    onStopEditing={onCancelRename}
                    onValidate={nonEmptyNameValidator}
                />
            </div>
            <div className="prv-tree-view-element-header__controls">
                {hasActions &&
                <MenuButton
                    anchor={{
                        x: MenuComponent.HorizontalAnchors.INNER_LEFT,
                        y: MenuComponent.VerticalAnchors.BOTTOM,
                    }}
                    icon
                    id={`${id}-actions`}
                    menuItems={actions}
                    simplifiedMenu={false}
                    repositionOnScroll={false}
                >
                    more_vert
                </MenuButton>}
            </div>
        </div>
    );
};

export default TreeElementHeader;
