import {
    createAction,
} from 'redux-actions';

import {isApiResultAction} from '../api/helpers';

export {
    createAction,
    handleActions,
} from 'redux-actions';

export {
    createApiCallAction,
    createApiCallResultAction,
    isApiResultAction,
    isErrorAction,
} from '../api/helpers';


export const createFetchAction = (type, payloadCreator) => (
    createAction(type, payloadCreator, () => ({cancelPrevious: true}))
);

export const apiActionHandler = (
    state, action,
    {
        error: errorField = 'error',
        loading: loadingField = 'loading',
        result: resultField = undefined,
        reader = undefined,
        resultHandler = undefined,
    } = {},
) => {
    if (isApiResultAction(action)) {
        const {error, payload} = action;
        const updatedState = {...state, [loadingField]: false, [errorField]: null};
        if (error) return {...updatedState, [errorField]: payload};
        if (resultHandler) {
            return resultHandler(payload, resultField, state);
        }
        if (resultField) updatedState[resultField] = reader ? reader(payload) : payload;
        return updatedState;
    }
    return {...state, [loadingField]: true, [errorField]: null};
};
