import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {Field} from 'redux-form';
import DatePicker from '../../components/date-picker';

const RenderDateField = ({className, input, meta, ...props}) => (
    <DatePicker
        className={cn(className, {invalid: meta.touched && meta.invalid})}
        date={input.value}
        onChange={input.onChange}
        onBlur={input.onBlur}
        {...props}
    />
);

RenderDateField.propTypes = {
    input: PropTypes.shape({
        value: PropTypes.any,
        onBlur: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
    }).isRequired,
    meta: PropTypes.shape({
        invalid: PropTypes.bool,
        touched: PropTypes.bool,
    }).isRequired,
    className: PropTypes.string,
};

const DateField = ({name, ...props}) => (
    <Field
        name={name}
        component={RenderDateField}
        {...props}
    />
);
export default DateField;

DateField.propTypes = {
    name: PropTypes.string.isRequired,
};
