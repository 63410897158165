import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import TextField from 'react-md/lib/TextFields/TextField';
import injectTooltip from 'react-md/lib/Tooltips/injectTooltip';

class TooltipReadyTextField extends React.PureComponent {
    static propTypes = {
        className: PropTypes.string,
        id: PropTypes.string.isRequired,
        label: PropTypes.string,
        onClick: PropTypes.func,
        tooltip: PropTypes.element,
        value: PropTypes.string,
    };

    render() {
        const {className, onClick, tooltip, id, label, value} = this.props;

        return (
            <div className={cn('prv-tooltip-ready-text-field', className)} onClick={onClick}>
                {tooltip}
                <TextField
                    id={`${id}--title`}
                    disabled
                    label={label}
                    onChange={null}
                    value={value}
                />
                <i className="md-icon material-icons">arrow_drop_down</i>
            </div>
        );
    }
}

export default injectTooltip(TooltipReadyTextField);
