import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Button from 'react-md/lib/Buttons/Button';
import {bankShape} from '../../../../store/data/client';
import EditableLabel from '../../../../components/EditableLabel/EditableLabel';
import useBoolean from '../../../../components/hooks/useBoolean';

const validateDescription = (newDescription) => (
    (newDescription || '').trim().length > 0 ? undefined : "Description can't be empty"
);

const BankColumn = ({bank, bankName, canEditDescription, id, onUpdateCredentialsClick, onUpdateDescription}) => {
    const [editing, startEditing, stopEditing] = useBoolean();
    const handleDescriptionClick = useCallback(
        () => canEditDescription && startEditing(),
        [canEditDescription, startEditing],
    );
    const handleUpdateClick = useCallback(
        () => onUpdateCredentialsClick && onUpdateCredentialsClick(bank),
        [bank, onUpdateCredentialsClick],
    );
    const handleEditDescription = useCallback(
        (description) => onUpdateDescription && onUpdateDescription({...bank, description}),
        [bank, onUpdateDescription],
    );
    return (
        <div className="prv-client-account__bank">
            <div className="prv-client-account__bank-name">
                {bankName}
                {bank && bank.isOutdated && (
                    <Button
                        className="prv-client-account__outdated-bank-button"
                        icon
                        id={id}
                        onClick={handleUpdateClick}
                        tooltipLabel="Bank credentials are outdated. Click to Update"
                        tooltipPosition="right"
                    >
                        error
                    </Button>
                )}
            </div>
            {bank && (
                <EditableLabel
                    className={cn(
                        'prv-client-account__bank-description',
                        {'prv-client-account__bank-description--empty': !bank.description},
                    )}
                    id={`bank-description-${bank.id}`}
                    isEditing={editing}
                    label={bank.description}
                    onClick={handleDescriptionClick}
                    onEdit={handleEditDescription}
                    onStopEditing={stopEditing}
                    onValidate={validateDescription}
                    placeholder={canEditDescription ? 'click to add description' : ''}
                />
            )}
        </div>
    );
};
BankColumn.propTypes = {
    bank: PropTypes.oneOfType([
        bankShape,
        PropTypes.oneOf([undefined]),
    ]),
    bankName: PropTypes.string.isRequired,
    canEditDescription: PropTypes.bool,
    id: PropTypes.string.isRequired,
    onUpdateCredentialsClick: PropTypes.func,
    onUpdateDescription: PropTypes.func,
};

export default BankColumn;
