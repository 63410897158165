import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {extractFileName} from 'helpers/url';
import {Field} from 'redux-form';

import styles from './any-file-field.module.css';

class RenderFileField extends Component {
    static propTypes = {
        input: PropTypes.shape({
            value: PropTypes.any,
            onChange: PropTypes.func,
        }).isRequired,
        extension: PropTypes.string,
    };
    onFileSelected = () => {
        const file = this.inputFile.files[0];
        const data = new FormData();
        data.append('csvFile', file);
        this.inputText.value = extractFileName(file.name);
        this.props.input.onChange(data);
    };

    render() {
        let {extension} = this.props;
        extension = extension && (extension[0] === '.' ? extension : `.${extension}`);

        return (
            <div className={styles.fileField}>
                <input
                    ref={(input) => {this.inputText = input;}}
                    type="text"
                    disabled
                />
                <button
                    className={styles.uploadButton}
                    onClick={() => this.inputFile.click()}
                >
                    Upload excel file
                </button>
                <input
                    ref={(input) => {
                        this.inputFile = input;
                    }}
                    onChange={this.onFileSelected}
                    className={styles.invisible}
                    type="file"
                    accept={extension}
                />
            </div>
        );
    }
}

const FileField = ({name, ...props}) => (
    <Field
        name={name}
        component={RenderFileField}
        {...props}
    />
);
export default FileField;

FileField.propTypes = {
    name: PropTypes.string,
};
