import React, {PureComponent} from 'react';
import cn from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Select from 'components/select/select';
import styles from './pagination.module.css';

export default class Pagination extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
        page: PropTypes.number.isRequired,
        totalPages: PropTypes.number.isRequired,
        perPage: PropTypes.number.isRequired,
        perPageOptions: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.object])).isRequired,
        onChangePage: PropTypes.func.isRequired,
        onChangePerPage: PropTypes.func.isRequired,
    };

    onNextPage = () => !this.isMax() && this.props.onChangePage(this.props.page + 1);
    onPrevPage = () => !this.isMin() && this.props.onChangePage(this.props.page - 1);
    onLastPage = () => !this.isMax() && this.props.onChangePage(this.props.totalPages - 1);
    onFirstPage = () => !this.isMin() && this.props.onChangePage(0);

    onChangePerPage = value => this.props.onChangePerPage(parseInt(value, 10));

    isMax = () => this.props.page >= this.props.totalPages - 1;
    isMin = () => this.props.page <= 0;

    render() {
        const {className, page, totalPages, perPage, perPageOptions} = this.props;

        const options = perPageOptions.map(perPageValue =>
            (_.isObject(perPageValue) ? perPageValue : {value: perPageValue, label: perPageValue}));
        return (
            <div className={cn(className, styles.wrapper)}>
                <span>Records per page</span>
                <Select
                    value={perPage}
                    onChange={this.onChangePerPage}
                    options={options}
                    clearable={false}
                />
                <div className={cn(styles.divider, styles.outerDivider)}/>
                <div className={styles.arrowsContainer}>
                    <div
                        className={cn(styles.firstPageArrow, {[styles.disabled]: this.isMin()})}
                        onClick={this.onFirstPage}
                    />
                    <div className={styles.divider}/>
                    <div
                        className={cn(styles.prevPageArrow, {[styles.disabled]: this.isMin()})}
                        onClick={this.onPrevPage}
                    />
                </div>
                <div className={styles.pageInfo}>
                    Page {page + 1} of {totalPages}
                </div>
                <div className={styles.arrowsContainer}>
                    <div
                        className={cn(styles.nextPageArrow, {[styles.disabled]: this.isMax()})}
                        onClick={this.onNextPage}
                    />
                    <div className={styles.divider}/>
                    <div
                        className={cn(styles.lastPageArrow, {[styles.disabled]: this.isMax()})}
                        onClick={this.onLastPage}
                    />
                </div>
            </div>
        );
    }
}
