import {createAction, createApiCallAction, createFetchAction} from '../helpers/actions';

export const fetchStructure = createFetchAction('structure/FETCH');
export const fetchAssets = createFetchAction('structure/FETCH_ASSETS');
export const saveStructure = createFetchAction('structure/SAVE');

export const addPortfolio = createAction('structure/ADD_PORTFOLIO');
export const createPortfolio = createAction('structure/CREATE_PORTFOLIO');
export const disablePortfolioRenaming = createAction('structure/DISABLE_PORTFOLIO_RENAMING');
export const enablePortfolioRenaming = createAction('structure/ENABLE_PORTFOLIO_RENAMING');
export const expandPortfolio = createAction('structure/EXPAND_PORTFOLIO');
export const movePortfolio = createAction('structure/MOVE_PORTFOLIO');
export const removePortfolio = createAction('structure/REMOVE_PORTFOLIO');
export const renamePortfolio = createAction('structure/RENAME_PORTFOLIO');
export const selectPortfolio = createAction('structure/SELECT_PORTFOLIO');

export const addAssets = createAction('structure/ADD_ASSETS');
export const linkAccount = createAction('structure/LINK_ACCOUNT');
export const removeAssets = createAction('structure/REMOVE_ASSETS');
export const unlinkAccount = createAction('structure/UNLINK_ACCOUNT');

export const changeStartDate = createAction('structure/CHANGE_DATE');

export const fetchPortfolio = createApiCallAction('structure/FETCH_PORTFOLIO', 'fetchPortfolio');
