import React from 'react';
import {Route} from 'react-router-dom';
import LoginPage from './scenes/auth/login-page';
import loginRequired from './scenes/auth/login-required';
import ChooseScenarioPage from './scenes/choose-scenario/choose-scenario-page';
import {
    LOGIN,
    ROOT,
} from './config/pathnames';
import * as roles from './config/roles';
import HasRole from './components/has-role/has-role';
import SaRmSwitch from './routes/sa-rm-routes';
import CustomerSwitch from './routes/customer-routes';
import {LoggedLayout} from './routes/logged-layouts';

const LoggedChooseScenarioPage = loginRequired(ChooseScenarioPage);

export const Routes = () => (
    <div style={{height: '100%', width: '100%'}}>
        <Route path={LOGIN} component={LoginPage}/>

        <React.Fragment>
            <Route path={ROOT} exact component={LoggedChooseScenarioPage} />
            <HasRole allowedRoles={[roles.SA, roles.RM]}>{SaRmSwitch}</HasRole>
            <HasRole allowedRoles={[roles.CUSTOMER]}>{CustomerSwitch}</HasRole>
            <HasRole allowedRoles={[roles.GUEST]}>
                {/* a dummy route to allow load the profile info when reloading the page */}
                <LoggedLayout />
            </HasRole>
        </React.Fragment>
    </div>
);

export default Routes;
