import {Action, ResultAction} from '../../helpers/types';
import {AnyAssetList, CustodyAccount, StructureItem} from '../types';
import {apiActionHandler} from '../../helpers/actions';
import {StructureState} from '../reducer';
import {createAccountGroups, parseObsoleteAssets, parseObsoleteCustodyAccounts} from '../helpers/structure';

interface FetchPayload {
    obsolete: {
        items: StructureItem<string>[],
        custodyAccounts: CustodyAccount[],
    },
    vacant: {
        items: AnyAssetList,
        custodyAccounts: CustodyAccount[],
    },
}

const fetchAssetsReducer = (
    state: StructureState,
    action: Action<string> | ResultAction<FetchPayload | Error>,
) => apiActionHandler(
    state,
    action,
    {
        resultHandler: (payload: FetchPayload, resultField: string, state: StructureState): StructureState => {
            const accountGroups = createAccountGroups(payload.vacant.items, payload.vacant.custodyAccounts);
            return {
                ...state,
                accountGroups,
                error: undefined,
                loading: false,
                obsoleteAssets: parseObsoleteAssets(payload.obsolete.items),
                obsoleteCustodyAccounts: parseObsoleteCustodyAccounts(payload.obsolete.custodyAccounts),
            };
        },
    },
);

export default fetchAssetsReducer;
