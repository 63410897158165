import {
    callApi,
    fromStore,
    dispatch,
} from 'store/helpers/sagas';

import {createApiCallResultAction} from 'store/helpers/actions';
import {actionDispatchers} from '../collections/saga_helpers';

import {getPinnedClient} from '../clients';
import {getFetchParams} from './index';

import {fetchPortfolios} from './actions';

const portfoliosFetched = createApiCallResultAction(fetchPortfolios);

function* fetcher({payload}) {
    const {id} = yield fromStore(getPinnedClient);
    const params = yield fromStore(getFetchParams, payload);
    try {
        const aggregatedPortfolios = yield callApi(
            'fetchCustomerAggregatedPortfolios',
            {id, limits: true, benchmarks: true, hasUsages: true, legacyOnly: false, ...params},
        );

        yield dispatch(portfoliosFetched(aggregatedPortfolios));
    } catch (error) {
        yield dispatch(portfoliosFetched(error));
    }
}

export default {
    [fetchPortfolios]: fetcher,
    ...actionDispatchers('clientPortfolios'),
};
