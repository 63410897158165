import LocalizationTerm from './types/LocalizationTerm';
import BankTerms from './types/BankTerms';
import Bank from '../../../../store/types/Bank';

const KEY_MAP: { [key: string]: string } = {
    AuthorizeBankOnboardingPage_Login_Placeholder: 'usernamePlaceholder',
    AuthorizeBankOnboardingPage_Login_Field_Name: 'username',
    AuthorizeBankOnboardingPage_Password_Placeholder: 'passwordPlaceholder',
    AuthorizeBankOnboardingPage_Password_Field_Name: 'password',
    AuthorizeBankOnboardingPage_HelpHeader: 'helpHeader',
    AuthorizeBankOnboardingPage_HelpText: 'help',
    AuthorizeBankOnboardingPage_BankApprovalTitle: 'bankApprovalTitle',
    BankReplace_Autorize_ConfirmationHelp: 'confirmationHelp',
    BankReplace_Autorize_ConfirmationHelpMitId: 'confirmationHelpMitId',
    AuthorizeBankOnboardingPage_PersonalCode_Format: 'personalCode',
    AuthorizeBankOnboardingPage_PersonalCode_Placeholder: 'personalCodePlaceholder',
    AuthorizeBankOnboardingPage_ConfirmationCode: 'confirmationCode',
    AuthorizeBankOnboardingPage_ConfirmationCode_Placeholder: 'confirmationCodePlaceholder',
    AuthorizeBankOnboardingPage_PersonalCode_MitId_Format: 'personalCodeMitId',
    AuthorizeBankOnboardingPage_PersonalCode_MitId_Placeholder: 'personalCodeMitIdPlaceholder',
    AuthorizeBankOnboardingPage_ConfirmationCode_MitId: 'confirmationCodeMitId',
    AuthorizeBankOnboardingPage_ConfirmationCode_MitId_Placeholder: 'confirmationCodeMitIdPlaceholder',
    AuthorizeBankOnboardingPage_Mitid_Field_Username: 'mitIdUsername',
};

const getBankTerms = (
    defaultBankTerms: BankTerms,
    bankName: string,
    terms?: LocalizationTerm[],
    locale = 'en',
    bankId?: Bank['id'],
): BankTerms => {
    if (!terms || !bankId) {
        return defaultBankTerms;
    }
    let filtered = terms.filter((term) => term.bankId === bankId && term.culture === locale);
    if (!filtered.length) {
        filtered = terms.filter((term) => term.bankId === undefined && term.culture === locale);
    }

    return filtered.reduce(
        (bankTerms, term) => ({
            ...bankTerms,
            [KEY_MAP[term.key]]: term.value.replace('{0}', bankName),
        }),
        defaultBankTerms,
    );
};

export default getBankTerms;
