import bind from '../helpers/bind';
import reducer, * as getters from './reducer';

const {wrapAll, binder} = bind(reducer);
export default binder;

export const {
    getGraph,
    getInfo,
    getPortfolios,
    isLoading,
    isPortfoliosLoading,
} = wrapAll(getters);
