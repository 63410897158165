import bind from '../helpers/bind';
import reducer, * as getters from './reducer';

const {binder, wrapAll} = bind(reducer);

export default binder;

export const {
    getAccounts,
    getCustodyAccounts,
    getCashAccounts,
    getAssetsManagers,
    getPortfolios,
    isLoading,
    isPortfoliosLoading,
    getBanks,
    isAddAccountLoading,
    isAccountUpdating,
    getAccountUpdateError,
    getAddAccountError,
    getBankAccounts,
    getError,
} = wrapAll(getters);
