import typeReader from 'store/helpers/type_reader';
import typeShape, {required, optional} from 'store/helpers/type_shape';

const AssetManagerType = {
    id: required(String),
    name: required(String),
    bondsRiskLevel: optional(Number),
    cashRiskLevel: optional(Number),
    equityRiskLevel: optional(Number),
    totalRiskLevel: optional(Number),
    benchmarks: [{
        entityForAssignId: required(String),
        id: required(String),
        name: optional(String),
        weight: required(Number),
    }],
    limits: [{
        id: required(String, 'typeId'),
        type: required(String, 'name'),
        value: required(Number),
    }],
};

export const readAssetsManager = typeReader(AssetManagerType);
export const assetsManagerShape = typeShape(AssetManagerType);
