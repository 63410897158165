import React from 'react';
import {Helmet} from 'react-helmet';
import ga from '../../config/ga';

export const renderHeader = (title, mobile) => (
    <Helmet>
        <title>{title}</title>
        {mobile && <meta name="viewport" content="width=device-width, initial-scale=1.0" />}
    </Helmet>
);

export const renderGA = () => (
    <Helmet>
        <script
            src={`https://www.googletagmanager.com/gtag/js?id=${ga.id}`}
            async
        />
        <script>
            {`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);};
                gtag('js', new Date());
                gtag('config', '${ga.id}');
            `}
        </script>
    </Helmet>
);

export const renderHotJar = () => (
    <Helmet>
        <script>
            {`
                (function(h,o,t,j,a,r){
                    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                    h._hjSettings={hjid:1676050,hjsv:6};
                    a=o.getElementsByTagName('head')[0];
                    r=o.createElement('script');r.async=1;
                    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                    a.appendChild(r);
                })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
            `}
        </script>
    </Helmet>
);

export default renderHeader;
