import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {formValueSelector} from 'redux-form';

import * as pathnames from 'config/pathnames';

import {connect} from 'store';
import navigate from 'store/navigate';

import {checkClientExistence, clearCheckExistenceError, createClient, updateClient} from 'store/new-client/actions';
import {isClientExist, isCheckExistenceLoading, getCheckExistenceError} from 'store/new-client';

import {BasicDialog as Dialog} from 'components/dialogs';

import ClientEditForm from './new-client-form';
import ButtonPanel from './button-panel';

const selector = formValueSelector('rm-add-new-client');

class NewClientPage extends Component {
    static propTypes = {
        navigate: PropTypes.func.isRequired,
        clientEmail: PropTypes.string,
        checkExistenceLoading: PropTypes.bool,
        checkExistenceError: PropTypes.shape({}),
        checkClientExistence: PropTypes.func.isRequired,
        clearCheckExistenceError: PropTypes.func.isRequired,
        updateClient: PropTypes.func.isRequired,
        createClient: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {isFormValid: null, clientId: null};
    }

    onFormRef = (form) => {
        this.form = form;
    };

    onNext = (e) => {
        this.props.checkClientExistence(this.props.clientEmail)
            .then((res) => {
                const callback = res.length === 0 ? () => this.form.wrappedInstance.submit(e) : null;
                this.setState({clientId: res}, callback);
            });
    };

    onSubmit = (data) => {
        const {clientId} = this.state;
        let promise;
        if (clientId) {
            promise = this.props.updateClient({id: clientId, ...data});
        } else {
            promise = this.props.createClient({...data});
        }

        return promise.then(({id}) => {
            this.props.navigate(`${pathnames.NEW_CLIENT}/${id}/edit`);
        });
    };

    onCancelUpdate = () => this.setState({clientId: null});
    onPerformUpdate = () => {
        this.form.wrappedInstance.submit();
        this.onCancelUpdate();
    };

    onValidateResult = isFormValid => this.setState({isFormValid});

    renderTopView = () => (
        <ButtonPanel
            buttonsDisabled={!this.state.isFormValid}
            onNextButtonClick={this.onNext}
        />
    );

    render() {
        const {checkExistenceLoading, checkExistenceError} = this.props;
        const {clientId} = this.state;

        return (
            <div>
                <ClientEditForm
                    ref={this.onFormRef}
                    form="rm-add-new-client"
                    onSubmit={this.onSubmit}
                    topView={this.renderTopView()}
                    onValidateResult={this.onValidateResult}
                    loading={checkExistenceLoading}
                    loadingError={checkExistenceError}
                    clearLoadingError={this.props.clearCheckExistenceError}
                />
                {clientId &&
                <Dialog
                    message="This client already exist. Do you want to update it'"
                    onCancel={this.onCancelUpdate}
                    onOk={this.onPerformUpdate}
                />}
            </div>
        );
    }
}

export default connect(
    {
        clientEmail: state => selector(state, 'email'),
        checkExistenceLoading: isCheckExistenceLoading,
        checkExistenceError: getCheckExistenceError,
        isExist: isClientExist,
    },
    {
        navigate,
        checkClientExistence,
        clearCheckExistenceError,
        createClient,
        updateClient,
    },
)(NewClientPage);
