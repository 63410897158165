import {handleActions, apiActionHandler} from 'store/helpers/actions';
import {actionHandlers} from 'store/collections/reducer_helpers';

import {readTransaction} from 'store/data/client';

import {
    addTransaction,
    clearAddTransactionError,
    clearUpdateTransactionError,
    updateTransaction,
} from './actions';

const initialState = {
    addTransactionError: null,
    addTransactionLoading: false,
    updateTransactionError: null,
    updateTransactionLoading: false,
};

export {
    getCollectionState,
    getPinnedError,
    getFetchParams,
} from 'store/collections/reducer_helpers';

export const isUpdateTransactionLoading = ({updateTransactionLoading}) => updateTransactionLoading;
export const getUpdateTransactionError = ({updateTransactionError}) => updateTransactionError;
export const isAddTransactionLoading = ({addTransactionLoading}) => addTransactionLoading;
export const getAddTransactionError = ({addTransactionError}) => addTransactionError;

export default handleActions(
    {
        [addTransaction]: (state, action) => apiActionHandler(
            state,
            action,
            {loading: 'addTransactionLoading', error: 'addTransactionError'},
        ),
        [updateTransaction]: (state, action) => apiActionHandler(
            state,
            action,
            {loading: 'updateTransactionLoading', error: 'updateTransactionError'},
        ),
        [clearAddTransactionError]: state => ({...state, addTransactionError: null}),
        [clearUpdateTransactionError]: state => ({...state, updateTransactionError: null}),
        ...actionHandlers('clientTransactions', readTransaction),
    },
    initialState,
);
