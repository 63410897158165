import React, {Component} from 'react';
import PropTypes from 'prop-types';
import * as roles from 'config/roles';
import {connect} from 'store';
import {getRole} from 'store/auth';

@connect({role: getRole})
export default class HasRole extends Component {
    static propTypes = {
        role: PropTypes.string,
        children: PropTypes.node,
        allowedRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
    };

    render() {
        const {children, role, allowedRoles = []} = this.props;
        if (!allowedRoles.includes(role)) return null;
        return children;
    }
}

export const IsSA = ({children}) => (<HasRole allowedRoles={[roles.SA]}>{children}</HasRole>);

IsSA.propTypes = {
    children: PropTypes.node,
};

export const IsRM = ({children}) => (<HasRole allowedRoles={[roles.RM]}>{children}</HasRole>);

IsRM.propTypes = {
    children: PropTypes.node,
};

export const IsCustomer = ({children}) => (<HasRole allowedRoles={[roles.CUSTOMER]}>{children}</HasRole>);

IsCustomer.propTypes = {
    children: PropTypes.node,
};
