const devApiServer = 'proveobackend-dev.azurewebsites.net';
const envApiServer = {
    test: 'proveobackend-test.azurewebsites.net',
    staging: 'proveobackend-staging.azurewebsites.net',
    production: 'proveo-backend.azurewebsites.net',
};

const apiServer = process.env.LO_SERVER === ''
    ? envApiServer[process.env.NODE_ENV] || devApiServer
    : process.env.LO_SERVER;

const apiUrl = `https://${apiServer}/api`;

/**
 * API base URL
 */
export default apiUrl;
export const BASE_API_URL = apiUrl;

export const TOKEN_KEY = '___token___';
export const LOGIN_POPUP_BLOCKED = '___login_popup_blocked___';

/**
 * API request timeout, ms
 */
export const DEFAULT_TIMEOUT = 15000;
