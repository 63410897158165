import typeReader from 'store/helpers/type_reader';
import typeShape, {required, optional} from 'store/helpers/type_shape';

const AssetType = {
    id: required(String),
    bankId: optional(String),
    bankName: optional(String),
    originalId: optional(String),
    isin: optional(String),
    name: required(String),
    region: optional(String),
    type: required(String),
    currency: optional(String),
    isNew: required(Number),
};

export const readAsset = typeReader(AssetType);
export const assetShape = typeShape(AssetType);
export const AssetTypeEnum = Object.freeze({
    equity: 'Equity',
    bonds: 'Bond',
    cash: 'Cash',
    alternative: 'Alternative',
});
export const Regions = [
    'Asia',
    'Denmark',
    'Emerging Markets',
    'Europe',
    'Nordic',
    'US',
    'World',
];
