import typeReader from 'store/helpers/type_reader';
import typeShape, {required, optional} from 'store/helpers/type_shape';

const BankType = {
    accounts: [{
        id: required(String),
        type: required(String),
        name: required(String),
    }],
    id: required(String),
    name: optional(String),
    description: optional(String),
    countryId: optional(String),
    isTinkSupported: optional(Boolean),
    isTinkOutdated: optional(Boolean),
    isOutdated: optional(Boolean),
    bankId: optional(String),
    authMethod: optional(String),
};

const BankAccountType = {
    id: required(String),
    type: required(String),
    name: required(String),
    value: optional(Number),
    currency: required(String),
};

export const readBank = typeReader(BankType);
export const bankShape = typeShape(BankType);

export const readBankAccount = typeReader(BankAccountType);
export const bankAccountShape = typeShape(BankAccountType);
