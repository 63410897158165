import typeReader from 'store/helpers/type_reader';
import typeShape, {required} from 'store/helpers/type_shape';

const PerformanceGraphType = {
    period: required(String),
    returns: [{
        value: required(Number),
        date: required(String),
    }],
    benchmark: [{
        value: required(Number),
        date: required(String),
    }],
};

const PerformanceInfoType = {
    totalBenchmark: required(Number),
    totalBenchmarkForYear: required(Number),
    totalBenchmarkForMonth: required(Number),
    totalBenchmarkForDay: required(Number),

    totalPortfolio: required(Number),
    totalPortfolioForYear: required(Number),
    totalPortfolioForMonth: required(Number),
    totalPortfolioForDay: required(Number),

    totalOutperformance: required(Number),
    totalOutperformanceForYear: required(Number),
    totalOutperformanceForMonth: required(Number),
    totalOutperformanceForDay: required(Number),
};

export const readPerformanceGraph = typeReader(PerformanceGraphType);
export const performanceGraphShape = typeShape(PerformanceGraphType);

export const readPerformanceInfo = typeReader(PerformanceInfoType);
export const performanceInfoShape = typeShape(PerformanceInfoType);
