import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {reduxForm, handleSubmitError} from 'components/forms';
import BasicDialog from './basic-dialog';

@reduxForm({form: 'dialog'})
export default class Dialog extends Component {
    static propTypes = {
        className: PropTypes.string,
        message: PropTypes.string.isRequired,
        onDelete: PropTypes.func,
        onBlock: PropTypes.func,
        onUnblock: PropTypes.func,
        onCancel: PropTypes.func.isRequired,
        submitting: PropTypes.bool,
        handleSubmit: PropTypes.func.isRequired,
    };

    componentDidMount() {
        window.addEventListener('keyup', this.onKeyUp);
    }

    componentWillUnmount() {
        window.removeEventListener('keyup', this.onKeyUp);
    }

    onKeyUp = (event) => {
        if (event.keyCode === 27) {
            this.props.onCancel();
        }
    };

    onSubmit = (event) => {
        event.preventDefault();
        const {onBlock, onDelete, onUnblock, handleSubmit} = this.props;
        const submitter = () => {
            const promise = (onBlock && onBlock())
                || (onDelete && onDelete())
                || (onUnblock && onUnblock());
            if (promise && typeof promise.then === 'function') {
                return handleSubmitError(promise);
            }
            return null;
        };
        return handleSubmit(submitter)(event);
    };

    render() {
        const {
            className, message, onCancel, submitting,
            onBlock, onDelete, onUnblock,
        } = this.props;

        return (
            <BasicDialog
                loading={submitting}
                className={className}
                message={message}
                onDelete={onDelete && this.onSubmit}
                onBlock={onBlock && this.onSubmit}
                onUnblock={onUnblock && this.onSubmit}
                onCancel={onCancel}
            />
        );
    }
}
