import typeReader from '../helpers/type_reader';
import typeShape, {required, optional} from '../helpers/type_shape';

const NotificationType = {
    id: required(String),
    subject: required(String),
    type: required(String),
    message: required(String),
    senderId: optional(String),
    senderFirstName: required(String),
    senderLastName: required(String),
    createdAt: required(String),
    sentAt: optional(String),
};

const CustomerType = {
    id: required(String),
    managerId: optional(String),
    managerFirstName: optional(String),
    managerLastName: optional(String),
    firstName: optional(String),
    lastName: optional(String),
    email: optional(String),
    status: optional(String),
    creationDate: optional(String),
};

export const readCustomer = typeReader(CustomerType);
export const readNotification = typeReader(NotificationType);
export const notificationShape = typeShape(NotificationType);

export const notificationTypes = {
    portfolioNews: 'Portfolio News',
    marketNews: 'Market News',
    proveoNews: 'Proveo News',
    other: 'Other',
};
