import React, {useCallback, useMemo, useState} from 'react';
import moment from 'moment';
import {Button, Cell, Grid, SelectField, TextField} from 'react-md';
import {ListValue} from 'react-md/lib/SelectFields/SelectField';
import {connect} from '../../../store';
import DatePicker from '../../date-picker';
import styles from './power-bi-report-dialog.module.css';
import Version from '../../../store/types/Version';
import {getVersions} from '../../../store/structure-version';

type Props = {
    data: FormState,
    onFieldChange: (id: keyof FormState, value: unknown) => void,
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void,
    onClose: () => void,
    versions: Version[],
}

type ReportType = 'Month' | 'Quarter' | 'Year'
export type FormState = {
    type: ReportType,
    dateTo: Date,
    proveoId?: string,
    clientName?: string,
    rmEmail: string,
    version: Version | undefined,
}

const TYPE_OPTIONS = [{
    label: 'Monthly',
    value: 'Month',
}, {
    label: 'Quarterly',
    value: 'Quarter',
}];

const ReportForm: React.FC<Props> = ({data, onSubmit, onClose, onFieldChange, versions}) => {
    const onVersionChanged = useCallback((value: ListValue) => {
        const version = versions.find(item => item.id === value);
        onFieldChange('version', version);
    }, [versions]);

    return (
        <React.Fragment>
            <form onSubmit={onSubmit}>
                <Grid>
                    <Cell size={12}>
                        <SelectField
                            id="type"
                            label="Type of reporting"
                            placeholder="Choose a type"
                            className={styles.fullWidth}
                            menuItems={TYPE_OPTIONS}
                            simplifiedMenu
                            value={data.type}
                            onChange={(value: ListValue) => onFieldChange('type', value)}
                        />
                    </Cell>
                </Grid>
                <Grid>
                    <Cell size={12}>
                        <div className="prv-edit-benchmark-group-dialog__label">Report date</div>
                        <DatePicker
                            date={data.dateTo}
                            onChange={(value: moment.Moment) => onFieldChange('dateTo', value)}
                            inputReadOnly
                            showNow={false}
                            snowToday={false}
                        />
                    </Cell>
                </Grid>
                <Grid>
                    <Cell size={12}>
                        <SelectField
                            id="version"
                            itemLabel="label"
                            itemValue="id"
                            className={styles.fullWidth}
                            label="Structure version"
                            placeholder="Choose a version"
                            menuItems={[{id: '', label: ''}, ...versions]}
                            onChange={onVersionChanged}
                            simplifiedMenu
                            value={data.version?.id}
                        />
                    </Cell>
                </Grid>
                <Grid>
                    <Cell size={12}>
                        <TextField
                            id="proveoId"
                            label="proVeo ID"
                            placeholder="Enter proVeo ID"
                            value={data.proveoId || ''}
                            disabled={true as boolean}
                            onChange={(val) => onFieldChange('proveoId', val)}
                        />
                    </Cell>
                </Grid>
                <Grid>
                    <Cell size={12}>
                        <TextField
                            id="clientName"
                            label="Client name"
                            placeholder="Enter client name"
                            value={data.clientName || ''}
                            onChange={(val) => onFieldChange('clientName', val)}
                            required
                            disabled={true as boolean}
                        />
                    </Cell>
                </Grid>
                <Grid>
                    <Cell size={12}>
                        <TextField
                            id="rmEmail"
                            label="Report send to:"
                            placeholder="Enter email"
                            value={data.rmEmail || ''}
                            onChange={(val) => onFieldChange('rmEmail', val)}
                            required
                            type="email"
                        />
                    </Cell>
                </Grid>
                <div className={styles.buttonsContainer}>
                    <Button flat secondary onClick={onClose}>Cancel</Button>
                    <Button flat primary type="submit">Send Report</Button>
                </div>
            </form>
        </React.Fragment>
    );
};

// @ts-ignore
export default connect({versions: getVersions})(ReportForm);
