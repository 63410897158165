import {actionDispatchers} from '../collections/saga_helpers';
import {getFetchParams} from './index';

export default {
    ...actionDispatchers('notifications', {
        fetch: {
            apiCall: 'fetchNotificationsList',
            getFetchParams,
        },
    }),
};
