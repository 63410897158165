import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import * as pathnames from 'config/pathnames';
import {connect} from 'store';
import {navigate} from 'store/navigate';
import {getProfile, isSA} from 'store/auth';
import SidebarMenuItem from './sidebar-menu-item';
import * as roles from '../../../config/roles';
import HasRole from '../../has-role/has-role';
import {profileShape} from '../../../store/data/profile';

class Sidebar extends Component {
    static propTypes = {
        className: PropTypes.string,
        isSA: PropTypes.bool.isRequired,
        navigate: PropTypes.func,
        profile: profileShape,
    };

    onClientCreate = e => this._onCreate(e, pathnames.CLIENTS);
    onRMSCreate = e => this._onCreate(e, pathnames.RMS);
    onBenchmarkCreate = e => this._onCreate(e, pathnames.CLIENT_BENCHMARKS);
    onNotificationCreate = e => this._onCreate(e, pathnames.NOTIFICATIONS);

    _onCreate = (e, path, postfix = 'create') => {
        e.preventDefault();
        this.props.navigate(`${path}/${postfix}`);
    };

    render() {
        const {className, profile} = this.props;
        return (
            <div className={cn('prv-sidebar-container', className)}>
                <div className="prv-sidebar">
                    <div className="prv-sidebar__header">
                        <div className="prv-sidebar__logo"/>
                    </div>
                    <HasRole allowedRoles={[roles.CUSTOMER]}>
                        {profile && (
                            <ul className="prv-sidebar__menu-items">
                                <SidebarMenuItem
                                    className="prv-sidebar__menu-item"
                                    icon="person"
                                    title="My proVeo"
                                    url={`${pathnames.CLIENTS}/${profile.id}`}
                                />
                            </ul>
                        )}
                    </HasRole>
                    <HasRole allowedRoles={[roles.SA, roles.RM]}>
                        <ul className="prv-sidebar__menu-items">
                            <SidebarMenuItem
                                className="prv-sidebar__menu-item"
                                icon="person"
                                onCreate={this.onClientCreate}
                                title="Clients"
                                url={pathnames.CLIENTS}
                            />
                            {this.props.isSA &&
                            <SidebarMenuItem
                                className="prv-sidebar__menu-item"
                                icon="supervisor_account"
                                onCreate={this.onRMSCreate}
                                title="RMs"
                                url={pathnames.RMS}
                            />}
                            <SidebarMenuItem
                                className="prv-sidebar__menu-item"
                                icon="timelapse"
                                title="MS API"
                                url={pathnames.MS_API}
                            />
                            <SidebarMenuItem
                                className="prv-sidebar__menu-item"
                                icon="show_chart"
                                onCreate={this.onBenchmarkCreate}
                                title="Benchmarks"
                                url={pathnames.CLIENT_BENCHMARKS}
                            />
                            <SidebarMenuItem
                                className="prv-sidebar__menu-item"
                                icon="notifications"
                                onCreate={this.onNotificationCreate}
                                title="Notifications"
                                url={pathnames.NOTIFICATIONS}
                            />
                            <SidebarMenuItem
                                className="prv-sidebar__menu-item"
                                icon="pie_chart"
                                title="Assets"
                                url={pathnames.ASSETS}
                            />
                            <SidebarMenuItem
                                className="prv-sidebar__menu-item"
                                icon="receipt"
                                title="Transactions"
                                url={pathnames.TRANSACTIONS}
                            />
                        </ul>
                    </HasRole>
                </div>
                <div className="prv-sidebar__bottom"/>
            </div>
        );
    }
}

export default connect(
    {
        isSA,
        profile: getProfile,
    },
    {navigate},
)(Sidebar);
