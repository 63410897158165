import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'store';

import Moire from 'components/moire/moire';
import {isCreatingNotification, getCreateNotificationError} from 'store/new-notifications';
import {createNotification, updateNotification, clearCreateNotificationError} from 'store/new-notifications/actions';
import SelectType from '../select-type';
import NotificationMessage from '../message';
import SelectNotificationType from '../select-users-count';
import translations from '../translate';

const firstStep = 1;
const lastStep = 5;

export class NewNotificationDialog extends React.Component {
    static propTypes = {
        onCancel: PropTypes.func.isRequired,
        // createNotificationError: PropTypes.func.isRequired,
        // loading: PropTypes.bool.isRequired,
        createNotification: PropTypes.func.isRequired,
        updateNotification: PropTypes.func.isRequired,
        notification: PropTypes.object.isRequired,
    };
    static defaultProps = {};

    state = {
        step: firstStep,
        notification: {
            customers: this.props.notification ? this.props.notification.recipients : [],
            type: this.props.notification ? this.props.notification.type : null,
            subject: this.props.notification ? this.props.notification.subject : null,
            message: this.props.notification ? this.props.notification.message : null,
            userType: this.props.notification ? this.props.notification.userType : 'proveo',
            id: this.props.notification ? this.props.notification.id : null,
            sentAt: this.props.notification ? this.props.notification.sentAt : null,
        },
        errors: [],
        sending: false,
    };

    onChange = field => value => {
        this.setState({
            notification: {
                ...this.state.notification,
                [field]: value,
            },
        });
    };

    firstStep = firstStep;

    lastStep = lastStep;

    nextStep = () => {
        this.setState((state) => ({
            step: state.step + 1,
        }));
    };

    prevStep = () => {
        this.setState((state) => ({
            step: state.step - 1,
        }));
    };

    createNotification = async () => {
        try {
            await this.setState({
                sending: true,
            });
            await this.props.createNotification({
                recipientIds: this.state.notification.customers.map(c => c.id),
                type: this.state.notification.type,
                subject: this.state.notification.subject,
                message: this.state.notification.message,
                sentAt: this.state.notification.sentAt || new Date(),
            });
            await this.props.onCancel();
        } catch (err) {
            if (err.message) {
                await this.setState({
                    errors: [err.message],
                });
            }
            await this.setState({
                sending: false,
            });
        }
    };

    updateNotification = async () => {
        try {
            await this.setState({
                sending: true,
            });
            await this.props.updateNotification({
                id: this.state.notification.id,
                recipientIds: this.state.notification.customers.map(c => c.id),
                type: this.state.notification.type,
                subject: this.state.notification.subject,
                message: this.state.notification.message,
                sentAt: this.state.notification.sentAt || new Date(),
            });
            await this.props.onCancel();
        } catch (err) {
            if (err.message) {
                await this.setState({
                    errors: [err.message],
                });
            }
            await this.setState({
                sending: false,
            });
        }
    };

    steps = (index) => [
        <SelectType
            next={this.nextStep}
            onChange={this.onChange}
            notification={this.state.notification}
        />,
        <SelectNotificationType
            back={this.prevStep}
            next={this.nextStep}
            onChange={this.onChange}
            notification={this.state.notification}
        />,
        <NotificationMessage
            back={this.prevStep}
            next={this.state.notification.id ? this.updateNotification : this.createNotification}
            onChange={this.onChange}
            notification={this.state.notification}
            errors={this.state.errors}
            loading={this.state.sending}
        />,
    ][index];

    render() {
        const {onCancel} = this.props;
        const language = window.navigator.language.includes('da') ? 'danske' : 'english';

        return (
            <Moire onCancel={onCancel}>
                <div className="prv-notification-edit-dialog prv-new-notification-edit-dialog">
                    <div
                        className="prv-notification-edit-dialog__header"
                    >
                        <span>
                            {this.state.notification.id
                                ? translations[language].general.update
                                : translations[language].general.create
                            }
                        </span>
                        <div
                            className="prv-notification-edit-dialog__close-icon"
                            onClick={onCancel}
                        />
                    </div>
                    {this.steps([this.state.step - 1])}
                    {this.state.errors.map(err => (
                        <div
                            className="prv-notification-edit-dialog__content--errors"
                        >
                            {err}
                        </div>
                    ))}
                </div>
            </Moire>
        );
    }
}


export default connect(
    {
        loading: isCreatingNotification,
        createNotificationError: getCreateNotificationError,
    },
    {
        createNotification,
        updateNotification,
        clearCreateNotificationError,
    },
)(NewNotificationDialog);
