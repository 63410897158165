import React from 'react';
import {SelectionControlGroup} from 'react-md';
import uuid from 'uuid';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import translations from '../translate';

export class SelectType extends React.PureComponent {
    static propTypes = {
        chooseNotificationType: PropTypes.func.isRequired,
        next: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
        notification: PropTypes.object.isRequired,
    }

    constructor(props) {
        super(props);

        this.ids = [uuid.v4()];
    }

    onChange = (value) => {
        this.props.onChange('userType')(value);
    }

    onSuccess = () => {
        // this.props.chooseNotificationType(this.state.value);
        this.props.next();
    }

    canNext = () => !(isEmpty(this.props.notification.userType))

    render() {
        const {notification, next, ...props} = this.props;
        const language = window.navigator.language.includes('da') ? 'danske' : 'english';

        return (
            <React.Fragment>
                <div
                    className="prv-notification-edit-dialog__content for-radios"
                >
                    <span className="subheader">
                        {translations[language].selectType.title}
                    </span>
                </div>
                <div
                    className="prv-notification-edit-dialog__content radios"
                >
                    <SelectionControlGroup
                        id={this.ids[0]}
                        name="using-custom-icons"
                        type="radio"
                        controls={[{
                            label: translations[language].selectType.proveo,
                            value: 'proveo',
                        }, {
                            label: translations[language].selectType.atlaz,
                            // value: 'atlaz',
                            value: 'atlazonboarded',
                        }]}
                        value={notification.userType}
                        className="prv-notification-edit-dialog__content-radios"
                        onChange={this.onChange}
                    />
                </div>
                <div
                    className="prv-notification-edit-dialog__footer"
                >
                    <button
                        className="prv-notification-edit-dialog__save-button"
                        onClick={next}
                        disabled={!this.canNext()}
                    >
                        {translations[language].general.next}
                    </button>
                </div>
            </React.Fragment>
        );
    }
}

export default SelectType;
