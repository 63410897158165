import React, {useEffect} from 'react';
import cn from 'classnames';
import CircularProgress from 'react-md/lib/Progress/CircularProgress';
import PortfolioTree from './components/PortfolioTree';
import Footer from './components/Footer';
import PortfolioAssets from './components/PortfolioAssets';
import AvailableAccounts from './components/AvailableAccounts';
import {connect} from '../../../store';
import {fetchStructure} from '../../../store/structure/actions';
import {fetchVersions} from '../../../store/structure-version/actions';
import {
    getError as getVersionError,
    getLoading as getVersionLoading,
    getDefaultVersion,
    getSelectedVersion,
} from '../../../store/structure-version';
import {getError as getStructureError, getLoading as getStructureLoading, getTree} from '../../../store/structure';
import {getPinnedClient} from '../../../store/clients';
import {StructureArray} from '../../../store/structure/types';
import Client from '../../../store/types/Client';
import Version from '../../../store/types/Version';

interface StateProps {
    client?: Client,
    isStructureLoading: boolean,
    isVersionLoading: boolean,
    structureError?: Error,
    tree: StructureArray,
    version?: Version,
    defaultVersion?: Version,
    versionError?: Error,
}

interface DispatchProps {
    fetchStructure: (versionId: Version['id']) => void,
}

interface Props extends StateProps, DispatchProps {}

const StructurePage: React.FC<Props> = ({
    client,
    fetchStructure,
    isStructureLoading,
    isVersionLoading,
    structureError,
    tree,
    version,
    versionError,
}) => {
    useEffect(() => {
        fetchVersions();
    }, [fetchVersions]);
    useEffect(
        () => {
            if (version) {
                fetchStructure(version.id);
            }
        },
        [fetchStructure, version],
    );
    const loading = isStructureLoading || isVersionLoading;
    const error = versionError || structureError;
    const centeredContent = loading || (!loading && error);
    return (
        <div className="prv-client-structure-page">
            <div
                className={cn(
                    'prv-client-structure-page__container',
                    {'prv-client-structure-page__container--center-content': centeredContent},
                )}
            >
                {loading && <CircularProgress id="structure-page-loader" scale={2}/>}
                {!loading && !error && client && version && tree.length > 0 && (
                    <>
                        <div className="prv-client-structure-page__content">
                            <PortfolioTree
                                className="prv-client-structure-page__portfolios"
                                client={client}
                            />
                            <PortfolioAssets className="prv-client-structure-page__portfolio-content"/>
                            <AvailableAccounts className="prv-client-structure-page__assets"/>
                        </div>
                    </>
                )}
                {!loading && error && (
                    <div>
                        <b>Unexpected error happened:</b>
                        <div>{error?.message}</div>
                        <b>Please try reloading the page.</b>
                    </div>
                )}
            </div>
            {!versionError && (
                <Footer
                    className="prv-client-structure-page__footer"
                    client={client}
                    showButtons={!isStructureLoading && !structureError}
                />
            )}
        </div>
    );
};

// FIXME: figure out how to type the connect function
// @ts-ignore
export default connect(
    {
        client: getPinnedClient,
        structureError: getStructureError,
        versionError: getVersionError,
        isStructureLoading: getStructureLoading,
        isVersionLoading: getVersionLoading,
        tree: getTree,
        version: getSelectedVersion,
        defaultVersion: getDefaultVersion,
    },
    {
        fetchStructure,
        fetchVersions,
    },
)(StructurePage);
