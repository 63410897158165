import _ from 'lodash';
import {handleActions, apiActionHandler} from 'store/helpers/actions';
import {actionHandlers} from 'store/collections/reducer_helpers';
import {readClientBenchmark} from 'store/data/client-benchmark';

import {
    createClientBenchmark,
    updateClientBenchmark,
    deleteClientBenchmark,
    fetchAvailableBenchmarks,
} from './actions';
import {readBenchmark} from '../data/client';

export {
    getCollectionState,
    getFetchParams,
} from 'store/collections/reducer_helpers';

const initialState = {
    creatingClientBenchmark: false,
    updatingClientBenchmark: false,
    deletingClientBenchmark: false,
    availableBenchmarks: [],
    availableBenchmarksLoading: false,
};

export const isCreatingClientBenchmark = ({creatingClientBenchmark}) => creatingClientBenchmark;
export const isUpdatingClientBenchmark = ({updatingClientBenchmark}) => updatingClientBenchmark;
export const isDeletingClientBenchmark = ({deletingClientBenchmark}) => deletingClientBenchmark;
export const getAvailableBenchmarks = ({availableBenchmarks}) => availableBenchmarks;
export const areAvailableBenchmarksLoading = ({availableBenchmarksLoading}) => availableBenchmarksLoading;

export default handleActions({
    ...actionHandlers('client-benchmarks', readClientBenchmark),
    [createClientBenchmark]: (state, action) => apiActionHandler(state, action, {loading: 'creatingClientBenchmark'}),
    [updateClientBenchmark]: (state, action) => apiActionHandler(state, action, {loading: 'updatingClientBenchmark'}),
    [deleteClientBenchmark]: (state, action) => apiActionHandler(state, action, {loading: 'deletingClientBenchmark'}),
    [fetchAvailableBenchmarks]: (state, action) => apiActionHandler(state, action, {
        loading: 'availableBenchmarksLoading',
        result: 'availableBenchmarks',
        reader: p => _.map(p.items, readBenchmark),
    }),
}, initialState);
