export const transactionsTypes =
    ['buy', 'sell', 'unknown', 'assetInterests', 'deposit', 'dividends', 'fees', 'interest', 'withdrawal',
        'settlement', 'tradeNeutral'];
export const typesWithCustodySetup = ['buy', 'sell', 'assetInterests', 'dividends', 'fees'];
export const typesWithPriceSetup = ['buy', 'sell'];

export const CURRENCY_LIST = [
    'AUD', 'BGN', 'BRL', 'CAD', 'CHF', 'CNY', 'CZK', 'DKK', 'EUR', 'GBP', 'HKD', 'HRK', 'HUF', 'IDR', 'ILS', 'INR',
    'ISK', 'JPY', 'KRW', 'MXN', 'MYR', 'NOK', 'NZD', 'PHP', 'PLN', 'RON', 'RUB', 'SEK', 'SGD', 'THB', 'TRY', 'USD',
    'ZAR',
];

export default transactionsTypes;
