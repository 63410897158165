import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {connect} from '../../store';
import {navigate} from '../../store/navigate';

import {
    fetchClient,
    fetchBanksForClient,
    resetState,
    deleteBank,
    clearClientBanksError,
    updateClient,
} from '../../store/new-client/actions';
import {
    getClient,
    isLoading,
    getClientBanksError,
    getClientBanks,
    isClientBanksLoading,
} from '../../store/new-client';

import {
    clientShape,
    editClient,
    bankShape,
} from '../../store/data/client';


import Spinner from '../../components/loading-spinner/loading-spinner';
import Table from '../../components/form-table';

import ClientEditForm from './new-client-form';
import AddBankAccount from './add-bank-dialog';
import ButtonPanel from './button-panel';

const columns = [
    {field: 'name', label: 'Bank'},
    {field: 'description', label: 'Description'},
];

@withRouter
@connect(
    {
        loading: isLoading,
        client: getClient,
        banks: getClientBanks,
        clientBanksLoading: isClientBanksLoading,
        clientBanksError: getClientBanksError,
    },
    {
        fetchClient,
        fetchBanksForClient,
        deleteBank,
        updateClient,
        clearClientBanksError,
        navigate,
        resetState,
    },
)
export default class NewClientPage extends Component {
    static propTypes = {
        client: clientShape,
        loading: PropTypes.bool,
        clientBanksLoading: PropTypes.bool,
        banks: PropTypes.arrayOf(bankShape),
        clientBanksError: PropTypes.object,
        fetchClient: PropTypes.func.isRequired,
        updateClient: PropTypes.func.isRequired,
        fetchBanksForClient: PropTypes.func.isRequired,
        deleteBank: PropTypes.func.isRequired,
        clearClientBanksError: PropTypes.func.isRequired,
        navigate: PropTypes.func.isRequired,
        resetState: PropTypes.func.isRequired,
        match: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {shouldRenderAddBankDialog: false};
    }

    componentWillMount() {
        const {match} = this.props;
        const {params} = match;
        this.props.fetchClient({id: params.id});
        this.props.fetchBanksForClient({id: params.id});
    }

    componentWillUnmount() {
        this.props.resetState();
    }

    onFormRef = (form) => {
        this.form = form;
    };

    onAddBank = () => {
        this.setState({shouldRenderAddBankDialog: true});
    };

    onAddBankSuccess = () => {
        this.setState({shouldRenderAddBankDialog: false});
        this.props.fetchBanksForClient({id: this.props.match.params.id});
    };

    onCloseAddBankDialog = () => {
        this.setState({shouldRenderAddBankDialog: false});
    };

    onSave = () => {
        this.form.wrappedInstance.submit();
    };

    onFinishWithClient = () => this.props.navigate('/');

    onUpdateClient = data => this.props.updateClient({id: this.props.match.params.id, ...data});

    onUpdateClientSuccess = () => this.props.fetchClient({id: this.props.match.params.id});

    onFilter = items => this.setState({filteredItemsCount: items && items.length});

    renderTopView = () => (
        <ButtonPanel
            buttonsDisabled={this.props.loading}
            onNextButtonClick={this.onSave}
            onFinishButtonClick={this.onFinishWithClient}
        />
    );

    renderTable = () => (
        <Table
            actionButton={this.renderAddAccountButton()}
            title={this.renderTitle()}
            items={this.props.banks || []}
            searchFields={['name', 'description']}
            columns={columns}
            canDelete={() => true}
            onDelete={({id}) => this.props.deleteBank({id})}
            refresh={() => this.props.fetchBanksForClient({id: this.props.match.params.id})}
            onFilter={this.onFilter}
            loading={this.props.clientBanksLoading}
            renderTableEmpty={this.renderTableEmpty}
            error={this.props.clientBanksError}
            onClearError={this.props.clearClientBanksError}
        />
    );

    renderTableEmpty = () => <div>The Client has no accounts</div>;

    renderTitle = () => (
        <div className="prv-new-client-edit-page__form-title">
            <span>Connected Banks</span>
            <div className="prv-new-client-edit-page__separator"/>
            <span className="prv-new-client-edit-page__bank-count">
                {
                    this.state.filteredItemsCount == null ?
                        this.props.banks && this.props.banks.length :
                        `Found ${this.state.filteredItemsCount}`
                }
            </span>
        </div>
    );

    renderAddAccountButton = () => (
        <button
            className="prv-new-client-edit-page__add-bank-button"
            onClick={this.onAddBank}
            disabled={this.props.loading}
        >
            <span className="prv-new-client-edit-page__add-bank-button-title">Add Bank</span>
        </button>
    );

    render() {
        const {shouldRenderAddBankDialog} = this.state;
        const {client, loading} = this.props;

        return (
            <div className="prv-new-client-edit-page">
                <ClientEditForm
                    ref={this.onFormRef}
                    initialValues={editClient(client)}
                    onSubmit={this.onUpdateClient}
                    onSubmitSuccess={this.onUpdateClientSuccess}
                    bottomView={this.renderTable()}
                    topView={this.renderTopView()}
                    enableReinitialize
                    emailDisabled
                />
                {shouldRenderAddBankDialog &&
                <AddBankAccount
                    onCancel={this.onCloseAddBankDialog}
                    onAddBankSuccess={this.onAddBankSuccess}
                />
                }
                {loading && <Spinner/>}
            </div>
        );
    }
}
