import typeReader from 'store/helpers/type_reader';
import typeShape, {required, optional} from 'store/helpers/type_shape';

const LimitType = {
    id: required(String, 'typeId'),
    name: required(String, 'name'),
    value: optional(Number, 'value'),
};

const CountryType = {
    id: required(String),
    name: required(String),
};

export const readLimit = typeReader(LimitType);
export const limitShape = typeShape(LimitType);

export const readCountry = typeReader(CountryType);
export const countryShape = typeShape(CountryType);
