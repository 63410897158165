export default {
    LOGIN: {
        BOERSEN: 'B2C_1_sign_boersen',
        ATLAZ: 'B2C_1_sign_boersen',
        PROVEO: 'B2C_1_signin',
    },
    RESET_PASSWORD: {
        BOERSEN: 'B2C_1_passwd',
        ATLAZ: undefined,
        PROVEO: undefined,
    },
    SIGNUP: {
        BOERSEN: 'B2C_1_sign_boersen',
        ATLAZ: 'B2C_1_sign_boersen',
        PROVEO: 'B2C_1_signup',
    },
};
