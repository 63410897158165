import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import SelectField from 'react-md/lib/SelectFields/SelectField';
import TextField from 'react-md/lib/TextFields/TextField';
import Button from 'react-md/lib/Buttons/Button';

export default class BenchmarkRow extends React.PureComponent {
    static propTypes = {
        availableBenchmarks: PropTypes.arrayOf(PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
        })),
        benchmark: PropTypes.object,
        className: PropTypes.string,
        errors: PropTypes.object,
        index: PropTypes.number.isRequired,
        onBenchmarkChange: PropTypes.func,
        onWeightChange: PropTypes.func,
        onDelete: PropTypes.func,
    };

    _handleBenchmarkChange = (id, selectedBenchmarkIndex) => {
        const {availableBenchmarks, index, onBenchmarkChange} = this.props;
        onBenchmarkChange && onBenchmarkChange(index, id, availableBenchmarks[selectedBenchmarkIndex].label);
    };

    _handleWeightChange = (value) => {
        let weight = parseFloat(value);
        if (Number.isNaN(weight) || weight < 0) {
            weight = undefined;
        }

        const {index, onWeightChange} = this.props;
        onWeightChange && onWeightChange(index, weight);
    };

    _handleDelete = () => {
        const {index, onDelete} = this.props;
        onDelete && onDelete(index);
    };

    render() {
        const {availableBenchmarks, benchmark, className, errors} = this.props;

        return (
            <div className={cn('prv-benchmark-row', className)}>
                <SelectField
                    id={`prv-benchmark-row-${benchmark.id}-name`}
                    className="prv-benchmark-row__name"
                    error={errors && errors.name}
                    fullWidth={false}
                    label="Name"
                    menuItems={availableBenchmarks}
                    onChange={this._handleBenchmarkChange}
                    required
                    simplifiedMenu={false}
                    value={benchmark.id}
                />
                <TextField
                    className="prv-benchmark-row__weight"
                    error={errors && errors.weight}
                    fullWidth={false}
                    id={`prv-benchmark-row-${benchmark.id}-weight`}
                    label="Weight"
                    max={1.0}
                    min={0.0}
                    onChange={this._handleWeightChange}
                    pattern="^\d+(\.|\,)\d{0,}"
                    required
                    step={0.05}
                    type="number"
                    value={benchmark.weight === 0 ? 0 : benchmark.weight || ''}
                />
                <Button
                    className="prv-benchmark-row__delete-button"
                    icon
                    onClick={this._handleDelete}
                    tooltipLabel="Delete benchmark"
                >
                    delete
                </Button>
            </div>
        );
    }
}
