import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'store';
import {isLimitsUpdating, getLimitsUpdateError, getPinnedClient} from 'store/clients';
import {clientShape} from 'store/data/client';
import {limitShape} from 'store/data/general-data';
import {updateLimits, clearLimitsUpdateError} from 'store/clients/actions';

import EditAccountDialog from '../edit-account-dialog/edit-account-dialog';

class SetLimitsDialog extends Component {
    static propTypes = {
        client: clientShape,
        error: PropTypes.shape({
            message: PropTypes.string,
        }),
        loading: PropTypes.bool,
        updateLimits: PropTypes.func.isRequired,
        clearError: PropTypes.func.isRequired,

        limits: PropTypes.arrayOf(limitShape),
        onUpdateSuccess: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
    };

    onLimitsUpdate = ({limits = []} = {}) => {
        this.props.updateLimits({
            id: this.props.client.id,
            limits: limits.map(({id, value}) => ({typeId: id, value})),
        })
            .then(this.props.onUpdateSuccess);
    };

    render() {
        const {limits, error, loading} = this.props;

        return (
            <EditAccountDialog
                renderHeaderTitle={() => <div>Edit</div>}
                shouldBeRendered={{limits: true}}
                limits={(limits && limits.map(({name, ...params}) => ({type: name, ...params}))) || []}
                confirmationButtonTitle="Set Limits"
                onSetAccount={this.onLimitsUpdate}
                onClose={this.props.onClose}

                error={error}
                loading={loading}
                onClearError={this.props.clearError}
            />
        );
    }
}

export default connect(
    {
        client: getPinnedClient,
        loading: isLimitsUpdating,
        error: getLimitsUpdateError,
    },
    {
        updateLimits,
        clearError: clearLimitsUpdateError,
    },
)(SetLimitsDialog);
