import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {connect} from 'store';
import {getToasts} from 'store/snackbar';
import {hideToast} from 'store/snackbar/actions';
import SnackbarContainer from 'react-md/lib/Snackbars/SnackbarContainer';

class Snackbar extends PureComponent {
    static propTypes = {
        hideToast: PropTypes.func.isRequired,
        toasts: PropTypes.array.isRequired,
    };

    render() {
        const {toasts} = this.props;
        const customOnDismiss = _.get(toasts, '0.onDismiss');

        return (
            <SnackbarContainer
                autohide={_.get(toasts, '0.autohide', true)}
                id="snackbar-container"
                toasts={toasts.map(({text, action}) => ({text, action}))}
                onDismiss={() => {
                    this.props.hideToast();
                    customOnDismiss && customOnDismiss();
                }}
            />
        );
    }
}

export default connect({toasts: getToasts}, {hideToast})(Snackbar);
