import {
    callApi,
    fromStore,
    dispatch,
} from 'store/helpers/sagas';

import {createApiCallResultAction} from 'store/helpers/actions';
import {actionDispatchers} from '../collections/saga_helpers';

import {getPinnedClient} from '../clients';
import {getFetchParams} from './index';

import {
    fetchAssets,
} from './actions';

const assetsFetched = createApiCallResultAction(fetchAssets);

function* fetcher({payload}) {
    const {id} = payload.id || (yield fromStore(getPinnedClient));
    const params = yield fromStore(getFetchParams, payload);
    const {accountId, portfolioId} = params || {};

    let apiMethodName = 'fetchAllocationsForCustomer';
    if (params.portfolioId) {
        apiMethodName += 'Portfolio';
        delete params.portfolioId;
    }
    if (params.accountId) {
        apiMethodName += 'Account';
        delete params.accountId;
    }

    try {
        const result = yield callApi(apiMethodName, {id, accountId, portfolioId, ...params});
        yield dispatch(assetsFetched(result));
    } catch (error) {
        yield dispatch(assetsFetched(error));
    }
}

export default {
    [fetchAssets]: fetcher,
    ...actionDispatchers('clientAssets'),
};
