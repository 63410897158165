import {actionDispatchers} from '../collections/saga_helpers';
import {getFetchParams} from './index';

export default {
    ...actionDispatchers('rms', {
        fetch: {
            apiCall: 'fetchRMs',
            getFetchParams,
        },
        pin: {
            apiCall: 'fetchRMById',
        },
    }),
};
