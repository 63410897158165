import * as roles from 'config/roles';
import {readProfile} from '../data/profile';
import {handleActions, isApiResultAction} from '../helpers/actions';

import {
    fetchProfile,
    signedUp,
} from './actions';

const initialState = {
    profile: null,
    loading: false,
    error: null,
};

export const getProfile = ({profile}) => profile;
export const isLoading = ({loading}) => loading;
export const getError = ({error}) => error;

export const getRole = ({profile}) => (profile ? profile.role : roles.GUEST);

export const isSA = state => getRole(state) === roles.SA;
export const isRM = state => getRole(state) === roles.RM;
export const isCustomer = state => getRole(state) === roles.CUSTOMER;

export const getInvite = ({invite}) => invite;
export const isInviteLoading = ({inviteLoading}) => inviteLoading;
export const getInviteError = ({inviteError}) => inviteError;

const onFetchProfile = (state, action) => {
    if (isApiResultAction(action)) {
        const {error, payload} = action;
        if (error) {
            return {...state, error: payload, loading: false};
        }
        const profile = readProfile(payload);
        return {...state, profile, error: null, loading: false};
    }
    return {...state, error: null, loading: true};
};

export default handleActions({
    [fetchProfile]: onFetchProfile,
    [signedUp]: onFetchProfile,
}, initialState);
