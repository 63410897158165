import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import {reducer as form} from 'redux-form';

import {bindReducers} from './helpers/bind';

import api from './api';
import auth from './auth';
import breadcrumbs from './breadcrumbs';
import clients from './clients';
import accounts from './accounts';
import rms from './rms';
import clientTransactions from './client-transactions';
import customerAssets from './client-assets';
import assets from './assets';
import performance from './performance';
import risk from './risk';
import clientsAMs from './clients-assets-managers';
import clientPortfolios from './client-portfolios';
import generalData from './general-data';
import benchmarks from './benchmarks';
import clientBenchmarks from './client-benchmarks';
import newClient from './new-client';
import notifications from './notifications';
import newNotifications from './new-notifications';
import transactions from './transactions';
import snackbar from './snackbar';
import structure from './structure';
import structureVersion from './structure-version';
import onboarding from './onboarding';

export default (history) => combineReducers(bindReducers({
    router: () => connectRouter(history),
    breadcrumbs,
    form: () => form,
    api,
    auth,
    generalData,
    clients,
    accounts,
    rms,
    clientTransactions,
    customerAssets,
    assets,
    performance,
    risk,
    clientsAMs,
    clientPortfolios,
    benchmarks,
    clientBenchmarks,
    newClient,
    notifications,
    newNotifications,
    transactions,
    snackbar,
    structure,
    structureVersion,
    onboarding,
}));
