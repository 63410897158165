import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Badge from 'react-md/lib/Badges';
import * as pathnames from 'config/pathnames';
import {connect} from 'store';
import {NavLink, withRouter} from 'react-router-dom';
import {getClientBanks, getPinnedClient} from '../../store/clients';
import {fetchClientBanks} from '../../store/clients/actions';
import {bankShape, clientShape} from '../../store/data/client';
import {getLoaded as getVersionsLoaded} from '../../store/structure-version';
import {fetchVersions, resetState} from '../../store/structure-version/actions';
import PageContentWrapper from '../../components/layout/page-content-wrapper';
import * as roles from '../../config/roles';
import {getRole} from '../../store/auth';

const navigationItems = [
    {label: 'Information', roles: [roles.SA, roles.RM], path: 'edit'},
    {label: 'Information', roles: [roles.CUSTOMER], path: 'view'},
    {label: 'Accounts', roles: [roles.SA, roles.RM], path: 'accounts'},
    {label: 'Portfolios', roles: [roles.SA, roles.RM], path: 'portfolios'},
    {label: 'Transactions', roles: [roles.SA, roles.RM], path: 'transactions'},
    {label: 'Allocation', roles: [roles.SA, roles.RM], path: 'allocation'},
    {label: 'Performance', roles: [roles.SA, roles.RM, roles.CUSTOMER], path: 'performance'},
    {label: 'Structure', roles: [roles.SA, roles.RM], nonAtlazOnly: true, path: 'structure'},
];

class ClientProfileLayout extends Component {
    static propTypes = {
        client: clientShape,
        clientBanks: PropTypes.arrayOf(bankShape), // eslint-disable-line react/no-unused-prop-types
        children: PropTypes.node,
        match: PropTypes.object,
        role: PropTypes.string,
        versionsLoaded: PropTypes.bool.isRequired,
        fetchClientBanks: PropTypes.func.isRequired,
        resetState: PropTypes.func.isRequired,
        fetchVersions: PropTypes.func.isRequired,
    };

    static getDerivedStateFromProps(props) {
        return {
            hasOutdatedBanks: props.clientBanks.some(({isOutdated}) => isOutdated),
            title: ['Clients', `${props.client?.firstName || ''} ${props.client?.lastName || ''}`],
        };
    }

    constructor() {
        super();
        this.state = {
            hasOutdatedBanks: false,
            title: ['Clients'],
        };
    }

    componentDidMount() {
        const {client} = this.props;
        if (client) {
            this.props.fetchClientBanks({id: client.id});
            this.props.resetState();
            this.props.fetchVersions();
        }
    }

    getBadgeProps(v) {
        const props = {
            badgeClassName: 'prv-client-profile-layout__badge',
            badgeContent: 0,
            badgeId: `badge-${v}`,
            circular: true,
            invisibleOnZero: true,
        };
        if (v === 'accounts' && this.state.hasOutdatedBanks) {
            props.badgeContent = '!';
            props.badgeClassName += ' prv-client-profile-layout__badge--error';
        }
        return props;
    }

    render() {
        const {children, match, client, versionsLoaded, role} = this.props;
        const {title} = this.state;
        const {params} = match;
        if (!client || !versionsLoaded) {
            return null;
        }

        const navigation = navigationItems
            .filter(item => item.roles.includes(role))
            .filter(item => !item.nonAtlazOnly || (item.nonAtlazOnly && !client.atlazId));

        return (
            <PageContentWrapper
                className="prv-client-profile-layout"
                title={title}
            >
                <div className="prv-client-profile-layout__navigation-menu">
                    {
                        navigation.map(item => (
                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                            <NavLink
                                activeClassName="prv-client-profile-layout__navigation-item--active"
                                className="prv-client-profile-layout__navigation-item"
                                key={item.path}
                                to={`${pathnames.CLIENTS}/${params.id}/${item.path}`}
                            >
                                <Badge {...this.getBadgeProps(item.path)}>{item.label}</Badge>
                            </NavLink>
                        ))
                    }
                </div>
                {children}
            </PageContentWrapper>
        );
    }
}

export default withRouter(connect(
    {
        client: getPinnedClient,
        clientBanks: getClientBanks,
        role: getRole,
        versionsLoaded: getVersionsLoaded,
    },
    {
        fetchClientBanks,
        fetchVersions,
        resetState,
    },
)(ClientProfileLayout));
