import React, {Component} from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import Button from 'react-md/lib/Buttons/Button';
import {withRouter} from 'react-router-dom';
import {profileShape} from 'store/data/profile';
import {AdminScope} from 'config/adminscope';
import * as pathnames from '../../config/pathnames';
import {connect} from '../../store';
import {collectionShape} from '../../store/collections';
import {rmShape} from '../../store/data/rm';
import {isSA, isRM, getProfile} from '../../store/auth';
import {getCollectionState, getActiveRMsLoading, getActiveRMs, getError} from '../../store/clients';
import {
    deleteClient,
    blockClient,
    unblockClient,
    fetchActiveRMs,
    clearError,
    recalculateClient,
    recalculateEverything,
} from '../../store/clients/actions';
import CollectionListPage from '../../components/collections/list-page';
import Spinner from '../../components/loading-spinner/loading-spinner';
import {navigate, refresh} from '../../store/navigate';
import ActionViewOptions from '../../components/resource-table/action-view-options';
import MessageBox from '../../components/dialogs/message-box';

const formatClientName = item => `${item.firstName || ''} ${item.lastName || ''}`;
const renderName = (firstName, lastName) => (x, item) => `${item[firstName] || ''} ${item[lastName] || ''}`;

const columns = [
    {field: 'firstName', label: 'Client Name', render: renderName('firstName', 'lastName')},
    {field: 'managerFirstName', label: 'Assigned Rm', render: renderName('managerFirstName', 'managerLastName')},
    {field: 'email', label: 'Email', width: '18%'},
    {field: 'phone', label: 'Phone Number', width: '18%'},
];

const yes = () => true;

export const rmClientsColumns = columns.slice();
rmClientsColumns.splice(1, 1);

class CustomersPage extends Component {
    static propTypes = {
        collection: collectionShape,
        location: PropTypes.shape({
            state: PropTypes.objectOf(PropTypes.bool),
        }),
        isSA: PropTypes.bool.isRequired,
        isRM: PropTypes.bool.isRequired,
        profile: profileShape,
        error: PropTypes.object,
        deleteClient: PropTypes.func.isRequired,
        blockClient: PropTypes.func.isRequired,
        unblockClient: PropTypes.func.isRequired,
        fetchActiveRMs: PropTypes.func.isRequired,
        activeRMs: PropTypes.arrayOf(rmShape),
        activeRMsLoading: PropTypes.bool,
        navigate: PropTypes.func.isRequired,
        refresh: PropTypes.func.isRequired,
        clearError: PropTypes.func.isRequired,
        recalculateClient: PropTypes.func.isRequired,
        recalculateEverything: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            recalculationDialog: undefined,
        };
    }

    componentWillMount() {
        if (this.props.isSA) this.props.fetchActiveRMs();
        this.props.clearError();
    }

    onEdit = ({id}) => this.props.navigate(`${pathnames.CLIENTS}/${id}/edit`);
    onDelete = item => this.props.deleteClient(item.id);
    onToggleBlock = ({id, status}) => (
        status === 'blocked' ? this.props.unblockClient(id) : this.props.blockClient(id)
    );
    onRecalculate = async (client) => {
        try {
            await this.props.recalculateClient({clientId: client.id});
            this.setState({recalculationDialog:
                    `Recalculation of outdated data has started for Client "${(formatClientName(client))}".\n
                    It will take some time.`,
            });
        } catch (e) {
            this.setState({recalculationDialog:
                    `Failed to start recalculation of outdated data for Client "${formatClientName(client)}".\n
                    Please try again later.`,
            });
        }
    };
    onForceRecalculate = async (client) => {
        try {
            await this.props.recalculateClient({clientId: client.id, force: true});
            this.setState({recalculationDialog:
                    `Recalculation of all data has started for Client "${(formatClientName(client))}".\n
                    It will take some time.`,
            });
        } catch (e) {
            this.setState({recalculationDialog:
                    `Failed to start recalculation of all data for Client "${formatClientName(client)}".\n
                    Please try again later.`,
            });
        }
    }
    onRecalculateEverything = async () => {
        try {
            await this.props.recalculateEverything({});
            this.setState({
                recalculationDialog: 'Recalculation of outdated data has started. It will take some time.',
            });
        } catch (e) {
            this.setState({recalculationDialog:
                    'Recalculation of outdated data failed. Please try again later.',
            });
        }
    };
    onForceRecalculateEverything = async () => {
        try {
            await this.props.recalculateEverything({force: true});
            this.setState({recalculationDialog:
                    'Recalculation of all data has started. It will take some time.',
            });
        } catch (e) {
            this.setState({recalculationDialog: 'Recalculation of all data failed. Please try again later.'});
        }
    };

    getFiltersConfig = () => {
        const filters = ({
            status: {
                title: 'Status',
                options: {
                    active: 'Active',
                    blocked: 'Blocked',
                },
            },
        });

        if (this.props.isSA) {
            filters.managerId = {
                title: 'Assigned RM',
                options: this.props.activeRMs.length > 0 ?
                    this.props.activeRMs.reduce((prev, cur) => ({
                        ...prev,
                        [cur.id]: `${cur.firstName} ${cur.lastName}`,
                    }), {}) :
                    {},
            };

            if (this.props.profile.scope === AdminScope.ATLAZ) {
                filters.userType = {
                    title: 'Users',
                    options: {
                        all: 'All',
                        atlaz: 'Atlaz',
                        proveo: 'proVeo',
                    },
                    noEmptyOption: true,
                    emptyItem: 'all',
                };
            }
        }

        if (this.props.isRM) {
            filters.managerId = {
                title: 'Clients',
                options: {
                    all: 'All',
                    [this.props.profile.id]: 'My Clients',
                },
                noEmptyOption: true,
                emptyItem: 'all',
            };
        }

        // should be at the end of filters
        filters.recalculate = {
            render: () => (
                <React.Fragment>
                    <Button flat onClick={this.onRecalculateEverything}>Recalculate Outdated</Button>
                    <Button flat onClick={this.onForceRecalculateEverything}>Recalculate All</Button>
                </React.Fragment>
            ),
        };

        return filters;
    };

    getMessage = (item, msg) => (
        <div>
            {msg}
            <br/> {item.firstName} {item.lastName}?
        </div>
    );

    getDeleteMessage = item => this.getMessage(item, 'Do you want to delete');

    getBlockMessage = item => this.getMessage(
        item, `Do you want to ${item.status === 'blocked' ? 'un' : ''}block`,
    );

    getNotification = () => {
        const {created, deleted} = this.props.location.state || {};
        if (created) {
            return `Customer ${created} has been successfully created`;
        }
        if (deleted) {
            return `Customer ${deleted} has been successfully deleted`;
        }

        return null;
    };

    clearNotification = () => this.props.refresh(this.props.location, {});

    _handleHideRecalculationDialog = () => this.setState({recalculationDialog: undefined});

    render() {
        const {collection, activeRMsLoading, error, isRM, isSA} = this.props;
        const {recalculationDialog} = this.state;

        if (activeRMsLoading) return <Spinner/>;

        return (
            <CollectionListPage
                actionView={ActionViewOptions.Icons}
                name="clients"
                title="Clients"
                collection={collection}
                columns={columns}
                filters={this.getFiltersConfig()}
                canEdit={yes}
                onEdit={this.onEdit}
                canDelete={yes}
                onDelete={this.onDelete}
                canBlock={yes}
                onToggleBlock={this.onToggleBlock}
                canRecalculate={yes}
                onRecalculate={this.onRecalculate}
                canForceRecalculate={yes}
                onForceRecalculate={this.onForceRecalculate}
                notification={this.getNotification()}
                clearNotification={this.clearNotification}
                getDeleteMessage={this.getDeleteMessage}
                getBlockMessage={this.getBlockMessage}
                error={error}
                onClearError={this.props.clearError}
                initialState={{
                    filters: {managerId: isRM ? this.props.profile.id : null, userType: isSA ? 'all' : null},
                }}
            >
                {recalculationDialog &&
                <MessageBox
                    onHide={this._handleHideRecalculationDialog}
                    title="Recalculation"
                    text={<p>{recalculationDialog}</p>}
                    visible={!!recalculationDialog}
                />}
            </CollectionListPage>
        );
    }
}

export default withRouter(connect(
    {
        collection: getCollectionState,
        activeRMs: getActiveRMs,
        activeRMsLoading: getActiveRMsLoading,
        isSA,
        isRM,
        profile: getProfile,
        error: getError,
    },
    {
        deleteClient,
        blockClient,
        unblockClient,
        fetchActiveRMs,
        navigate,
        refresh,
        clearError,
        recalculateClient,
        recalculateEverything,
    },
)(CustomersPage));
