import React from 'react';
import cn from 'classnames';
import {connect} from '../../../../store';
import {getAccountGroups} from '../../../../store/structure';
import AccountGroups from './AccountGroups';
import {AccountGroup as AccountGroupType} from '../../../../store/structure/types';

interface Props {
    groups: AccountGroupType[],
    className?: string,
}

const AvailableAccounts: React.FC<Props> = ({className, groups}) => (
    <div className={cn('prv-available-accounts', className)}>
        <div className="prv-available-accounts__title">
            Available Assets
        </div>
        <AccountGroups
            className="prv-available-accounts__groups"
            groups={groups}
            isPortfolioAssets={false}
        />
    </div>
);

// FIXME: figure out how to type the connect function
// @ts-ignore
export default connect(
    {
        groups: getAccountGroups,
    },
    {
    },
)(AvailableAccounts);
