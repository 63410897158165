import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import Select from 'components/select/select';
import {Field} from 'redux-form';

import styles from './select-field.module.css';

export const optionsShape = PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.objectOf(PropTypes.string),
]));

const RenderSelectField = ({className, options, placeholder, input, meta, ...props}) => (
    <Select
        className={cn(styles.dropdown, className, {invalid: meta.invalid && meta.touched})}
        placeholder={placeholder}
        value={input.value || ''}
        onChange={input.onChange}
        onBlur={input.onBlur}
        options={options}
        {...props}
    />
);

RenderSelectField.propTypes = {
    input: PropTypes.shape({
        value: PropTypes.any,
        onBlur: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
    }).isRequired,
    meta: PropTypes.shape({
        invalid: PropTypes.bool,
        touched: PropTypes.bool,
    }).isRequired,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    disablePlaceholder: PropTypes.bool,
    options: optionsShape.isRequired,
};

const SelectField = ({name, ...props}) => (
    <Field
        name={name}
        component={RenderSelectField}
        {...props}
    />
);
export default SelectField;

SelectField.propTypes = {
    name: PropTypes.string.isRequired,
};
