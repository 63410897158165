/**
 * API power BI
 */
const POWER_BI_API_URL = 'https://prod-54.northeurope.logic.azure.com:443/workflows/67fcaf131c9d46b88532c0517f0246ae/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=_D6m_gxhwmSC9-UYyHqyyk6RZlQ-kmkLz7OpXH7BZg4';

type Props = {
    /** client name? */
    ClientName?: string;
    /** undefined for now - will be supplied with the next task */
    StructureName?: string;
    /**  */
    ReportType: 'Month' | 'Quarter' | 'Year';
    /** format: 31-03-2021 */
    ReportDate: string;
    /** email of relationship man */
    rmEmail: string;
    /** proVeo ID */
    ProveoId: string;
    /** version id */
    VersionId: string | undefined
    /** version name */
    VersionName: string | undefined
};

async function postData(url = '', data = {}) {
    // Default options are marked with *
    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'omit',
        headers: {
            'Content-Type': 'application/json',
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify(data),
    });
    return response;
}

export enum ReportState {
    FORM = 'form',
    SUCCESS = 'success',
    ERROR = 'error'
}

export async function triggerPowerBIReport(data: Props): Promise<ReportState> {
    try {
        const res = await postData(POWER_BI_API_URL, data);
        if (!res.ok) {
            throw new Error(`PowerBI res.status: ${res.status}`);
        }
        return ReportState.SUCCESS;
    } catch (error) {
        return ReportState.ERROR;
    }
}
