export const ROOT = '/';

export const LOGIN = '/login';

export const NEW_CLIENT = '/new-client';

export const PROFILE = '/profile';

export const CLIENTS = '/clients';

export const RMS = '/rms';

export const MS_API = '/ms-api';

export const CLIENT_BENCHMARKS = '/client-benchmarks';

export const NOTIFICATIONS = '/notifications';

export const NEW_NOTIFICATIONS = '/new-notifications';

export const ASSETS = '/assets';

export const TRANSACTIONS = '/transactions';
