import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {collectionShape} from '../../store/collections';
import buildActions from '../../store/collections/actions_builder';
import ErrorPanel, {SuccessPanel} from '../../components/panel/panel';
import PreviewPanelContainer from '../../components/layout/preview-panel-container';
import Table from '../../components/resource-table/resource-table';
import SubHeaderFilters from '../../components/sub-header/sub-header-filters';
import Pagination from '../../components/sub-header/pagination';
import Dialog from '../../components/dialogs/dialogs';
import CollectionListForm from './list-form';

import styles from './list-page.module.css';

export default class CollectionsListPage extends Component {
    static propTypes = {
        actionView: PropTypes.string,
        initialState: PropTypes.shape({}),
        tag: PropTypes.string,
        className: PropTypes.string,
        name: PropTypes.string.isRequired,
        title: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.arrayOf(PropTypes.string),
        ]),
        context: PropTypes.string,
        collection: collectionShape.isRequired,
        columns: PropTypes.arrayOf(PropTypes.object),
        filters: PropTypes.objectOf(PropTypes.object),
        displayOptions: PropTypes.arrayOf(PropTypes.node),
        notification: PropTypes.string,
        totalKey: PropTypes.string,
        clearNotification: PropTypes.func,
        error: PropTypes.object,
        onClearError: PropTypes.func,
        renderContent: PropTypes.func,
        canSearch: PropTypes.bool,
        canSelect: PropTypes.func,
        canEdit: PropTypes.func,
        onEdit: PropTypes.func,
        canDelete: PropTypes.func,
        onDelete: PropTypes.func,
        renderDeleteDialog: PropTypes.func,
        canBlock: PropTypes.func,
        onToggleBlock: PropTypes.func,
        canRecalculate: PropTypes.func,
        onRecalculate: PropTypes.func,
        canForceRecalculate: PropTypes.func,
        onForceRecalculate: PropTypes.func,
        getDeleteMessage: PropTypes.func,
        getBlockMessage: PropTypes.func,
        renderBlockDialog: PropTypes.func,
        collectionTitle: PropTypes.string,
        createItemButtonTitle: PropTypes.string,
        onCreateItem: PropTypes.func,
        children: PropTypes.node,
        perPageOptions: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.object])),
    };

    static defaultProps = {
        canSelect: () => false,
        canRecalculate: () => false,
        perPageOptions: [10, 25, 50],
    };

    constructor(props) {
        super(props);
        this.collectionActions = buildActions(props.name);
        this.state = {};
    }

    componentDidMount = () => {
        this.initialize();
    };

    componentWillReceiveProps(nextProps) {
        const {notification, clearNotification, collection} = nextProps;
        if (notification && clearNotification) {
            setTimeout(() => {
                if (this.props.notification) {
                    this.props.clearNotification();
                }
            }, 3000);
        }
        if (collection && collection.error) {
            setTimeout(() => {
                if (this.props.collection && this.props.collection.error) {
                    this.clearCollectionError();
                }
            }, 3000);
        }
    }

    componentWillUnmount() {
        this.dispatch(this.collectionActions.clearCollection());
    }

    onRefForm = (form) => {
        this.form = form;
    };

    onRefresh = () => {
        this.dispatch(this.collectionActions.fetchItems(this.props.context));
    };

    onSelectItems = (items) => {
        this.dispatch(this.collectionActions.selectItems(items));
    };

    onSort = (sort) => {
        this.dispatch(this.collectionActions.sortItems(sort));
    };

    onSearch = (search) => {
        this.dispatch(this.collectionActions.search(search));
    };

    onSelectFilter = (filters) => {
        this.dispatch(this.collectionActions.changeFilter(filters));
    };

    onChangePage = (page) => {
        this.dispatch(this.collectionActions.changePage(page));
    };

    onChangePerPage = (perPage) => {
        this.dispatch(this.collectionActions.changePerPage(perPage));
    };

    onAskDeleteItem = item => this.setState({itemToDelete: item, deleteError: null});

    onPerformDelete = params => this.props.onDelete(this.state.itemToDelete, params);

    onCancelDelete = () => this.setState({itemToDelete: null});

    onCompletedDelete = () => {
        this.setState({itemToDelete: null});
        this.onRefresh();
    };

    onFailedDelete = ({_error: error}) => {
        this.setState({itemToDelete: null, deleteError: error});
        this.onRefresh();
    };

    onAskBlockItem = item => this.setState({
        itemToBlock: item, blockError: null, blockItemStatus: item.status,
    });

    onPerformBlock = params => this.props.onToggleBlock(this.state.itemToBlock, params);

    onCompleteBlock = () => {
        this.setState({itemToBlock: null});
        this.onRefresh();
    };

    onFailedBlock = ({_error: error}) => {
        this.setState({itemToBlock: null, blockError: error});
        this.onRefresh();
    };

    onCancelBlock = () => this.setState({itemToBlock: null});

    initialize = () => {
        const {initialState} = this.props;
        if (_.isObject(initialState) && !_.isEmpty(initialState)) {
            this.dispatch(this.collectionActions.initialize(initialState));
        }
        this.onRefresh();
    };

    clearCollectionError = () => {
        this.dispatch(this.collectionActions.clearFetchError());
    };

    clearDeleteError = () => this.setState({deleteError: null});
    clearBlockError = () => this.setState({blockError: null});

    dispatch = action => this.form.wrappedInstance.props.dispatch(action);

    wrapApiAction = fn => (...args) => this.form.wrappedInstance.handleApiActionCall(fn(...args));
    handleApiActionCall = promise => this.form.wrappedInstance.handleApiActionCall(promise);

    renderFilters = () => {
        const {
            canSearch,
            collection,
            collectionTitle,
            createItemButtonTitle,
            displayOptions,
            filters: filtersConfig = {},
            onCreateItem,
            perPageOptions,
        } = this.props;
        const {search, filters, totalPages, page, perPage, total} = collection;

        const pagination = (
            <Pagination
                page={page}
                totalPages={totalPages}
                perPage={perPage}
                perPageOptions={perPageOptions}
                onChangePage={this.onChangePage}
                onChangePerPage={this.onChangePerPage}
            />
        );

        return (
            <div>
                {collectionTitle &&
                <div className={styles.collectionHeader}>
                    <div className={styles.collectionTitle}>
                        <span>{collectionTitle}</span>
                        <div className={styles.collectionTitleSeparator}/>
                        <span className={styles.collectionTitleCount}>
                            {
                                search.length <= 0 ?
                                    total :
                                    `Found ${total}`
                            }
                        </span>
                    </div>
                    {pagination}
                </div>
                }
                <SubHeaderFilters
                    displayOptions={displayOptions}
                    searchPlaceholder={collectionTitle && 'Search'}
                    searchTerm={search}
                    onSearch={canSearch === false ? null : this.onSearch}
                    filters={filters}
                    filtersConfig={filtersConfig}
                    onFilterChange={this.onSelectFilter}
                    createItemButtonTitle={createItemButtonTitle}
                    onCreateItem={onCreateItem}
                >
                    {!collectionTitle && pagination}
                </SubHeaderFilters>
            </div>
        );
    };

    renderTable = () => {
        const {
            tag,
            collection,
            columns,
            onDelete,
            canSelect,
            canDelete,
            renderContent,
            canEdit,
            onEdit,
            canBlock,
            onToggleBlock,
            canRecalculate,
            onRecalculate,
            canForceRecalculate,
            onForceRecalculate,
            actionView,
            totalKey,
        } = this.props;
        const {
            items,
            selected,
            sortField,
            sortOrder,
        } = collection;
        const table = (
            <Table
                actionView={actionView}
                canBlock={canBlock}
                canDelete={canDelete}
                canEdit={canEdit}
                canSelect={canSelect}
                canRecalculate={canRecalculate}
                canForceRecalculate={canForceRecalculate}
                columns={columns}
                items={items || []}
                onDelete={onDelete ? this.onAskDeleteItem : null}
                onEdit={onEdit}
                onSelect={this.onSelectItems}
                onSort={this.onSort}
                onToggleBlock={onToggleBlock ? this.onAskBlockItem : null}
                onRecalculate={onRecalculate}
                onForceRecalculate={onForceRecalculate}
                selected={selected}
                sortField={sortField}
                sortOrder={sortOrder}
                tag={tag}
                wrapApiAction={this.wrapApiAction}
                totalKey={totalKey}
            />
        );
        return renderContent ? renderContent(table) : table;
    };

    renderDeleteDialog = () => {
        const {
            getDeleteMessage = () => 'Delete selected items?',
            renderDeleteDialog: renderer,
        } = this.props;
        const {itemToDelete} = this.state;
        if (!itemToDelete) return null;

        return renderer ?
            renderer({
                item: itemToDelete,
                onSubmit: this.onPerformDelete,
                onCancel: this.onCancelDelete,
                onSubmitSuccess: this.onCompletedDelete,
                onSubmitFail: this.onFailedDelete,
            }) :
            (
                <Dialog
                    message={getDeleteMessage(itemToDelete)}
                    onDelete={this.onPerformDelete}
                    onCancel={this.onCancelDelete}
                    onSubmitSuccess={this.onCompletedDelete}
                    onSubmitFail={this.onFailedDelete}
                />
            );
    };

    renderBlockDialog = () => {
        const {
            getBlockMessage = () => 'Block selected items?',
            renderBlockDialog,
        } = this.props;
        const {itemToBlock, blockItemStatus} = this.state;
        if (!itemToBlock) return null;

        return renderBlockDialog && itemToBlock.status !== 'blocked' ?
            renderBlockDialog({
                item: itemToBlock,
                onSubmit: this.onPerformBlock,
                onCancel: this.onCancelBlock,
                onSubmitSuccess: this.onCompleteBlock,
                onSubmitFail: this.onFailedBlock,
            }) :
            (
                <Dialog
                    message={getBlockMessage(itemToBlock)}
                    onBlock={blockItemStatus !== 'blocked' ? this.onPerformBlock : null}
                    onUnblock={blockItemStatus === 'blocked' ? this.onPerformBlock : null}
                    onCancel={this.onCancelBlock}
                    onSubmitSuccess={this.onCompleteBlock}
                    onSubmitFail={this.onFailedBlock}
                />
            );
    };

    render() {
        const {
            name, className, collection, notification,
            clearNotification, title, children,
        } = this.props;
        const {deleteError, blockError} = this.state;
        const {loading, error} = collection;

        return (
            <CollectionListForm
                form={`${name}-collection-form`}
                title={title}
                className={className}
                loading={loading}
                ref={this.onRefForm}
            >
                {notification &&
                <SuccessPanel
                    error={{message: notification}}
                    onClear={clearNotification}
                />}
                {error && <ErrorPanel error={error} onClear={this.clearCollectionError}/>}
                {this.props.error &&
                <ErrorPanel error={this.props.error} onClear={this.props.onClearError}/>}
                {deleteError && <ErrorPanel error={deleteError} onClear={this.clearDeleteError}/>}
                {blockError && <ErrorPanel error={blockError} onClear={this.clearBlockError}/>}
                {this.renderDeleteDialog()}
                {this.renderBlockDialog()}
                {this.renderFilters()}
                {children}
                <PreviewPanelContainer>
                    {this.renderTable()}
                </PreviewPanelContainer>
            </CollectionListForm>
        );
    }
}
