import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import _ from 'lodash';
import {connectActions} from 'store';
import DialogContainer from 'react-md/lib/Dialogs/DialogContainer';
import TextField from 'react-md/lib/TextFields/TextField';
import SelectField from 'react-md/lib/SelectFields/SelectField';
import {AssetTypeEnum, Regions, assetShape} from 'store/data/assets';
import LoadingSpinner from 'components/loading-spinner/loading-spinner';
import {showToast} from 'store/snackbar/actions';
import {CURRENCY_LIST} from '../clients/transactions/transactions-types';

const assetTypeItems = Object.keys(AssetTypeEnum).map(value => ({label: AssetTypeEnum[value], value}));

class EditAssetDialog extends React.PureComponent {
    static propTypes = {
        asset: assetShape.isRequired,
        className: PropTypes.string,
        error: PropTypes.object,
        loading: PropTypes.bool,
        onCancel: PropTypes.func,
        onClose: PropTypes.func,
        onUpdate: PropTypes.func,
        showToast: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            asset: props.asset,
            isValid: true,
        };
    }

    componentWillReceiveProps(nextProps) {
        const {asset, error} = nextProps;

        if (this.props.asset !== asset) {
            this.setState({asset});
        }
        if (this.props.error !== error && !!error) {
            this.props.showToast({text: error});
        }
    }

    onUpdate = () => {
        if (!this._validate()) {
            return;
        }

        const {onUpdate} = this.props;
        onUpdate && onUpdate(this.state.asset);
    };

    onCancel = () => {
        const {onCancel} = this.props;
        onCancel && onCancel();
    };

    onClose = () => {
        const {onClose} = this.props;
        onClose && onClose();
    };

    onNameChange = (name) => {
        this.setState({
            asset: Object.assign({}, this.state.asset, {name}),
            isValid: !_.isEmpty(name) && !!_.trim(name),
        });
    };

    onIsinChange = (isin) => {
        this.setState({asset: Object.assign({}, this.state.asset, {isin})});
    };

    onTypeChange = (type) => {
        this.setState({asset: Object.assign({}, this.state.asset, {type})});
    };

    onRegionChange = (region) => {
        this.setState({asset: Object.assign({}, this.state.asset, {region})});
    };

    onCurrencyChange = (currency) => {
        this.setState({asset: Object.assign({}, this.state.asset, {currency})});
    };

    _validate = () => {
        const {asset} = this.state;
        return !_.isEmpty(asset.name);
    };

    render() {
        const {className, loading} = this.props;
        const {asset, isValid} = this.state;

        return (
            <DialogContainer
                actions={[
                    {secondary: true, children: 'Cancel', onClick: this.onCancel},
                    {primary: true, children: 'Update', disabled: !isValid, onClick: this.onUpdate},
                ]}
                className={cn('prv-edit-asset-dialog', className)}
                id="edit-asset-dialog"
                onHide={this.onClose}
                title="Update Asset"
                visible
            >
                {loading && <LoadingSpinner/>}
                <TextField
                    errorText="Name is required"
                    id="edit-asset-name"
                    label="Asset Name"
                    defaultValue={asset.name}
                    onChange={this.onNameChange}
                    required
                />
                <TextField
                    id="edit-asset-isin"
                    label="ISIN"
                    defaultValue={asset.isin}
                    onChange={this.onIsinChange}
                />
                <SelectField
                    block
                    defaultValue={asset.type}
                    fullWidth
                    id="edit-asset-type"
                    label="Type"
                    menuItems={assetTypeItems}
                    onChange={this.onTypeChange}
                    simplifiedMenu={false}
                />
                <SelectField
                    block
                    defaultValue={asset.region}
                    fullWidth
                    id="edit-asset-region"
                    label="Region"
                    menuItems={Regions}
                    onChange={this.onRegionChange}
                    simplifiedMenu={false}
                />
                <SelectField
                    block
                    defaultValue={asset.currency}
                    fullWidth
                    id="edit-asset-currency"
                    label="Currency"
                    menuItems={CURRENCY_LIST}
                    onChange={this.onCurrencyChange}
                    simplifiedMenu={false}
                />
            </DialogContainer>
        );
    }
}

export default connectActions({showToast})(EditAssetDialog);
