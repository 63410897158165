import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {countryShape} from 'store/data/general-data';

import Spinner from 'components/loading-spinner/loading-spinner';

import styles from './choose-country.module.css';

export default class ChooseCountry extends React.Component {
    static propTypes = {
        countries: PropTypes.arrayOf(countryShape),
        loading: PropTypes.bool,
        onSelect: PropTypes.func,
        selectedCountryId: PropTypes.string,
    };

    constructor(props) {
        super(props);
        this.state = {
            search: null,
        };
    }

    onSearch = e => this.setState({search: e.target.value});

    onCountryClick = (country) => {
        const {onSelect, selectedCountryId} = this.props;
        onSelect && onSelect(selectedCountryId === country.id ? null : country);
    };

    getFilteredCountries = () => {
        const {countries} = this.props;
        let {search} = this.state;
        search = (search || '').trim().toLowerCase();
        return search ? countries.filter(country => country.name.toLowerCase().includes(search)) : countries;
    };

    render() {
        const {loading, selectedCountryId} = this.props;
        const countries = this.getFilteredCountries();

        return (
            <div className={styles.choosingCountryWrapper}>
                <div className={styles.searchWrapper}>
                    <input
                        disabled={loading}
                        type="text"
                        className={styles.search}
                        onChange={this.onSearch}
                        defaultValue=""
                        placeholder="Search"
                    />
                </div>
                <ul>
                    {loading && <Spinner className={styles.spinner}/>}
                    {!loading && _.map(countries, country => (
                        <li
                            key={country.id}
                            onClick={() => this.onCountryClick(country)}
                            className={selectedCountryId === country.id ? styles.selected : null}
                        >
                            {country.name}
                        </li>
                    ))}
                </ul>
            </div>
        );
    }
}
