import moment from 'moment';
import React, {useCallback, useEffect, useState} from 'react';
import {DialogContainer} from 'react-md';
import {useSelector} from 'react-redux';
import {ReportState, triggerPowerBIReport} from '../../../helpers/powerBI';
import {getProfile} from '../../../store/auth';
import {getActiveRMs, getPinnedClient} from '../../../store/clients';
import Client from '../../../store/types/Client';
import Profile from '../../../store/types/Profile';
import {RM} from '../../../store/types/RM';
import LoadingSpinner from '../../loading-spinner/loading-spinner';
import styles from './power-bi-report-dialog.module.css';
import ReportForm, {FormState} from './report-form';
import {ReportSentErrorInfo} from './report-sent-info-error';
import {ReportSentInfo} from './report-sent-info-success';
import * as Roles from '../../../config/roles';
import {getClosestWorkingDay} from '../../../helpers/dates';

type Props = {
    open: boolean;
    onClose: () => void;
}

const getFormData = (client: Client, clientRmEmail?: string): FormState => ({
    type: 'Month',
    dateTo: getClosestWorkingDay().toDate(),
    proveoId: client.proveoId,
    clientName: (client.firstName ?? '').trim(),
    rmEmail: clientRmEmail ?? '',
    version: undefined,
});

export const PowerBiReportDialog: React.FC<Props> = ({
    open, onClose,
}) => {
    const profile: Profile = useSelector(getProfile);
    const client: Client = useSelector(getPinnedClient);
    const activeRms: RM[] = useSelector(getActiveRMs);
    const clientRmEmail = profile.role === Roles.RM
        ? profile.info?.email
        : activeRms.find(rm => rm.id === client.managerId)?.email;
    const [status, setStatus] = useState(ReportState.FORM);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<FormState>(getFormData(client, clientRmEmail));

    useEffect(() => {
        setData(getFormData(client, clientRmEmail));
    }, [client.firstName, client.lastName, client.proveoId, client.managerId, clientRmEmail]);

    const handleFieldChange = useCallback((id: string, value: unknown) => {
        setData((fs) => ({...fs, [id]: value}));
    }, [setData]);

    const handleSubmit = useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (data.rmEmail) {
            setLoading(true);
            const status = await triggerPowerBIReport({
                ClientName: data.clientName,
                StructureName: '',
                ReportType: data.type,
                ReportDate: moment(data.dateTo).format('YYYY-MM-DD'),
                rmEmail: data.rmEmail,
                ProveoId: client.proveoId || '',
                VersionId: data.version?.id,
                VersionName: data.version?.name,
            });
            setStatus(status);
            setLoading(false);
        }
    }, [onClose, data, client.proveoId]);

    const handleClose = useCallback(() => {
        setStatus(ReportState.FORM);
        onClose();
    }, [onClose]);

    return (
        <div className={styles.positionRelative}>
            <DialogContainer
                id="power-bi-report-data"
                visible={open}
                onHide={handleClose}
                title="Generate report"
                activeElementFocus={false}
                modal
                width={450}
            >
                {status === ReportState.FORM && (
                    <ReportForm
                        data={data}
                        onFieldChange={handleFieldChange}
                        onSubmit={handleSubmit}
                        onClose={handleClose}
                    />
                )}
                {status === ReportState.SUCCESS && <ReportSentInfo onClose={handleClose} rmEmail={data.rmEmail} />}
                {status === ReportState.ERROR && <ReportSentErrorInfo onClose={onClose} />}
                {loading && <LoadingSpinner />}
            </DialogContainer>
        </div>
    );
};
