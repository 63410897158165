import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import Layover from 'react-md/lib/Helpers/Layover';
import List from 'react-md/lib/Lists/List';
import Button from 'react-md/lib/Buttons/Button';
import Divider from 'react-md/lib/Dividers/Divider';
import TooltippedTextField from './TooltippedTextField';
import CheckboxMenuControlItem from './CheckboxMenuControlItem';

const defaultAnchor = {
    x: Layover.HorizontalAnchors.CENTER,
    y: Layover.VerticalAnchors.BOTTOM,
};

export const CheckboxMenuControlItemShape = PropTypes.shape({
    checked: PropTypes.bool,
    label: PropTypes.string,
    value: PropTypes.string,
});

export default class CheckboxMenuControl extends React.PureComponent {
    static propTypes = {
        canApply: PropTypes.func,
        fieldClassName: PropTypes.string,
        getText: PropTypes.func,
        id: PropTypes.string.isRequired,
        items: PropTypes.arrayOf(CheckboxMenuControlItemShape).isRequired,
        label: PropTypes.string,
        onChange: PropTypes.func,
        text: PropTypes.string,
    };

    static defaultProps = {
        canApply: () => true,
        text: 'Select',
    };

    constructor(props) {
        super(props);
        this.state = {
            items: props.items,
            menuVisible: false,
        };
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.items !== nextProps.items) {
            this.setState({items: nextProps.items});
        }
    }

    getText = () => {
        const {text, getText} = this.props;
        const {items} = this.state;
        return getText ? getText(items) : text;
    };

    _toggleOpen = () => {
        this.setState(prevState => ({menuVisible: !prevState.menuVisible}));
    };

    _handleCancel = () => {
        this.setState({menuVisible: false, items: this.props.items});
    };

    _handleItemClick = (value, checked) => {
        this.setState(({items}) => {
            const itemIndex = items.findIndex(item => item.value === value);
            if (itemIndex < 0) {
                return null;
            }

            return {items: [
                ...items.slice(0, itemIndex),
                {...items[itemIndex], checked},
                ...items.slice(itemIndex + 1),
            ]};
        });
    };

    _handleApply = () => {
        this.setState({menuVisible: false});
        const {onChange} = this.props;
        onChange && onChange(this.state.items);
    };

    _handleCheckboxButtonClick = () => {
        this.setState(prevState => {
            const checked = prevState.items.filter(({checked}) => checked).length < prevState.items.length;
            return ({items: prevState.items.map(item => ({...item, checked}))});
        });
    };

    _getButtonIcon = (items) => {
        const checkedCount = items.filter(({checked}) => checked).length;
        if (checkedCount === 0) {
            return 'check_box_outline_blank';
        } else if (checkedCount === items.length) {
            return 'check_box';
        }
        return 'indeterminate_check_box';
    };

    render() {
        const {canApply, fieldClassName, id, label} = this.props;
        const {items, menuVisible} = this.state;
        const applyButtonEnabled = canApply(items);
        const text = this.getText();
        const checkboxButtonIcon = this._getButtonIcon(items);

        return (
            <Layover
                anchor={defaultAnchor}
                className="prv-checkbox-menu-control"
                id={id}
                onClose={this._handleCancel}
                toggle={(
                    <TooltippedTextField
                        className={cn('prv-checkbox-menu-control__field', fieldClassName)}
                        id={id}
                        label={label}
                        onClick={this._toggleOpen}
                        tooltipLabel={text}
                        tooltipPosition="top"
                        value={text}
                    />
                )}
                visible={menuVisible}
            >
                <div className="prv-checkbox-menu-control__dropdown md-paper md-paper--2">
                    <Button icon onClick={this._handleCheckboxButtonClick}>
                        {checkboxButtonIcon}
                    </Button>
                    <Divider />
                    <List>
                        {items.map(({label, value, checked}) => (
                            <CheckboxMenuControlItem
                                key={`${id}-item-${value}`}
                                checked={checked}
                                id={id}
                                label={label}
                                onClick={this._handleItemClick}
                                value={value}
                            />
                        ))}
                    </List>
                    <Divider />
                    <div>
                        <Button
                            flat
                            iconChildren="close"
                            onClick={this._handleCancel}
                            secondary
                        >
                            Cancel
                        </Button>
                        <Button
                            disabled={!applyButtonEnabled}
                            flat
                            iconChildren="check"
                            onClick={this._handleApply}
                            primary
                        >
                            Apply
                        </Button>
                    </div>
                </div>
            </Layover>
        );
    }
}
