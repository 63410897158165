import {createApiCallAction, createAction} from '../helpers/actions';

export const checkClientExistence = createApiCallAction('newClient/CHECK_EXISTENCE', 'checkClientExistence');
export const createClient = createApiCallAction('newClient/CREATE_CLIENT', 'createClient');
export const updateClient = createApiCallAction('newClient/UPDATE_CLIENT', 'updateClient');
export const fetchClient = createApiCallAction('newClient/FETCH_CLIENT', 'fetchClientById');
export const setClient = createAction('newClient/SET_CLIENT');
export const fetchBanks = createApiCallAction('newClient/FETCH_BANKS', 'fetchBanks');
export const fetchBankAuthMethods = createApiCallAction('newClient/FETCH_BANK_AUTH_METHOD', 'fetchBankAuthMethods');
export const fetchBanksForClient = createApiCallAction('newClient/FETCH_BANKS_FOR_CLIENT', 'fetchBanksForClient');
export const deleteBank = createApiCallAction('newClient/DELETE_BANK', 'deleteBank');

export const bankSignIn = createApiCallAction('newClient/BANK_SIGN_IN', 'bankSignIn');
export const updateBankCredentials = createApiCallAction('client/UPDATE_BANK_CREDENTIALS', 'updateBankCredentials');
export const updateBankDescription = createApiCallAction('client/UPDATE_BANK_DESCRIPTION', 'updateBankDescription');

export const clearError = createAction('newClient/CLEAR_ERROR');
export const clearAddBankError = createAction('newClient/CLEAR_ADD_BANK_ERROR');
export const clearClientBanksError = createAction('newClient/CLEAR_CLIENT_BANKS_ERROR');
export const clearCheckExistenceError = createAction('newClient/CLEAR_CHECK_EXISTENCE_ERROR');
export const resetState = createAction('newClient/RESET_STATE');
export const resetAddBankState = createAction('newClient/RESET_ADD_BANK_STATE');

export const fetchTinkUrl = createApiCallAction('newClient/FETCH_TINK_URL', 'fetchTinkUrl');

export const fetchLocalizationTerms = createApiCallAction('newClient/FETCH_LOCALIZATION_TERMS', 'fetchLocalizationTerms');
