import React, {MouseEvent, SyntheticEvent, useCallback, useEffect, useRef, useState} from 'react';
import cn from 'classnames';
import TextField from 'react-md/lib/TextFields/TextField';
import Portal from 'react-md/lib/Helpers/Portal';
import AccessibleFakeButton from 'react-md/lib/Helpers/AccessibleFakeButton';
import Tooltipped from 'react-md/lib/Tooltips/Tooltipped';

const defaultValidator = () => undefined;

interface Props {
    className?: string,
    id: string,
    isEditing?: boolean,
    label: string,
    onClick?: (event: MouseEvent<HTMLElement>) => void,
    onEdit?: (newLabel: string) => void,
    onStopEditing?: (cancelled: boolean) => void,
    onValidate?: (newLabel: string) => string | undefined,
    placeholder?: string,
}

const EditableLabel: React.FC<Props> = ({
    className,
    id,
    isEditing,
    label,
    onClick,
    onEdit,
    onStopEditing,
    onValidate = defaultValidator,
    placeholder,
}) => {
    const [text, setText] = useState(label);
    const [error, setError] = useState<string | undefined>();
    useEffect(() => {setText(label);}, [label]); // to refresh the state when props are changed
    const handleChangeLabel = useCallback(
        (value) => {
            setError(undefined);
            setText(value);
        },
        [setError, setText],
    );
    const handleCancelEditing = useCallback(
        (e: SyntheticEvent) => {
            e.stopPropagation();
            onStopEditing && onStopEditing(true);
            setText(label);
        },
        [onStopEditing, setError, setText, label],
    );
    const handleLabelKeyUp = useCallback(
        (event: React.KeyboardEvent<HTMLElement>) => {
            if (event.key === 'Escape') {
                handleCancelEditing(event);
            } else if (event.key === 'Enter') {
                event.stopPropagation();
                const error = onValidate ? onValidate(text) : undefined;
                setError(error);
                if (error) {
                    return;
                }

                onStopEditing && onStopEditing(false);
                onEdit && onEdit(text);
            }
        },
        [handleCancelEditing, onEdit, onStopEditing, onValidate, setError, text],
    );

    // nicely handle focus
    const previouslyFocusedElement = useRef<any>(null);
    const setTextFieldRef = useCallback(
        (textField) => {
            if (textField) {
                previouslyFocusedElement.current = document.activeElement;
                setTimeout(() => textField.focus());
            } else if (previouslyFocusedElement.current?.focus) {
                previouslyFocusedElement.current.focus();
            }
        },
        [],
    );

    return (
        isEditing
            ? (
                <div className={cn('prv-editable-label', className)}>
                    <Portal visible>
                        <AccessibleFakeButton
                            id={`${id}-overlay`}
                            className="md-overlay"
                            onClick={handleCancelEditing}
                        />
                    </Portal>
                    <TextField
                        className="prv-editable-label__text-field"
                        error={!!error}
                        errorText={error || ''}
                        id={id}
                        onChange={handleChangeLabel}
                        onKeyUp={handleLabelKeyUp}
                        ref={setTextFieldRef}
                        value={text ?? ''}
                    />
                </div>
            )
            : (
                <Tooltipped
                    label={label}
                    position="top"
                    delay={500}
                    setPosition
                >
                    <div className={cn('prv-editable-label', className)}>
                        <span onClick={onClick}>{label ?? placeholder}</span>
                    </div>
                </Tooltipped>
            )
    );
};

export default EditableLabel;
