import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const FullPage = ({className, children}) => (
    <div className="prv-full-page">
        <div className={cn('prv-full-page__content', className)}>
            {children}
        </div>
    </div>
);
export default FullPage;

FullPage.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
};
