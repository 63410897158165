import {Level} from '../types';

/**
 * Converts string level into an array representation. '/1/2/3/' -> [1, 2, 3]
 * @param stringLevel
 */
export const parseLevel = (stringLevel: string) => stringLevel.split('/').filter(x => !!x).map(x => parseInt(x, 10));

/**
 * Converts level array back into level string
 * @param level
 */
export const levelToString = (level: Level) => (level.length ? `/${level.join('/')}/` : '');

/**
 * returns parent level (i.e. level without last item)
 * @param level
 * @param upTo
 */
export const getParentLevel = (level: Level, upTo: number = -1) => level.slice(0, upTo);

/**
 * Returns index of a node in a parent.
 * @param level
 */
export const getLastIndex = (level: Level) => level.slice(-1)[0];

/**
 * Update one index in level. If `at` param is omitted, then the last index is updated.
 * @param level
 * @param newIndex
 * @param at
 */
export const updateLevelIndex = (level: Level, newIndex: number, at: number = -1): Level => {
    if ((at >= 0 && level.length <= at) || (at < 0 && at + level.length < 0)) {
        return [...level]; // guarantee a new instance in result
    }
    return at === -1 ? [...level.slice(0, -1), newIndex] : [...level.slice(0, at), newIndex, ...level.slice(at + 1)];
};

/**
 * Increments the last index in level
 * @param level
 */
export const incrementLastIndex = (level: Level) => updateLevelIndex(level, getLastIndex(level) + 1);

/**
 * Ensures that a and b has the same parent. If a and b are of the same length, it's checking that parent
 * levels of a and b are the same. If one level is shorter than another, it's checking that the shorter
 * level is fully present in the larger one.
 * @param a
 * @param b
 */
export const hasSameParent = (a: Level, b: Level) => (
    a.length < b.length
        ? a.every((value, index) => value === b[index])
        : getParentLevel(b).every((value, index) => value === a[index])
);

/**
 * Check if levels are equal
 * @param a
 * @param b
 */
export const isSameLevel = (a: Level, b: Level) => a.length === b.length &&
    a.every((value, index) => value === b[index]);

/**
 * Check if itemLevel is a children on parentLevel. I.e. that itemLevel fully includes parentLevel.
 * @param itemLevel
 * @param parentLevel
 */
export const isChildren = (itemLevel: Level, parentLevel: Level) =>
    parentLevel.length < itemLevel.length && hasSameParent(parentLevel, itemLevel);

/**
 * Checks if itemLevel is a direct children of parentLevel. I.e. that itemLevel = pathLevel + one more index.
 * @param itemLevel
 * @param parentLevel
 */
export const isDirectChildren = (itemLevel: Level, parentLevel: Level) =>
    parentLevel.length === itemLevel.length - 1 && hasSameParent(parentLevel, itemLevel);

/**
 * Check that a and b are sibling levels (their length is same and parent levels are same)
 * @param a
 * @param b
 */
export const isSibling = (a: Level, b: Level) => a.length === b.length && hasSameParent(a, b);

/**
 * Ensures that a and b are siblings and that a comes after b
 * @param a
 * @param b
 */
export const isSiblingAfter = (a: Level, b: Level) => isSibling(a, b) && getLastIndex(a) > getLastIndex(b);
