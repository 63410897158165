import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';

import * as pathnames from 'config/pathnames';

import {formatUtcDateTime} from 'helpers/dates';

import {connect} from 'store';
import {navigate} from 'store/navigate';
import {getProfile, isSA} from 'store/auth';
import {collectionShape} from 'store/collections';
import {getCollectionState} from 'store/notifications';
import {deleteNotification} from 'store/new-notifications/actions';
import {getActiveRMs, getActiveRMsLoading} from 'store/clients';
import {fetchActiveRMs} from 'store/clients/actions';

import {notificationTypes} from 'store/data/notification';
import {rmShape} from 'store/data/rm';
import {profileShape} from 'store/data/profile';

import CollectionListPage from 'components/collections/list-page';
import Spinner from 'components/loading-spinner/loading-spinner';

import NotificationEditDialog from 'scenes/notifications/notification-edit-dialog';
import NewNotificationDialog from 'scenes/notifications/new-notification-dialog';

const renderType = value => notificationTypes[value];

const columns = [
    {
        field: 'senderFirstName',
        label: 'Sender',
        sortField: false,
        width: '120px',
        render: (value, item) => `${item.senderFirstName} ${item.senderLastName}`,
    },
    {field: 'type', label: 'Type', width: '96px', render: renderType},
    {field: 'subject', label: 'Subject', width: '100px'},
    {field: 'message', label: 'Message', width: '220px'},
    {field: 'createdAt', label: 'Created At', width: '166px', render: formatUtcDateTime},
    {field: 'sentAt', label: 'Sent At', width: '166px', render: formatUtcDateTime},
];

@withRouter
@connect(
    {
        isSA,
        activeRMs: getActiveRMs,
        activeRMsLoading: getActiveRMsLoading,
        collection: getCollectionState,
        profile: getProfile,
    },
    {
        fetchActiveRMs,
        deleteNotification,
        navigate,
    },
)
export default class NotificationsListPage extends Component {
    static propTypes = {
        location: PropTypes.shape({
            hash: PropTypes.string,
            pathname: PropTypes.string,
        }),
        collection: collectionShape,
        profile: profileShape,
        isSA: PropTypes.bool.isRequired,
        activeRMs: PropTypes.arrayOf(rmShape),
        activeRMsLoading: PropTypes.bool,
        fetchActiveRMs: PropTypes.func.isRequired,
        deleteNotification: PropTypes.func.isRequired,
        navigate: PropTypes.func.isRequired,
    };

    state = {
        notificationId: null,
    }

    componentWillMount() {
        if (this.props.isSA) this.props.fetchActiveRMs();
    }

    onRefCollectionList = (collectionList) => {
        this.collectionList = collectionList;
    };

    onSuccessEdit = () => {
        this.collectionList.onRefresh();
        this.onCloseEditDialog();
    };

    onCloseEditDialog = () => {
        if (this.state.notificationId) {
            this.setState({
                notificationId: null,
            });

            return;
        }
        this.props.navigate(pathnames.NOTIFICATIONS);
    }

    onDelete = item => this.props.deleteNotification(item.id);

    onEdit = item => {
        this.setState({
            notificationId: item.id,
        });
    };

    getFiltersConfig = () => {
        const {profile, activeRMs} = this.props;

        const filters = {};

        if (this.props.isSA) {
            const options = {[profile.id]: 'Super Admin'};
            activeRMs.reduce(
                (prev, cur) => {
                    const tmp = prev;
                    tmp[cur.id] = `${cur.firstName} ${cur.lastName}`;
                    return tmp;
                }, options,
            );
            filters.senderId = {
                title: 'Sender',
                options,
            };
        }

        return filters;
    };

    render() {
        const {collection, activeRMsLoading, location} = this.props;
        const notification = this.props.collection && this.props.collection.items
            ? this.props.collection.items.find(collection => collection.id === this.state.notificationId)
            : null;
        const shouldRenderNotificationDialog = location.pathname.endsWith('create') || notification;
        // const shouldRenderNewNotificationDialog = location.pathname.endsWith('new') || notification;

        if (activeRMsLoading) return <Spinner/>;

        return (
            <div>
                <CollectionListPage
                    ref={this.onRefCollectionList}
                    name="notifications"
                    title="Notifications"
                    collection={collection}
                    columns={columns}
                    filters={this.getFiltersConfig()}
                    canDelete={() => true}
                    onDelete={this.onDelete}
                    canEdit={() => true}
                    onEdit={this.onEdit}
                />
                {/* {
                    shouldRenderNotificationDialog &&
                    <NotificationEditDialog
                        onSubmitSuccess={this.onSuccessEdit}
                        onCancel={this.onCloseEditDialog}
                    />
                } */}
                {shouldRenderNotificationDialog &&
                    <NewNotificationDialog
                        onSubmitSuccess={this.onSuccessEdit}
                        onCancel={this.onCloseEditDialog}
                        notification={notification}
                    />
                }
            </div>
        );
    }
}
