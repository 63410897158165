import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connectActions} from 'store';
import {navigate} from 'store/navigate';
import HeaderProfile from 'components/layout/header/header-profile';

class NewClientHeader extends Component {
    static propTypes = {
        navigate: PropTypes.func.isRequired,
    };

    render() {
        return (
            <div className="prv-new-client-header">
                <div className="prv-new-client-header__title">
                    <div className="prv-new-client-header__button-back" onClick={() => this.props.navigate('/')}/>
                    <span className="prv-new-client-header__label">New Client</span>
                </div>
                <HeaderProfile/>
            </div>
        );
    }
}

export default connectActions({navigate})(NewClientHeader);
