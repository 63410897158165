import React, {useCallback, useEffect, useState} from 'react';
import cn from 'classnames';
import debounce from 'lodash/debounce';
import {AccountGroup as AccountGroupType, AssetNode, CashAccountNode} from '../../../../store/structure/types';
import AccountGroup from './AccountGroup';
import TextFieldWithSwitch from '../../../../components/TextFieldWithSwitch/TextFieldWithSwitch';

const doesAssetMatchQuery = ({name}: AssetNode | CashAccountNode, query: string) => name.toLowerCase().includes(query);

/**
 * Checks if given group is matched by the query.
 * Method assumes that query is all lowercase and trimmed. See filterGroups method.
 * @param group
 * @param query
 */
const doesGroupMatchQuery = (group: AccountGroupType, query: string) =>
    group.name.toLowerCase().includes(query) || group.assets.some((asset) => doesAssetMatchQuery(asset, query));

const filterGroups = (groups: AccountGroupType[], query: string, filterAssets: boolean) => {
    const searchText = query.trim().toLowerCase();
    if (!searchText.length) {
        return groups;
    }
    return filterAssets
        ? groups
            .map((group) => ({...group, assets: group.assets.filter(asset => doesAssetMatchQuery(asset, searchText))}))
            .filter(({assets}) => assets.length)
        : groups.filter(group => doesGroupMatchQuery(group, searchText));
};

interface Props {
    groups: AccountGroupType[],
    className?: string,
    isPortfolioAssets?: boolean,
}

const AccountGroups: React.FC<Props> = ({groups, className, isPortfolioAssets = false}) => {
    const [shouldFilterAssets, setFilterAssets] = useState(false);
    const [query, setQuery] = useState('');
    const handleQueryChange = useCallback((value) => setQuery(value), [setQuery]);

    // filter groups with a little debouncing
    const [filteredGroups, setFilteredGroups] = useState(groups);
    const updateFilteredGroups = useCallback(
        debounce(
            (query: string, shouldFilterAssets: boolean) =>
                setFilteredGroups(filterGroups(groups, query, shouldFilterAssets)),
            300,
        ),
        [groups],
    );
    useEffect(() => updateFilteredGroups(query, shouldFilterAssets), [query, shouldFilterAssets, updateFilteredGroups]);

    const switchLabel = shouldFilterAssets ? 'Filtering assets' : 'Filtering by account or asset name';

    return (
        <div className={cn('prv-account-groups', {'prv-account-groups--padded': isPortfolioAssets}, className)}>
            {!isPortfolioAssets && (
                <TextFieldWithSwitch
                    className="prv-account-groups__search-field"
                    fullWidth
                    id={isPortfolioAssets ? 'structure-portfolio-assets-search' : 'structure-available-assets-search'}
                    onChange={handleQueryChange}
                    placeholder="Filter assets..."
                    switchContainerClassName="prv-account-groups__search-toggle-button"
                    switchProps={{
                        'aria-label': switchLabel,
                        checked: shouldFilterAssets,
                        onChange: setFilterAssets,
                        tooltipProps: {label: switchLabel, position: 'left'},
                    }}
                    value={query}
                />
            )}
            {filteredGroups.map(accountGroup => (
                <AccountGroup
                    key={accountGroup.id}
                    {...accountGroup}
                    isAdded={isPortfolioAssets}
                />
            ))}
            {!filteredGroups.length && (
                <div className="prv-account-groups__no-assets-message">
                    No Assets
                </div>
            )}
        </div>
    );
};

export default AccountGroups;
