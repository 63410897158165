export type StructureItemId = string;

/**
 * Possible node states.
 * MOVED should be used when only level has changed. If both level and name or level and custodyAccounts
 * have changed, then prefer MODIFIED over MOVED.
 */
export enum StructureItemState {
    CREATED = 'created',
    INITIAL = 'initial',
    MOVED = 'moved',
    MODIFIED = 'modified',
    DELETED = 'deleted',
}

export enum StructureNodeDtoType {
    ASSET = 'ProveoWms.Services.Reporting.Model.Dto.AssetNodeDto, ProveoWms.Services',
    CASH = 'ProveoWms.Services.Reporting.Model.Dto.CashNodeDto, ProveoWms.Services',
    CUSTOMER = 'ProveoWms.Services.Reporting.Model.Dto.CustomerNodeDto, ProveoWms.Services',
    PORTFOLIO = 'ProveoWms.Services.Reporting.Model.Dto.PortfolioNodeDto, ProveoWms.Services',
}

export type Level = number[];

export interface StructureItem<L = Level> {
    id: StructureItemId,
    level: L,
    name: string,
    state: StructureItemState,
    $type: string,
}

export interface AssetNode extends StructureItem {
    assetId: string,
    assetName: string,
    accountId: string,
    accountName: string,
    $type: StructureNodeDtoType.ASSET,
}

export interface CashAccountNode extends StructureItem {
    accountId: string,
    accountName: string,
    $type: StructureNodeDtoType.CASH,
}

export type AnyAssetList = Array<AssetNode | CashAccountNode>;

export interface CustomerNode extends StructureItem {
    $type: StructureNodeDtoType.CUSTOMER,
}

export interface PortfolioNode extends StructureItem {
    custodyAccounts: CustodyAccount[],
    $type: StructureNodeDtoType.PORTFOLIO,
}

export type StructureArray = Array<AssetNode | CashAccountNode | CustomerNode | PortfolioNode>;

export interface CustodyAccount {
    id: string,
    name: string,
}

export interface AccountGroup extends CustodyAccount {
    assets: Array<AssetNode | CashAccountNode>
    isLinked: boolean,
    isCashAccountGroup?: boolean,
}
