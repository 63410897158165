import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DialogContainer from 'react-md/lib/Dialogs/DialogContainer';
import DatePicker from '../date-picker';
import BenchmarkList from './benchmark-list';
import {showToast} from '../../store/snackbar/actions';
import {connectActions} from '../../store';

const dateFormat = 'YYYY-MM-DD[T]00:00:00+00:00';

class EditBenchmarkGroupDialog extends React.PureComponent {
    static propTypes = {
        availableBenchmarks: PropTypes.arrayOf(PropTypes.object),
        benchmarks: PropTypes.arrayOf(PropTypes.object),
        date: PropTypes.instanceOf(Date),
        onCancel: PropTypes.func,
        onSave: PropTypes.func,
        showToast: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            availableBenchmarks: this._convertToValueLabelList(props.availableBenchmarks),
            benchmarks: props.benchmarks || [],
            date: props.date || new Date(),
        };
    }

    componentWillReceiveProps(nextProps) {
        const {availableBenchmarks, benchmarks} = nextProps;
        if (this.props.benchmarks !== benchmarks) {
            this.setState({benchmarks});
        }
        if (this.props.availableBenchmarks !== availableBenchmarks) {
            this.setState({availableBenchmarks: this._convertToValueLabelList(availableBenchmarks)});
        }
    }

    _convertToValueLabelList = benchmarks => benchmarks.map(({id, name}) => ({value: id, label: name || id}));

    _handleChangeDate = (date) => {
        this.setState({date});
    };

    _handleAddSubBenchmark = () => {
        this.setState(prevState => ({benchmarks: [...prevState.benchmarks, {}]}));
    };

    _modifyBenchmarkByIndex = (index, getUpdatedBenchmark) => {
        if (index < 0) {
            return;
        }

        this.setState(prevState => {
            const updatedBenchmark = getUpdatedBenchmark(prevState.benchmarks[index]);

            return {benchmarks: [
                ...prevState.benchmarks.slice(0, index),
                ...(updatedBenchmark ? [updatedBenchmark] : []),
                ...prevState.benchmarks.slice(index + 1),
            ]};
        });
    };

    _handleChangeSubBenchmark = (index, newId, name) => {
        this._modifyBenchmarkByIndex(index, benchmark => ({...benchmark, id: newId, name}));
    };

    _handleSubBenchmarkWeightChange = (index, weight) => {
        this._modifyBenchmarkByIndex(index, benchmark => ({...benchmark, weight}));
    };

    _handleDeleteSubBenchmark = (index) => {
        this._modifyBenchmarkByIndex(index, () => undefined);
    };

    _getBenchmarkNameById = (id) => {
        const benchmarkItem = this.state.availableBenchmarks.find(item => item.value === id);
        return benchmarkItem ? benchmarkItem.label : undefined;
    };

    _getValidationErrorMessage = () => {
        const {benchmarks} = this.state;

        const benchmarkSet = new Set();
        let totalWeight = 0,
            decimalsCount = 1,
            errorMessage;

        benchmarks.some((benchmark, index) => {
            if (!benchmark.id) {
                errorMessage = 'Please choose benchmark from the list.';
                return true;
            }

            const name = this._getBenchmarkNameById(benchmark.id);
            if (!name) {
                errorMessage = `Invalid benchmark #${index + 1}, please try choosing another one.`;
                return true;
            }

            if (!benchmark.weight || Number.isNaN(benchmark.weight) || benchmark.weight < Number.EPSILON) {
                errorMessage = `Please set weight for "${name}" benchmark.`;
                return true;
            }
            totalWeight += benchmark.weight;

            const decimals = String(benchmark.weight).split('.')[1];
            if (decimals && decimals.length > decimalsCount) {
                decimalsCount = decimals.length;
            }

            if (benchmarkSet.has(benchmark.id)) {
                errorMessage = `Benchmark selected twice: "${name}".`;
                return true;
            }
            benchmarkSet.add(benchmark.id);

            return false;
        });

        // only check totalWeight if previous validation was successful
        if (!errorMessage && totalWeight.toFixed(decimalsCount) !== '1.'.padEnd(2 + decimalsCount, 0)) {
            errorMessage = 'Total benchmarks weight must be equal 1.';
        }

        return errorMessage;
    };

    _handleSaveClick = () => {
        const {onSave, showToast} = this.props;
        const {date, benchmarks} = this.state;
        const errorMessage = this._getValidationErrorMessage();

        if (errorMessage) {
            showToast && showToast({text: errorMessage});
        } else {
            onSave && onSave({date: moment(date).toDate(), benchmarks});
        }
    };

    render() {
        const {date: originalDate, onCancel} = this.props;
        const {availableBenchmarks, benchmarks} = this.state;
        const isEdit = !!originalDate;
        const date = moment(this.state.date).format(dateFormat);

        // TODO: add total weight & duplicated sub-benchmarks validation
        const isValid = !!date && benchmarks && benchmarks.length;

        return (
            <DialogContainer
                actions={[
                    {
                        children: 'Cancel',
                        className: 'prv-edit-benchmark-group-dialog__cancel-button',
                        onClick: onCancel,
                        secondary: true,
                    },
                    {
                        children: isEdit ? 'Save' : 'Create',
                        className: 'prv-edit-benchmark-group-dialog__save-button',
                        disabled: !isValid,
                        onClick: this._handleSaveClick,
                        primary: true,
                    },
                ]}
                dialogClassName="prv-edit-benchmark-group-dialog"
                id="edit-benchmark-dialog"
                initialFocus={isEdit
                    ? '.prv-edit-benchmark-group-dialog__save-button'
                    : '.prv-edit-benchmark-group-dialog__cancel-button'
                }
                onHide={onCancel}
                title={isEdit ? 'Edit Benchmark' : 'Create Benchmark'}
                visible
            >
                <span className="prv-edit-benchmark-group-dialog__label">Date</span>
                <DatePicker
                    date={date}
                    onChange={this._handleChangeDate}
                />
                <div className="prv-edit-benchmark-group-dialog__benchmarks">
                    <span className="prv-edit-benchmark-group-dialog__label">Benchmarks</span>
                    <BenchmarkList
                        availableBenchmarks={availableBenchmarks}
                        benchmarks={benchmarks}
                        onAddBenchmark={this._handleAddSubBenchmark}
                        onBenchmarkChange={this._handleChangeSubBenchmark}
                        onDelete={this._handleDeleteSubBenchmark}
                        onWeightChange={this._handleSubBenchmarkWeightChange}
                    />
                </div>
            </DialogContainer>
        );
    }
}

export default connectActions({showToast})(EditBenchmarkGroupDialog);
