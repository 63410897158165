import typeReader from '../helpers/type_reader';
import typeShape, {required} from '../helpers/type_shape';

const BenchmarkType = {
    id: required(String),
    name: required(String),
    morningstarId: required(String),
    morningstarPerformanceId: required(String),
    isin: required(String),
    currency: required(String),
    type: required(String),
};

export const readBenchmark = typeReader(BenchmarkType);
export const clientBenchmarkShape = typeShape(BenchmarkType);

// please adjust the column width in BenchmarksPage component when you change this model
export const benchmarkTypes = {
    tradeable: 'Tradeable',
    marketIndex: 'Market index',
};

