import {
    AssetNode,
    CashAccountNode,
    CustomerNode,
    PortfolioNode,
    StructureArray,
    StructureItem,
    StructureNodeDtoType,
} from '../types';

/**
 * Checks that the given item is a CustomerNode, which is considered to be a root item.
 * @param item
 */
export const isRootItem = (item: StructureItem): item is CustomerNode =>
    item.$type === StructureNodeDtoType.CUSTOMER || item.level.length < 2;

/**
 * Checks that the given item is not a CustomerNode which is considered to be a root item.
 * @param item
 */
export const isNotRootItem = (item: StructureItem): item is Exclude<StructureArray[number], CustomerNode> =>
    !isRootItem(item);

/**
 * Checks that the given item is a Portfolio
 * @param item
 */
export const isPortfolio = (item: StructureItem): item is PortfolioNode =>
    item.$type === StructureNodeDtoType.PORTFOLIO;

/**
 * Checks that the given item is a Cash Account
 * @param item
 */
export const isCashAccount = (item: StructureItem): item is CashAccountNode => item.$type === StructureNodeDtoType.CASH;

/**
 * Checks that the given item is an Asset
 * @param item
 */
export const isAsset = (item: StructureItem): item is AssetNode => item.$type === StructureNodeDtoType.ASSET;

/**
 * Checks that the given item is any asset - either an Asset or a Cash Account
 * @param item
 */
export const isAnyAsset = (item: StructureItem): item is (AssetNode | CashAccountNode) =>
    isCashAccount(item) || isAsset(item);
