import _ from 'lodash';
import moment from 'moment';
import Benchmark from '../../../store/types/Benchmark';

const createBenchmarkGroups = (benchmarks: Benchmark[]) => {
    const benchmarkGroups = _.groupBy(benchmarks, ({startDate}) => startDate);
    return Object.keys(benchmarkGroups)
        .map((date) => ({
            date: moment(date).toDate(),
            benchmarks: benchmarkGroups[date].map(({id, name, weight}) => ({id, name, weight})),
        }))
        .sort((groupA, groupB) => groupA.date.valueOf() - groupB.date.valueOf());
};

export default createBenchmarkGroups;
