import {handleActions, apiActionHandler} from 'store/helpers/actions';
import {actionHandlers} from 'store/collections/reducer_helpers';

import {readTransaction} from 'store/data/client';

import {
    clearUpdateTransactionError,
    updateTransaction,
} from './actions';

const initialState = {
    updateTransactionError: null,
    updateTransactionLoading: false,
};

export {
    getCollectionState,
    getPinnedError,
    getFetchParams,
} from 'store/collections/reducer_helpers';

export const isUpdateTransactionLoading = ({updateTransactionLoading}) => updateTransactionLoading;
export const getUpdateTransactionError = ({updateTransactionError}) => updateTransactionError;

export default handleActions(
    {
        [updateTransaction]: (state, action) => apiActionHandler(
            state,
            action,
            {loading: 'updateTransactionLoading', error: 'updateTransactionError'},
        ),
        [clearUpdateTransactionError]: state => ({...state, updateTransactionError: null}),
        ...actionHandlers('transactions', readTransaction),
    },
    initialState,
);
