import React, {useMemo} from 'react';
import cn from 'classnames';
import {PortfolioNode, StructureArray} from '../../../../store/structure/types';
import AccountGroups from './AccountGroups';
import {connect} from '../../../../store';
import {getSelectedPortfolio, getTree} from '../../../../store/structure';
import {createAccountGroups, getChildrenNodes} from '../../../../store/structure/helpers/structure';
import {isAsset, isCashAccount} from '../../../../store/structure/helpers/assertion';

interface Props {
    className?: string,
    portfolio?: PortfolioNode,
    tree: StructureArray,
}

const PortfolioAssets: React.FC<Props> = ({className, portfolio, tree}) => {
    const groups = useMemo(
        () => createAccountGroups(
            portfolio
                ? getChildrenNodes(tree, portfolio).filter(node => isCashAccount(node) || isAsset(node))
                : [],
            portfolio?.custodyAccounts || [],
            true,
        ),
        [tree, portfolio],
    );

    return (
        <div className={cn('prv-portfolio-assets', className)}>
            <div className="prv-portfolio-assets__portfolio-name">
                {portfolio ? portfolio.name : 'Please select portfolio'}
            </div>
            {portfolio && (
                <AccountGroups
                    className="prv-portfolio-assets__groups"
                    groups={groups}
                    isPortfolioAssets
                />
            )}
        </div>
    );
};

// FIXME: figure out how to type the connect function
// @ts-ignore
export default connect({
    portfolio: getSelectedPortfolio,
    tree: getTree,
})(PortfolioAssets);
