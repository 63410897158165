import {handleActions} from 'store/helpers/actions';
import {actionHandlers} from 'store/collections/reducer_helpers';

import {readAssetsAllocation} from 'store/data/client';

export {
    getCollectionState,
    getPinnedError,
    getFetchParams,
} from 'store/collections/reducer_helpers';

const initialState = {};

export default handleActions(
    {...actionHandlers('clientAssets', readAssetsAllocation)},
    initialState,
);
