import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {CLIENTS, LOGIN, ROOT} from '../config/pathnames';
import ClientPinner from '../scenes/clients/client-pinner';
import ClientProfileLayout from '../scenes/clients/client-profile-layout';
import ViewInformationPage from '../scenes/clients/information/view-information-page';
import ClientAccountsPage from '../scenes/clients/account/client-account';
import ClientPerformancePage from '../scenes/clients/performance/performance-page';
import ErrorPage from '../scenes/error-page';
import {LoggedLayout} from './logged-layouts';

export default (
    <Switch>
        <Route path={ROOT} exact />
        <Route path={LOGIN} />
        <Route
            path={`${CLIENTS}/:id`}
            render={() => (
                <LoggedLayout>
                    <ClientPinner>
                        <ClientProfileLayout>
                            <Switch>
                                <Redirect exact from={`${CLIENTS}/:id`} to={`${CLIENTS}/:id/view`} />
                                <Route path={`${CLIENTS}/:id/view`} component={ViewInformationPage} />
                                <Route path={`${CLIENTS}/:id/performance`} component={ClientPerformancePage} />
                            </Switch>
                        </ClientProfileLayout>
                    </ClientPinner>
                </LoggedLayout>
            )}
        />
        <Route path="*" component={ErrorPage}/>
    </Switch>
);
