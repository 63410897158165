import React, {memo, useCallback, useMemo} from 'react';
import cn from 'classnames';
import {debounce} from 'lodash';
import Button from 'react-md/lib/Buttons/Button';
import Collapse from 'react-md/lib/Helpers/Collapse';
import {
    AccountGroup as AccountGroupType,
    AnyAssetList,
    CustodyAccount,
    StructureItem,
    StructureItemId,
} from '../../../../store/structure/types';
import useBoolean from '../../../../components/hooks/useBoolean';
import AssetItem from './AssetItem';
import AssetGroupTitle from './AssetGroupTitle';
import {connect} from '../../../../store';
import {addAssets, linkAccount, removeAssets, unlinkAccount} from '../../../../store/structure/actions';
import {getObsoleteAssets, getObsoleteCustodyAccounts} from '../../../../store/structure';
import {isSameLevel} from '../../../../store/structure/helpers/level';

interface StoreProps {
    obsoleteAssets: {[key in StructureItemId]: StructureItem},
    obsoleteCustodyAccounts: {[key in CustodyAccount['id']]: CustodyAccount},
}

interface DispatchProps {
    addAssets?: (payload: AnyAssetList) => void,
    linkAccount?: (payload: CustodyAccount) => void,
    removeAssets?: (payload: AnyAssetList) => void,
    unlinkAccount?: (payload: CustodyAccount) => void,
}

interface Props extends AccountGroupType, DispatchProps, StoreProps {
    className?: string,
    isAdded?: boolean,
}

const AccountGroup: React.FC<Props> = memo(({
    assets,
    className,
    id,
    isAdded,
    isCashAccountGroup,
    isLinked,
    name,
    obsoleteAssets,
    obsoleteCustodyAccounts,

    addAssets,
    linkAccount,
    removeAssets,
    unlinkAccount,
}) => {
    const [isExpanded,,, toggleExpanded] = useBoolean();
    const hasAssets = assets.length > 0;

    const handleLinkAccount = useCallback(
        debounce(
            () => (isLinked ?
                unlinkAccount && unlinkAccount({id, name})
                : linkAccount && linkAccount({id, name})),
            500,
        ),
        [id, name, linkAccount, unlinkAccount],
    );

    const handleAddAllAssets = useCallback(
        debounce(() => (isAdded ? removeAssets && removeAssets(assets) : addAssets && addAssets(assets)), 500),
        [id, name, addAssets, removeAssets],
    );

    const hasObsoleteAssets = useMemo(
        () => isAdded && assets
            .some(asset => obsoleteAssets[asset.id] && isSameLevel(obsoleteAssets[asset.id].level, asset.level)),
        [assets, isAdded],
    );

    const isObsolete = isAdded && !!obsoleteCustodyAccounts[id];

    return (
        <div className={cn('prv-account-group', className)}>
            <div className="prv-account-group__header">
                <AssetGroupTitle
                    className="prv-account-group__title"
                    expanded={isExpanded}
                    hasAssets={hasAssets}
                    markObsolete={hasObsoleteAssets || isObsolete}
                    onClick={toggleExpanded}
                    title={name}
                />
                <div className="prv-account-group__controls">
                    {!isCashAccountGroup && (
                        <Button
                            className={cn(
                                'prv-account-group__link-button',
                                {'prv-account-group__link-button--obsolete': isObsolete},
                            )}
                            icon
                            onClick={handleLinkAccount}
                            tooltipLabel={isLinked ? 'Unlink from Portfolio' : 'Link to Portfolio'}
                            tooltipPosition="left"
                        >
                            {isLinked ? 'link_off' : 'link'}
                        </Button>
                    )}
                    {hasAssets && (
                        <Button
                            icon
                            onClick={handleAddAllAssets}
                            tooltipLabel={isAdded ? 'Remove all assets from Portfolio' : 'Add all assets to Portfolio'}
                            tooltipPosition="left"
                        >
                            {isAdded ? 'remove' : 'add'}
                        </Button>
                    )}
                </div>
            </div>
            <Collapse collapsed={!isExpanded}>
                <div className="prv-account-group__asset-list">
                    {assets.map(asset => (
                        <AssetItem
                            asset={asset}
                            isAdded={isAdded}
                            key={asset.id}
                            obsoleteAssets={obsoleteAssets}
                        />
                    ))}
                </div>
            </Collapse>
        </div>
    );
});

// FIXME: figure out how to type the connect function
// @ts-ignore
export default connect(
    {obsoleteAssets: getObsoleteAssets, obsoleteCustodyAccounts: getObsoleteCustodyAccounts},
    {addAssets, linkAccount, removeAssets, unlinkAccount},
)(AccountGroup);
