import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import _ from 'lodash';
import {connect} from 'store';

import {
    bankSignIn,
    fetchBankAuthMethods,
    clearAddBankError,
    fetchBanks,
    resetAddBankState,
    updateBankCredentials, fetchLocalizationTerms,
} from 'store/new-client/actions';

import {
    getAddBankError,
    getAgreements,
    getBanks,
    getClient,
    getLocalizationTerms,
    getLoginMessage,
    getLoginStatus,
    getLoginSubStatuses,
    isAddBankLoading,
} from 'store/new-client';

import {bankShape, clientShape} from 'store/data/client';
import {getReferenceData} from 'store/general-data';
import {countryShape} from 'store/data/general-data';

import Moire from 'components/moire/moire';
import ChooseBank from 'components/bank-components/choose-bank';
import ChooseCountry from 'components/bank-components/choose-country';
import ChooseAuthMethod from 'components/bank-components/choose-auth-method';
import ErrorPanel from 'components/panel/panel';
import LoginForm from 'components/bank-components/login-form';
import BankAuthenticationStatus from 'components/bank-components/BankAuthenticationStatus';
import Spinner from 'components/loading-spinner/loading-spinner';
import AuthMethods from 'components/bank-components/AuthMethods';

// eslint-disable-next-line max-len
const bankPreviewMessage = 'Når du trykker ”Next”, accepterer du at Proveo indhenter kontant- og fondsinformationer fra dit pengeinstitut. Proveo kan ikke foretage transaktioner på dine vegne. Proveo anvender udelukkende de indhentede informationer til analyseformål til betjening af dig. Proveo kan ikke gøres ansvarlig for oplysninger forevist i appen. Alle informationer behandles efter gældende persondatalovgivning';

const SpinnerTextType = {
    common: 1,
    mitid: 2,
};

class AddBankDialog extends Component {
    static propTypes = {
        onCancel: PropTypes.func.isRequired,
        onAddBankSuccess: PropTypes.func.isRequired,

        banks: PropTypes.arrayOf(bankShape),
        referenceData: PropTypes.shape({
            country: PropTypes.arrayOf(countryShape),
        }),
        client: clientShape.isRequired,
        loading: PropTypes.bool,
        error: PropTypes.object,
        localizationTerms: PropTypes.arrayOf(PropTypes.shape({})),
        loginMessage: PropTypes.string,
        loginStatus: PropTypes.string,
        loginSubStatuses: PropTypes.arrayOf(PropTypes.string),
        agreements: PropTypes.shape({}),
        fetchBanks: PropTypes.func.isRequired,
        fetchLocalizationTerms: PropTypes.func.isRequired,
        bankSignIn: PropTypes.func.isRequired,
        fetchBankAuthMethods: PropTypes.func.isRequired,
        updateBankCredentials: PropTypes.func.isRequired,
        clearAddBankError: PropTypes.func.isRequired,
        resetAddBankState: PropTypes.func.isRequired,
        // fetchTinkUrl: PropTypes.func.isRequired,
        selectedBank: PropTypes.oneOfType([
            bankShape,
            PropTypes.oneOf([undefined]),
        ]),
        hideDescription: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.steps = {
            previewMessage: 0,
            chooseCountry: 1,
            chooseBank: 2,
            chooseAuthMethod: 3,
            login: 4,
        };

        this.defaultState = {
            shouldRenderAddBankDialog: false,
            step: this.steps.previewMessage,
            authData: null,
            selectedBankId: null,
            selectedCountryId: undefined,
            banks: [],
            attempts: 0,
            addingBank: false,
            authMethods: [],
            selectedAuthMethod: null,
            spinnerTextType: SpinnerTextType.common,
        };

        this.state = this.defaultState;
    }

    async componentWillMount() {
        this.props.fetchBanks();
        if (this.props.selectedBank) {
            const {fetchBankAuthMethods} = this.props;
            const auth = await fetchBankAuthMethods({bankId: this.getBankId(this.props.selectedBank)});
            this.setState({authMethods: auth});
            if (auth && auth.length === 1) {
                this.setState({selectedAuthMethod: auth[0]});
            }

            // eslint-disable-next-line no-nested-ternary
            const authMethod = !this.props.selectedBank.authMethod
                ? auth.length === 1
                    ? auth.authMethods[0]
                    : undefined
                : this.props.selectedBank.authMethod;
            this.setState({selectedAuthMethod: authMethod});

            this.setState({selectedBankId: this.getBankId(this.props.selectedBank)});

            // eslint-disable-next-line no-nested-ternary
            const step = this.props.selectedBank.authMethod === AuthMethods.MitId && auth.includes(AuthMethods.MitId)
                ? this.steps.login
                : auth.length > 1
                    ? this.steps.chooseAuthMethod
                    : this.steps.login;
            this.setState({step});
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.loginMessage) {
            this.setState({attempts: 0});
        }
        if (nextProps.banks) {
            this.setState({banks: nextProps.banks});
        }
    }

    componentWillUnmount() {
        this.props.resetAddBankState();
    }

    onLoginFormRef = (form) => {
        this.loginForm = form;
    };
    onLoginFormValidate = isLoginFormValid => this.setState({isLoginFormValid});

    onBankSignIn = (data, extra = {}) => {
        const {bankSignIn, client: {id}, loginMessage, selectedBank, updateBankCredentials} = this.props;
        const {selectedBankId, selectedAuthMethod} = this.state;
        let {attempts} = this.state;
        attempts = loginMessage ? 0 : attempts + 1;

        const authData = Object.assign({}, this.state.authData, data);
        // if we have the agreementId already in the state, and now the agreementId is not set, keep the previous one
        if (_.get(this.state, ['authData', 'agreementId']) && data.agreementId === undefined) {
            authData.agreementId = this.state.authData.agreementId;
        }
        this.setState({authData, attempts, addingBank: true});

        const payload = {
            id,
            bank: selectedBankId,
            attempts,
            BankApprovalSupport: true,
            authMethod: selectedAuthMethod,
            ...authData,
            ...extra,
        };

        // make an api call to bankSignIn when adding bank and to updateBankCredentials when updating bank creds
        if (selectedBank) {
            return updateBankCredentials({bankUserId: selectedBank.id, data: payload})
                .then((response) => {
                    if (response.status === BankAuthenticationStatus.ConfirmationCodeRequired) {
                        this.setState((state) => ({authData: {...state.authData, confirmationCode: ''}}));
                    }

                    // For MitId the request is performed and spinner is displayed just after entering MitID user name
                    if (response.status === BankAuthenticationStatus.MitIdApprovalRequired) {
                        this.setState({spinnerTextType: SpinnerTextType.mitid});
                        return this.onBankSignIn({}, {BankApprovalStatus: true});
                    }

                    return response;
                });
        }
        return bankSignIn({data: payload})
            .then((response) => {
                if (response.status === BankAuthenticationStatus.ConfirmationCodeRequired) {
                    this.setState((state) => ({authData: {...state.authData, confirmationCode: ''}}));
                }

                // For MitId the request is performed and spinner is displayed just after entering MitID user name
                if (response.status === BankAuthenticationStatus.MitIdApprovalRequired) {
                    this.setState({spinnerTextType: SpinnerTextType.mitid});
                    return this.onBankSignIn({}, {BankApprovalStatus: true});
                }

                return response;
            });
    };

    onNemIdApprovalRequested = async () => {
        try {
            const response = await this.onBankSignIn({}, {BankApprovalStatus: true});
            this.onAddBankSuccess(response);
        } catch (e) {
            this.onAddBankFail();
        }
    };

    onAddBankSuccess = (res) => {
        this.setState({addingBank: false});
        if (!(res && (res.message || res.agreements))) {
            this.props.onAddBankSuccess();
        }
    };

    onAddBankFail = () => {
        this.setState({addingBank: false});
    };

    onNextStep = (event) => {
        const {step, attempts, selectedCountryId, authMethods, selectedAuthMethod, selectedBank} = this.state;
        const {loginStatus} = this.props;
        this.setState({spinnerTextType: SpinnerTextType.common});

        if (step === this.steps.previewMessage) {
            this.setState({step: this.steps.chooseCountry});
        } else if (step === this.steps.chooseCountry) {
            this.setState({
                banks: this.props.banks.filter(({countryId}) => countryId === selectedCountryId),
                step: this.steps.chooseBank,
            });
        } else if (step === this.steps.chooseBank) {
            if (authMethods.length > 1 && !selectedBank?.isOutdated) {
                this.setState({step: this.steps.chooseAuthMethod});
            } else {
                this.setState({step: this.steps.login});
            }
        } else if (step === this.steps.chooseAuthMethod) {
            this.setState({step: this.steps.login});
        } else if (step === this.steps.login) {
            if (attempts <= 2) {
                if (loginStatus === BankAuthenticationStatus.NemIdApprovalRequired
                    || loginStatus === BankAuthenticationStatus.MitIdApprovalRequired) {
                    if (loginStatus === BankAuthenticationStatus.MitIdApprovalRequired) {
                        this.setState({spinnerTextType: SpinnerTextType.mitid});
                    }
                    this.onNemIdApprovalRequested();
                } else {
                    this.loginForm.wrappedInstance.submit(event);
                }
            }
        }
    };

    onPrevStep = () => {
        const {step, authMethods} = this.state;
        if (step >= this.steps.login) {
            this.props.resetAddBankState();
            this.setState({step: authMethods.length > 1 ? this.steps.chooseAuthMethod : this.steps.chooseBank});
        } else {
            this.setState(prevState => ({step: prevState.step - 1}));
        }
    };

    onSelectCountry = country => {
        this.setState({selectedCountryId: country && country.id});
    };

    onSelectBank = async (bank) => {
        // if (bank.isTinkSupported) {
        //     this.props.fetchTinkUrl({
        //         bankId: bank.id,
        //         callback: window.location.href,
        //         // headers: {
        //         //     [UserScopeHeader]: UserScopes.PROVEO,
        //         // },
        //     });
        // } else {
        if (bank) {
            const {fetchBankAuthMethods} = this.props;
            const auth = await fetchBankAuthMethods({bankId: this.getBankId(bank)});
            this.setState({authMethods: auth});
            if (auth && auth.length === 1) {
                this.setState({selectedAuthMethod: auth[0]});
            }
        }
        this.setState({selectedBankId: bank && bank.id});
        // }
    };

    onChooseAuthMethod = (authMethod) => {
        this.setState({selectedAuthMethod: authMethod});
    };

    getBankId = (bank) => (bank.isOutdated ? bank.bankId : bank.id);

    canNext = () => {
        const {step, selectedBankId, selectedCountryId, isLoginFormValid, banks,
            authMethods, selectedAuthMethod} = this.state;
        const {loading} = this.props;

        switch (step) {
            case this.steps.previewMessage:
                return true;
            case this.steps.chooseCountry:
                return !!selectedCountryId && !loading;
            case this.steps.chooseBank:
                return !!selectedBankId && !loading && banks.some(({id}) => id === selectedBankId);
            case this.steps.chooseAuthMethod:
                return authMethods.length < 2 || selectedAuthMethod !== null;
            case this.steps.login:
                return isLoginFormValid && !loading;
            default:
                return false;
        }
    };

    renderStepsHeader = () => {
        const error = _.cloneDeep(this.props.error);
        if (this.props.error && this.props.error.errors && Array.isArray(this.props.error.errors)
            && this.props.error.errors.length > 0) {
            error.errors[0].message = `${this.props.error.errors[0].message} (${this.props.error.errors[0].details || ''})`;
        }
        return (
            <div>
                <div className="prv-add-bank-dialog__steps-header">
                    <div className="prv-add-bank-dialog__close-icon" onClick={this.props.onCancel}/>
                    <div className="prv-add-bank-dialog__step prv-add-bank-dialog__step--active">
                        <div className="prv-add-bank-dialog__step-icon prv-add-bank-dialog__step-icon--bank"/>
                        <div className="prv-add-bank-dialog__step-title">Banks</div>
                    </div>
                    <div className="prv-add-bank-dialog__step prv-add-bank-dialog__step--divider"/>
                    <div
                        className={cn('prv-add-bank-dialog__step', {
                            'prv-add-bank-dialog__step--active': this.state.step > this.steps.chooseBank,
                        })}
                    >
                        <div className="prv-add-bank-dialog__step-icon prv-add-bank-dialog__step-icon--login"/>
                        <div className="prv-add-bank-dialog__step-title">Login</div>
                    </div>
                </div>
                {this.props.error &&
                <ErrorPanel
                    className="prv-add-bank-dialog__error-panel"
                    error={error}
                    onClear={this.props.clearAddBankError}
                />}
            </div>
        );
    };

    renderDialogFooter = () => {
        const {step} = this.state;
        const {loading, selectedBank} = this.props;
        const nextButtonCaption = 'Next';

        return (
            <div className="prv-add-bank-dialog__footer">
                {
                    step > this.steps.chooseCountry && !selectedBank &&
                    <button
                        className="prv-add-bank-dialog__button-cancel"
                        onClick={this.onPrevStep}
                        disabled={loading}
                    >
                        Back
                    </button>
                }
                <button
                    className="prv-add-bank-dialog__button-next"
                    onClick={this.onNextStep}
                    disabled={!this.canNext()}
                >
                    {nextButtonCaption}
                </button>
            </div>
        );
    };

    render() {
        const {
            fetchLocalizationTerms,
            hideDescription,
            localizationTerms,
            loginMessage,
            loginStatus,
            loginSubStatuses = [],
            agreements = {},
            loading,
            referenceData,
            selectedBank,
        } = this.props;

        const {step, authData, attempts, addingBank, banks, selectedBankId, selectedCountryId,
            selectedAuthMethod, spinnerTextType, authMethods} = this.state;

        const bank = banks.find(({id}) => id === selectedBankId);

        const loginStepMessage = loginMessage?.includes('Exceptions.MitIdApprovalRequiredException') || loginMessage?.includes('Exceptions.NemIdApprovalRequiredException')
            ? ''
            : loginMessage;

        return (
            <Moire onCancel={this.props.onCancel}>
                <div className="prv-add-bank-dialog">
                    {this.renderStepsHeader()}
                    {
                        step === this.steps.previewMessage &&
                        <div className="prv-add-bank-dialog__bank-preview-message">{bankPreviewMessage}</div>
                    }
                    {
                        step === this.steps.chooseCountry &&
                        <ChooseCountry
                            countries={referenceData.country}
                            onSelect={this.onSelectCountry}
                            selectedCountryId={selectedCountryId}
                        />
                    }
                    {
                        step === this.steps.chooseBank &&
                        <ChooseBank
                            banks={banks}
                            onSelectBank={this.onSelectBank}
                            selectedBankId={selectedBankId}
                        />
                    }
                    {
                        step === this.steps.chooseAuthMethod &&
                        <ChooseAuthMethod
                            onChoose={this.onChooseAuthMethod}
                            authMethod={selectedAuthMethod}
                            authMethods={authMethods}
                        />
                    }
                    {
                        step === this.steps.login && bank &&
                        <LoginForm
                            credentialsOnly={!!selectedBank}
                            disabled={attempts > 2}
                            fetchLocalizationTerms={fetchLocalizationTerms}
                            hideDescription={hideDescription}
                            initialValues={{...authData, agreementId: Object.keys(agreements)[0]}}
                            localizationTerms={localizationTerms}
                            status={loginStatus}
                            subStatuses={loginSubStatuses}
                            selectedBank={bank}
                            authMethod={selectedAuthMethod}
                            withAgreements={_.isObject(agreements) && !_.isEmpty(agreements)}
                            agreements={agreements}
                            message={loginStepMessage}
                            onValidateResult={this.onLoginFormValidate}
                            ref={this.onLoginFormRef}
                            onSubmit={this.onBankSignIn}
                            onSubmitSuccess={this.onAddBankSuccess}
                            onSubmitFail={this.onAddBankFail}
                        />
                    }
                    {this.renderDialogFooter()}
                    {(addingBank || loading) &&
                        <Spinner className="add-bank-dialog-spinner">
                            {spinnerTextType === SpinnerTextType.common && <div>Please wait...</div>}
                            {spinnerTextType === SpinnerTextType.mitid && !window.navigator.language.includes('da') &&
                            <>
                                <div>Waiting for MitID approval.</div>
                                <div>Open your MitID mobile app and approve.</div>
                            </>}
                            {spinnerTextType === SpinnerTextType.mitid && window.navigator.language.includes('da') &&
                            <>
                                <div>Afventer MitID godkendelse.</div>
                                <div>Åben din MitID mobil app og godkend.</div>
                            </>}
                        </Spinner>
                    }
                </div>
            </Moire>
        );
    }
}

export default connect(
    {
        client: getClient,
        banks: getBanks,
        referenceData: getReferenceData,
        loading: isAddBankLoading,
        error: getAddBankError,
        localizationTerms: getLocalizationTerms,
        loginMessage: getLoginMessage,
        loginStatus: getLoginStatus,
        loginSubStatuses: getLoginSubStatuses,
        agreements: getAgreements,
    },
    {
        fetchBanks,
        fetchLocalizationTerms,
        bankSignIn,
        fetchBankAuthMethods,
        updateBankCredentials,
        clearAddBankError,
        resetAddBankState,
        // fetchTinkUrl,
    },
)(AddBankDialog);
