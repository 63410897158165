import moment from 'moment';

moment.locale(navigator.language);

export const format = (string, fmt, parseFormat) => {
    if (!string) {
        return null;
    }
    const date = moment(string, parseFormat);
    return date.isValid() ? date.local().format(fmt) : null;
};

export const formatUtcDateTimeAsIs = dateTimeString => {
    if (!dateTimeString) {
        return null;
    }
    const date = moment.utc(dateTimeString);
    return date.isValid() ? date.format('lll') : null;
};

export const formatUtcDateTime = dateTimeString => format(dateTimeString, 'lll') || '-';

export const formatUtcDate = dateString => format(dateString, 'll') || '-';

export const formatUtcTime = timeString => format(timeString, 'LT', 'HH:mm::ss') || '-';

export const formatUtcDayMonth = dateString => format(dateString, 'MMM DD');

export const formatUtcDateInterval = (startDate, endDate) => [
    format(startDate, 'LL') || '',
    format(endDate, 'LL') || '',
].join(' - ');

export const timeLeft = (expire, units = 'days') => (
    expire && moment.utc(expire).diff(moment(), units)
);

export const listOfDatesBetween = (startDate, endDate) => {
    const start = moment.utc(startDate);
    const end = moment.utc(endDate);
    const duration = end.diff(start, 'days');
    const dates = [];
    for (let i = 0; i < duration; i += 1) {
        dates.push(moment(start).add(i, 'day'));
    }
    return dates;
};

export const getDateInPast = milliseconds => new Date(new Date().getTime() - milliseconds);
export const hoursToMillis = hours => (hours * 1000 * 60 * 60);
export const daysToMillis = days => (days * hoursToMillis(1) * 24);

export const getClosestWorkingDay = () => {
    // Subtracting 3 workdays from the current date. Substracted days don't consider Saturday and Sunday.
    // https://proveo.atlassian.net/browse/PWEB-125
    const today = moment();
    const reportDay = today.day();
    const diff = [5, 5, 5, 5, 3, 3, 4];
    return today.subtract(diff[reportDay], 'd');
};
