import {PortfolioNode, StructureArray} from '../types';
import {StructureState} from '../reducer';
import {getLastIndex, isChildren, isSiblingAfter, updateLevelIndex} from '../helpers/level';
import {createAccountGroups, getAllChildrenRecursively} from '../helpers/structure';
import {isAnyAsset, isPortfolio} from '../helpers/assertion';
import {groupComparator, nameFieldComparator, structureComparator} from '../helpers/sort';

/**
 * Removes given portfolio from the tree. To do that we need:
 *
 * 1. delete all sub-nodes of the portfolio's portfolio being removed
 * 2. update levels for sibling portfolios to shift them accordingly
 * 3. update siblings' subportfolios (and deeper) levels accordingly
 * 4. remove all portfolio assets (and sub-portfolio assets) from the structure and make them available again

 To better illustrate steps 1-4 let's assume we had the following structure:
 * 1 - A
 * 2 - B
 * 2-1 - B1
 * 2-1-1 - B11
 * 2-2 - B2
 * 2-2-1 - B21
 * 2-2-1-1 - B211
 * 2-2-1-2 - B212
 * 3 - C
 * 3-1 - C1
 * 3-1-1 - C11
 *
 * If we remove node B, we'll remove B1, B11, B2, B21, B211, B212 ana all their assets.
 * Node C will be shifted, thus we need to update level of C and all C* nodes (and all their assets).
 *
 * This is the tree we should get after B node removal:
 * 1 - A
 * 2 - C
 * 2-1 - C1
 * 2-1-1 - C11
 *
 * To explain some terminology:
 * - direct children: nodes B1 and B2 are direct children of node B
 * - children: all B* nodes are children of node B
 * - sibling - nodes A and C are siblings of node B. But A comes before B, so we don't need to update it.
 *
 * Removal relies on the fact that the tree is sorted. And thus nodes come exactly in the order shown on examples
 * above, i.e. depth-first sorting is required. Reducer guarantees that the tree is sorted afterwards.
 */
const removePortfolioReducer = (state: StructureState, {payload: portfolio}: {payload: PortfolioNode}) => {
    const {tree} = state;

    // find what will be the next sibling index
    let index = getLastIndex(portfolio.level);

    // also find the content of portfolio that we'll have to remove from the structure
    const itemsToRemove = new Set(getAllChildrenRecursively(tree, portfolio));
    const custodyAccountIdsToUnlink = new Set(Array.from(itemsToRemove)
        .filter(isPortfolio)
        .flatMap(portfolio => portfolio.custodyAccounts.map(({id}) => id)),
    );
    portfolio.custodyAccounts.forEach(({id}) => custodyAccountIdsToUnlink.add(id));

    // Iterate over the structure and update levels for subportfolios, [sub-]subportfolios, for sibling portfolios,
    // and for sibling's [sub-]subportfolios.
    // Also update selectedPortfolio item if it was one of updated portfolios.
    let selectedPortfolio = state.selectedPortfolio === portfolio ? undefined : state.selectedPortfolio;
    let siblingPortfolio: StructureArray[number] | undefined; // to track most recently updated sibling
    const updatedTree = tree
        .map(item => {
            let {level} = item;

            const isSibling = isSiblingAfter(level, portfolio.level);
            if (isSibling) {
                level = updateLevelIndex(portfolio.level, index++); // eslint-disable-line no-plusplus
                siblingPortfolio = isSibling ? item : siblingPortfolio;
            } else if (siblingPortfolio && isChildren(level, siblingPortfolio.level)) {
                level = updateLevelIndex(level, index - 1, siblingPortfolio.level.length - 1);
            } else {
                siblingPortfolio = undefined;
            }

            if (level !== item.level) {
                const updatedItem = {...item, level};
                if (selectedPortfolio === item) {
                    selectedPortfolio = updatedItem as PortfolioNode;
                }
                return updatedItem;
            }

            return item;
        })
        .filter(item => item !== portfolio && !itemsToRemove.has(item)) // remove (sub) portfolio assets and (sub) portfolio itself
        .sort(structureComparator); // ensure tree is still sorted

    // add custody accounts and assets back into available assets list
    let {accountGroups} = state;
    if (portfolio.custodyAccounts || itemsToRemove.size) {
        // update linked status (and make a copy of account groups)
        accountGroups = accountGroups
            .map(group => ({...group, isLinked: custodyAccountIdsToUnlink.has(group.id) ? false : group.isLinked}));
        const groupIdToGroupIndex = new Map(accountGroups.map(({id}, index) => [id, index]));
        // create account groups from freed custody accounts & assets and then merge with existing accountGroups
        createAccountGroups(Array.from(itemsToRemove).filter(isAnyAsset), portfolio.custodyAccounts).forEach(group => {
            const existingGroupIndex = groupIdToGroupIndex.get(group.id);
            if (existingGroupIndex) {
                accountGroups[existingGroupIndex] = {
                    ...accountGroups[existingGroupIndex],
                    assets: [...accountGroups[existingGroupIndex].assets, ...group.assets].sort(nameFieldComparator),
                    // mark custody account unlinked only if it was assigned to (sub-)portfolio(s) being removed
                    isLinked: custodyAccountIdsToUnlink.has(group.id)
                        ? false
                        : accountGroups[existingGroupIndex].isLinked,
                };
            } else {
                accountGroups.push({
                    ...group,
                    isLinked: custodyAccountIdsToUnlink.has(group.id) ? false : group.isLinked,
                });
            }
        });
        accountGroups.sort(groupComparator);
    }

    return {...state, accountGroups, selectedPortfolio, tree: updatedTree};
};

export default removePortfolioReducer;
