import React, {MouseEvent, useCallback} from 'react';
import cn from 'classnames';
import {defaultNodeRenderer} from './FlatTreeElement';
import {BaseFlatTreeProps, TreeNode} from './tree';

interface Props extends BaseFlatTreeProps {
    tree: TreeNode[],
    onDeselect?: (e: MouseEvent<HTMLElement>) => void,
}

const FlatTreeView: React.FC<Props> = (props) => {
    const {className, getNodeId, tree, renderNode = defaultNodeRenderer} = props;
    const handleClick = useCallback(
        (e: MouseEvent<HTMLElement>) => {
            // @ts-ignore
            if (e?.target?.className?.split(' ').includes('prv-tree-view')) {
                props.onDeselect && props.onDeselect(e);
            }
        },
        [],
    );
    return (
        <div
            className={cn('prv-tree-view', 'prv-tree-view--flat', className)}
            onClick={handleClick}
        >
            {tree.map(node => (
                renderNode(node, {key: `tree-node-${getNodeId(node)}`, ...props})
            ))}
        </div>
    );
};

export default FlatTreeView;
