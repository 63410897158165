import {
    callApi,
    fromStore,
    dispatch,
    apiCaller,
} from 'store/helpers/sagas';

import {createApiCallResultAction} from 'store/helpers/actions';
import {actionDispatchers} from '../collections/saga_helpers';

import {getPinnedClient} from '../clients';
import {getFetchParams} from './index';

import {
    fetchTransactions,
    pinTransactionById,
} from './actions';

const transactionsFetched = createApiCallResultAction(fetchTransactions);

function* fetcher({payload}) {
    const {id} = yield fromStore(getPinnedClient);
    const params = yield fromStore(getFetchParams, payload);

    try {
        const result = yield callApi('fetchClientTransactions', {id, ...params});
        // FIXME: a hack, backend should return "type" instead of "types" in the future
        if (result && result.filters && result.filters.types) {
            result.filters.type = result.filters.types;
        }
        yield dispatch(transactionsFetched(result));
    } catch (error) {
        yield dispatch(transactionsFetched(error));
    }
}

export default {
    [fetchTransactions]: fetcher,
    [pinTransactionById]: apiCaller('fetchTransactionById', createApiCallResultAction(pinTransactionById)),
    ...actionDispatchers('clientTransactions'),
};
