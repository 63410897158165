import axios, {AxiosInstance} from 'axios';
import qs from 'query-string';

import ApiError from './error';

export default class ApiBase {
    axios: AxiosInstance;

    constructor({baseURL, timeout}: {baseURL: string, timeout?: number}) {
        this.axios = axios.create({
            baseURL,
            timeout,
            paramsSerializer: (params) => qs.stringify(params, {arrayFormat: 'none'}),
        });
    }

    get(url: string, {noAuth = false, ...options} = {}) {
        const {cancel, config} = this.processOptions(options, {noAuth});
        return this.processResponse(this.axios.get(url, config), cancel);
    }

    post(url: string, data: any, {noAuth = false, ...options} = {}) {
        const {cancel, config} = this.processOptions(options, {noAuth});
        return this.processResponse(this.axios.post(url, data, config), cancel);
    }

    patch(url: string, data: any, {noAuth = false, ...options} = {}) {
        const {cancel, config} = this.processOptions(options, {noAuth});
        return this.processResponse(this.axios.patch(url, data, config), cancel);
    }

    put(url: string, data: any, {noAuth = false, ...options} = {}) {
        const {cancel, config} = this.processOptions(options, {noAuth});
        return this.processResponse(this.axios.put(url, data, config), cancel);
    }

    delete(url: string, {noAuth = false, ...options} = {}) {
        const {cancel, config} = this.processOptions(options, {noAuth});
        return this.processResponse(this.axios.delete(url, config), cancel);
    }

    uploadFile({url, file, ...options}: {url: string, file: File}) {
        const {cancel, config} = this.processOptions(options, {noAuth: true});
        config.headers = {
            ...config.headers,
            'x-ms-blob-type': 'BlockBlob',
            'Content-Type': file.type,
        };
        return this.processResponse(this.axios.put(url, file, config), cancel);
    }

    processOptions(options: any, {noAuth}: {noAuth?: boolean} = {}) {
        const config = {...options};
        let cancel;
        if (!config.cancelToken) {
            const source = axios.CancelToken.source();
            config.cancelToken = source.token;
            cancel = source.cancel;
        }
        if (!noAuth) {
            const authToken = this.getAuthToken();
            if (authToken) {
                config.headers = {
                    ...config.headers,
                    Authorization: `Bearer ${authToken}`,
                };
            }
        }
        const {headers = {}} = config;
        if (!headers['Content-Type']) {
            config.headers = {
                ...config.headers,
                'Content-Type': 'application/json',
            };
        }
        return {cancel, config};
    }

    processResponse(promise: Promise<any>, cancel?: any) {
        const response: any = promise
            .then(reply => ({reply}))
            .catch(error => (axios.isCancel(error)
                ? {}
                : {error: this.processError(error)}
            ))
            .then(({reply, error}: any) => {
                if (error) throw error;
                if (!reply) return null;
                return reply.config.fullResponse ? reply : reply.data;
            });
        if (cancel) {
            // response[CANCEL] = cancel;
            response.cancel = cancel;
        }
        return response;
    }

    processError(error: any) {
        const response = error.response;
        const status = response && response.status;
        const data = response && response.data;
        const result: any =
            (data && this.errorFromData(data))
            || (status && this.errorFromStatus(status))
            || error;
        if (!result.status) {
            result.status = status;
        }
        return result;
    }

    errorFromData(data: any|string) { // eslint-disable-line class-methods-use-this
        if (typeof data === 'string') {
            return new ApiError(data);
        }
        return new ApiError(data.message || 'Server error', data.errors || data);
    }

    errorFromStatus(status: number) { // eslint-disable-line class-methods-use-this
        switch (status) {
            case 401:
                return new ApiError('Authentication failed');
            default:
                return null;
        }
    }

    /**
     * Method to return auth token. Can be overridden.
     */
    // eslint-disable-next-line class-methods-use-this
    getAuthToken(): string | undefined {
        return undefined;
    }
}
