import React, {PureComponent} from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import {actionsProperties} from './resource-table';
import styles from './resource-table.module.css';
import ActionViewOptions from './action-view-options';

const invertSort = (sorted, sortOrder) => {
    if (!sorted) return 'asc';
    return sortOrder === 'desc' ? 'asc' : 'desc';
};

export const columnProps = {
    label: PropTypes.string,
    field: PropTypes.string,
    sortField: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
    ]),
    width: PropTypes.string,
    headerClassName: PropTypes.string,
};

export default class ResourceTableHeader extends PureComponent {
    static propTypes = {
        actionView: PropTypes.string,
        columns: PropTypes.arrayOf(PropTypes.shape(columnProps)).isRequired,
        sortField: PropTypes.string,
        sortOrder: PropTypes.string,
        onSort: PropTypes.func,
        isTableHaveEditableItem: PropTypes.bool,
        isTableHaveBlockableItem: PropTypes.bool,
        isTableHaveDeletableItem: PropTypes.bool,
        isTableHaveRecalculatableItem: PropTypes.bool,
        canSelectAll: PropTypes.func,
    };

    static defaultProps = {
        actionView: ActionViewOptions.Buttons,
    };

    getActionsWidth = () => {
        const {
            isTableHaveEditableItem, isTableHaveBlockableItem,
            isTableHaveDeletableItem, isTableHaveRecalculatableItem, actionView,
        } = this.props;
        let width = 0;

        if (actionView === ActionViewOptions.Menu) {
            return 50;
        }

        const iconOnly = actionView === ActionViewOptions.Icons;

        if (isTableHaveEditableItem) {
            width += (iconOnly ? 40 : actionsProperties.details.width) + actionsProperties.margin;
        }
        /* unblock button have more width than block button so in case where one of both can be rendered
           use button that have more width for calculation */
        if (isTableHaveBlockableItem) {
            width += (iconOnly ? 40 : actionsProperties.unblock.width) + actionsProperties.margin;
        }
        if (isTableHaveDeletableItem) {
            width += (iconOnly ? 40 : actionsProperties.delete.width) + actionsProperties.margin;
        }
        if (isTableHaveRecalculatableItem) {
            width += (iconOnly ? 40 : actionsProperties.recalculate.width) + actionsProperties.margin;
        }
        width -= actionsProperties.margin; // last elem haven't a margin

        return width;
    };

    renderColumn = ({field, label, headerClassName, width, sortField: _sortField}) => {
        const {sortField, sortOrder, onSort} = this.props;
        const columnSortField = _sortField || field;
        const sortable = _sortField !== false;
        const sorted = columnSortField == sortField; // eslint-disable-line eqeqeq
        const desc = sortOrder === 'desc';
        const sort = field && sortable
            ? () => onSort({sortField: columnSortField, sortOrder: invertSort(sorted, sortOrder)})
            : null;
        return (
            <th
                key={field}
                onClick={sort}
                width={width}
                className={cn(headerClassName, styles.header, {
                    [styles.headerSortable]: sortable,
                    [styles.headerSort]: sorted,
                    [styles.headerSortDesc]: sorted && desc,
                })}
            >
                {label}
            </th>
        );
    };

    render() {
        const {
            columns, canSelectAll, isTableHaveEditableItem,
            isTableHaveBlockableItem, isTableHaveDeletableItem,
        } = this.props;
        const haveToRenderActions = isTableHaveEditableItem
            || isTableHaveBlockableItem
            || isTableHaveDeletableItem;

        return (
            <thead className={styles.wrapper}>
                <tr>
                    {canSelectAll && <th/>}
                    {columns.map(this.renderColumn)}
                    {haveToRenderActions &&
                    <th
                        width={`${this.getActionsWidth()}px`}
                        className={cn(styles.actionsHeader)}
                    >
                        Actions
                    </th>
                    }
                </tr>
            </thead>
        );
    }
}
