import typeReader from '../helpers/type_reader';
import typeShape, {required, optional} from '../helpers/type_shape';

const ClientBenchmarkType = {
    id: optional(String),
    name: optional(String),
    ownerId: optional(String),
    ownerName: optional(String),
    isDefault: optional(Boolean),
    currency: optional(String),
    regionId: optional(String),
    assetClassId: optional(String),
    assetSubClassId: optional(String),
    benchmarks: [{
        id: required(String),
        name: required(String),
        weight: required(Number),
    }],
};

export const readClientBenchmark = typeReader(ClientBenchmarkType);
export const clientBenchmarkShape = typeShape(ClientBenchmarkType);

// please adjust the column width in ClientBenchmarksPage component when you change this model
export const benchmarkTypes = {
    custom: 'Custom',
    tradeable: 'Tradeable',
    marketIndex: 'Market index',
};
