import {AccountGroup, Level, StructureItem} from '../types';

/**
 * Level comparator that can be used for sorting. Guarantees the depth-first sorting.
 * 1/1/1 comes before 1/1/2, 1/2/1, 1/12
 * 1/1 comes before 1/1/1, 1/12
 *
 * @param a
 * @param b
 * @returns -1 if a < b, 0 if a === b, and 1 when a > b
 */
export const levelComparator = (a: Level, b: Level) => {
    const limit = Math.min(a.length, b.length);
    let res = 0;
    for (let i = 0; i < limit && res === 0; res = a[i] - b[i], i += 1);
    return res === 0 ? a.length - b.length : res;
};

/**
 * Comparator to sort arrays of objects by the name field.
 * @param a
 * @param b
 */
export const nameFieldComparator = (a: {name: string}, b: {name: string}) => (a.name || '').localeCompare(b.name || '');

/**
 * Comparator to sort groups by name, but also to make Cash Account group to be the first
 * @param a
 * @param b
 */
export const groupComparator = (a: AccountGroup, b: AccountGroup) => {
    if (a.isCashAccountGroup) {
        return b.isCashAccountGroup ? 0 : -1;
    } else if (b.isCashAccountGroup) {
        return 1;
    }
    return nameFieldComparator(a, b);
};

/**
 * Structure comparator for convenient usage in sort method.
 * @param a
 * @param b
 */
export const structureComparator = (a: StructureItem, b: StructureItem) => levelComparator(a.level, b.level);

/**
 * Sorts given structure array. Introduced for better code readability.
 * @param structure
 */
export const sortStructure = <T extends StructureItem[]>(structure: T): T => structure.sort(structureComparator);
