import React from 'react';
import cn from 'classnames';
import SelectField from 'react-md/lib/SelectFields/SelectField';
import {connect} from '../../../../store';
import {getVersions} from '../../../../store/structure-version';
import Version from '../../../../store/types/Version';

interface Props {
    className?: string,
    id: string,
    selectedVersionId?: Version['id'],
    versions: Version[],
    onChange: (versionId?: Version['id']) => void,
    disabled?: boolean,
}

const VersionSelector: React.FC<Props> = ({
    className,
    id,
    selectedVersionId,
    versions,
    onChange,
    disabled,
}) => (
    <div className={cn('prv-client-perfomance-version-selector', className)}>
        <SelectField
            className="prv-client-perfomance-version-selector__select-field"
            id={`${id}-select-field`}
            itemLabel="label"
            itemValue="id"
            label="Structure version"
            menuItems={[{id: '', label: ''}, ...versions]}
            onChange={id => onChange(id as string)}
            simplifiedMenu={false}
            value={selectedVersionId}
            disabled={disabled}
        />
    </div>
);

// FIXME: figure out how to type the connect function
// @ts-ignore
export default connect({versions: getVersions})(VersionSelector);
