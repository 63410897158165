import React from 'react';
import PropTypes from 'prop-types';
import ProgressIndicator from 'components/loading-spinner/loading-spinner';
import cn from 'classnames';
import styles from './basic-dialog.module.css';

const BasicDialog = ({className, message, onOk, onDelete, onBlock, onUnblock, onCancel, loading}) => (
    <div className={cn(styles.moire, className)}>
        {loading && <ProgressIndicator/>}
        <div className={styles.container}>
            <div className={styles.message}>{message}</div>
            <div className={styles.actions}>
                {onCancel &&
                <button className={styles.cancelButton} onClick={onCancel}>Cancel</button>}
                {onOk &&
                <button className={styles.okButton} onClick={onOk}>OK</button>}
                {onDelete &&
                <button className={styles.deleteButton} onClick={onDelete}>Delete</button>}
                {onBlock &&
                <button className={styles.blockButton} onClick={onBlock}>Block</button>}
                {onUnblock &&
                <button className={styles.blockButton} onClick={onUnblock}>Unblock</button>}
            </div>
        </div>
    </div>
);

export default BasicDialog;

BasicDialog.propTypes = {
    className: PropTypes.string,
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    onOk: PropTypes.func,
    onDelete: PropTypes.func,
    onBlock: PropTypes.func,
    onUnblock: PropTypes.func,
    onCancel: PropTypes.func,
    loading: PropTypes.bool,
};
