import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import {redirect, refresh} from '../../../store/navigate';
import ClientEditForm from '../client-edit-form-new';
import {CURRENCY_LIST} from '../transactions/transactions-types';
import {
    accountsProfitShape,
    clientBenchmarkShape,
    clientShape,
    editClient,
    sharedBenchmarkShape,
} from '../../../store/data/client';
import {connect} from '../../../store';
import {isSA} from '../../../store/auth';
import {
    getAccountsProfit,
    getActiveRMs,
    getAllocationClasses,
    getBenchmarksUpdateError,
    getClientBenchmarks,
    getLimits,
    getPinnedClient,
    getSharedBenchmarks,
    isBenchmarksLoading,
    isLimitsLoading,
    getClientReportingBenchmarksLoading,
    getClientReportingBenchmarks,
} from '../../../store/clients';
import {buildActions} from '../../../store/collections/actions_builder';
import {
    blockClient,
    deleteClient,
    fetchAccountsProfit,
    fetchActiveRMs,
    fetchAllocationClasses,
    fetchClientBenchmarks,
    fetchLimits,
    fetchSharedBenchmarks,
    fetchReportingBenchmarks,
    updateReportingBenchmarks,
    unblockClient,
    updateClient,
    updateClientBenchmarks,
    syncClientStart,
    syncClientCheck,
} from '../../../store/clients/actions';
import BenchmarkGroupList from '../../../components/benchmark/benchmark-group-list';
import FormTable from '../../../components/form-table';
import SetLimitsDialog from '../set-limits-dialog/set-limits-dialog';
import ActionsBar from './actions-bar';
import {limitShape} from '../../../store/data/general-data';
import {rmShape} from '../../../store/data/rm';
import createBenchmarkGroups from './create-benchmark-groups';
import ProfitInformation from './ProfitInformation';
import ValueChanges from './ValueChanges';
import AllocationClasses from './AllocationClasses';
import {getVersions} from '../../../store/structure-version';

const dateFormat = 'YYYY-MM-DD[T]00:00:00+00:00';

const {pinItemById} = buildActions('clients');
const CURRENCY_OPTIONS = CURRENCY_LIST.map(currency => ({value: currency, label: currency}));

const limitsColumns = [
    {
        field: 'name',
        label: 'Type',
        className: 'prv-client-details__limit-list-column',
    },
    {
        field: 'value',
        label: 'Value',
        className: 'prv-client-details__limit-list-column',
    },
];

export class InformationPage extends React.Component {
    static propTypes = {
        accountsProfit: accountsProfitShape,
        allocationClasses: PropTypes.array,
        benchmarksLoading: PropTypes.bool,
        client: clientShape,
        clientBenchmarks: PropTypes.arrayOf(clientBenchmarkShape),
        fetchAccountsProfit: PropTypes.func.isRequired,
        fetchAllocationClasses: PropTypes.func.isRequired,
        fetchClientBenchmarks: PropTypes.func.isRequired,
        fetchSharedBenchmarks: PropTypes.func.isRequired,
        limits: PropTypes.arrayOf(limitShape),
        limitsLoading: PropTypes.bool,
        isSA: PropTypes.bool,
        rms: PropTypes.arrayOf(rmShape),
        sharedBenchmarks: PropTypes.arrayOf(sharedBenchmarkShape),
        updateClientBenchmarks: PropTypes.func,

        redirect: PropTypes.func.isRequired,
        refresh: PropTypes.func.isRequired,
        updateClient: PropTypes.func.isRequired,
        deleteClient: PropTypes.func.isRequired,
        blockClient: PropTypes.func.isRequired,
        unblockClient: PropTypes.func.isRequired,
        syncClientStart: PropTypes.func.isRequired,
        syncClientCheck: PropTypes.func.isRequired,
        fetchActiveRMs: PropTypes.func.isRequired,
        fetchLimits: PropTypes.func.isRequired,
        pinItemById: PropTypes.func.isRequired,
        fetchReportingBenchmarks: PropTypes.func.isRequired,
        updateReportingBenchmarks: PropTypes.func.isRequired,
        clientReportingBenchmarksLoading: PropTypes.bool.isRequired,
        clientReportingBenchmarks: PropTypes.array,
        versions: PropTypes.array,
    };
    static defaultProps = {
        accountsProfit: {},
    };
    static getDerivedStateFromProps(props) {
        return {
            benchmarkGroups: createBenchmarkGroups(props.clientBenchmarks || []),
            reportingBenchmarksGroups: createBenchmarkGroups(props.clientReportingBenchmarks || []),
        };
    }
    constructor(props) {
        super(props);
        this.state = {
            benchmarkGroups: createBenchmarkGroups(this.props.clientBenchmarks),
            reportingBenchmarksGroups: createBenchmarkGroups(this.props.clientReportingBenchmarks),
            shouldSetLimit: false,
        };
    }

    componentDidMount() {
        const {client, versions} = this.props;
        this.props.fetchAllocationClasses(client.id);
        this.props.fetchClientBenchmarks(client.id);
        this.props.fetchSharedBenchmarks({perPage: 99999}); // fetch all
        this.props.fetchLimits(client.id);
        this.props.fetchReportingBenchmarks({customerId: client.id});
        if (this.props.isSA) this.props.fetchActiveRMs();

        const primary = versions.find(v => v.primary);
        this.props.fetchAccountsProfit({id: client.id, versionId: primary ? primary.id : null});
    }

    _handleBenchmarkGroupsUpdate =
        (reporting = false) => (benchmarkGroups) => {
            const clientId = this.props.client.id;
            const benchmarkList = [].concat(...benchmarkGroups.map(
                group => group.benchmarks.map(({id, weight}) => _.pickBy(
                    {id, weight, startDate: moment(group.date).format(dateFormat), deleted: group.deleted},
                    _.identity,
                )),
            ));

            const fn = reporting
                ? this.props.updateReportingBenchmarks
                : this.props.updateClientBenchmarks;

            fn({
                id: clientId,
                benchmarks: benchmarkList,
            })
                .then(() => {
                    if (reporting) {
                        this.props.fetchReportingBenchmarks({customerId: clientId});
                    } else {
                        this.props.fetchClientBenchmarks(clientId);
                    }
                });
        };
    _handleTotalBenchmarkGroupsUpdate = this._handleBenchmarkGroupsUpdate()
    _handleReportingBenchmarkGroupsUpdate = this._handleBenchmarkGroupsUpdate(true)
    _handleEditLimit = () => this.setState({shouldSetLimit: true});
    _handleLimitsUpdated = () => {
        this.setState({shouldSetLimit: false});
        this.props.fetchLimits(this.props.client.id);
    };
    _handleRestrictionsDialogClose = () => {
        this.setState({shouldSetLimit: false});
    };
    updateClient = async (client) => {
        await this.props.updateClient(client);
        await this.props.pinItemById({id: this.props.client.id});
    };
    renderCreationButton = (title, onClick) => (
        <button
            className="prv-client-details__edit-button"
            onClick={onClick}
        >
            <span className="prv-client-details__edit-button-label">{title}</span>
        </button>
    );

    render() {
        const {
            accountsProfit,
            allocationClasses,
            blockClient,
            client,
            deleteClient,
            isSA,
            limits,
            limitsLoading,
            pinItemById,
            redirect,
            refresh,
            rms,
            sharedBenchmarks,
            syncClientStart,
            syncClientCheck,
            unblockClient,
            updateClient,
            benchmarksLoading,
        } = this.props;
        const {benchmarkGroups, shouldSetLimit} = this.state;
        return (
            <div>
                <ActionsBar
                    client={client}
                    redirect={redirect}
                    refresh={refresh}
                    deleteClient={deleteClient}
                    blockClient={blockClient}
                    unblockClient={unblockClient}
                    pinItemById={pinItemById}
                    onSyncClientStart={syncClientStart}
                    onSyncClientCheck={syncClientCheck}
                />
                <div className="client-information-layout">
                    <div className="client-information-square client-information-form">
                        <ClientEditForm
                            managers={
                                isSA && rms
                                    ? rms.map(rm => ({value: rm.id, label: `${rm.firstName} ${rm.lastName}`}))
                                    : null
                            }
                            currencies={CURRENCY_OPTIONS}
                            initialValues={editClient(client)}
                            updateClient={updateClient}
                        />
                    </div>
                    <div className="client-information-graph">
                        <ProfitInformation accountsProfit={accountsProfit} client={client} />
                        <ValueChanges accountsProfit={accountsProfit} client={client} />
                        <AllocationClasses allocationClasses={allocationClasses} client={client} />
                    </div>
                </div>
                <BenchmarkGroupList
                    availableBenchmarks={sharedBenchmarks}
                    benchmarkGroups={benchmarkGroups}
                    className="prv-client-details__benchmark-group"
                    headerless={false}
                    loading={benchmarksLoading}
                    onUpdate={this._handleTotalBenchmarkGroupsUpdate}
                    title="Total Benchmark"
                />
                <BenchmarkGroupList
                    availableBenchmarks={this.props.sharedBenchmarks}
                    benchmarkGroups={this.state.reportingBenchmarksGroups}
                    className="prv-client-details__benchmark-group"
                    headerless={false}
                    loading={this.props.clientReportingBenchmarksLoading}
                    onUpdate={this._handleReportingBenchmarkGroupsUpdate}
                    addButtonTitle="Add Additional Benchmark"
                    title="Reporting Benchmarks"
                />
                <FormTable
                    actionButton={this.renderCreationButton('Edit Limits', this._handleEditLimit)}
                    title="Limits"
                    items={limits}
                    loading={limitsLoading}
                    searchable={false}
                    columns={limitsColumns}
                />
                {
                    shouldSetLimit &&
                    <SetLimitsDialog
                        limits={limits}
                        onUpdateSuccess={this._handleLimitsUpdated}
                        onClose={this._handleRestrictionsDialogClose}
                    />
                }
            </div>
        );
    }
}

export default connect({
    client: getPinnedClient,
    isSA,
    rms: getActiveRMs,
    accountsProfit: getAccountsProfit,
    versions: getVersions,
    clientBenchmarks: getClientBenchmarks,
    sharedBenchmarks: getSharedBenchmarks,
    benchmarksLoading: isBenchmarksLoading,
    benchmarksUpdateError: getBenchmarksUpdateError,
    limits: getLimits,
    limitsLoading: isLimitsLoading,
    allocationClasses: getAllocationClasses,
    clientReportingBenchmarksLoading: getClientReportingBenchmarksLoading,
    clientReportingBenchmarks: getClientReportingBenchmarks,
}, {
    pinItemById,
    updateClient,
    fetchActiveRMs,
    fetchAccountsProfit,
    fetchLimits,
    fetchClientBenchmarks,
    fetchSharedBenchmarks,
    updateClientBenchmarks,
    deleteClient,
    blockClient,
    unblockClient,
    refresh,
    redirect,
    fetchAllocationClasses,

    fetchReportingBenchmarks,
    updateReportingBenchmarks,
    syncClientStart,
    syncClientCheck,
})(InformationPage);
