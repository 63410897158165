import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './switch.module.css';

const Switch = ({items, renderItem, activeItem, className, itemClassName, onItemClick, idKey}) => (
    <div className={cn(styles.wrapper, className)}>
        {
            items.map(item => (
                <div
                    key={idKey ? item[idKey] : item}
                    className={cn(itemClassName, {[styles.active]: item === activeItem})}
                    onClick={() => onItemClick(item)}
                >
                    {renderItem ? renderItem(item) : item}
                </div>
            ))
        }
    </div>
);
export default Switch;

Switch.propTypes = {
    activeItem: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    className: PropTypes.string,
    idKey: PropTypes.string,
    itemClassName: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.any).isRequired,
    onItemClick: PropTypes.func.isRequired,
    renderItem: PropTypes.func,
};
