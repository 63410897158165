import invariant from 'invariant';
import moment from 'moment';
import {callApi, dispatch, fromStore} from '../helpers/sagas';
import {changeStartDate, fetchAssets, fetchStructure, saveStructure} from './actions';
import {getOriginalTree, getStartDate, getTree} from './index';
import {exportStructure, structureDiff} from './helpers/structure';
import {getPinnedClient} from '../clients';
import {createApiCallResultAction} from '../api/helpers';
import {showToast} from '../snackbar/actions';
import Client from '../types/Client';
import Version from '../types/Version';

export const structureFetched = createApiCallResultAction(fetchStructure);
export const assetsFetched = createApiCallResultAction(fetchAssets);
export const structureSaved = createApiCallResultAction(saveStructure);

function* structureFetcher({payload: versionId}: {payload: Version['id']}) {
    try {
        const client: Client = yield fromStore(getPinnedClient);
        invariant(!!client, 'Creating version with no pinned client');
        const result = yield callApi('fetchStructure', {customerId: client.id, versionId});
        yield dispatch(structureFetched(result));
    } catch (error) {
        yield dispatch(structureFetched(error));
    }
}

function* assetsFetcher({payload: {startDate, versionId}}: {payload: { startDate: Date, versionId: Version['id'] }}) {
    try {
        const client: Client = yield fromStore(getPinnedClient);
        invariant(!!client, 'Creating version with no pinned client');
        // convert to be UTC
        const date = moment.utc(startDate).subtract(startDate.getTimezoneOffset(), 'minutes').toDate();
        yield dispatch(changeStartDate(date));

        const result = yield callApi(
            'fetchStructureAssets',
            {customerId: client.id, versionId, date: date.toISOString()},
        );
        yield dispatch(assetsFetched(result));
    } catch (error) {
        yield dispatch(assetsFetched(error));
    }
}

interface ErrorPayload {
    errors: Array<{message: string}>
}

function* structureSaver({payload: versionId}: {payload: Version['id']}) {
    const client: Client = yield fromStore(getPinnedClient);
    invariant(!!client, 'Saving structure with no pinned client');
    const structure = exportStructure(structureDiff(yield fromStore(getOriginalTree), yield fromStore(getTree)));
    if (!structure.length) {
        yield dispatch(structureSaved(undefined));
    }
    const date = yield fromStore(getStartDate);

    try {
        const result = yield callApi(
            'saveStructure',
            {customerId: client.id, versionId, payload: {structure, date: date.toISOString()}},
        );
        yield dispatch(structureSaved(result));
    } catch (error) {
        let message = 'Unknown error, please try again later';
        const errorPayload = error as ErrorPayload;
        if (Array.isArray(errorPayload.errors) && errorPayload.errors.length) {
            message = 'Error: ' + (error as ErrorPayload).errors[0].message;
        }

        yield dispatch(structureSaved(undefined));
        yield dispatch(showToast({text: message, autohide: false, action: 'Dismiss'}));
    }
}

export default {
    [fetchStructure]: structureFetcher,
    [fetchAssets]: assetsFetcher,
    [saveStructure]: structureSaver,
};
