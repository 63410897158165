import React from 'react';
import {Button} from 'react-md';
import styles from './power-bi-report-dialog.module.css';

type Props = {
    onClose: () => void,
}

export const ReportSentErrorInfo: React.FC<Props> = ({onClose}) => (
    <div>
        <div className={styles.messageContainer}>
            <h2>Report generation failed</h2>
            <p>Please contant site support.</p>
        </div>
        <div className={styles.buttonsContainer}>
            <Button flat primary onClick={onClose}>Close</Button>
        </div>
    </div>
);
