import React, {Component} from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {money} from 'helpers/formatters';
import {formatUtcDateTimeAsIs} from 'helpers/dates';

import {connect} from 'store';
import {collectionShape} from 'store/collections';
import {isLoading as isAccountsLoading, getAccounts, getPortfolios} from 'store/accounts';
import {getPinnedClient} from 'store/clients';
import {getCollectionState} from 'store/assets';
import {fetchAssets} from 'store/assets/actions';
import {
    getCollectionState as getTransactionsCollection,
    getUpdateTransactionError,
    isUpdateTransactionLoading,
    getAddTransactionError,
    isAddTransactionLoading,
} from 'store/client-transactions';
import {
    addTransaction,
    updateTransaction,
    clearUpdateTransactionError,
    clearAddTransactionError,
    deleteTransaction,
} from 'store/client-transactions/actions';
import {fetchAccounts, fetchCustomerPortfolios} from 'store/accounts/actions';
import {accountShape, clientShape, portfolioShape} from 'store/data/client';

import TransactionEditDialog from 'scenes/clients/transactions/transaction-edit-dialog';
import CollectionListPage from 'components/collections/list-page';

import transactionsTypes from './transactions-types';
import CheckboxMenuControl from '../../../components/filter-menu/CheckboxMenuControl';

const yes = () => true;

const renderType = type => (
    <span
        className={cn('prv-client-transaction__transaction-type', {
            'prv-client-transaction__transaction-type--buy': type === 'buy',
            'prv-client-transaction__transaction-type--other': type !== 'buy',
        })}
    >
        {_.startCase(type)}
    </span>
);

const INITIAL_TRANSACTION_TYPE_OPTIONS = transactionsTypes.map((cur) => (
    {label: _.startCase(cur), value: cur, checked: true}));

const getTransactionTypesText = (items) => {
    const checkedItems = items.filter(({checked}) => checked);
    if (checkedItems.length === 0) {
        return 'None';
    }
    if (checkedItems.length === items.length) {
        return 'All';
    }
    if (checkedItems.length === items.length - 1) {
        const unchecked = items.find(({checked}) => !checked);
        return `All except ${unchecked.label}`;
    }

    return checkedItems.map(({label}) => label).join(', ');
};

const canApplyTransactionTypes = (items) => items.some(({checked}) => checked);

const renderTime = time => formatUtcDateTimeAsIs(time);
const renderDates = (item, row) => <span>{renderTime(row.tradeDate)}/<br/>{renderTime(row.settlementDate)}</span>;

const columns = [
    {field: 'accountName', label: 'Account', width: '120px'},
    {field: 'assetName', label: 'Asset', width: '100px'},
    {field: 'type', label: 'Type', width: '110px', render: renderType},
    {field: 'tradeDate', label: 'Trade/Settlement Time', width: '160px', render: renderDates},
    {field: 'originalValue', label: 'Value', width: '80px', render: money},
    {field: 'currency', label: 'Currency', width: '60px'},
    {field: 'originalId', label: 'Original ID', width: '140px'},
    {field: 'message', label: 'Message', width: '140px'},
];

const EMPTY_TRANSACTION = Object.freeze({
    accountId: null,
    assetId: '00000000-0000-0000-0000-000000000000',
    currency: null,
    custodyAccountId: null,
    message: null,
    price: null,
    settlementDate: null,
    tradeDate: null,
    type: 'unknown',
    originalValue: null,
});

class TransactionsPage extends Component {
    static propTypes = {
        client: clientShape,
        transactionsCollection: collectionShape,
        updateTransactionLoading: PropTypes.bool.isRequired,
        updateTransactionError: PropTypes.shape({
            message: PropTypes.string,
        }),
        addTransactionLoading: PropTypes.bool.isRequired,
        addTransactionError: PropTypes.shape({
            message: PropTypes.string,
        }),
        accounts: PropTypes.arrayOf(accountShape),
        assets: collectionShape,
        portfolios: PropTypes.arrayOf(portfolioShape),
        accountsLoading: PropTypes.bool.isRequired,
        fetchAccounts: PropTypes.func.isRequired,
        fetchAssets: PropTypes.func.isRequired,
        fetchCustomerPortfolios: PropTypes.func.isRequired,
        addTransaction: PropTypes.func.isRequired,
        updateTransaction: PropTypes.func.isRequired,
        deleteTransaction: PropTypes.func.isRequired,
        clearAddTransactionError: PropTypes.func.isRequired,
        clearUpdateTransactionError: PropTypes.func.isRequired,

    };

    state = {
        editableTransaction: null,
        showAddTransactionDialog: false,
    };

    componentDidMount() {
        const {id} = this.props.client;
        this.props.fetchAccounts({id});
        this.props.fetchAssets({perPage: 999999});
        this.props.fetchCustomerPortfolios({id});
    }

    onRefCollectionList = (collectionList) => {
        this.collectionList = collectionList;
    };

    onEdit = transaction => this.setState({editableTransaction: transaction});

    onDelete = ({id}) => this.props.deleteTransaction({clientId: this.props.client.id, transactionId: id});

    onAddTransactionClicked = () => this.setState({showAddTransactionDialog: true});

    onTransactionEditClose = () => this.setState({editableTransaction: null});

    onTransactionAddClose = () => this.setState({showAddTransactionDialog: false});

    onUpdateTransaction = ({
        transactionType,
        custodyAccountId,
        currency,
        accountId,
        assetId,
        originalValue,
        price,
        message,
        tradeDate,
        settlementDate,
    }) => {
        const {client} = this.props;
        const {editableTransaction} = this.state;

        this.props.updateTransaction({
            clientId: client.id,
            transactionId: editableTransaction.id,
            type: transactionType,
            custodyAccountId,
            currency,
            accountId,
            assetId,
            originalValue,
            price,
            message,
            tradeDate,
            settlementDate,
        })
            .then(() => {
                this.onTransactionEditClose();
                this.collectionList.onRefresh();
            });
    };

    onAddTransaction = ({
        transactionType,
        custodyAccountId,
        currency,
        accountId,
        assetId,
        originalValue,
        price,
        message,
        tradeDate,
        settlementDate,
    }) => {
        const {client} = this.props;

        this.props.addTransaction({
            clientId: client.id,
            type: transactionType,
            custodyAccountId,
            currency,
            accountId,
            assetId,
            originalValue,
            price,
            message,
            tradeDate,
            settlementDate,
        })
            .then(() => {
                this.onTransactionAddClose();
                this.collectionList.onRefresh();
            });
    };

    getFiltersConfig = () => {
        const {accounts, portfolios} = this.props;
        const accountsOptions = accounts.reduce((result, item) => Object.assign(result, {[item.id]: item.name}), {});
        const portfolioOptions = portfolios.reduce((result, item) => Object.assign(result, {[item.id]: item.name}), {});

        return ({
            accountId: {
                title: 'Account',
                options: accountsOptions,
            },
            assetType: {
                title: 'Asset',
                options: {
                    equity: 'Equities',
                    bonds: 'Bonds',
                    cash: 'Cash',
                    alternative: 'Alternatives',
                },
            },
            portfolioId: {
                title: 'Portfolio',
                options: portfolioOptions,
            },
            type: {
                render: ({value: values, onChange}) => {
                    let items = INITIAL_TRANSACTION_TYPE_OPTIONS;
                    if (values && values.length) {
                        items = items.map(item => ({...item, checked: values.includes(item.value)}));
                    }

                    const onFilterMenuChange = (items) => {
                        let newValues = items.filter(({checked}) => checked).map(({value}) => value);
                        if (newValues.length === items.length) {
                            newValues = undefined;
                        }
                        onChange(newValues);
                    };

                    return (
                        <CheckboxMenuControl
                            key="transactionType"
                            canApply={canApplyTransactionTypes}
                            fieldClassName="prv-subheader-filters__filter"
                            getText={getTransactionTypesText}
                            id="transactionType"
                            items={items}
                            label="Type"
                            onChange={onFilterMenuChange}
                        />
                    );
                },
            },
            dateInterval: {
                title: 'Date',
                options: {
                    dtD: 'Daily',
                    mtD: 'Month to Date',
                    ytD: 'Year to Date',
                },
            },
        });
    };

    renderCollection = () => {
        const {transactionsCollection} = this.props;

        return (
            <CollectionListPage
                className="prv-client-transaction__collection"
                ref={this.onRefCollectionList}
                name="clientTransactions"
                collection={transactionsCollection}
                collectionTitle="Transactions"
                columns={columns}
                filters={this.getFiltersConfig()}
                canEdit={yes}
                canDelete={yes}
                onEdit={this.onEdit}
                onDelete={this.onDelete}
                createItemButtonTitle="Add Transaction"
                onCreateItem={this.onAddTransactionClicked}
                perPageOptions={[10, 25, 50, 100, 1000, {label: 'All', value: 1000000000}]}
            />
        );
    };

    renderEditDialog = () => {
        const {
            accounts, accountsLoading, assets, updateTransactionLoading, updateTransactionError, client,
        } = this.props;
        const {editableTransaction} = this.state;

        if (!editableTransaction) return null;

        return (
            <TransactionEditDialog
                accounts={accounts}
                assets={assets.items || []}
                client={client}
                error={updateTransactionError}
                fetchAccounts={this.props.fetchAccounts}
                fetchAssets={this.props.fetchAssets}
                loading={updateTransactionLoading || accountsLoading}
                onClearError={this.props.clearUpdateTransactionError}
                onClose={this.onTransactionEditClose}
                onSetTransaction={this.onUpdateTransaction}
                transaction={editableTransaction}
            />
        );
    };

    renderAddTransactionDialog = () => {
        const {accounts, accountsLoading, assets, addTransactionLoading, addTransactionError, client} = this.props;

        if (!this.state.showAddTransactionDialog) return null;

        return (
            <TransactionEditDialog
                accounts={accounts}
                actionButtonTitle="Add"
                assets={assets.items || []}
                client={client}
                error={addTransactionError}
                fetchAccounts={this.props.fetchAccounts}
                fetchAssets={this.props.fetchAssets}
                loading={addTransactionLoading || accountsLoading}
                onClearError={this.props.clearAddTransactionError}
                onClose={this.onTransactionAddClose}
                onSetTransaction={this.onAddTransaction}
                title="Add Transaction"
                transaction={EMPTY_TRANSACTION}
            />
        );
    };

    render() {
        return (
            <div>
                {this.renderCollection()}
                {this.renderEditDialog()}
                {this.renderAddTransactionDialog()}
            </div>
        );
    }
}

export default connect(
    {
        transactionsCollection: getTransactionsCollection,
        accounts: getAccounts,
        assets: getCollectionState,
        portfolios: getPortfolios,
        accountsLoading: isAccountsLoading,
        client: getPinnedClient,
        updateTransactionLoading: isUpdateTransactionLoading,
        updateTransactionError: getUpdateTransactionError,
        addTransactionLoading: isAddTransactionLoading,
        addTransactionError: getAddTransactionError,
    },
    {
        fetchAccounts,
        fetchCustomerPortfolios,
        fetchAssets,
        deleteTransaction,
        addTransaction,
        updateTransaction,
        clearUpdateTransactionError,
        clearAddTransactionError,
    },
)(TransactionsPage);
