const BankAuthenticationStatus = {
    Ok: 'Ok',
    ConfirmationCodeRequired: 'ConfirmationCodeRequired',
    AgreementRequired: 'AgreementRequired',
    CredentialsInvalid: 'CredentialsInvalid',
    PhoneNumberRequired: 'PhoneNumberRequired',
    NemIdApprovalRequired: 'NemIdApprovalRequired',
    MitIdApprovalRequired: 'MitIdApprovalRequired',
} as const;

export const BankAuthenticationSubStatus = {
    PersonalCodeRequired: 'PersonalCodeRequired',
} as const;

export default BankAuthenticationStatus;
