import _ from 'lodash';

import {handleActions, apiActionHandler} from 'store/helpers/actions';
import {readAccount, readBank, readBankAccount, readPortfolio} from 'store/data/client';

import {
    fetchAccounts,
    fetchAssetsManagers,
    fetchBanks,
    fetchBankAccounts,
    fetchCustomerPortfolios,
    clearAddAccountError,
    importAccounts,
    updateAccount,
    clearError,
    clearUpdateAccountError,
    resetState,
} from './actions';

const initialState = {
    accounts: [],
    assetsManagers: [],
    error: null,
    addAccountError: null,
    accountsLoading: false,
    assetsManagersLoading: false,
    addAccountLoading: false,
    accountUpdating: false,
    accountUpdateError: null,
    banks: null,
    bankAccounts: null,
    portfolios: [],
    portfoliosLoading: false,
};

export const getAccounts = ({accounts}) => accounts;
export const getCustodyAccounts = ({accounts}) => accounts.filter(a => a.type === 'deposit');
export const getCashAccounts = ({accounts}) => accounts.filter(a => a.type === 'cash');

export const getAssetsManagers = ({assetsManagers}) => assetsManagers;
export const getPortfolios = ({portfolios}) => portfolios;

export const isLoading = ({accountsLoading: l1, assetsManagersLoading: l2, portfoliosLoading: l3}) => l1 || l2 || l3;

export const getError = ({error}) => error;

export const getBanks = ({banks}) => banks;
export const isAddAccountLoading = ({addAccountLoading}) => addAccountLoading;
export const getAddAccountError = ({addAccountError}) => addAccountError;
export const getBankAccounts = ({bankAccounts}) => bankAccounts;

export const isAccountUpdating = ({accountUpdating}) => accountUpdating;
export const getAccountUpdateError = ({accountUpdateError}) => accountUpdateError;

export default handleActions(
    {
        [fetchAccounts]: (state, action) => apiActionHandler(
            state,
            action,
            {loading: 'accountsLoading', result: 'accounts', reader: p => p.items.map(readAccount)},
        ),

        [fetchAssetsManagers]: (state, action) => apiActionHandler(
            state,
            action,
            {loading: 'assetsManagersLoading', result: 'assetsManagers', reader: p => p.items.map(readAccount)},
        ),

        [fetchCustomerPortfolios]: (state, action) => apiActionHandler(
            state,
            action,
            {loading: 'portfoliosLoading', result: 'portfolios', reader: p => p.items.map(readPortfolio)},
        ),

        [fetchBanks]: (state, action) => apiActionHandler(
            state,
            action,
            {
                loading: 'addAccountLoading', error: 'addAccountError',
                result: 'banks',
                reader: banks => _.map(banks, readBank),
            },
        ),

        [fetchBankAccounts]: (state, action) => apiActionHandler(
            state,
            action,
            {
                loading: 'addAccountLoading', error: 'addAccountError',
                result: 'bankAccounts',
                reader: accounts => _.map(accounts, readBankAccount),
            },
        ),

        [importAccounts]: (state, action) => apiActionHandler(
            state,
            action,
            {loading: 'addAccountLoading', error: 'addAccountError'},
        ),

        [updateAccount]: (state, action) => apiActionHandler(
            state,
            action,
            {loading: 'accountUpdating', error: 'accountUpdateError'},
        ),

        [clearAddAccountError]: state => ({...state, addAccountError: null}),
        [clearError]: state => ({...state, error: null}),
        [clearUpdateAccountError]: state => ({...state, accountUpdateError: null}),
        [resetState]: () => initialState,
    },
    initialState,
);
