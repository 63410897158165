import React from 'react';
import PropTypes from 'prop-types';
import FullPage from './full-page';

const InfoMessage = ({children}) => (
    <FullPage className="prv-info-message">
        <p className="prv-info-message__body">{children}</p>
    </FullPage>
);
export default InfoMessage;

InfoMessage.propTypes = {
    children: PropTypes.node,
};
