import React from 'react';
import PropTypes from 'prop-types';
import DialogContainer from 'react-md/lib/Dialogs/DialogContainer';
import styles from './message-box.module.css';

export default class MessageBox extends React.PureComponent {
    static propTypes = {
        title: PropTypes.string,
        text: PropTypes.any,
        visible: PropTypes.bool,
        onHide: PropTypes.func.isRequired,
    };

    static defaultProps = {
        title: '',
        visible: false,
    };

    render() {
        const {title, text, onHide, visible} = this.props;

        return (
            <DialogContainer
                actions={[{
                    id: 'message-box-ok',
                    children: 'Ok',
                    onClick: onHide,
                }]}
                dialogClassName={styles.container}
                id="message-box"
                modal
                onHide={onHide}
                renderNode={document.body}
                title={title}
                visible={visible}
            >
                {text}
            </DialogContainer>
        );
    }
}
