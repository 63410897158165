import moment from 'moment';
import {Action, ResultAction} from '../../helpers/types';
import {apiActionHandler} from '../../helpers/actions';
import {StructureState} from '../reducer';
import {sortStructure} from '../helpers/sort';
import {getRootItem, parseStructure} from '../helpers/structure';
import {StructureArray, StructureItem} from '../types';

interface SavePayload {
    date: string,
    structure: StructureItem<string>[],
}

const saveStructureReducer = (
    state: StructureState,
    action: Action<any> | ResultAction<SavePayload | Error>,
) => apiActionHandler(
    state,
    action,
    {
        resultHandler: (payload: SavePayload, resultField: string, state: StructureState) => {
            if (payload === undefined) {
                // probably there was nothing to save, just returning
                return {...state, loading: false, error: undefined};
            }

            let startDate = moment.utc(payload.date);
            if (!startDate.isValid()) {
                startDate = moment.utc().startOf('day');
            }
            const tree = sortStructure(parseStructure(payload.structure));
            const rootItem = getRootItem(tree as StructureArray);
            if (!rootItem) {
                const message = 'Invalid structure returned: no root node';
                console.error(message, tree); // eslint-disable-line no-console
                return {...state, loading: false, error: new Error(message)};
            }
            const expandedPortfolioIds = new Set(state.expandedPortfolioIds);
            expandedPortfolioIds.add(rootItem.id); // to guarantee root node is still expanded

            return {
                ...state,
                error: undefined,
                expandedPortfolioIds,
                loading: false,
                originalStartDate: startDate.toDate(),
                originalTree: [...tree],
                selectedPortfolio: undefined,
                startDate: startDate.toDate(),
                tree,
            };
        },
    },
);

export default saveStructureReducer;
