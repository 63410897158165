import React from 'react';
import PropTypes from 'prop-types';
import ListItemControl from 'react-md/lib/Lists/ListItemControl';
import Checkbox from 'react-md/lib/SelectionControls/Checkbox';

export default class CheckboxMenuControlItem extends React.PureComponent {
    static propTypes = {
        checked: PropTypes.bool,
        id: PropTypes.string.isRequired,
        label: PropTypes.string,
        onClick: PropTypes.func,
        value: PropTypes.string.isRequired,
    };

    _handleItemClick = () => {
        const {checked, onClick, value} = this.props;
        onClick && onClick(value, !checked);
    };

    render() {
        const {checked, id, label, value} = this.props;
        return (
            <ListItemControl
                primaryAction={(
                    <Checkbox
                        checked={checked}
                        id={`${id}-item-${value}`}
                        label={label || value}
                        name={`${id}-item-${value}`}
                        onChange={this._handleItemClick}
                    />
                )}
            />
        );
    }
}
