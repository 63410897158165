import invariant from 'invariant';
import sortBy from 'lodash/sortBy';
import {callApi, dispatch, fromStore} from '../helpers/sagas';
import {createVersion, deleteVersion, fetchVersions, selectVersion, renameVersion, makePrimary} from './actions';
import {getPinnedClient} from '../clients';
import {createApiCallResultAction} from '../api/helpers';
import {showToast} from '../snackbar/actions';
import Version from '../types/Version';
import Client from '../types/Client';
import ApiError from '../api/error';
import {getSelectedVersion} from './index';

export const versionsFetched = createApiCallResultAction(fetchVersions);
export const versionCreated = createApiCallResultAction(createVersion);
export const versionRenamed = createApiCallResultAction(renameVersion);
export const versionMadePrimary = createApiCallResultAction(makePrimary);
export const versionDeleted = createApiCallResultAction(deleteVersion);

const getErrorMessage = (
    error: ApiError,
    defaultMessage: string = 'Unexpected error happened, please try again later',
) => {
    let message = defaultMessage;
    if (Array.isArray(error.errors) && error.errors.length) {
        message = 'Error: ' + error.errors[0].message;
    }
    return message;
};

function* onFetchVersions() {
    try {
        const client: Client = yield fromStore(getPinnedClient);
        invariant(!!client, 'Fetching versions with no pinned client');
        let versions: Version[] = yield callApi('fetchStructureVersions', {customerId: client.id});
        versions = sortBy(versions, 'name');
        yield dispatch(versionsFetched(versions));
        const selectedVersion: Version | undefined = yield fromStore(getSelectedVersion);
        if (!selectedVersion && versions.length) {
            yield dispatch(selectVersion(versions.find(v => v.primary) || versions[0]));
        }
    } catch (error) {
        yield dispatch(versionsFetched(error));
    }
}

function* onCreateVersion(
    {payload: {name, templateVersionId}}: {payload: {name: string, templateVersionId?: Version['id']}},
) {
    const client = yield fromStore(getPinnedClient);
    invariant(!!client, 'Creating version with no pinned client');

    try {
        const version = yield callApi('createStructureVersion', {
            customerId: client.id,
            name,
            templateVersionId,
        });
        yield dispatch(versionCreated(version));
        yield dispatch(fetchVersions({customerId: client.id}));
        yield dispatch(selectVersion(version));
    } catch (error) {
        yield dispatch(versionCreated(undefined));
        const text = getErrorMessage(error, 'Failed to create version, please try again later');
        yield dispatch(showToast({text, autohide: false, action: 'Dismiss'}));
    }
}

function* onRenameVersion({payload: {versionId, name}}: {payload: {versionId: Version['id'], name: string}}) {
    const client = yield fromStore(getPinnedClient);
    invariant(!!client, 'Renaming version with no pinned client');

    try {
        const version = yield callApi('updateStructureVersion', {customerId: client.id, versionId, name});
        yield dispatch(versionRenamed(version));
        yield dispatch(fetchVersions({customerId: client.id}));
    } catch (error) {
        yield dispatch(versionRenamed(undefined));
        const text = getErrorMessage(error, 'Failed to rename version, please try again later');
        yield dispatch(showToast({text, autohide: false, action: 'Dismiss'}));
    }
}

function* onMakePrimaryVersion({payload: {versionId}}: {payload: {versionId: Version['id']}}) {
    const client = yield fromStore(getPinnedClient);
    invariant(!!client, 'Making primary version with no pinned client');

    try {
        const version = yield callApi('updateStructureVersion', {customerId: client.id, versionId, primary: true});
        yield dispatch(versionMadePrimary(version));
        yield dispatch(fetchVersions({customerId: client.id}));
    } catch (error) {
        yield dispatch(versionMadePrimary(undefined));
        const text = getErrorMessage(error, 'Failed to make primary version, please try again later');
        yield dispatch(showToast({text, autohide: false, action: 'Dismiss'}));
    }
}

function* onDeleteVersion({payload: {versionId}}: {payload: {versionId: Version['id']}}) {
    const client = yield fromStore(getPinnedClient);
    invariant(!!client, 'Updating version with no pinned client');

    try {
        yield callApi('deleteStructureVersion', {customerId: client.id, versionId});
        yield dispatch(selectVersion(undefined));
        yield dispatch(versionDeleted(undefined));
        yield dispatch(fetchVersions({customerId: client.id}));
    } catch (error) {
        yield dispatch(versionDeleted(undefined));
        const text = getErrorMessage(error, 'Failed to delete version, please try again later');
        yield dispatch(showToast({text, autohide: false, action: 'Dismiss'}));
    }
}

export default {
    [fetchVersions]: onFetchVersions,
    [createVersion]: onCreateVersion,
    [renameVersion]: onRenameVersion,
    [deleteVersion]: onDeleteVersion,
    [makePrimary]: onMakePrimaryVersion,
};
