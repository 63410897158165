import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import * as pathnames from 'config/pathnames';

import {connect} from 'store';
import {navigate, redirect, refresh} from 'store/navigate';

import {updateRM, fetchClients, blockRM, unblockRM, deleteRM, clearError} from 'store/rms/actions';
import {
    getPinnedRM,
    getFormItems,
    getFormItemsLoading,
    getFormItemsError,
} from 'store/rms';

import {rmShape, editRM} from 'store/data/rm';

import buildActions from 'store/collections/actions_builder';

import Table from 'components/form-table';
import {RmDialog as Dialog} from 'components/dialogs';

import RMEditForm from './rm-edit-form';
import {rmClientsColumns as columns} from '../clients/clients-list-page';

const {pinItemById} = buildActions('rms');

class EditRMPage extends Component {
    static propTypes = {
        rm: rmShape,
        items: PropTypes.arrayOf(PropTypes.object),
        itemsLoading: PropTypes.bool,
        error: PropTypes.object,
        location: PropTypes.object,
        navigate: PropTypes.func.isRequired,
        redirect: PropTypes.func.isRequired,
        refresh: PropTypes.func.isRequired,
        updateRM: PropTypes.func.isRequired,
        fetchClients: PropTypes.func.isRequired,
        deleteRM: PropTypes.func.isRequired,
        blockRM: PropTypes.func.isRequired,
        unblockRM: PropTypes.func.isRequired,
        pinItemById: PropTypes.func.isRequired,
        clearError: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {filteredItemsCount: null};
    }

    componentWillMount() {
        this.props.fetchClients(this.props.rm.id);
    }

    componentWillUpdate(nextProps) {
        const {update} = nextProps.location.state || {};
        if (update) {
            this.props.pinItemById(nextProps.rm.id);
            this.props.refresh(nextProps.location, {});
        }
    }

    onEdit = data => this.props.updateRM({id: this.props.rm.id, ...data});
    onCancel = () => this.props.redirect(pathnames.RMS);
    onDelete = () => this.props.deleteRM({id: this.props.rm.id, assignTo: this.assignTo});

    onToggleBlock = () => (this.props.rm.status === 'blocked' ?
        this.props.unblockRM(this.props.rm.id) :
        this.props.blockRM({id: this.props.rm.id, assignTo: this.assignTo}));

    onSuccess = (a, b, {values: rm}) => this.props.redirect({
        pathname: pathnames.RMS,
        state: {updated: this.getRmName(rm)},
    });
    onSuccessDelete = () => this.props.redirect({
        pathname: pathnames.RMS,
        state: {deleted: this.getRmName(this.props.rm)},
    });

    onTableEdit = item => this.props.navigate(`${pathnames.CLIENTS}/${item.id}/edit`);

    onFilter = items => this.setState({filteredItemsCount: items && items.length});

    getRmName = rm => `${rm.firstName} ${rm.lastName}`;

    getMessage = msg => (
        <div>
            {msg}
            <br/> {this.getRmName(this.props.rm)}?
        </div>
    );
    getDeleteMessage = () => this.getMessage('Do you want to delete');
    getBlockMessage = () => this.getMessage(
        `Do you want to ${this.props.rm.status === 'blocked' ? 'un' : ''}block`,
    );

    renderDialog = (action, {onSubmit, onCancel, onSubmitSuccess, onSubmitFail}) => (
        <Dialog
            item={this.props.rm}
            onDelete={action === 'delete' ? onSubmit : null}
            onBlock={action === 'block' ? onSubmit : null}
            onCancel={onCancel}
            onSelectRM={(assignTo) => {
                this.assignTo = assignTo;
            }}
            onSubmitSuccess={onSubmitSuccess}
            onSubmitFail={onSubmitFail}
        />
    );

    renderTitle = () => (
        <div className="prv-rm-edit-page__form-title">
            <span>Clients</span>
            <div className="prv-rm-edit-page__separator"/>
            <span className="prv-rm-edit-page__rm-count">
                {
                    this.state.filteredItemsCount == null ?
                        this.props.items && this.props.items.length :
                        `Found ${this.state.filteredItemsCount}`
                }
            </span>
        </div>
    );

    renderTable = () => (
        <Table
            className="prv-rm-edit-page__form"
            title={this.renderTitle()}
            items={this.props.items || []}
            searchFields={['email', 'firstName', 'lastName']}
            columns={columns}
            canEdit={() => true}
            onEdit={this.onTableEdit}
            onFilter={this.onFilter}
            loading={this.props.itemsLoading}
            renderTableEmpty={this.renderTableEmpty}
            error={this.props.error}
            onClearError={this.props.clearError}
        />
    );

    renderTableEmpty = () => (
        <div className="prv-rm-edit-page__form-empty">
            The Relationship Manager has no clients
        </div>
    );

    render() {
        const {rm} = this.props;
        return (
            <RMEditForm
                form="edit-rm"
                initialValues={editRM(rm)}
                title={['RMs', this.getRmName(rm)]}
                actionTitle="Save Changes"
                onSubmit={this.onEdit}
                onCancel={this.onCancel}
                onSubmitSuccess={this.onSuccess}
                onToggleBlock={this.onToggleBlock}
                blocked={rm.status === 'blocked'}
                onDelete={this.onDelete}
                onSuccessDelete={this.onSuccessDelete}
                getDeleteMessage={this.getDeleteMessage}
                getBlockMessage={this.getBlockMessage}
                renderDeleteDialog={params => this.renderDialog('delete', params)}
                renderBlockDialog={params => this.renderDialog('block', params)}
                bottomView={this.renderTable()}
            />
        );
    }
}

export default withRouter(connect(
    {
        rm: getPinnedRM,
        items: getFormItems,
        itemsLoading: getFormItemsLoading,
        error: getFormItemsError,
    },
    {
        updateRM,
        fetchClients,
        deleteRM,
        blockRM,
        unblockRM,
        pinItemById,
        navigate,
        redirect,
        refresh,
        clearError,
    },
)(EditRMPage));
