import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import * as pathnames from 'config/pathnames';

import {connect} from 'store';
import {getProfile, isCustomer, isSA} from 'store/auth';
import {logout} from 'store/auth/actions';
import {profileShape} from 'store/data/profile';
import {Link} from 'react-router-dom';

class HeaderProfile extends Component {
    static propTypes = {
        className: PropTypes.string,
        profile: profileShape,
        isCustomer: PropTypes.bool.isRequired,
        isSA: PropTypes.bool.isRequired,
        logout: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {showDropdown: false};
    }

    onToggleDropdown = () => this.setState({showDropdown: !this.state.showDropdown});
    onBlur = (e) => {
        if (e.target.contains(e.relatedTarget)) return null;
        return this.setState({showDropdown: false});
    };
    _handleLogout = () => {
        this.props.logout();
    };

    render() {
        const {className, profile = {}, isCustomer, isSA} = this.props;
        const {showDropdown} = this.state;
        if (!profile) return null;
        const info = profile.info || {};
        const fakeName = isSA ? 'Superadmin' : 'unknown';

        return (
            <div
                className={cn('prv-header-profile', className)}
                tabIndex={-1}
                onClick={this.onToggleDropdown}
                onBlur={this.onBlur}
            >
                <div className="prv-header-profile__menu-button">
                    <div className="prv-header-profile__profile">
                        <span className="prv-header-profile__user-name">
                            {info.firstName || fakeName} {info.lastName}
                        </span>
                        <span className="prv-header-profile__user-role">
                            {profile.role.charAt(0).toUpperCase() + profile.role.slice(1)}
                        </span>
                    </div>
                    <div className="prv-header-profile__menu-arrow"/>
                </div>
                <div className="prv-header-profile__dropdown-menu">
                    <ul
                        className={cn('prv-header-profile__dropdown-menu-content', {
                            'prv-header-profile__dropdown-menu-content--visible': showDropdown,
                        })}
                    >
                        {(!isSA && !isCustomer) &&
                        <li
                            className={cn(
                                'prv-header-profile__dropdown-menu-item',
                                'prv-header-profile__dropdown-menu-item--profile',
                            )}
                        >
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <Link
                                className="prv-header-profile__profile-link"
                                to={pathnames.PROFILE}
                            >
                                My profile
                            </Link>
                        </li>}
                        <li
                            className={cn(
                                'prv-header-profile__dropdown-menu-item',
                                'prv-header-profile__dropdown-menu-item--logout',
                            )}
                            onClick={this._handleLogout}
                        >
                            Logout
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}

export default connect(
    {
        isCustomer,
        isSA,
        profile: getProfile,
    },
    {
        logout,
    },
)(HeaderProfile);
