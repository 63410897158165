import bind from '../helpers/bind';
import reducer, * as getters from './reducer';

const {binder, wrapAll} = bind(reducer);

export const {
    getCurrentStep,
    getTotalSteps,
    getSelectedBankId,
    getSelectedAccounts,
    getBoersenId,
    getCallbackUri,
    getOnboardingFlow,
    getLastAddedBank,
    getAdditionalStep,
    getOnboardingResult,
} = wrapAll(getters);

export default binder;
