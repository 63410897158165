import {actionDispatchers} from '../collections/saga_helpers';
import {getFetchParams} from './index';

export default {
    ...actionDispatchers('benchmarks', {
        fetch: {
            apiCall: 'fetchBenchmarksList',
            getFetchParams,
        },
    }),
};
