import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {bankShape} from 'store/data/client';

import Spinner from 'components/loading-spinner/loading-spinner';

import styles from './choose-bank.module.css';

export default class ChooseBank extends React.Component {
    static propTypes = {
        banks: PropTypes.arrayOf(bankShape),
        loading: PropTypes.bool,
        onSelectBank: PropTypes.func,
        selectedBankId: PropTypes.string,
    };

    constructor(props) {
        super(props);
        this.state = {
            search: null,
        };
    }

    onSearch = e => this.setState({search: e.target.value});

    onSelectBank = (bank) => {
        const {onSelectBank, selectedBankId} = this.props;
        onSelectBank && onSelectBank(selectedBankId === bank.id ? null : bank);
    };

    getFilteredBanks = () => {
        const {banks} = this.props;
        const {search} = this.state;

        if (!search || search.length === 0) return banks;

        return _.filter(banks, bank => bank.name.toLowerCase().includes(search.toLowerCase()));
    };

    render() {
        const {loading, selectedBankId} = this.props;

        const banks = this.getFilteredBanks();

        return (
            <div className={styles.choosingBankWrapper}>
                <div className={styles.searchWrapper}>
                    <input
                        disabled={loading}
                        type="text"
                        className={styles.search}
                        onChange={this.onSearch}
                        defaultValue=""
                        placeholder="Search"
                    />
                </div>
                <ul>
                    {loading && <Spinner className={styles.spinner}/>}
                    {!loading && _.map(banks, bank => (
                        <li
                            key={bank.id}
                            onClick={() => this.onSelectBank(bank)}
                            className={selectedBankId === bank.id ? styles.selected : null}
                        >
                            {bank.name}
                            {bank.description && <span className={styles.description}>{bank.description}</span>}
                        </li>
                    ))}
                </ul>
            </div>
        );
    }
}
