/* TODO: refactor - fix styles naming and add errors handler
and spinner(dialog should be opened while data is updating) */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {connect} from 'store';
import _ from 'lodash';
import moment from 'moment';
import Switch from 'react-md/lib/SelectionControls/Switch';
import DatePicker from '../../../components/date-picker';
import Moire from '../../../components/moire/moire';
import Select from '../../../components/select/select';
import ErrorPanel from '../../../components/panel/panel';
import Spinner from '../../../components/loading-spinner/loading-spinner';
import BenchmarkGroupList from '../../../components/benchmark/benchmark-group-list';

import {getLimits, getSharedBenchmarks} from '../../../store/general-data';
import {fetchSharedBenchmarks} from '../../../store/general-data/actions';

import {getCashAccounts} from '../../../store/accounts';
import {
    accountShape,
    assetsManagerShape,
    portfolioShape,
    clientBenchmarkShape,
    sharedBenchmarkShape,
} from '../../../store/data/client';
import {limitShape} from '../../../store/data/general-data';

import LimitsTypesDialog from './limits-types-dialog';
import EditableSideDialog from './editable-side-dialog';

const getAccountsAsOptions = accounts => accounts.map(a => ({value: a.id, label: a.name}));

const endDateFormat = 'YYYY-MM-DD[T]00:00:00+00:00';

class EditAccountDialog extends Component {
    static propTypes = {
        shouldBeRendered: PropTypes.shape({
            accountEndDate: PropTypes.bool,
            accountName: PropTypes.bool,
            accountType: PropTypes.bool,
            accountCurrency: PropTypes.bool,
            ams: PropTypes.bool,
            benchmarks: PropTypes.bool,
            reportingBenchmarks: PropTypes.bool,
            cashAccount: PropTypes.bool,
            invoices: PropTypes.bool,
            limits: PropTypes.bool,
            proveoId: PropTypes.bool,
            portfolio: PropTypes.bool,
        }),
        assetsManagers: PropTypes.arrayOf(assetsManagerShape),
        addCustomAssetManager: PropTypes.bool,
        addOwnAssetManagerItem: PropTypes.bool,
        portfolios: PropTypes.arrayOf(portfolioShape),
        account: PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
            type: PropTypes.string,
            currency: PropTypes.string,
            cashAccountId: PropTypes.string,
            proveoId: PropTypes.string,
            endDate: PropTypes.string,
        }),
        className: PropTypes.string,
        bankName: PropTypes.string,

        cashAccounts: PropTypes.arrayOf(accountShape),

        accountType: PropTypes.string,
        accountCurrency: PropTypes.string,
        selectedAMs: PropTypes.arrayOf(PropTypes.object),
        selectedCustomAM: PropTypes.string,
        selectedPortfolio: portfolioShape,
        selectedCustomPortfolio: PropTypes.string,
        limits: PropTypes.arrayOf(PropTypes.object),
        benchmarks: PropTypes.arrayOf(clientBenchmarkShape),
        reportingBenchmarks: PropTypes.arrayOf(clientBenchmarkShape),
        reportingLock: PropTypes.bool,
        invoicingAUMLock: PropTypes.bool,
        invoicingReturnLock: PropTypes.bool,
        invoicingFixedLock: PropTypes.bool,

        confirmationButtonTitle: PropTypes.string,

        onClose: PropTypes.func.isRequired,
        onSetAccount: PropTypes.func.isRequired,
        renderHeaderTitle: PropTypes.func,

        limitsList: PropTypes.arrayOf(limitShape),
        sharedBenchmarks: PropTypes.arrayOf(sharedBenchmarkShape),
        fetchSharedBenchmarks: PropTypes.func,

        error: PropTypes.shape({message: PropTypes.string}),
        loading: PropTypes.bool,
        onClearError: PropTypes.func,

        invoices: PropTypes.oneOfType([
            PropTypes.shape({
                invoicingAUM: PropTypes.bool,
                invoicingReturn: PropTypes.bool,
                invoicingFixed: PropTypes.bool,
                proveoScoreCalculated: PropTypes.bool,
                reporting: PropTypes.bool,
                createReport: PropTypes.bool,
            }).isRequired,
            PropTypes.oneOf([null, undefined]).isRequired,
        ]),
    };

    static defaultProps = {
        addCustomAssetManager: true,
        addOwnAssetManagerItem: true,
        shouldBeRendered: {
            accountName: true,
            accountType: true,
            accountEndDate: true,
            accountCurrency: false,
            cashAccount: true,
            ams: true,
            portfolio: true,
            proveoId: true,
            benchmarks: true,
            limits: true,
            invoices: false,
        },
    };

    constructor(props) {
        super(props);
        this.state = {
            accountName: props.account && props.account.name,
            accountType: props.accountType || (props.account && props.account.type),
            accountCurrency: props.accountCurrency || (props.account && props.account.currency),
            cashAccountId: props.account && props.account.cashAccountId,
            selectedAMs: props.selectedAMs || [],
            selectedCustomAM: props.selectedCustomAM,
            selectedPortfolio: props.selectedPortfolio || null,
            selectedCustomPortfolio: props.selectedCustomPortfolio || null,
            proveoId: props.account && props.account.proveoId,
            limits: (props.selectedAMs || props.shouldBeRendered.limits)
                ? props.limits
                : [this.getEmptyLimit()],
            error: null,
            limitsTypesDialogIndex: null,
            amDialog: false,
            benchmarkGroups: (props.selectedAMs || props.shouldBeRendered.benchmarks)
                ? this._createBenchmarkGroups(props.benchmarks)
                : [],
            reportingBenchmarkGroups: (props.selectedAMs || props.shouldBeRendered.reportingBenchmarks)
                ? this._createBenchmarkGroups(props.reportingBenchmarks)
                : [],
            endDate: props.account ? moment(props.account.endDate || null) : null,
            invoices: props.shouldBeRendered.invoices ? props.invoices : {},
        };
    }

    componentWillMount() {
        this.props.fetchSharedBenchmarks && this.props.fetchSharedBenchmarks({perPage: 99999}); // fetch all
    }

    componentWillReceiveProps(nextProps) {
        const {benchmarks, reportingBenchmarks, invoices} = nextProps;

        if (this.props.benchmarks !== benchmarks) {
            this.setState({benchmarkGroups: this._createBenchmarkGroups(benchmarks)});
        }
        if (this.props.reportingBenchmarks !== reportingBenchmarks) {
            this.setState({benchmarkGroups: this._createBenchmarkGroups(reportingBenchmarks)});
        }
        if (this.props.invoices !== invoices) {
            this.setState({invoices: nextProps.invoices});
        }
    }

    onChangeName = e => this.setState({accountName: e.target.value});

    onAddLimit = () => {
        this.setState({limits: [...this.state.limits, this.getEmptyLimit()]}, () => {
            this.setState({maxHeight: this.dialogWrapper.offsetHeight});
        });
    };

    onSelectAssetsManagers = (items) => {
        this.setState({selectedAMs: items, selectedCustomAM: null});
        this.closeAmDialog();
    };

    onSelectCustomAssetManager = value => {
        this.setState({selectedCustomAM: value, selectedAMs: []}, () => this.closeAmDialog());
    };

    onSelectPortfolio = (items) => {
        this.setState({selectedPortfolio: items.length ? items[0] : null, selectedCustomPortfolio: null});
        this.closePortfoliosDialog();
    };

    onSelectCustomPortfolio = value => {
        this.setState({selectedCustomPortfolio: value, selectedPortfolio: null});
        this.closePortfoliosDialog();
    };

    onChangeProveoId = e => this.setState({proveoId: e.target.value});

    onSelectLimitType = (type) => {
        const {limits, limitsTypesDialogIndex} = this.state;

        limits[limitsTypesDialogIndex].type = type[0].name;
        limits[limitsTypesDialogIndex].id = type[0].id;
        this.forceUpdate();
        this.closeLimitsTypesDialog();
    };

    onUpdateAccount = () => {
        const {
            accountName,
            cashAccountId,
            selectedAMs,
            selectedCustomAM,
            proveoId,
            benchmarkGroups,
            reportingBenchmarkGroups,
            limits,
            accountType,
            selectedPortfolio,
            selectedCustomPortfolio,
            invoices,
            endDate,
        } = this.state;
        this.clearError();
        if (this.validate()) {
            // eslint-disable-next-line prefer-spread
            const benchmarks = [].concat.apply([], benchmarkGroups.map(
                group => group.benchmarks.map(({id, weight}) => _.pickBy(
                    {id, weight, startDate: group.date.toISOString(), deleted: group.deleted},
                    _.identity,
                )),
            ));

            // eslint-disable-next-line prefer-spread
            const reportingBenchmarks = [].concat.apply([], reportingBenchmarkGroups.map(
                group => group.benchmarks.map(({id, weight}) => _.pickBy(
                    {id, weight, startDate: group.date.toISOString(), deleted: group.deleted},
                    _.identity,
                )),
            ));

            const data = {
                accountName,
                accountType,
                cashAccountId,
                selectedAMs,
                selectedCustomAM,
                selectedPortfolio,
                selectedCustomPortfolio,
                proveoId,
                benchmarks,
                reportingBenchmarks,
                limits,
                invoices,
            };
            const parsedEndDate = moment(endDate);
            if (parsedEndDate.isValid()) {
                data.endDate = parsedEndDate.format(endDateFormat);
            }

            this.props.onSetAccount(data);
        }
    };

    getEmptyLimit = () => ({
        id: null,
        type: null,
        value: null,
    });

    _createBenchmarkGroups = benchmarks => {
        const benchmarkGroups = _.groupBy(benchmarks, ({startDate}) => startDate);
        return Object.keys(benchmarkGroups)
            .map((date) => ({
                date: moment(date).toDate(),
                benchmarks: benchmarkGroups[date].map(({id, name, weight}) => ({id, name, weight})),
            }))
            .sort((groupA, groupB) => groupA.date - groupB.date);
    };

    _handleChangeEndDate = endDate => this.setState({endDate});

    _handleBenchmarkGroupsUpdate = (benchmarkGroups) => {
        this.setState({benchmarkGroups});
    };

    _handleReportingBenchmarkGroupsUpdate = (reportingBenchmarkGroups) => {
        this.setState({reportingBenchmarkGroups});
    };

    _handleInvoiceChange = (invoiceName, value) => {
        this.setState(state => (invoiceName === 'reporting' && !value ?
            ({invoices: {...state.invoices, reporting: false, createReport: false}}) :
            ({invoices: {...state.invoices, [invoiceName]: value}})));
    };

    showAmDialog = () => this.setState({
        amDialog: true, limitsTypesDialogIndex: null, portfoliosDialog: null,
    });
    closeAmDialog = () => this.setState({amDialog: false});

    showLimitsTypesDialog = index => this.setState({
        limitsTypesDialogIndex: index, amDialog: false, portfoliosDialog: null,
    });
    closeLimitsTypesDialog = () => this.setState({limitsTypesDialogIndex: null});

    showPortfoliosDialog = () => this.setState({
        amDialog: null, limitsTypesDialogIndex: null, portfoliosDialog: true,
    });
    closePortfoliosDialog = () => this.setState({portfoliosDialog: false});

    clearError = () => {
        this.setState({error: null});
    };

    validateName = () => {
        if (!this.props.shouldBeRendered.accountName) {
            return true;
        }

        const {accountName} = this.state;
        if (!accountName || !accountName.length) {
            this.setState({error: {message: 'Name is required'}});
            return false;
        }
        return true;
    };

    validateLimits = () => {
        const {limits} = this.state;

        if (!this.props.shouldBeRendered.limits || !limits || !limits.length) {
            return true;
        }

        const checkRepeats = {};

        const isValid = limits.reduce((prev, cur) => {
            const x = prev && cur.type && cur.value !== null && !checkRepeats[cur.id];
            checkRepeats[cur.id] = true;
            return x;
        }, true);

        if (!isValid) {
            this.setState({error: {message: 'invalid limits'}});
        }

        return isValid;
    };

    validateAM = () => {
        if (!this.props.shouldBeRendered.ams) {
            return true;
        }

        if (this.state.selectedAMs.length === 0 && !this.state.selectedCustomAM) {
            this.setState({error: {message: 'Choose Assets manager'}});
            return false;
        }
        return true;
    };

    validate = () => (
        this.validateName()
        && this.validateAM()
        && this.validateLimits()
    );

    renderHeader = () => (
        <div className="prv-edit-account-dialog__header">
            {this.props.renderHeaderTitle && this.props.renderHeaderTitle()}
            {
                !this.props.renderHeaderTitle &&
                <div>
                    <div className="prv-edit-account-dialog__header-title">
                        {this.props.account.name}: ID{this.props.account.id}
                    </div>
                    <div>{this.props.bankName}</div>
                </div>
            }
            <div className="prv-edit-account-dialog__close-icon" onClick={this.props.onClose}/>
        </div>
    );

    renderName = () => (
        <div className="prv-edit-account-dialog__simple-section">
            <div className="prv-edit-account-dialog__label">Name</div>
            <input
                className="prv-edit-account-dialog__name-input"
                onChange={this.onChangeName}
                type="text"
                value={this.state.accountName}
            />
        </div>
    );

    renderCurrency = () => (
        <div className="prv-edit-account-dialog__simple-section">
            <div className="prv-edit-account-dialog__label">Currency: {this.state.accountCurrency}</div>
        </div>
    );

    renderType = () => (
        <div className="prv-edit-account-dialog__simple-section">
            <div className="prv-edit-account-dialog__label">Type: {this.state.accountType}</div>
        </div>
    );

    renderEndDate = () => (
        <div
            className={cn(
                'prv-edit-account-dialog__simple-section',
                'prv-edit-account-dialog__datepicker',
                'prv-edit-account-dialog__datepicker--end-date',
            )}
        >
            <div className="prv-edit-account-dialog__label">Account end date</div>
            <DatePicker
                date={this.state.endDate}
                startDate={null}
                endDate={null}
                onChange={this._handleChangeEndDate}
                isClearable
            />
        </div>
    )

    renderSelectCashAccount = () => {
        const {cashAccounts} = this.props;
        const {accountType, cashAccountId} = this.state;

        if (accountType === 'cash') return null;

        return (
            <div className="prv-edit-account-dialog__simple-section">
                <div className="prv-edit-account-dialog__label">Cash account</div>
                <Select
                    placeholder="Select account"
                    onChange={v => this.setState({cashAccountId: v})}
                    value={cashAccountId}
                    options={getAccountsAsOptions(cashAccounts)}
                    clearable={false}
                />
            </div>
        );
    };

    renderSelectAssetManager = () => (
        <div className="prv-edit-account-dialog__simple-section">
            <div className="prv-edit-account-dialog__label">Asset Manager</div>
            <button className="prv-edit-account-dialog__select-button" onClick={this.showAmDialog}>
                {
                    this.state.selectedCustomAM
                    || (this.state.selectedAMs[0] && this.state.selectedAMs[0].name)
                    || 'Select Asset Manager'
                }
            </button>
        </div>
    );

    renderSelectPortfolio = () => (
        <div className="prv-edit-account-dialog__simple-section">
            <div className="prv-edit-account-dialog__datepicker-row">
                <div>
                    <div className="prv-edit-account-dialog__label">Portfolio</div>
                    <button className="prv-edit-account-dialog__select-button" onClick={this.showPortfoliosDialog}>
                        {
                            this.state.selectedCustomPortfolio
                            || (this.state.selectedPortfolio && this.state.selectedPortfolio.name)
                            || 'Select Portfolio'
                        }
                    </button>
                </div>
            </div>
        </div>
    );

    renderProveoId = () => (
        <div className="prv-edit-account-dialog__simple-section">
            <div className="prv-edit-account-dialog__label">Proveo ID</div>
            <input
                className="prv-edit-account-dialog__proveoid-input"
                onChange={this.onChangeProveoId}
                type="text"
                value={this.state.proveoId}
            />
        </div>
    );

    renderBenchmarks = () => {
        const {sharedBenchmarks} = this.props;
        const {benchmarkGroups} = this.state;

        return (
            <div>
                <div className="prv-edit-account-dialog__section-label">Benchmarks</div>
                <BenchmarkGroupList
                    addButtonTitle="Add Benchmarks"
                    availableBenchmarks={sharedBenchmarks}
                    benchmarkGroups={benchmarkGroups}
                    onUpdate={this._handleBenchmarkGroupsUpdate}
                />
            </div>
        );
    };

    renderReportingBenchmarks = () => {
        const {sharedBenchmarks} = this.props;
        const {reportingBenchmarkGroups} = this.state;

        return (
            <div>
                <div className="prv-edit-account-dialog__section-label">Investment Strategy</div>
                <BenchmarkGroupList
                    addButtonTitle="Add Strategy"
                    availableBenchmarks={sharedBenchmarks}
                    benchmarkGroups={reportingBenchmarkGroups}
                    onUpdate={this._handleReportingBenchmarkGroupsUpdate}
                />
            </div>
        );
    };

    renderInvoices = () => (this.state.invoices ?
        <div>
            <div className="prv-edit-account-dialog__section-label">Invoicing</div>
            <div className="prv-edit-account-dialog__section-row">
                <Switch
                    checked={this.state.invoices.invoicingAUM}
                    disabled={this.props.invoicingAUMLock}
                    id="invoicingAum"
                    label="AUM"
                    name="invoicingAum"
                    onChange={(value) => this._handleInvoiceChange('invoicingAUM', value)}
                />
            </div>
            <div className="prv-edit-account-dialog__section-row">
                <Switch
                    checked={this.state.invoices.invoicingReturn}
                    disabled={this.props.invoicingReturnLock}
                    id="invoicingReturn"
                    label="Return"
                    name="invoicingReturn"
                    onChange={(value) => this._handleInvoiceChange('invoicingReturn', value)}
                />
            </div>
            <div className="prv-edit-account-dialog__section-row">
                <Switch
                    checked={this.state.invoices.invoicingFixed}
                    disabled={this.props.invoicingFixedLock}
                    id="invoicingFixed"
                    label="Fixed"
                    name="invoicingFixed"
                    onChange={(value) => this._handleInvoiceChange('invoicingFixed', value)}
                />
            </div>
            <div className="prv-edit-account-dialog__section-label">Reporting</div>
            <div className="prv-edit-account-dialog__section-row">
                <Switch
                    checked={this.state.invoices.reporting}
                    disabled={this.props.reportingLock}
                    id="reporting"
                    label="Reporting"
                    name="reporting"
                    onChange={(value) => this._handleInvoiceChange('reporting', value)}
                />
            </div>
            <div className="prv-edit-account-dialog__section-row">
                <Switch
                    checked={this.state.invoices.proveoScoreCalculated}
                    id="proveoScoreCalculated"
                    label="Calculate proVeo score"
                    name="proveoScoreCalculated"
                    onChange={(value) => this._handleInvoiceChange('proveoScoreCalculated', value)}
                />
            </div>
            {this.state.invoices.reporting && (
                <div className="prv-edit-account-dialog__section-row">
                    <Switch
                        checked={this.state.invoices.createReport}
                        id="createReport"
                        label="Create individual report"
                        name="createReport"
                        onChange={(value) => this._handleInvoiceChange('createReport', value)}
                    />
                </div>
            )}
        </div> : null
    );

    renderLimits = () => (
        <div>
            <div className="prv-edit-account-dialog__section-label">Limits</div>
            <ul className="prv-edit-account-dialog__section-list">
                {this.state.limits.map((limit, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <li className="prv-edit-account-dialog__section-list-item" key={i}>
                        <button
                            className="prv-edit-account-dialog__select-button"
                            onClick={() => this.showLimitsTypesDialog(i)}
                        >
                            {limit.type || 'Select Type'}
                        </button>
                        <div className="prv-edit-account-dialog__section-list-item-value">
                            <div className="prv-edit-account-dialog__input-value-label">Value</div>
                            <input
                                className="prv-edit-account-dialog__input-value-field"
                                onChange={({currentTarget: {value}}) => {
                                    limit.value = value; // eslint-disable-line no-param-reassign
                                    this.forceUpdate();
                                }}
                                placeholder={0}
                                type="number"
                                value={limit.value || ''}
                            />
                        </div>
                        <div
                            className="prv-edit-account-dialog__delete-button"
                            onClick={() => {
                                this.state.limits.splice(i, 1); // FIXME: no state modifications w/o setState
                                this.forceUpdate(() => (
                                    this.setState({maxHeight: this.dialogWrapper.offsetHeight})
                                ));
                            }}
                        />
                    </li>
                ))}
                <li className="prv-edit-account-dialog__section-list-buttons">
                    <button
                        className="prv-edit-account-dialog__add-item-button"
                        onClick={this.onAddLimit}
                    >
                        Add limit
                    </button>
                </li>
            </ul>
        </div>
    );

    renderFooter = () => (
        <div className="prv-edit-account-dialog__footer">
            <button className="prv-edit-account-dialog__save-button" onClick={this.onUpdateAccount}>
                {this.props.confirmationButtonTitle || 'Update Account'}
            </button>
        </div>
    );

    render() {
        const {
            className,
            addCustomAssetManager,
            addOwnAssetManagerItem,
            assetsManagers,
            portfolios,
            limitsList,
            shouldBeRendered,
            loading,
            error: propsError,
        } = this.props;

        const {
            amDialog,
            selectedAMs,
            selectedCustomAM,
            error: stateError,
            maxHeight,
            limits,
            limitsTypesDialogIndex,
            selectedPortfolio,
            selectedCustomPortfolio,
            portfoliosDialog,
        } = this.state;

        return (
            <Moire
                className={className}
                onClose={this.props.onClose}
                onCancel={this.props.onClose}
            >
                <div className="prv-edit-account-dialog">
                    {loading && <Spinner/>}
                    <div
                        className="prv-edit-account-dialog__wrapper"
                        ref={(elem) => {
                            if (elem && !this.state.maxHeight) {
                                this.setState({maxHeight: elem.offsetHeight});
                                this.dialogWrapper = elem;
                            }
                        }}
                    >
                        {
                            stateError &&
                            <ErrorPanel
                                className="prv-edit-account-dialog__error-panel"
                                error={stateError}
                                onClear={this.clearError}
                            />
                        }
                        {
                            propsError &&
                            <ErrorPanel
                                className="prv-edit-account-dialog__error-panel"
                                error={propsError}
                                onClear={this.props.onClearError}
                            />
                        }
                        {this.renderHeader()}
                        <div className="prv-edit-account-dialog__content">
                            {shouldBeRendered.accountName && this.renderName()}
                            {shouldBeRendered.accountType && this.renderCurrency()}
                            {shouldBeRendered.accountType && this.renderType()}
                            {shouldBeRendered.accountEndDate && this.renderEndDate()}
                            {shouldBeRendered.portfolio && this.renderSelectPortfolio()}
                            {shouldBeRendered.cashAccount && this.renderSelectCashAccount()}
                            {shouldBeRendered.ams && this.renderSelectAssetManager()}
                            {shouldBeRendered.proveoId && this.renderProveoId()}
                            {shouldBeRendered.limits && this.renderLimits()}
                            {shouldBeRendered.benchmarks && this.renderBenchmarks()}
                            {shouldBeRendered.reportingBenchmarks && this.renderReportingBenchmarks()}
                            {shouldBeRendered.invoices && this.renderInvoices()}
                        </div>
                        {this.renderFooter()}
                    </div>
                    {
                        amDialog &&
                        <div style={{maxHeight}}>
                            <EditableSideDialog
                                addCustom={addCustomAssetManager}
                                addOwnAccountItem={addOwnAssetManagerItem}
                                className="prv-edit-account-dialog__side-dialog"
                                customItemName={selectedCustomAM}
                                dialogTitle="Asset Managers"
                                itemName="Asset Manager"
                                items={assetsManagers}
                                onAddCustom={this.onSelectCustomAssetManager}
                                onClose={this.closeAmDialog}
                                onConfirm={this.onSelectAssetsManagers}
                                selectedItems={selectedAMs || []}
                            />
                        </div>
                    }
                    {
                        portfoliosDialog &&
                        <div style={{maxHeight}}>
                            <EditableSideDialog
                                className="prv-edit-account-dialog__side-dialog"
                                customItemName={selectedCustomPortfolio}
                                dialogTitle="Portfolios"
                                itemName="Portfolio"
                                items={portfolios}
                                onAddCustom={this.onSelectCustomPortfolio}
                                onClose={this.closePortfoliosDialog}
                                onConfirm={this.onSelectPortfolio}
                                selectedItems={selectedPortfolio ? [selectedPortfolio] : []}
                            />
                        </div>
                    }
                    {
                        limitsTypesDialogIndex !== null &&
                        <div style={{maxHeight}}>
                            <LimitsTypesDialog
                                className="prv-edit-account-dialog__side-dialog"
                                items={limitsList}
                                selectedItems={[limits[limitsTypesDialogIndex]]}
                                onClose={this.closeLimitsTypesDialog}
                                onConfirm={this.onSelectLimitType}
                                disableOnBlur
                            />
                        </div>
                    }
                </div>
            </Moire>
        );
    }
}

export default connect(
    {
        cashAccounts: getCashAccounts,
        limitsList: getLimits,
        sharedBenchmarks: getSharedBenchmarks,
    },
    {
        fetchSharedBenchmarks,
    },
)(EditAccountDialog);
