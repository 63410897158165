import React from 'react';
import uuid from 'uuid';
import {connect} from 'store';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import {getCustomers} from 'store/new-notifications';
import {
    fetchCustomers,
    chooseCustomers,
} from 'store/new-notifications/actions';
import {
    SelectionControlGroup,
    FontIcon,
    Autocomplete,
} from 'react-md';
import classnames from 'classnames';
import translations from '../translate';

const TYPES = {
    SINGLE: 'single',
    MULTIPLE: 'multiple',
};

export class SelectNotificationType extends React.Component {
    static propTypes = {
        fetchCustomers: PropTypes.func.isRequired,
        customers: PropTypes.array,
        back: PropTypes.func.isRequired,
        next: PropTypes.func.isRequired,
        onChange: PropTypes.func.isRequired,
        notification: PropTypes.object.isRequired,
    }

    static defaultProps = {
        customers: [],
    }

    constructor(props) {
        super(props);

        this.ids = [
            uuid.v4(),
            uuid.v4(),
        ];
    }

    state = {
        amount: TYPES.SINGLE,
        autocomplete: '',
    }

    componentDidMount() {
        this.props.fetchCustomers({
            page: 0,
            perPage: 99999,
            userScope: this.props.notification.userType /* 'atlazonboarded' */,
        });
    }

    onSuccess = () => {
        // this.props.chooseCustomers(this.state.user);
        this.props.next();
    };

    // eslint-disable-next-line no-confusing-arrow
    getCustomerName = customer => customer
        ? {primaryText: `${customer.firstName} ${customer.lastName}`, key: customer.id}
        : '';

    selectCount = async (value, ...rest) => {
        await this.setState({
            amount: value,
            autocomplete: '',
        });
        await this.props.fetchCustomers({
            page: 0,
            perPage: 99999,
            userScope: this.props.notification.userType,
        });
        if (value === TYPES.MULTIPLE) {
            await this.props.onChange('customers')(this.props.customers);
        } else {
            await this.props.onChange('customers')([]);
        }
    }

    selectUser = customers => (user, index, data) => {
        const foundUser = customers.find(customer => customer.id === data[index].key);
        this.setState({
            autocomplete: user,
        });
        if (foundUser) {
            this.props.onChange('customers')([foundUser]);
        }
    }

    changeUser = customers => (value, event, ...rest) => {
        this.setState({
            autocomplete: value,
        });

        this.props.onChange('customers')([]);
    }

    canNext = () => !(isEmpty(this.props.notification.customers));

    render() {
        const {customers, notification, next, back, ...props} = this.props;
        const language = window.navigator.language.includes('da') ? 'danske' : 'english';

        return (
            <React.Fragment>
                <div
                    className="prv-notification-edit-dialog__content for-radios"
                >
                    <span className="subheader">
                        {translations[language].selectUsers.title}
                    </span>
                </div>
                <div
                    className="prv-notification-edit-dialog__content radios"
                >
                    <SelectionControlGroup
                        id={this.ids[0]}
                        name="using-custom-icons"
                        type="radio"
                        controls={[{
                            label: translations[language].selectUsers.single,
                            value: TYPES.SINGLE,
                        }, {
                            label: translations[language].selectUsers.multiple,
                            value: TYPES.MULTIPLE,
                        }]}
                        value={this.state.amount}
                        className="prv-notification-edit-dialog__content-radios"
                        onChange={this.selectCount}
                    />
                </div>
                <div
                    className="prv-notification-edit-dialog__content"
                >
                    <span className="subheader">
                        {translations[language].selectUsers.searchUsers}
                    </span>
                </div>
                <div
                    className="prv-notification-edit-dialog__content"
                >
                    <Autocomplete
                        id={this.ids[1]}
                        placeholder={translations[language].selectUsers.searchUsers}
                        className="prv-notification-edit-dialog__content-select"
                        data={customers.map(this.getCustomerName)}
                        filter={Autocomplete.caseInsensitiveFilter}
                        floating={false}
                        rightIcon={<FontIcon>search</FontIcon>}
                        disabled={this.state.amount === TYPES.MULTIPLE}
                        onAutocomplete={this.selectUser(customers)}
                        onChange={this.changeUser(customers)}
                        value={this.state.autocomplete}
                    />

                </div>
                <div
                    className="prv-notification-edit-dialog__footer prv-notification-edit-dialog__footer--customers"
                >
                    <button
                        className={classnames(
                            'prv-notification-edit-dialog__save-button',
                            'prv-notification-edit-dialog__save-button--outline',
                        )}
                        onClick={back}
                    >
                        {translations[language].general.back}
                    </button>
                    <button
                        className="prv-notification-edit-dialog__save-button"
                        onClick={this.onSuccess}
                        disabled={Array.isArray(notification.customers)
                            ? notification.customers.length === 0
                            : true
                        }
                    >
                        {translations[language].general.next}
                    </button>
                </div>
            </React.Fragment>
        );
    }
}

export default connect({customers: getCustomers}, {fetchCustomers, chooseCustomers})(SelectNotificationType);
