import bind from '../helpers/bind';
import reducer, * as getters from './reducer';

const {binder, wrapAll} = bind(reducer);
export default binder;

export const {

    getProfile,
    getProfileUID,
    getRole,
    isLoading,
    getError,

    getInvite,
    isInviteLoading,
    getInviteError,

    isSA,
    isRM,
    isCustomer,
    getActiveUsers,

    getSelectedCrmConfig,

} = wrapAll(getters);
