import invariant from 'invariant';
import {StructureState} from '../reducer';
import removePortfolioReducer from './removePortfolioReducer';
import {isPortfolio} from '../helpers/assertion';

const disablePortfolioRenamingReducer = (
    state: StructureState,
    {payload: cancelled}: {payload: boolean},
): StructureState => {
    const {deleteOnCancelRenaming, portfolioBeingRenamedId, tree} = state;
    const updatedState = {...state, portfolioBeingRenamedId: undefined, deleteOnCancelRenaming: false};
    if (portfolioBeingRenamedId === undefined || !deleteOnCancelRenaming || !cancelled) {
        return updatedState;
    }
    const portfolio = tree.filter(isPortfolio).find(({id}) => id === portfolioBeingRenamedId);
    invariant(
        portfolio !== undefined,
        `Portfolio being renamed (${portfolioBeingRenamedId}) not found in structure: ${JSON.stringify(tree)}`,
    );
    return removePortfolioReducer(updatedState, {payload: portfolio});
};

export default disablePortfolioRenamingReducer;
