import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {clearSubmitError, handleSubmitError, reduxForm} from '../forms';
import PageContentWrapper from '../layout/page-content-wrapper';
import Spinner from '../loading-spinner/loading-spinner';
import ErrorPanel from '../panel/panel';

@reduxForm()
class CollectionListForm extends Component {
    static propTypes = {
        className: PropTypes.string,
        loading: PropTypes.bool,
        handleSubmit: PropTypes.func.isRequired,
        submitting: PropTypes.bool,
        error: PropTypes.shape({
            message: PropTypes.string,
        }),
        title: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.arrayOf(PropTypes.string),
        ]),
        children: PropTypes.node,
    };

    handleApiActionCall = promise => this.props.handleSubmit(() => handleSubmitError(promise))();

    clearApiActionError = () => clearSubmitError(this.props);

    render() {
        const {
            className, loading, submitting, error,
            title, children,
        } = this.props;
        return (
            <PageContentWrapper className={className} title={title}>
                {(loading || submitting) && <Spinner/>}
                {error && <ErrorPanel error={error} onClear={this.clearApiActionError}/>}
                {children}
            </PageContentWrapper>
        );
    }
}

export default CollectionListForm;
