import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import numeral from 'numeral';
import Button from 'react-md/lib/Buttons/Button';
import EditBenchmarkGroupDialog from './edit-benchmark-group-dialog';
import Table from '../form-table';
import {formatUtcDate} from '../../helpers/dates';
import ActionViewOptions from '../resource-table/action-view-options';

const benchmarksColumns = [
    {
        field: 'date',
        label: 'Date',
        className: 'prv-benchmark-group-list__column',
        render: (date) => formatUtcDate(date),
        sortField: false,
    },
    {
        field: 'benchmarks',
        label: 'Benchmarks',
        className: 'prv-benchmark-group-list__column',
        render: (benchmarks) => (benchmarks && benchmarks.length
            ? benchmarks.map(({id, weight, name}) =>
                <div key={id}>{numeral(weight).format('0.0[0]')} &times; {name}</div>)
            : 'No benchmarks'),
        sortField: false,
    },
];

const yes = () => true;
const no = () => false;
const byDate = () => 'date';

export default class BenchmarkGroupList extends React.PureComponent {
    static propTypes = {
        addButtonTitle: PropTypes.string,
        availableBenchmarks: PropTypes.arrayOf(PropTypes.object),
        benchmarkGroups: PropTypes.arrayOf(PropTypes.object),
        className: PropTypes.string,
        editable: PropTypes.bool,
        headerless: PropTypes.bool,
        loading: PropTypes.bool,
        onUpdate: PropTypes.func,
        title: PropTypes.string,
        canAdd: PropTypes.bool,
    };

    static defaultProps = {
        addButtonTitle: 'New Benchmarks',
        editable: true,
        headerless: true,
        title: 'Benchmarks',
        canAdd: true,
    };

    constructor(props) {
        super(props);
        this.state = {
            groupBeingEdited: undefined,
        };
    }

    _handleCreateBenchmarks = () => {
        if (!this.props.editable) {
            return;
        }
        this.setState({groupBeingEdited: {}}); // empty group
    };

    _handleEditBenchmarks = (row) => {
        if (!this.props.editable) {
            return;
        }
        this.setState({groupBeingEdited: row});
    };

    _handleDeleteBenchmarks = (benchmarkGroup) => {
        if (!this.props.editable) {
            return;
        }
        this._handleSaveBenchmarkGroup(Object.assign({}, benchmarkGroup, {deleted: true}));
    };

    _handleCancelEditing = () => {
        this.setState({groupBeingEdited: undefined});
    };

    _handleSaveBenchmarkGroup = (benchmarkGroup) => {
        const {benchmarkGroups, onUpdate} = this.props;
        const groupIndex = benchmarkGroups.findIndex(group =>
            group.date.valueOf() === benchmarkGroup.date.valueOf());
        let updatedBenchmarkGroups;

        if (groupIndex < 0) {
            // this was a new item, adding it to the end
            updatedBenchmarkGroups = benchmarkGroups.concat(benchmarkGroup);
            updatedBenchmarkGroups.sort((groupA, groupB) => groupA.date - groupB.date);
        } else {
            updatedBenchmarkGroups = [
                ...benchmarkGroups.slice(0, groupIndex),
                benchmarkGroup,
                ...benchmarkGroups.slice(groupIndex + 1),
            ];
        }

        onUpdate && onUpdate(updatedBenchmarkGroups);

        this._handleCancelEditing();
    };

    render() {
        const {
            addButtonTitle,
            availableBenchmarks,
            benchmarkGroups,
            className,
            editable,
            headerless,
            loading,
            title,
            canAdd,
        } = this.props;
        const {groupBeingEdited} = this.state;

        return (
            <div className={cn('prv-benchmark-group-list', className)}>
                <Table
                    actionButton={
                        canAdd && !headerless ?
                            <Button
                                flat
                                iconChildren="add"
                                onClick={this._handleCreateBenchmarks}
                                secondary
                                swapTheming
                            >
                                {addButtonTitle}
                            </Button> :
                            null
                    }
                    canDelete={editable ? yes : no}
                    canEdit={editable ? yes : no}
                    className={cn('prv-benchmark-group-list__table', {
                        'prv-benchmark-group-list__table--headerless': headerless,
                    })}
                    columns={benchmarksColumns}
                    getId={byDate}
                    actionView={ActionViewOptions.Icons}
                    items={(benchmarkGroups || []).filter(group => !group.deleted)}
                    loading={loading}
                    onEdit={this._handleEditBenchmarks}
                    onDelete={this._handleDeleteBenchmarks}
                    searchable={false}
                    title={title}
                />
                {groupBeingEdited &&
                <EditBenchmarkGroupDialog
                    availableBenchmarks={availableBenchmarks}
                    benchmarks={groupBeingEdited.benchmarks}
                    date={groupBeingEdited.date}
                    onCancel={this._handleCancelEditing}
                    onSave={this._handleSaveBenchmarkGroup}
                />}
                {headerless &&
                <Button
                    className="prv-benchmark-group-list__bottom-add-button"
                    flat
                    iconChildren="add"
                    onClick={this._handleCreateBenchmarks}
                    secondary
                    swapTheming
                >
                    {addButtonTitle}
                </Button>}
            </div>
        );
    }
}
