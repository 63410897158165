import React, {Component} from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {withRouter} from 'react-router-dom';
import {money} from 'helpers/formatters';
import {formatUtcDateTimeAsIs} from 'helpers/dates';

import {connect} from 'store';
import {collectionShape} from 'store/collections';
import {isLoading as isAccountsLoading, getAccounts} from 'store/accounts';
import {getCollectionState} from 'store/assets';
import {fetchAssets} from 'store/assets/actions';
import {
    getCollectionState as getTransactionsCollection,
    getUpdateTransactionError,
    isUpdateTransactionLoading,
} from 'store/transactions';
import {
    updateTransaction,
    clearUpdateTransactionError,
    deleteTransaction,
} from 'store/transactions/actions';
import {fetchAccounts} from 'store/accounts/actions';
import {accountShape} from 'store/data/client';

import TransactionEditDialog from 'scenes/transactions/transaction-edit-dialog';
import CollectionListPage from 'components/collections/list-page';

import transactionsTypes from './transactions-types';

const yes = () => true;

const renderType = type => (
    <span
        className={cn('prv-client-transaction__transaction-type', {
            'prv-client-transaction__transaction-type--buy': type === 'buy',
            'prv-client-transaction__transaction-type--other': type !== 'buy',
        })}
    >
        {type.toUpperCase()}
    </span>
);

const renderName = (firstName, lastName) => (x, item) => `${item[firstName] || ''} ${item[lastName] || ''}`;

const columns = [
    {
        field: 'customerName',
        label: 'Client',
        width: '120px',
        render: renderName('customerFirstName', 'customerLastName'),
    },
    {field: 'accountName', label: 'Account', width: '120px'},
    {field: 'type', label: 'Type', width: '110px', render: renderType},
    {
        field: 'tradeDate',
        label: 'Trade Time',
        width: '166px',
        render: (date) => (date ? formatUtcDateTimeAsIs(date) : ''),
    },
    {field: 'originalValue', label: 'Value', width: '80px', render: money},
    {field: 'currency', label: 'Currency', width: '60px'},
    {field: 'message', label: 'Message', width: '150px'},
];

class TransactionsPage extends Component {
    static propTypes = {
        transactionsCollection: collectionShape,
        updateTransactionLoading: PropTypes.bool.isRequired,
        updateTransactionError: PropTypes.shape({
            message: PropTypes.string,
        }),
        clientAccounts: PropTypes.arrayOf(accountShape),
        clientAssets: collectionShape,
        accountsLoading: PropTypes.bool.isRequired,
        fetchAccounts: PropTypes.func.isRequired,
        fetchAssets: PropTypes.func.isRequired,
        updateTransaction: PropTypes.func.isRequired,
        deleteTransaction: PropTypes.func.isRequired,
        clearUpdateTransactionError: PropTypes.func.isRequired,
    };

    state = {
        editableTransaction: null,
    };

    onRefCollectionList = (collectionList) => {
        this.collectionList = collectionList;
    };

    onEdit = transaction => this.setState({editableTransaction: transaction});

    onDelete = ({id}) => this.props.deleteTransaction({transactionId: id});

    onTransactionEditClose = () => this.setState({editableTransaction: null});

    onUpdateTransaction = ({
        transactionType,
        custodyAccountId,
        currency,
        accountId,
        assetId,
        originalValue,
        price,
        message,
        tradeDate,
        settlementDate,
    }) => {
        const {editableTransaction} = this.state;

        this.props.updateTransaction({
            transactionId: editableTransaction.id,
            type: transactionType,
            custodyAccountId,
            currency,
            accountId,
            assetId,
            originalValue,
            price,
            message,
            tradeDate,
            settlementDate,
        })
            .then(() => {
                this.onTransactionEditClose();
                this.collectionList.onRefresh();
            });
    };

    getFiltersConfig = () => {
        const typesOptions = transactionsTypes.reduce((prev, cur) => ({...prev, [cur]: _.startCase(cur)}), {});

        return ({
            type: {
                title: 'Type',
                options: typesOptions,
            },
        });
    };

    renderCollection = () => {
        const {transactionsCollection} = this.props;

        return (
            <CollectionListPage
                ref={this.onRefCollectionList}
                name="transactions"
                collection={transactionsCollection}
                title="Transactions"
                columns={columns}
                filters={this.getFiltersConfig()}
                canEdit={yes}
                canDelete={yes}
                onEdit={this.onEdit}
                onDelete={this.onDelete}
            />
        );
    };

    renderEditDialog = () => {
        const {
            clientAccounts, accountsLoading, clientAssets, updateTransactionLoading, updateTransactionError,
        } = this.props;
        const {editableTransaction} = this.state;

        if (!editableTransaction) return null;

        return (
            <TransactionEditDialog
                accounts={clientAccounts || []}
                assets={clientAssets.items || []}
                clientId={editableTransaction.customerId}
                error={updateTransactionError}
                fetchAccounts={this.props.fetchAccounts}
                fetchAssets={this.props.fetchAssets}
                loading={updateTransactionLoading || accountsLoading}
                onClearError={this.props.clearUpdateTransactionError}
                onClose={this.onTransactionEditClose}
                onSetTransaction={this.onUpdateTransaction}
                transaction={editableTransaction}
            />
        );
    };

    render() {
        return (
            <div>
                {this.renderCollection()}
                {this.renderEditDialog()}
            </div>
        );
    }
}

export default withRouter(connect(
    {
        transactionsCollection: getTransactionsCollection,
        clientAccounts: getAccounts,
        clientAssets: getCollectionState,
        accountsLoading: isAccountsLoading,
        updateTransactionLoading: isUpdateTransactionLoading,
        updateTransactionError: getUpdateTransactionError,
    },
    {
        fetchAccounts,
        fetchAssets,
        deleteTransaction,
        updateTransaction,
        clearUpdateTransactionError,
    },
)(TransactionsPage));
