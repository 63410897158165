import {UserScopeHeader} from '../store/api/api';
import {UserScopes} from './userscope';

export const STEPS = {
    REGISTER: 1,
    BANK_SELECTION: 2,
    BANK_LOGIN: 3,
    FINAL: 4,
    SECURITY: 5,
} as const;

export const FLOWS = {
    ONBOARDED: 'onboarded',
    ONBOARDING: 'onboarding',
};

export const norkonEnpoint = 'https://norkon.borsen.dk/proveo/register';

export const defaultSuccessfulRedirectBorsen = 'https://borsen.dk/investor/';
export const defaultSuccessfulRedirectAtlaz = 'https://atlaz.dk/success/';

export const auth = {
    username: process.env.AUTH_USERNAME,
    password: process.env.AUTH_PASSWORD,
};

export const TinkErrors = {
    UnknownError: 'UnknownError',
    NoError: 'NoError',
    BadRequest: 'BadRequest',
    UserCancelled: 'UserCancelled',
    AuthenticationError: 'AuthenticationError',
    InternalError: 'InternalError',
    TemporaryError: 'TemporaryError',
};

export enum Theme {
    ATLAZ = 'atlaz',
    BORSEN = 'borsen',
}

export const getDefaultSuccessfulRedirect = (theme: Theme) => (theme === Theme.ATLAZ
    ? defaultSuccessfulRedirectAtlaz
    : defaultSuccessfulRedirectBorsen
);

export const getHeaders = (theme: Theme, headers?: {[key: string]: string}) => ({
    ...headers,
    [UserScopeHeader]: theme === Theme.ATLAZ ? UserScopes.ATLAZ_WEB : UserScopes.BORSEN_WEB,
});
