import bind from '../helpers/bind';
import reducer, * as getters from './reducer';

const {binder, wrapAll} = bind(reducer);
export default binder;

export const {
    // @ts-ignore
    getVersions, getLoading, getError, getSelectedVersion, getDefaultVersion, getLoaded,
} = wrapAll(getters);
