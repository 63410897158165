import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'store';
import _ from 'lodash';
import {createClientBenchmark, updateClientBenchmark, fetchAvailableBenchmarks} from 'store/client-benchmarks/actions';
import {getAvailableBenchmarks} from 'store/client-benchmarks';
import DialogContainer from 'react-md/lib/Dialogs/DialogContainer';
import BenchmarkProperties from './benchmark-properties';
import BenchmarkList from '../../../components/benchmark/benchmark-list';
import {showToast} from '../../../store/snackbar/actions';
import {isSA} from '../../../store/auth';
import {benchmarkShape, sharedBenchmarkShape} from '../../../store/data/client';
import {getReferenceData} from '../../../store/general-data';

class EditBenchmarkDialog extends Component {
    static propTypes = {
        availableBenchmarks: PropTypes.arrayOf(benchmarkShape),
        benchmark: sharedBenchmarkShape,
        createClientBenchmark: PropTypes.func.isRequired,
        fetchAvailableBenchmarks: PropTypes.func.isRequired,
        isSA: PropTypes.bool,
        onCancel: PropTypes.func,
        onSubmitSuccess: PropTypes.func.isRequired,
        referenceData: PropTypes.object,
        showToast: PropTypes.func,
        updateClientBenchmark: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            availableBenchmarks: this._convertToValueLabelList(props.availableBenchmarks),
            benchmark: props.benchmark || {},
            benchmarkErrors: {},
            subBenchmarksErrors: [],
            subBenchmarks: (props.benchmark && props.benchmark.benchmarks) || [],
        };
    }

    componentWillMount() {
        this.props.fetchAvailableBenchmarks({perPage: 99999}); // fetch all
    }

    componentWillReceiveProps(nextProps) {
        const {availableBenchmarks, benchmark} = nextProps;
        if (this.props.benchmark !== benchmark) {
            this.setState({benchmark});
        }

        if (this.props.availableBenchmarks !== availableBenchmarks) {
            this.setState({availableBenchmarks: this._convertToValueLabelList(availableBenchmarks)});
        }
    }

    _convertToValueLabelList = benchmarks => benchmarks.map(({id, name, morningstarId}) =>
        ({value: id, label: name || `MSID ${morningstarId}` || id}));

    _getBenchmarkNameById = (id) => {
        const benchmarkItem = this.state.availableBenchmarks.find(item => item.value === id);
        return benchmarkItem ? benchmarkItem.label : undefined;
    };

    _getValidationErrorMessage = (noNewErrors) => {
        const {referenceData} = this.props;
        const {benchmark, benchmarkErrors: currentBenchmarkErrors, subBenchmarks} = this.state;

        const
            benchmarkSet = new Set(),
            benchmarkErrors = {},
            subBenchmarksErrors = [];
        let totalWeight = 0,
            decimalsCount = 1,
            errorMessage;

        if (_.isNil(benchmark.name) || !benchmark.name.trim()) {
            errorMessage = errorMessage || 'Please input non-empty Name.';
            benchmarkErrors.name = noNewErrors ? currentBenchmarkErrors.name : true;
        }

        if (_.isNil(benchmark.currency)) {
            errorMessage = errorMessage || 'Please choose Currency.';
            benchmarkErrors.currency = noNewErrors ? currentBenchmarkErrors.currency : true;
        }

        if (_.isNil(benchmark.assetClassId)) {
            errorMessage = errorMessage || 'Please choose Asset Class.';
            benchmarkErrors.assetClass = noNewErrors ? currentBenchmarkErrors.assetClass : true;
        }

        if (!_.isNil(benchmark.assetClassId) && referenceData && referenceData.assetClass) {
            const assetClass = referenceData.assetClass.find(({id}) => id === benchmark.assetClassId);
            if (assetClass && assetClass.subClasses.length && _.isNil(benchmark.assetSubClassId)) {
                errorMessage = errorMessage || 'Please choose Asset SubClass.';
                benchmarkErrors.assetSubClass = noNewErrors ? currentBenchmarkErrors.assetSubClass : true;
            }
        }

        if (_.isNil(benchmark.regionId)) {
            errorMessage = errorMessage || 'Please choose Region.';
            benchmarkErrors.region = noNewErrors ? currentBenchmarkErrors.region : true;
        }

        if (!subBenchmarks || !subBenchmarks.length) {
            errorMessage = errorMessage || 'Please add at least one benchmark.';
        }

        subBenchmarks.forEach((benchmark, index) => {
            if (!benchmark.id) {
                errorMessage = errorMessage || 'Please choose benchmark from the list.';
                subBenchmarksErrors[index] = {name: true};
                return true;
            }

            const name = this._getBenchmarkNameById(benchmark.id);
            if (!name) {
                errorMessage = errorMessage || `Invalid benchmark #${index + 1}, please try choosing another one.`;
                subBenchmarksErrors[index] = {name: true};
                return true;
            }

            if (!benchmark.weight || Number.isNaN(benchmark.weight) || benchmark.weight < Number.EPSILON) {
                errorMessage = errorMessage || `Please set weight for "${name}" benchmark.`;
                subBenchmarksErrors[index] = {weight: true};
                return true;
            }
            totalWeight += benchmark.weight;

            const decimals = String(benchmark.weight).split('.')[1];
            if (decimals && decimals.length > decimalsCount) {
                decimalsCount = decimals.length;
            }

            if (benchmarkSet.has(benchmark.id)) {
                errorMessage = errorMessage || `Benchmark selected twice: "${name}".`;
                subBenchmarksErrors[index] = {name: true};
                return true;
            }
            benchmarkSet.add(benchmark.id);

            return false;
        });

        // only check totalWeight if previous validation was successful
        if (!errorMessage && totalWeight.toFixed(decimalsCount) !== '1.'.padEnd(2 + decimalsCount, 0)) {
            errorMessage = 'Total benchmarks weight must be equal 1.';
        }

        this.setState({benchmarkErrors, subBenchmarksErrors});

        return errorMessage;
    };

    _handleSaveClick = () => {
        const errorMessage = this._getValidationErrorMessage();
        if (errorMessage) {
            this.props.showToast && this.props.showToast({text: errorMessage});
            return;
        }

        const {benchmark, onSubmitSuccess} = this.props;
        const {
            benchmark: {name, isDefault, currency, regionId, assetClassId, assetSubClassId},
            subBenchmarks,
        } = this.state;
        const params = _.pickBy(
            {
                name: name.trim(),
                isDefault,
                currency,
                regionId,
                assetClassId,
                assetSubClassId,
                benchmarks: subBenchmarks,
            },
            _.identity,
        );

        const promise = benchmark // is this editing or creating a benchmark?
            ? this.props.updateClientBenchmark({id: benchmark.id, ...params})
            : this.props.createClientBenchmark(params);

        promise
            .then(() => {
                onSubmitSuccess && onSubmitSuccess();
            })
            .catch(() => {
                this.props.showToast && this.props.showToast({text: 'Failed to save Benchmark. Please try again.'});
            });
    };

    _handleBenchmarkChange = (benchmark) => {
        this.setState({benchmark}, () => {
            this._getValidationErrorMessage(true);
        });
    };

    _handleAddSubBenchmark = () => {
        this.setState(prevState => ({subBenchmarks: [...prevState.subBenchmarks, {}]}));
    };

    _modifyBenchmarkByIndex = (index, getUpdatedBenchmark) => {
        if (index < 0) {
            return;
        }

        this.setState(prevState => {
            const updatedBenchmark = getUpdatedBenchmark(prevState.subBenchmarks[index]);

            return {subBenchmarks: [
                ...prevState.subBenchmarks.slice(0, index),
                ...(updatedBenchmark ? [updatedBenchmark] : []),
                ...prevState.subBenchmarks.slice(index + 1),
            ]};
        });
    };

    _handleChangeSubBenchmark = (index, newId, name) => {
        this._modifyBenchmarkByIndex(index, benchmark => ({...benchmark, id: newId, name}));
    };

    _handleSubBenchmarkWeightChange = (index, weight) => {
        this._modifyBenchmarkByIndex(index, benchmark => ({...benchmark, weight}));
    };

    _handleDeleteSubBenchmark = (index) => {
        this._modifyBenchmarkByIndex(index, () => undefined);
    };

    render() {
        const {benchmark: originalBenchmark, isSA, onCancel} = this.props;
        const {availableBenchmarks, benchmark, benchmarkErrors, subBenchmarksErrors, subBenchmarks} = this.state;
        const isEdit = !!originalBenchmark;

        return (
            <DialogContainer
                actions={[
                    {secondary: true, children: 'Cancel', onClick: onCancel},
                    {
                        children: isEdit ? 'Save' : 'Create',
                        onClick: this._handleSaveClick,
                        primary: true,
                    },
                ]}
                dialogClassName="prv-edit-benchmark-dialog"
                id="edit-benchmark-dialog"
                onHide={onCancel}
                title={isEdit ? 'Edit Benchmark' : 'Create Benchmark'}
                visible
            >
                <BenchmarkProperties
                    benchmark={benchmark}
                    canChangeDefault={isSA}
                    className="prv-edit-benchmark-dialog__properties"
                    errors={benchmarkErrors}
                    onChange={this._handleBenchmarkChange}
                />
                <div className="prv-edit-benchmark-dialog__sub-benchmarks">
                    <span className="prv-edit-benchmark-dialog__sub-benchmarks-label">Benchmarks</span>
                    <BenchmarkList
                        availableBenchmarks={availableBenchmarks}
                        benchmarks={subBenchmarks}
                        errors={subBenchmarksErrors}
                        onAddBenchmark={this._handleAddSubBenchmark}
                        onBenchmarkChange={this._handleChangeSubBenchmark}
                        onDelete={this._handleDeleteSubBenchmark}
                        onWeightChange={this._handleSubBenchmarkWeightChange}
                    />
                </div>
            </DialogContainer>
        );
    }
}

export default connect(
    {
        availableBenchmarks: getAvailableBenchmarks,
        isSA,
        referenceData: getReferenceData,
    },
    {
        fetchAvailableBenchmarks,
        createClientBenchmark,
        updateClientBenchmark,
        showToast,
    },
)(EditBenchmarkDialog);
