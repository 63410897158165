import React, {Component} from 'react';
import PropTypes from 'prop-types';
import * as pathnames from 'config/pathnames';

import {connect} from 'store';
import {redirect} from 'store/navigate';

import Spinner from 'components/loading-spinner/loading-spinner';

import {createClient, fetchActiveRMs} from 'store/clients/actions';
import {rmShape} from 'store/data/rm';
import {getActiveRMs, getActiveRMsLoading} from 'store/clients';
import {isSA} from 'store/auth';

import ClientEditForm from './client-edit-form';
import {CURRENCY_LIST} from './transactions/transactions-types';

const defaultValues = {
    baseCurrency: '',
    birthday: '',
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    proveoId: '',
    startMonth: 1,
};

class CreateClientPage extends Component {
    static propTypes = {
        rms: PropTypes.arrayOf(rmShape).isRequired,
        loading: PropTypes.bool.isRequired,
        redirect: PropTypes.func.isRequired,
        createClient: PropTypes.func.isRequired,
        fetchActiveRMs: PropTypes.func.isRequired,
        isSA: PropTypes.bool.isRequired,
        initialValues: PropTypes.object,
    };

    componentWillMount() {
        if (this.props.isSA) this.props.fetchActiveRMs();
    }

    onCreate = data => this.props.createClient({...data});

    onSuccess = (a, b, {values: client}) => this.props.redirect({
        pathname: pathnames.CLIENTS,
        state: {created: this.getName(client)},
    });

    onCancel = () => this.props.redirect(pathnames.CLIENTS);

    getName = entity => entity && `${entity.firstName} ${entity.lastName}`;

    render() {
        const {rms, loading} = this.props;
        const currencies = CURRENCY_LIST.map(val => ({value: val, label: val}));
        if (loading) {
            return (<Spinner/>);
        }
        return (
            <ClientEditForm
                isSA={this.props.isSA || false}
                form="create-client"
                title={['Clients', 'New Client']}
                actionTitle="Send Invitation"
                onSubmit={this.onCreate}
                onCancel={this.onCancel}
                onSubmitSuccess={this.onSuccess}
                currencies={currencies}
                managers={this.props.isSA ?
                    rms && rms.map(rm => ({value: rm.id, label: this.getName(rm)})) :
                    null
                }
                initialValues={this.props.initialValues || defaultValues}
            />
        );
    }
}

export default connect(
    {
        rms: getActiveRMs,
        loading: getActiveRMsLoading,
        isSA,
    },
    {
        createClient,
        fetchActiveRMs,
        redirect,
    },
)(CreateClientPage);
