import {useCallback, useRef} from 'react';
import {History, Location} from 'history';
import useBoolean from './useBoolean';

type PromptResult = {
    handlePromptMessage: (location: Location) => boolean,
    visible: boolean,
    hide: () => void,
    confirmLeave: () => void,
}

const useChangeLocationPrompt = (history: History, shouldShowPrompt: boolean): PromptResult => {
    const [visible, show, hide] = useBoolean();
    const targetLocation = useRef<Location | undefined>();
    const confirmLeave = useCallback(
        () => {
            targetLocation.current && history.push(targetLocation.current);
        },
        [history, targetLocation],
    );
    const handlePromptMessage = useCallback(
        (location: Location) => {
            if (shouldShowPrompt) {
                targetLocation.current = location;
                show();
                return false;
            }

            return true;
        },
        [shouldShowPrompt, show, targetLocation, visible],
    );
    return {handlePromptMessage, visible, hide, confirmLeave};
};

export default useChangeLocationPrompt;
