import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {connect} from 'store';
import CollectionListPage from 'components/collections/list-page';
import {collectionShape} from 'store/collections';
import {getCollectionState, isUpdateAssetLoading, getUpdateAssetError} from 'store/assets';
import {AssetTypeEnum} from 'store/data/assets';
import {clearUpdateAssetError, fetchAssets, updateAsset} from 'store/assets/actions';
import EditAssetDialog from './edit-asset-dialog';

const yes = () => true;

const columns = [
    {field: 'name', label: 'Asset', width: '96px'},
    {field: 'bankName', label: 'Bank', width: '96px'},
    {field: 'originalId', label: 'Original ID', width: '300px'},
    {field: 'isin', label: 'ISIN', width: '96px'},
    {field: 'region', label: 'Region', width: '96px'},
    {field: 'type', label: 'Type', width: '96px', render: assetType => AssetTypeEnum[assetType]},
    {field: 'currency', label: 'Currency', width: '80px'},
    {field: 'isNew', label: 'New', width: '46px', render: isNew => (isNew ? 'Yes' : 'No')},
];

class AssetsListPage extends Component {
    static propTypes = {
        clearUpdateAssetError: PropTypes.func.isRequired,
        collection: collectionShape,
        fetchAssets: PropTypes.func.isRequired,
        updateAsset: PropTypes.func.isRequired,
        updateAssetError: PropTypes.object,
        updateAssetLoading: PropTypes.bool,
    };

    constructor(props) {
        super(props);
        this.state = {
            assetBeingEdited: null,
        };
    }

    onEditAssetClicked = (asset) => {
        this.setState({assetBeingEdited: asset});
    };

    onCloseEditAssetDialog = () => {
        this.setState({assetBeingEdited: null});
    };

    onUpdateAssetClicked = (asset) => {
        this.props.updateAsset({
            assetId: asset.id,
            name: asset.name,
            isin: asset.isin,
            region: asset.region,
            type: asset.type,
            currency: asset.currency,
            isNew: true,
        })
            .then(() => {
                this.onCloseEditAssetDialog();
                this.props.clearUpdateAssetError();
                this.props.fetchAssets();
            });
    };

    render() {
        const {collection, updateAssetError, updateAssetLoading} = this.props;
        const {assetBeingEdited} = this.state;

        return (
            <div>
                <CollectionListPage
                    name="assets"
                    title="Assets"
                    collection={collection}
                    columns={columns}
                    canEdit={yes}
                    onEdit={this.onEditAssetClicked}
                />
                {assetBeingEdited &&
                <EditAssetDialog
                    asset={assetBeingEdited}
                    error={updateAssetError}
                    loading={updateAssetLoading}
                    onCancel={this.onCloseEditAssetDialog}
                    onClose={this.onCloseEditAssetDialog}
                    onUpdate={this.onUpdateAssetClicked}
                />}
            </div>
        );
    }
}

export default withRouter(connect(
    {
        collection: getCollectionState,
        updateAssetError: getUpdateAssetError,
        updateAssetLoading: isUpdateAssetLoading,
    },
    {
        clearUpdateAssetError,
        fetchAssets,
        updateAsset,
    },
)(AssetsListPage));
