import React from 'react';
import PropTypes from 'prop-types';
import SideDialog from '../../../components/dialogs/choose-items-side-dialog';

const renderItem = ({name}) => name;

export default class EditableSideDialog extends React.PureComponent {
    static propTypes = {
        addCustom: PropTypes.bool,
        addOwnAccountItem: PropTypes.bool,
        className: PropTypes.string,
        customItemName: PropTypes.string,
        dialogTitle: PropTypes.string.isRequired,
        itemName: PropTypes.string.isRequired,
        items: PropTypes.array.isRequired,
        onAddCustom: PropTypes.func.isRequired,
        onClose: PropTypes.func,
        onConfirm: PropTypes.func.isRequired,
        selectedItems: PropTypes.array.isRequired,
    };

    static defaultProps = {
        addCustom: true,
        addOwnAccountItem: false,
    };

    onSelect = (items) => {
        const {onAddCustom, onConfirm} = this.props;
        if (items.length && items[0].id === 'custom') {
            onAddCustom(items[0].name);
        } else {
            onConfirm(items);
        }
    };

    render() {
        const {
            customItemName, items: propsItems, selectedItems, onAddCustom, className, onClose, addOwnAccountItem,
            dialogTitle, itemName, addCustom,
        } = this.props;

        let items = addOwnAccountItem ? [{name: 'Own Account', id: '00000000-0000-0000-0000-000000000000'}] : [];
        if (customItemName) {
            items.unshift({name: customItemName, id: 'custom', isNew: true});
        }
        items =
            items.concat(propsItems.filter(({id}) => id !== '00000000-0000-0000-0000-000000000000' && id !== 'custom'));

        return (
            <SideDialog
                addCustom={addCustom}
                className={className}
                disableOnBlur
                itemName={itemName}
                items={items}
                onAddCustom={onAddCustom}
                onClose={onClose}
                onConfirm={this.onSelect}
                renderItem={renderItem}
                selectedItems={customItemName
                    ? [{name: customItemName, id: 'custom', isNew: true}]
                    : selectedItems
                }
                title={dialogTitle}
                uniqueKey="id"
            />
        );
    }
}
