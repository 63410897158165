import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

const PreviewPanelContainer = ({className, children, previewPanel}) => (
    <div className={cn('prv-preview-panel-container', className)}>
        <div className="prv-preview-panel-container__content">
            {children}
        </div>
        <div className="prv-preview-panel-container__preview-panel">
            {previewPanel}
        </div>
    </div>
);
export default PreviewPanelContainer;

PreviewPanelContainer.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    previewPanel: PropTypes.node,
};
