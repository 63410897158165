import bind from '../helpers/bind';
import reducer, * as getters from './reducer';

const {binder, wrapAll} = bind(reducer);
export default binder;

export const {
    getCollectionState,
    getFetchParams,
    getPinnedClient,
    getActiveRMsLoading,
    getActiveRMs,
    isLoading,
    getError,

    isAssetsLoading,
    getAssets,
    isAccountsProfitLoading,
    getAccountsProfit,
    isClientsAmsLoading,
    getClientsAms,
    isLimitsLoading,
    getLimits,
    isLimitsUpdating,
    getLimitsUpdateError,
    isBanksLoading,
    isBenchmarksLoading,
    isBenchmarksUpdating,
    getBenchmarksUpdateError,
    getClientBanks,
    getClientBenchmarks,
    getSharedBenchmarks,
    getAllocatedAssets,
    getAllocationClasses,
    getClientReportingBenchmarksLoading,
    getClientReportingBenchmarks,
} = wrapAll(getters);
