import React, {useCallback, useEffect, useMemo, useState} from 'react';
import cn from 'classnames';
import Button from 'react-md/lib/Buttons/Button';
import SelectField, {ListValue} from 'react-md/lib/SelectFields/SelectField';
import TextField from 'react-md/lib/TextFields/TextField';
import {connect} from '../../../../store';
import {createVersion, deleteVersion, selectVersion, renameVersion} from '../../../../store/structure-version/actions';
import {getSelectedVersion, getVersions} from '../../../../store/structure-version';
import Version from '../../../../store/types/Version';
import ConfirmationDialog from '../../../../components/dialogs/ConfirmationDialog';
import useBoolean from '../../../../components/hooks/useBoolean';

const NO_ID: Version['id'] = '00000000-0000-0000-0000-000000000000';

interface Props {
    className?: string,
    createVersion: (payload: {name: string, templateVersionId?: Version['id']}) => void,
    deleteVersion: (payload: {versionId: Version['id']}) => void,
    renameVersion: (payload: {versionId: Version['id'], name: string}) => void,
    id: string,
    onSetAsPrimary: () => void,
    onBeforeChange?: () => Promise<boolean>,
    selectVersion?: (version?: Version) => void,
    selectedVersion?: Version,
    versions: Version[],
}

const VersionSelector: React.FC<Props> = ({
    className,
    createVersion,
    deleteVersion,
    id,
    onBeforeChange,
    selectVersion,
    selectedVersion,
    renameVersion,
    onSetAsPrimary,
    versions,
}) => {
    const [currentValue, setCurrentValue] = useState(selectedVersion);
    useEffect(() => {setCurrentValue(selectedVersion);}, [selectedVersion]);

    // this is hack needed to reset label in the SelectField back when onChange didn't happen
    const refreshCurrentValue = () => {
        setCurrentValue(undefined);
        setTimeout(() => setCurrentValue(selectedVersion), 0);
    };
    const handleChangeVersion = useCallback(
        async (versionId, index) => {
            if (onBeforeChange) {
                try {
                    const canChange = await onBeforeChange();
                    if (!canChange) {
                        refreshCurrentValue();
                        return;
                    }
                } catch (e) {
                    refreshCurrentValue();
                    return;
                }
            }
            selectVersion && selectVersion(versions[index]);
        },
        [selectVersion, versions],
    );

    const [deleteConfirmationVisible, showDeleteConfirmation, hideDeleteConfirmation] = useBoolean();
    const handleDeleteVersion = useCallback(
        () => deleteVersion({versionId: selectedVersion!.id}),
        [deleteVersion, selectedVersion],
    );

    const [versionName, setVersionName] = useState<string>(selectedVersion?.name ?? '');
    const isEmptyVersionName = !versionName || !versionName.trim();
    const [templateVersionId, setTemplateVersionId] = useState<Version['id'] | undefined>();

    const [renameDialogVisible, showRenameDialog, hideRenameDialog] = useBoolean();
    const handleRenameClick = useCallback(
        () => {
            setVersionName(selectedVersion!.name);
            showRenameDialog();
        },
        [selectedVersion, setVersionName, showRenameDialog],
    );
    const handleRenameVersion = useCallback(
        () => renameVersion({versionId: selectedVersion!.id, name: versionName}),
        [selectedVersion, renameVersion, versionName],
    );

    const [createDialogVisible, showCreateDialog, hideCreateDialog] = useBoolean();
    const handleCreateClick = useCallback(
        () => {
            setVersionName('');
            setTemplateVersionId(undefined);
            showCreateDialog();
        },
        [setTemplateVersionId, setVersionName, showCreateDialog],
    );
    const handleCreateVersion = useCallback(
        () => createVersion({name: versionName, templateVersionId}),
        [createVersion, versionName, templateVersionId],
    );

    const handleChangeTemplateVersion = useCallback(
        (value: ListValue) => {
            setTemplateVersionId(value === NO_ID ? undefined : value as Version['id']);
        },
        [setTemplateVersionId],
    );

    const versionsWithLabels = useMemo(
        () => versions.map(({id, name, primary}) => ({value: id, label: `${name}${primary ? ' (primary)' : ''}`})),
        [versions],
    );
    const versionsWithEmptyOption = useMemo(
        () => [{label: <i>No Template</i>, value: NO_ID}, ...versionsWithLabels],
        [versionsWithLabels],
    );

    return (
        <div className={cn('prv-structure-version-selector', className)}>
            <SelectField
                className="prv-structure-version-selector__select-field"
                id={`${id}-select-field`}
                label="Structure version"
                menuItems={versionsWithLabels}
                onChange={handleChangeVersion}
                simplifiedMenu={false}
                value={currentValue?.id}
            />
            <Button
                disabled={!selectedVersion}
                icon
                id={`${id}-edit-button`}
                onClick={handleRenameClick}
                tooltipLabel="Rename version"
                tooltipPosition="top"
            >
                create
            </Button>
            <Button
                icon
                id={`${id}-create-button`}
                onClick={handleCreateClick}
                tooltipLabel="Create new version"
                tooltipPosition="top"
            >
                add
            </Button>
            <Button
                disabled={!selectedVersion}
                icon
                id={`${id}-delete-button`}
                onClick={showDeleteConfirmation}
                tooltipLabel="Delete selected version"
                tooltipPosition="top"
            >
                delete
            </Button>
            {selectedVersion && !selectedVersion.primary && (
                <Button
                    icon
                    id={`${id}-set-primary-button`}
                    onClick={onSetAsPrimary}
                    tooltipLabel="Set selected version as Primary"
                    tooltipPosition="top"
                >
                    star
                </Button>
            )}
            <ConfirmationDialog
                confirmButtonLabel="Rename"
                focusOnConfirm
                id={`${id}-rename-dialog`}
                onConfirm={handleRenameVersion}
                onHide={hideRenameDialog}
                visible={renameDialogVisible}
            >
                <TextField
                    id={`${id}-change-version-name`}
                    // @ts-ignore
                    onChange={setVersionName}
                    value={versionName}
                />
            </ConfirmationDialog>
            <ConfirmationDialog
                confirmButtonLabel="Create"
                disableConfirmButton={isEmptyVersionName}
                focusOnConfirm
                id={`${id}-create-dialog`}
                onConfirm={handleCreateVersion}
                onHide={hideCreateDialog}
                title="New Version"
                visible={createDialogVisible}
            >
                <TextField
                    id={`${id}-version-name`}
                    // @ts-ignore
                    onChange={setVersionName}
                    placeholder="Please enter version name..."
                    value={versionName}
                />
                <SelectField
                    className="prv-structure-version-selector__select-field"
                    id={`${id}-select-field`}
                    label="Template"
                    menuItems={versionsWithEmptyOption}
                    onChange={handleChangeTemplateVersion}
                    simplifiedMenu={false}
                    value={templateVersionId ?? NO_ID}
                />
            </ConfirmationDialog>
            <ConfirmationDialog
                confirmButtonLabel="Delete"
                focusOnConfirm={false}
                id={`${id}-delete-confirmation`}
                onConfirm={handleDeleteVersion}
                onHide={hideDeleteConfirmation}
                // eslint-disable-next-line max-len
                text={`Version "${selectedVersion?.name}" and its structure will be deleted. You can not undo this operation.`}
                title="Do you really want to delete version?"
                visible={deleteConfirmationVisible}
            />
        </div>
    );
};

// FIXME: figure out how to type the connect function
// @ts-ignore
export default connect(
    {
        versions: getVersions,
        selectedVersion: getSelectedVersion,
    },
    {
        createVersion,
        deleteVersion,
        selectVersion,
        renameVersion,
    },
)(VersionSelector);
