import {createApiCallAction} from '../helpers/actions';
import buildActions from '../collections/actions_builder';

const collection = buildActions('client-benchmarks');

export const fetchClientBenchmarks = collection.fetchItems;
export const createClientBenchmark = createApiCallAction('CLIENT_BENCHMARKS/CREATE_BENCHMARK', 'createClientBenchmark');
export const updateClientBenchmark = createApiCallAction('CLIENT_BENCHMARKS/UPDATE_BENCHMARK', 'updateClientBenchmark');
export const deleteClientBenchmark = createApiCallAction('CLIENT_BENCHMARKS/DELETE_BENCHMARK', 'deleteClientBenchmark');
export const fetchAvailableBenchmarks = createApiCallAction(
    'CLIENT_BENCHMARKS/FETCH_AVAILABLE_BENCHMARKS',
    'fetchBenchmarksList',
);
export const recalculateBenchmark = createApiCallAction('CLIENT_BENCHMARKS/recalculate', 'recalculateBenchmark');
