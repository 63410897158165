import {Level, PortfolioNode, StructureItemState, StructureNodeDtoType} from '../types';
import {StructureState} from '../reducer';
import {getLastIndex, hasSameParent, isSameLevel, isSiblingAfter, updateLevelIndex} from '../helpers/level';
import {getNextChildrenLevel} from '../helpers/structure';
import {isPortfolio} from '../helpers/assertion';
import {structureComparator} from '../helpers/sort';

export const FAKE_ROOT_PORTFOLIO: Readonly<PortfolioNode> = {
    id: 'root',
    level: [1],
    $type: StructureNodeDtoType.PORTFOLIO,
    custodyAccounts: [],
    name: 'Move to top level',
    state: StructureItemState.INITIAL,
};

/**
 * Checks if `pattern` is fully included into `level`.
 * @param level
 * @param pattern
 */
const includesLevel = (level: Level, pattern: Level) =>
    (level.length < pattern.length || !pattern.length
        ? false
        : pattern.every((value, index) => value === level[index])
    );

/**
 * Updates level to make it have new beginning.
 * It removes `remove` levels at the beginning and replaces them with `newBeginning`.
 *
 * @param level
 * @param remove
 * @param newBeginning
 */
const updateLevelBeginning = (level: Level, remove: number, newBeginning: Level): Level =>
    [...newBeginning, ...level.slice(remove)];

/**
 * Checks if `level` is a sibling of `parentOrSibling` or a children of sibling of `parentOrSibling`
 * and that it comes after parentOrSibling.
 *
 * @param level
 * @param parentOrSibling
 */
const isAfter = (level: Level, parentOrSibling: Level) =>
    hasSameParent(level, parentOrSibling) && level[parentOrSibling.length - 1] > getLastIndex(parentOrSibling);

type MovePayload = {payload: {portfolio: PortfolioNode, targetPortfolio: PortfolioNode}};

/**
 * Moves given portfolio into new parent portfolio. To do that we need:
 *
 * 1. update portfolio and its children levels, so that they're moving into the target portfolio
 * 3. update levels for sibling portfolios to shift them accordingly

 To better illustrate steps 1-4 let's assume we had the following structure:
 * 1 - A
 * 2 - B
 * 2-1 - B1
 * 2-1-1 - B11
 * 2-2 - B2
 * 2-2-1 - B21
 * 2-2-1-1 - B211
 * 2-2-1-2 - B212
 * 2-3 - B3
 * 3 - C
 * 3-1 - C1
 * 3-1-1 - C11
 *
 * If we move B1 into C, we should get the followind tree:
 *
 * 1 - A
 * 2 - B
 * 2-1 - B2
 * 2-1-1 - B21
 * 2-1-1-1 - B211
 * 2-1-1-2 - B212
 * 2-2 - B3
 * 3 - C
 * 3-1 - C1
 * 3-1-1 - C11
 * 3-2 - B1
 * 3-2-1 - B11
 *
 * As one can see the following levels have changed:
 * (2-1) -> (3-2)
 * (2-1-1) -> (3-2-1)
 * (2-2) -> (2-1)
 * (2-3) -> (2-2)
 *
 * To explain some terminology:
 * - direct children: nodes B1 and B2 are direct children of node B
 * - children: all B* nodes are children of node B
 * - sibling - nodes A and C are siblings of node B. But A comes before B, so we don't need to update it.
 *
 * Moving relies on the fact that the tree is sorted. And thus nodes come exactly in the order shown on examples
 * above, i.e. depth-first sorting is required. Reducer guarantees that the tree is sorted afterwards.
 */
const removePortfolioReducer = (state: StructureState, {payload: {portfolio, targetPortfolio}}: MovePayload) => {
    const {tree} = state;

    let findLevel: Level = [];
    let replaceByLevel = getNextChildrenLevel(tree, targetPortfolio);

    // find what will be the next sibling index
    let index = getLastIndex(portfolio.level);

    // Iterate over the structure and update levels for all portfolios going after `portfolio`
    // Also update levels of the `portfolio` and all its children
    let selectedPortfolio = state.selectedPortfolio === portfolio ? undefined : state.selectedPortfolio;
    const updatedTree = tree
        .map(item => {
            let {level} = item;

            if (includesLevel(level, findLevel)) {
                level = updateLevelBeginning(
                    level,
                    portfolio.level.length,
                    replaceByLevel,
                );
            } else if (isSameLevel(level, portfolio.level)) {
                findLevel = portfolio.level;
                if (isAfter(targetPortfolio.level, portfolio.level)) {
                    // target portfolio level will change, need to calculate target portfolio
                    // level after movement
                    replaceByLevel = updateLevelBeginning(
                        getNextChildrenLevel(tree, targetPortfolio),
                        targetPortfolio.level.length,
                        updateLevelIndex(
                            targetPortfolio.level,
                            targetPortfolio.level[portfolio.level.length - 1] - 1, portfolio.level.length - 1,
                        ),
                    );
                } else {
                    replaceByLevel = getNextChildrenLevel(tree, targetPortfolio);
                }
                level = replaceByLevel;
            } else if (isSiblingAfter(level, portfolio.level)) {
                findLevel = level;
                replaceByLevel = updateLevelIndex(portfolio.level, index++); // eslint-disable-line no-plusplus
                level = replaceByLevel;
            }

            if (level !== item.level) {
                const updatedItem = {...item, level};
                if (selectedPortfolio === item && isPortfolio(updatedItem)) {
                    selectedPortfolio = updatedItem;
                }
                return updatedItem;
            }

            return item;
        })
        .sort(structureComparator); // ensure tree is still sorted

    return {...state, selectedPortfolio, tree: updatedTree};
};

export default removePortfolioReducer;
