import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
    ErrorsRow,
    InputRow,
    SelectRow,
    DateRow,
} from '../../components/editing/editing-rows';
import {reduxForm} from '../../components/forms';
import validate from '../../components/forms/validations/validate';
import EditingForm from '../../components/editing/editing-form';
import EditingTable from '../../components/editing/editing-table';

const formName = 'default-client-form';

const rmFields = {
    firstName: {
        required: true,
        name: true,
        maxLength: 50,
    },
    lastName: {
        required: true,
        name: true,
        maxLength: 50,
    },
    email: {
        required: true,
        email: true,
    },
    phone: {
        required: true,
        phone: true,
    },
    baseCurrency: {
        required: true,
    },
    startMonth: {
        required: true,
    },
};

const saFields = {
    managerId: {
        required: true,
    },
    ...rmFields,
};

class RenderClientEditForm extends React.Component {
    componentDidMount() {
        this.props.initialize(this.props.initialValues);
        this.props.touch(...Object.keys(this.props.initialValues));
    }

    render() {
        const {
            managers,
            currencies,
            baseCurrency = {},
            assignedRm = {},
            className,
            ...props
        } = this.props;

        return (
            <EditingForm
                className={className}
                {...props}
            >
                <EditingTable
                    className="prv-client-edit-form__content"
                    {...props}
                >
                    {managers && <SelectRow
                        name="managerId"
                        label="Relationship Manager"
                        selectClassName="prv-client-edit-form__rm-select-field"
                        options={[{value: null, label: ''}, ...managers]}
                        placeholder={assignedRm.label || 'Choose RM'}
                        clearable={false}
                        required
                    />}
                    {managers && <ErrorsRow name="managerId"/>}

                    <InputRow
                        name="firstName"
                        label="First Name"
                        required
                    />
                    <ErrorsRow name="firstName"/>

                    <InputRow
                        name="lastName"
                        label="Last Name"
                        required
                    />
                    <ErrorsRow name="lastName"/>

                    <DateRow name="birthday" label="Birthdate"/>
                    <ErrorsRow name="birthday"/>

                    <InputRow
                        name="email"
                        label="Email"
                        disabled={Object.keys(assignedRm).length > 0}
                        required
                    />
                    <ErrorsRow name="email"/>

                    <InputRow
                        name="phone"
                        label="Phone number"
                        required
                    />
                    <ErrorsRow name="phone"/>

                    <InputRow name="proveoId" label="Proveo ID"/>
                    <ErrorsRow name="proveoId"/>

                    <SelectRow
                        name="baseCurrency"
                        label="Base Currency"
                        selectClassName="prv-client-edit-form__currency-select-field"
                        options={currencies}
                        placeholder={baseCurrency.label || 'Choose Base Currency'}
                        clearable={false}
                        required
                    />
                    <ErrorsRow name="baseCurrency"/>

                    <SelectRow
                        name="startMonth"
                        label="Start Month"
                        selectClassName="prv-client-edit-form__currency-select-field"
                        options={moment.months().map((month, index) => ({
                            value: index + 1,
                            label: month,
                        }))}
                        placeholder="Choose Start Month"
                        clearable={false}
                        required
                    />
                </EditingTable>
            </EditingForm>
        );
    }
}

RenderClientEditForm.propTypes = {
    managers: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
    })),
    assignedRm: PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
    }),
    currencies: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
    })),
    baseCurrency: PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
    }),
    className: PropTypes.string,
    initialValues: PropTypes.object.isRequired,
    initialize: PropTypes.func.isRequired,
    touch: PropTypes.func.isRequired,
};

export const SAClientEditForm = ({form}) => reduxForm({
    form: form || formName,
    forceUnregisterOnUnmount: true,
    destroyOnUnmount: true,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    ...validate(saFields),
})(RenderClientEditForm);


export const RMClientEditForm = ({form}) => reduxForm({
    form: form || formName,
    forceUnregisterOnUnmount: true,
    destroyOnUnmount: true,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    ...validate(rmFields),
})(RenderClientEditForm);

const ClientEditForm = ({isSA, ...props}) => {
    const Component = (isSA
        ? SAClientEditForm(props)
        : RMClientEditForm(props)
    );
    return <Component {...props} />;
};

export default ClientEditForm;
ClientEditForm.propTypes = {
    form: PropTypes.string,
    isSA: PropTypes.bool.isRequired,
};
