import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {accountsProfitShape, clientShape} from '../../../store/data/client';
import {connect} from '../../../store';
import {getAccountsProfit, getAllocationClasses, getPinnedClient} from '../../../store/clients';
import {fetchAccountsProfit, fetchAllocationClasses} from '../../../store/clients/actions';
import {getVersions} from '../../../store/structure-version';
import Field from './field';
import ProfitInformation from './ProfitInformation';
import ValueChanges from './ValueChanges';
import AllocationClasses from './AllocationClasses';

export class InformationPage extends React.Component {
    static propTypes = {
        accountsProfit: accountsProfitShape,
        allocationClasses: PropTypes.array,
        client: clientShape,
        fetchAccountsProfit: PropTypes.func.isRequired,
        fetchAllocationClasses: PropTypes.func.isRequired,
        versions: PropTypes.array,
    };
    static defaultProps = {
        accountsProfit: {},
    };

    componentDidMount() {
        const {client, versions} = this.props;
        this.props.fetchAllocationClasses(client.id);
        const primary = versions.find(v => v.primary);
        this.props.fetchAccountsProfit({id: client.id, versionId: primary ? primary.id : null});
    }

    render() {
        const {accountsProfit, allocationClasses, client} = this.props;
        return (
            <div>
                <div className="client-information-layout">
                    <div className="client-information-square client-information-view">
                        {client.firstName && <Field title="First name">{client.firstName}</Field>}
                        {client.lastName && <Field title="Last name">{client.lastName}</Field>}
                        {client.birthday && <Field title="Birthday">{moment(client.birthday).format('LL')}</Field>}
                        {client.email && <Field title="Email">{client.email}</Field>}
                        {client.phone && <Field title="Phone number">{client.phone}</Field>}
                        {client.proveoId && <Field title="proVeo ID">{client.proveoId}</Field>}
                    </div>
                    <div className="client-information-graph">
                        <ProfitInformation accountsProfit={accountsProfit} client={client} />
                        <ValueChanges accountsProfit={accountsProfit} client={client} />
                        <AllocationClasses allocationClasses={allocationClasses} client={client} />
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    {
        client: getPinnedClient,
        accountsProfit: getAccountsProfit,
        allocationClasses: getAllocationClasses,
        versions: getVersions,
    },
    {
        fetchAccountsProfit,
        fetchAllocationClasses,
    },
)(InformationPage);
