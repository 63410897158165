import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import {accountShape} from '../../../store/data/client/account';

export default class AccountItem extends React.PureComponent {
    static propTypes = {
        account: accountShape.isRequired,
        accountParams: PropTypes.object,
        isAccountBeingEdited: PropTypes.bool,
        isSelected: PropTypes.bool,
        onCancelAssetManagerClick: PropTypes.func,
        onEditAssetManagerClick: PropTypes.func,
    };

    onEditAssetManagerClick = () => {
        const {account, onEditAssetManagerClick} = this.props;
        onEditAssetManagerClick && onEditAssetManagerClick(account.id);
    };

    onCancelAssetManagerClick = () => {
        const {account, onCancelAssetManagerClick} = this.props;
        onCancelAssetManagerClick && onCancelAssetManagerClick(account.id);
    };

    formatValue = value => {
        if (value === undefined || value === null) {
            return 'N/A';
        }
        return numeral(value).format('0,0.00');
    };

    render() {
        const {account, accountParams, isAccountBeingEdited, isSelected} = this.props;
        const accountName = accountParams ? accountParams.accountName : account.name;
        const accountSelectedAM = accountParams &&
            (accountParams.selectedCustomAM || accountParams.selectedAMs[0].name);

        return (
            <li
                className={cn('prv-add-account-dialog-account', {
                    'prv-add-account-dialog-account--selected': isSelected,
                })}
            >
                <div>
                    {`Name: ${accountName}`}
                    <br/>
                    {`Type: ${account.type === 'cash' ? 'Cash' : 'Custody'}`}
                    <br/>
                    {`id: ${account.id}`}
                    <br/>
                    {`Value: ${this.formatValue(account.value)}`}
                </div>
                <button
                    className={cn('prv-add-account-dialog-account__edit-asset-manager-button', {
                        'prv-add-account-dialog-account__edit-asset-manager-button--active': isAccountBeingEdited,
                    })}
                    onClick={this.onEditAssetManagerClick}
                >
                    {accountSelectedAM || 'Select Asset Manager'}
                </button>
                {accountParams &&
                <div
                    className="prv-add-account-dialog-account__cancel-asset-manager-button"
                    onClick={this.onCancelAssetManagerClick}
                />}
            </li>
        );
    }
}
