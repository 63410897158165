import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {reduxForm, handleSubmitError} from 'components/forms';
import {connect} from 'store';

import Spinner from 'components/loading-spinner/loading-spinner';
import Select from 'components/select/select';

import {getActiveRMs, getActiveRMsLoading} from 'store/clients';
import {fetchActiveRMs} from 'store/clients/actions';
import {rmShape} from 'store/data/rm';

import styles from './rm-dialog.module.css';

@reduxForm({form: 'dialog'})
@connect({rms: getActiveRMs, rmsLoading: getActiveRMsLoading}, {fetchRMs: fetchActiveRMs})
export default class Dialog extends Component {
    static propTypes = {
        item: PropTypes.shape({
            id: PropTypes.string.isRequired,
            firstName: PropTypes.string.isRequired,
            lastName: PropTypes.string.isRequired,
        }).isRequired,
        onDelete: PropTypes.func,
        onBlock: PropTypes.func,
        onCancel: PropTypes.func.isRequired,
        onSelectRM: PropTypes.func.isRequired,
        submitting: PropTypes.bool,
        handleSubmit: PropTypes.func.isRequired,

        rms: PropTypes.arrayOf(rmShape),
        rmsLoading: PropTypes.bool,
        fetchRMs: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {selectedRMId: null};
    }

    componentWillMount() {
        this.props.fetchRMs();
    }

    componentDidMount() {
        window.addEventListener('keyup', this.onKeyUp);
    }

    componentWillUnmount() {
        window.removeEventListener('keyup', this.onKeyUp);
    }

    onKeyUp = (event) => {
        if (event.keyCode === 27) {
            this.props.onCancel();
        }
    }

    onSubmit = (event) => {
        event.preventDefault();
        const {onBlock, onDelete, handleSubmit} = this.props;
        const submitter = () => {
            const promise = (onBlock && onBlock()) || (onDelete && onDelete());
            if (promise && typeof promise.then === 'function') {
                return handleSubmitError(promise);
            }
            return null;
        };
        return handleSubmit(submitter)(event);
    }

    onSelectRM = (selectedRMId) => {
        this.setState({selectedRMId});
        this.props.onSelectRM(selectedRMId);
    }

    render() {
        const {
            item, onCancel, submitting,
            onBlock, onDelete, rmsLoading,
            rms = [],
        } = this.props;

        const {selectedRMId} = this.state;
        return (
            <div className={styles.moire}>
                {(submitting || rmsLoading) && <Spinner/>}
                <div className={styles.container}>
                    <div className={styles.message}>Select manager to reassign clients</div>

                    <div className={styles.rmContentWrapper}>
                        <div className={styles.deletedRm}>
                            <span>{item.firstName} {item.lastName}</span>
                        </div>

                        <div className={styles.arrowIcon}/>

                        <div className={styles.selectRm}>
                            <Select
                                placeholder="Select RM"
                                onChange={this.onSelectRM}
                                value={selectedRMId || ''}
                                options={rms.filter(rm => rm.id !== item.id)
                                    .map(rm => ({value: rm.id, label: `${rm.firstName} ${rm.lastName}`}))}
                                clearable={false}
                            />
                        </div>
                    </div>

                    <div className={styles.actions}>
                        {onCancel &&
                        <button className={styles.cancelButton} onClick={onCancel}>Cancel</button>}
                        {onDelete &&
                        <button
                            className={styles.deleteButton}
                            onClick={this.onSubmit}
                            disabled={!selectedRMId}
                        >
                            Delete
                        </button>}
                        {onBlock &&
                        <button className={styles.blockButton} onClick={this.onSubmit} disabled={!selectedRMId}>
                            Block
                        </button>}
                    </div>
                </div>
            </div>
        );
    }
}
