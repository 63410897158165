export const isNullOrUndef = o => (o === null) || (typeof o === 'undefined');

export const same = (o1, o2) => (o1 === o2) || (isNullOrUndef(o1) && isNullOrUndef(o2));

export const nonEmpty = (o) => {
    if (!o && o !== false) return false;
    if (o.length && o.length === 0) return false;
    if (typeof o === 'object' && Object.keys(o).length === 0) return false;
    return true;
};
