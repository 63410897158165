import React, {Component} from 'react';
import PropTypes from 'prop-types';
import * as pathnames from 'config/pathnames';

import {connect} from 'store';
import {redirect, refresh} from 'store/navigate';

import {updateRM} from 'store/rms/actions';

import {fetchProfile} from 'store/auth/actions';
import {getProfile} from 'store/auth';

import {profileShape, editProfile} from 'store/data/profile';

import Spinner from 'components/loading-spinner/loading-spinner';
import ErrorPanel, {SuccessPanel} from 'components/panel/panel';

import RMEditForm from '../rms/rm-edit-form';

class ProfilePage extends Component {
    static propTypes = {
        profile: profileShape,
        redirect: PropTypes.func.isRequired,
        updateRM: PropTypes.func.isRequired,
        fetchProfile: PropTypes.func.isRequired,
        refresh: PropTypes.func.isRequired,
        location: PropTypes.shape({state: PropTypes.object}),
    };

    onEdit = data => this.props.updateRM({id: this.props.profile.info.id, ...data});

    onCancel = () => this.props.redirect(pathnames.CLIENTS);

    onSuccess = () => this.props.fetchProfile()
        .then(() => this.props.redirect({
            pathname: pathnames.PROFILE,
            state: {updated: true},
        }));

    getRmName = rm => `${rm.firstName} ${rm.lastName}`;

    clearNotification = () => this.props.refresh(this.props.location, {});

    render() {
        const {profile, location} = this.props;

        if (!profile) {
            return <Spinner/>;
        }

        const blocked = profile.info.status === 'blocked';

        return (
            <div className="prv-profile">
                {location && location.state && location.state.updated &&
                <SuccessPanel
                    className="prv-profile__success-panel"
                    error={{message: 'Profile has been successfully updated'}}
                    onClear={this.clearNotification}
                />}
                {blocked &&
                <ErrorPanel
                    className="prv-profile__success-panel"
                    error={{message: 'Your account is blocked. For unblock account contact with superadmin'}}
                />
                }
                <RMEditForm
                    form="edit-rm"
                    initialValues={editProfile(profile.info)}
                    title={['Profile']}
                    actionTitle="Save Changes"
                    onSubmit={this.onEdit}
                    onCancel={!blocked ? this.onCancel : null}
                    onSubmitSuccess={this.onSuccess}
                    blocked={blocked}
                    disabled={blocked}
                />
            </div>
        );
    }
}

export default connect(
    {
        profile: getProfile,
    },
    {
        updateRM,
        redirect,
        fetchProfile,
        refresh,
    },
)(ProfilePage);
