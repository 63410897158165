import {PortfolioNode} from '../types';
import {StructureState} from '../reducer';

const enablePortfolioRenamingReducer = (
    state: StructureState,
    {payload}: {payload: {portfolio: PortfolioNode, deleteOnCancel?: boolean}},
): StructureState => ({
    ...state,
    portfolioBeingRenamedId: payload.portfolio.id,
    deleteOnCancelRenaming: !!payload.deleteOnCancel,
});

export default enablePortfolioRenamingReducer;
