import {createApiCallAction, createAction} from '../helpers/actions';

export const fetchAccounts = createApiCallAction('accounts/FETCH', 'fetchAccounts');
export const createAccount = createApiCallAction('accounts/CREATE', 'createAccount');
export const deleteAccount = createApiCallAction('accounts/DELETE', 'deleteAccount');

export const fetchBanks = createApiCallAction('accounts/FETCH_BANKS', 'fetchBanksForClient');
export const fetchBankAccounts = createApiCallAction('accounts/FETCH_BANK_ACCOUNTS', 'fetchAccountsForBank');
export const fetchAssetsManagers = createApiCallAction('accounts/FETCH_ASSETS_MANAGERS', 'fetchAssetsManagers');
export const fetchCustomerPortfolios = createApiCallAction('accounts/FETCH_PORTFOLIOS', 'fetchCustomerPortfolios');
export const importAccounts = createApiCallAction('accounts/IMPORT_ACCOUNTS', 'importAccounts');
export const updateAccount = createApiCallAction('accounts/UPDATE_ACCOUNT', 'updateAccount');

export const clearAddAccountError = createAction('accounts/CLEAR_ADD_ACCOUNT_ERROR');
export const clearError = createAction('accounts/CLEAR_ERROR');
export const clearUpdateAccountError = createAction('accounts/CLEAR_UPDATE_ACCOUNT_ERROR');
export const resetState = createAction('accounts/RESET_STATE');

export const recalculateAccount = createApiCallAction('accounts/recalculate', 'recalculateAccount');
