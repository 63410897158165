import React from 'react';
import Tooltipped, {TooltippedProps} from 'react-md/lib/Tooltips/Tooltipped';
import Switch, {SwitchProps} from 'react-md/lib/SelectionControls/Switch';
import TextField, {TextFieldProps} from 'react-md/lib/TextFields/TextField';
import {IdPropType} from 'react-md/lib';

interface Props extends TextFieldProps {
    switchContainerClassName?: string,
    switchProps?: Omit<SwitchProps, 'id' | 'name'> & {
        id?: IdPropType,
        name?: number | string,
        tooltipProps?: Omit<TooltippedProps, 'children'>,
    },
}

const TextFieldWithSwitch: React.FC<Props> = ({
    id,
    switchContainerClassName,
    switchProps,
    ...restProps
}) => {
    const {tooltipProps, ...restSwitchProps} = switchProps ?? {};
    return (
        <TextField
            id={id}
            {...restProps}
            inlineIndicator={(
                <div className={switchContainerClassName}>
                    <Tooltipped setPosition {...tooltipProps}>
                        <div>
                            <Switch
                                {...restSwitchProps}
                                id={restSwitchProps?.id || `${id}-switch`}
                                name={restSwitchProps?.name || `${restSwitchProps?.id || id}-name`}
                            />
                        </div>
                    </Tooltipped>
                </div>
            )}
        />
    );
};

export default TextFieldWithSwitch;
