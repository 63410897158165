import {getPath} from 'store/helpers/property';
import * as validations from './validations';

export const syncValidate = (values, props, config) => {
    const errors = {};
    const fields = Object.keys(config).map(name => ({name, config: config[name]}));

    fields.forEach((field) => {
        Object.keys(field.config).forEach((type) => {
            const validationFn = validations[type];
            const validationConfig = field.config[type];
            const value = getPath(field.name.split('.'))(values) || '';
            const hasError = validationFn(field.name, value, validationConfig, values, props);

            if (hasError) {
                errors[field.name] = {[type]: field.config[type]};
                return true;
            }

            return false;
        });
    });
    return errors;
};

export default config => ({
    validate: (values, props) => syncValidate(values, props, config),
});
