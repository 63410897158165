import moment from 'moment';
import {Action, ResultAction} from '../../helpers/types';
import {AnyAssetList, CustodyAccount, StructureArray, StructureItem} from '../types';
import {apiActionHandler} from '../../helpers/actions';
import {
    createAccountGroups,
    getRootItem,
    parseObsoleteAssets,
    parseObsoleteCustodyAccounts,
    parseStructure,
} from '../helpers/structure';
import {StructureState} from '../reducer';
import {sortStructure} from '../helpers/sort';

interface FetchPayload {
    obsolete: {
        items: StructureItem<string>[],
        custodyAccounts: CustodyAccount[],
    },
    vacant: {
        items: AnyAssetList,
        custodyAccounts: CustodyAccount[],
    },
    structure: {
        date: string,
        structure: StructureItem<string>[],
    },
}

const fetchStructureReducer = (
    state: StructureState,
    action: Action<string> | ResultAction<FetchPayload | Error>,
) => apiActionHandler(
    state,
    action,
    {
        resultHandler: (payload: FetchPayload, resultField: string, state: StructureState): StructureState => {
            const tree = sortStructure(parseStructure(payload.structure.structure));
            const accountGroups = createAccountGroups(payload.vacant.items, payload.vacant.custodyAccounts);

            const rootItem = getRootItem(tree as StructureArray);
            if (!rootItem) {
                const message = 'Invalid structure returned: no root node';
                console.error(message, tree); // eslint-disable-line no-console
                return {...state, loading: false, error: new Error(message)};
            }

            let startDate = moment.utc(payload.structure.date);
            if (!startDate.isValid()) {
                startDate = moment.utc().startOf('day');
            }

            const expandedPortfolioIds = new Set([rootItem.id]);

            // TODO: fix showing unlinked custody accounts in accountGroups
            // This is needed because currently API doesn't return a custody account in custodyAccounts
            // if all its assets are present in a tree. May be this is a bug, to be discussed.

            return {
                ...state,
                accountGroups,
                error: undefined,
                expandedPortfolioIds,
                loading: false,
                obsoleteAssets: parseObsoleteAssets(payload.obsolete.items),
                obsoleteCustodyAccounts: parseObsoleteCustodyAccounts(payload.obsolete.custodyAccounts),
                originalStartDate: startDate.toDate(),
                originalTree: [...tree] as StructureArray,
                selectedPortfolio: undefined,
                startDate: startDate.toDate(),
                tree: tree as StructureArray,
            };
        },
    },
);

export default fetchStructureReducer;
