import invariant from 'invariant';
import {CustodyAccount, PortfolioNode} from '../types';
import {StructureState} from '../reducer';
import {findCustodyAccountPortfolioIndex} from '../helpers/structure';

const linkAccountReducer = (state: StructureState, {payload: account}: {payload: CustodyAccount}) => {
    const {selectedPortfolio, tree} = state;

    if (!selectedPortfolio) {
        return state;
    }

    // adding given account to a selected portfolio
    const selectedPortfolioIndex = tree.findIndex(node => node.id === selectedPortfolio.id);
    invariant(
        selectedPortfolioIndex >= 0,
        `Can't link account, selected node (${JSON.stringify(selectedPortfolio)}) not found: ${JSON.stringify(tree)}`,
    );
    const updatedPortfolio = {...selectedPortfolio, custodyAccounts: [...selectedPortfolio.custodyAccounts, account]};
    const updatedTree = [
        ...tree.slice(0, selectedPortfolioIndex),
        updatedPortfolio,
        ...tree.slice(selectedPortfolioIndex + 1),
    ];

    // check if this account was linked to another portfolio before and unlink it then
    const oldPortfolioIndex = findCustodyAccountPortfolioIndex(tree, account.id);
    if (oldPortfolioIndex >= 0) {
        const oldPortfolio = updatedTree[oldPortfolioIndex] as PortfolioNode;
        updatedTree[oldPortfolioIndex] = {
            ...oldPortfolio,
            custodyAccounts: oldPortfolio.custodyAccounts.filter(({id}) => id !== account.id),
        };
    }

    // also update groups
    return {
        ...state,
        tree: updatedTree,
        selectedPortfolio: updatedPortfolio,
        accountGroups: state.accountGroups
            // remove account from groups if it had no assets
            .filter(group => group.id !== account.id || group.assets.length)
            // otherwise just mark it as linked
            .map(group => (group.id === account.id ? {...group, isLinked: true} : group)),
    };
};

export default linkAccountReducer;
