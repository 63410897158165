import {handleActions, apiActionHandler} from 'store/helpers/actions';
import {actionHandlers} from 'store/collections/reducer_helpers';

import {readAggregatedPortfolio} from 'store/data/client/portfolio';

import {
    updatePortfolio,
    clearUpdatePortfolioError,
} from './actions';

const initialState = {
    updatePortfolioLoading: false,
    updatePortfolioError: null,
};

export {
    getCollectionState,
    getFetchParams,
} from 'store/collections/reducer_helpers';

export const isUpdatePortfolioLoading = ({updatePortfolioLoading}) => updatePortfolioLoading;
export const getUpdatePortfolioError = ({updatePortfolioError}) => updatePortfolioError;

export default handleActions(
    {
        ...actionHandlers('clientPortfolios', readAggregatedPortfolio),
        [updatePortfolio]: (state, action) => apiActionHandler(
            state,
            action,
            {loading: 'updatePortfolioLoading', error: 'updatePortfolioError'},
        ),
        [clearUpdatePortfolioError]: state => ({...state, updatePortfolioError: null}),
    },
    initialState,
);
