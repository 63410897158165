import castArray from 'lodash/castArray';
import {StructureItem} from '../types';
import {StructureState} from '../reducer';

const expandPortfolioReducer = (
    state: StructureState,
    {payload: {node, expanded}}: {payload: {node: StructureItem | StructureItem[], expanded: boolean}},
) => {
    const expandedPortfolioIds = new Set(state.expandedPortfolioIds);
    castArray(node).forEach(({id}: StructureItem) => {
        expanded ? expandedPortfolioIds.add(id) : expandedPortfolioIds.delete(id);
    });
    return {...state, expandedPortfolioIds};
};

export default expandPortfolioReducer;
