import buildActions from '../collections/actions_builder';
import {createApiCallAction, createAction} from '../helpers/actions';

const collection = buildActions('clientPortfolios');

export const fetchPortfolios = collection.fetchItems;
export const changePerPage = collection.changePerPage;
export const clearPortfolios = collection.clearCollection;

export const updatePortfolio = createApiCallAction('clientPortfolios/UPDATE_PORTFOLIO', 'updatePortfolio');
export const deletePortfolio = createApiCallAction('clientPortfolios/DELETE_PORTFOLIO', 'deletePortfolio');

export const clearUpdatePortfolioError = createAction('clientPortfolios/CLEAR_UPDATE_PORTFOLIO_ERROR');

export const recalculatePortfolio = createApiCallAction('clientPortfolios/recalculate', 'recalculatePortfolio');

export const updateInvoices = createApiCallAction('client/UPDATE_PORTFOLIO', 'updateInvoices');
export const getInvoices = createApiCallAction('client/GET_PORTFOLIO', 'getInvoices');
