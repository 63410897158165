import POLICIES from '../config/policies';

export const ENV = {
    dev: 'dev',
    test: 'test',
    staging: 'staging',
    prod: 'production',
};

let servName = 'proveodev';
if (process.env.NODE_ENV === ENV.staging) {
    servName = 'proveostaging';
} else if (process.env.NODE_ENV === ENV.prod) {
    servName = 'proveoprod';
} else if (process.env.NODE_ENV === ENV.test) {
    servName = 'proveotest';
}

const host = `https://${servName}.b2clogin.com/${servName}.onmicrosoft.com/oauth2/v2.0/authorize`;

let clientId = '48f69e72-0bba-4dbe-bde4-b3821d6eb57e';
if (process.env.NODE_ENV === ENV.staging) {
    clientId = '6b3e54bd-657e-46fa-bac7-0ae666927dd2';
} else if (process.env.NODE_ENV === ENV.prod) {
    clientId = '16fe24b9-b271-43a8-bf1a-f8e1a8fc5bfa';
} else if (process.env.NODE_ENV === ENV.test) {
    clientId = '5efc2fdd-0b71-4975-bbb6-5724de630170';
}

const commonParams = {
    clientId,
    scope: 'openid',
    nonce: 'defaultNonce',
    redirectUri: `${window.location.origin}/`,
    responseType: 'id_token',
};

const resetPasswdParams = {
    policy: POLICIES.LOGIN.PROVEO,
    prompt: 'login',
    ...commonParams,
};

const signInParams = {
    policy: POLICIES.LOGIN.PROVEO,
    prompt: 'login',
    ...commonParams,
};

const signUpParams = {
    ...commonParams,
    policy: POLICIES.SIGNUP.PROVEO,
    prompt: 'login',
};

const queryParamsMap = {
    policy: 'p',
    clientId: 'client_id',
    nonce: 'nonce',
    redirectUri: 'redirect_uri',
    scope: 'scope',
    responseType: 'response_type',
    prompt: 'prompt',
    state: 'state',
};

// TODO: use qs library
const mapQueryParams = conf => host + '?' +
    Object.keys(conf).map(cur => `${queryParamsMap[cur]}=${encodeURIComponent(conf[cur])}`).join('&');

export const getLoginUrl = (params = {}) => mapQueryParams({...signInParams, ...params});

export const getSignUpUrl = (params = {}) => mapQueryParams({...signUpParams, ...params});

export const getResetPasswdUrl = (params = {}) => mapQueryParams({...resetPasswdParams, ...params});
