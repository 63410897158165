import invariant from 'invariant';
import {PortfolioNode} from '../types';
import {StructureState} from '../reducer';

const renamePortfolioReducer = (state: StructureState, {payload: node}: {payload: PortfolioNode}) => {
    const {tree} = state;
    const nodeIndex = tree.findIndex(({id}) => id === node.id);
    invariant(nodeIndex >= 0, `Node to update is not found in the tree: ${JSON.stringify(node)}`);
    const selectedPortfolio = state.selectedPortfolio && state.selectedPortfolio.id === node.id
        ? node
        : state.selectedPortfolio;
    return {
        ...state,
        selectedPortfolio,
        tree: [...tree.slice(0, nodeIndex), node, ...tree.slice(nodeIndex + 1)],
    };
};

export default renamePortfolioReducer;
