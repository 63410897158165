import typeReader from 'store/helpers/type_reader';
import typeShape, {required, optional} from 'store/helpers/type_shape';

const AccountType = {
    id: required(String),
    bankUserId: required(String),
    customerId: required(String),
    cashAccountId: optional(String),
    assetsManagerId: required(String),
    assetsManagerName: required(String),
    portfolioId: optional(String),
    portfolioName: optional(String),
    proveoId: optional(String),
    bank: required(String),
    name: required(String),
    type: required(String),
    originalId: optional(String),
    importStatus: required(String),
    currency: optional(String),
    value: required(Number),
    valueChange: required(Number, 'totalValueChange'),
    totalValueChange: required(Number),
    totalValueChangeForDay: required(Number),
    totalValueChangeForMonth: required(Number),
    totalValueChangeForYear: required(Number),
    bondsRiskLevel: optional(Number),
    cashRiskLevel: optional(Number),
    equityRiskLevel: optional(Number),
    totalRiskLevel: optional(Number),
    endDate: optional(String),
    benchmarks: [{
        id: required(String),
        name: required(String),
        weight: required(Number),
        startDate: required(String),
    }],
    limits: [{
        id: required(String, 'typeId'),
        name: required(String),
        value: required(Number),
    }],
    lastSync: optional(String),
};

const AccountsProfitType = {
    totalValue: required(Number, 'value'),
    totalValueChange: required(Number),
    totalValueChangeForYear: required(Number),
    totalValueChangeForMonth: required(Number),
    totalValueChangeForDay: required(Number),
};

export const readAccount = typeReader(AccountType);
export const accountShape = typeShape(AccountType);

export const readAccountsProfit = typeReader(AccountsProfitType);
export const accountsProfitShape = typeShape(AccountsProfitType);

export const accountTypes = {
    cash: 'Cash Account',
    deposit: 'Custody Account',
    unknown: 'Unknown',
};
