import React from 'react';
import cn from 'classnames';
import {money} from '../../../helpers/formatters';
import AccountProfit from '../../../store/types/AccountProfit';
import Client from '../../../store/types/Client';

interface Props {
    accountsProfit: AccountProfit,
    client: Client,
}

const ValueChanges: React.FC<Props> = ({accountsProfit, client}) => (
    <div className="client-information-square client-information-changes">
        <div className="client-information-graph-header">
            Client&apos;s Value Changes{client.baseCurrency ? ` (${client.baseCurrency})` : ''}
        </div>
        <div className="client-information-graph-content">
            <div className="client-information-value-changes">
                <div className="client-information-value-changes-header">
                    Value Change Daily
                </div>
                {accountsProfit &&
                <div
                    className={cn(
                        'client-information-value-changes-total',
                        parseFloat('' + accountsProfit.totalValueChangeForDay) >= 0
                            ? 'client-information-value-changes-total--green'
                            : 'client-information-value-changes-total--red',
                    )}
                >
                    {money(accountsProfit.totalValueChangeForDay)}
                </div>
                }
            </div>
            <div className="client-information-value-changes">
                <div className="client-information-value-changes-header">
                    Value Change MtD
                </div>
                {accountsProfit &&
                <div
                    className={cn(
                        'client-information-value-changes-total',
                        parseFloat('' + accountsProfit.totalValueChangeForMonth) >= 0
                            ? 'client-information-value-changes-total--green'
                            : 'client-information-value-changes-total--red',
                    )}
                >
                    {money(accountsProfit.totalValueChangeForMonth)}
                </div>
                }
            </div>
            <div className="client-information-value-changes">
                <div className="client-information-value-changes-header">
                    Value Change YtD
                </div>
                {accountsProfit &&
                <div
                    className={cn(
                        'client-information-value-changes-total',
                        parseFloat('' + accountsProfit.totalValueChangeForYear) >= 0
                            ? 'client-information-value-changes-total--green'
                            : 'client-information-value-changes-total--red',
                    )}
                >
                    {money(accountsProfit.totalValueChangeForYear)}
                </div>
                }
            </div>
        </div>
    </div>
);

export default ValueChanges;
