import {handleActions, apiActionHandler} from 'store/helpers/actions';
import {actionHandlers} from 'store/collections/reducer_helpers';

import {readRM} from 'store/data/rm';
import {readClient} from 'store/data/client';

import {fetchClients, clearError} from './actions';

export {
    getCollectionState,
    getPinnedItem as getPinnedRM,
    getPinnedError,
    getFetchParams,
} from 'store/collections/reducer_helpers';

const initialState = {
    formItemsLoading: false,
    formItems: null,
    formItemsError: null,
};

export const getFormItems = ({formItems}) => formItems;
export const getFormItemsLoading = ({formItemsLoading}) => formItemsLoading;
export const getFormItemsError = ({formItemsError}) => formItemsError;

export default handleActions(
    {
        ...actionHandlers('rms', readRM),
        [fetchClients]: (state, action) => apiActionHandler(
            state,
            action,
            {
                loading: 'formItemsLoading',
                result: 'formItems',
                error: 'formItemsError',
                reader: p => p.items.map(readClient),
            },
        ),
        [clearError]: state => ({...state, formItemsError: null}),
    },
    initialState,
);
