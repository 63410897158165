import bindSagas from './helpers/bindSagas';

import clients from './clients/saga';
import rms from './rms/saga';
import auth from './auth/saga';
import clientTransactions from './client-transactions/saga';
import assets from './assets/saga';
import clientAssets from './client-assets/saga';
import clientsAMs from './clients-assets-managers/saga';
import clientPortfolios from './client-portfolios/saga';
import benchmarks from './benchmarks/saga';
import clientBenchmarks from './client-benchmarks/saga';
import notifications from './notifications/saga';
import structure from './structure/saga';
import structureVersion from './structure-version/saga';
import transactions from './transactions/saga';
import onboarding from './onboarding/saga';

export default bindSagas({
    auth,
    clients,
    rms,
    clientTransactions,
    assets,
    clientAssets,
    clientsAMs,
    clientPortfolios,
    benchmarks,
    clientBenchmarks,
    notifications,
    structure,
    structureVersion,
    transactions,
    onboarding,
});
