import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'store';
import {collectionShape} from 'store/collections';
import {getCollectionState} from 'store/benchmarks';
import {benchmarkTypes} from 'store/data/benchmark';
import CollectionListPage from 'components/collections/list-page';

const renderType = value => benchmarkTypes[value];

const columns = [
    {field: 'name', label: 'Name'},
    {field: 'type', label: 'Type', width: '94px', render: renderType},
    {field: 'currency', label: 'Currency', width: '60px'},
    {field: 'morningstarId', label: 'MStarID', width: '15%'},
    {field: 'morningstarPerformanceId', label: 'MStarPerformanceID', width: '15%'},
    {field: 'isin', label: 'ISIN', width: '15%'},
];

@withRouter
@connect({collection: getCollectionState})
export default class BenchmarksPage extends Component {
    static propTypes = {
        collection: collectionShape,
    };

    getFiltersConfig = () => ({
        type: {
            title: 'Type',
            options: benchmarkTypes,
        },
    });

    render() {
        const {collection} = this.props;

        return (
            <div>
                <CollectionListPage
                    name="benchmarks"
                    title="MS API"
                    initialState={{perPage: 25}}
                    filters={this.getFiltersConfig()}
                    collection={collection}
                    columns={columns}
                />
            </div>
        );
    }
}
