import _ from 'lodash';
import {apiActionHandler, handleActions} from '../helpers/actions';
import {actionHandlers} from '../collections/reducer_helpers';
import {readRM} from '../data/rm';
import {
    readAccountsProfit,
    readAssets,
    readAssetsManager,
    readBank,
    readClient,
    readClientBenchmark,
    readSharedBenchmark,
} from '../data/client';
import {readLimit} from '../data/general-data';
import {
    clearBenchmarksUpdateError,
    clearError,
    clearLimitsUpdateError,
    fetchAccountsProfit,
    fetchActiveRMs,
    fetchAllocatedAssets,
    fetchAllocationClasses,
    fetchAssets,
    fetchClientBanks,
    fetchClientBenchmarks,
    fetchClientsAms,
    fetchLimits,
    fetchReportingBenchmarks,
    fetchSharedBenchmarks,
    updateClient,
    updateClientBenchmarks,
    updateLimits,
} from './actions';

export {
    getCollectionState,
    getPinnedItem as getPinnedClient,
    getPinnedError,
    getFetchParams,
} from '../collections/reducer_helpers';
const initialState = {
    activeRMsLoading: false,
    activeRMs: [],
    assetsLoading: false,
    assets: null,
    accountsProfileLoading: false,
    accountsProfit: null,
    clientsAmsLoading: false,
    clientsAms: [],
    limitsLoading: false,
    limitsUpdating: false,
    limitsUpdateError: null,
    limits: [],
    clientBanks: [],
    clientBenchmarks: [],
    sharedBenchmarks: [],
    clientBanksLoading: false,
    clientBenchmarksLoading: false,
    sharedBenchmarksLoading: false,
    benchmarksUpdating: false,
    benchmarksUpdateError: null,
    error: null,
    allocatedAssetsLoading: false,
    allocatedAssets: [],
    clientReportingBenchmarksLoading: false,
    clientReportingBenchmarks: [],
};

export const isLoading = ({activeRMsLoading, assetsLoading, accountsProfileLoading}) => (
    activeRMsLoading || assetsLoading || accountsProfileLoading
);
export const getError = ({error}) => error;

export const getActiveRMsLoading = ({activeRMsLoading}) => activeRMsLoading;
export const getActiveRMs = ({activeRMs}) => activeRMs;

export const isAssetsLoading = ({assetsLoading}) => assetsLoading;
export const getAssets = ({assets}) => assets;

export const isAccountsProfitLoading = ({accountsProfileLoading}) => accountsProfileLoading;
export const getAccountsProfit = ({accountsProfit}) => accountsProfit;

export const isClientsAmsLoading = ({clientsAmsLoading}) => clientsAmsLoading;
export const getClientsAms = ({clientsAms}) => clientsAms;

export const getLimits = ({limits}) => limits;
export const isLimitsLoading = ({limitsLoading}) => limitsLoading;
export const isLimitsUpdating = ({limitsUpdating}) => limitsUpdating;
export const getLimitsUpdateError = ({limitsUpdateError}) => limitsUpdateError;

export const getClientBanks = ({clientBanks}) => clientBanks;
export const isBanksLoading = ({clientBanksLoading}) => clientBanksLoading;

export const getClientBenchmarks = ({clientBenchmarks}) => clientBenchmarks;
export const getSharedBenchmarks = ({sharedBenchmarks}) => sharedBenchmarks;
export const isBenchmarksLoading = ({clientBenchmarksLoading}) => clientBenchmarksLoading;
export const isSharedBenchmarkListLoading = ({sharedBenchmarksLoading}) => sharedBenchmarksLoading;
export const isBenchmarksUpdating = ({benchmarksUpdating}) => benchmarksUpdating;
export const getBenchmarksUpdateError = ({benchmarksUpdateError}) => benchmarksUpdateError;

export const getClientReportingBenchmarksLoading = ({clientReportingBenchmarksLoading}) =>
    clientReportingBenchmarksLoading;
export const getClientReportingBenchmarks = ({clientReportingBenchmarks}) => clientReportingBenchmarks;

export const getAllocatedAssets = ({allocatedAssets}) => allocatedAssets;
export const getAllocationClasses = ({allocationClasses}) => allocationClasses;

export default handleActions(
    {
        ...actionHandlers('clients', readClient),
        [updateClient]: (state, action) => apiActionHandler(
            state,
            action,
            {
                resultHandler: (result, _, state) => ({
                    ...state,
                    collection: {
                        ...state.collection,
                        pinnedItem: result,
                    },
                }),
            },
        ),
        [fetchActiveRMs]: (state, action) => apiActionHandler(
            state,
            action,
            {loading: 'activeRMsLoading', result: 'activeRMs', reader: p => p.items.map(readRM)},
        ),
        [fetchAssets]: (state, action) => apiActionHandler(
            state,
            action,
            {loading: 'assetsLoading', result: 'assets', reader: p => (p ? readAssets(p) : null)},
        ),
        [fetchAccountsProfit]: (state, action) => apiActionHandler(
            state,
            action,
            {loading: 'accountsProfileLoading', result: 'accountsProfit', reader: readAccountsProfit},
        ),
        [clearError]: state => ({...state, error: null}),
        [fetchClientsAms]: (state, action) => apiActionHandler(
            state,
            action,
            {loading: 'clientsAmsLoading', result: 'clientsAms', reader: p => p.items.map(readAssetsManager)},
        ),
        [fetchLimits]: (state, action) => apiActionHandler(
            state,
            action,
            {loading: 'limitsLoading', result: 'limits', reader: p => _.map(p, readLimit)},
        ),
        [updateLimits]: (state, action) => apiActionHandler(
            state,
            action,
            {loading: 'limitsUpdating', error: 'limitsUpdateError'},
        ),
        [fetchClientBanks]: (state, action) => apiActionHandler(
            state,
            action,
            {
                loading: 'clientBanksLoading',
                result: 'clientBanks',
                reader: p => _.map(p, readBank),
            },
        ),
        [fetchClientBenchmarks]: (state, action) => apiActionHandler(
            state,
            action,
            {
                loading: 'clientBenchmarksLoading',
                result: 'clientBenchmarks',
                reader: p => _.map(p, readClientBenchmark),
            },
        ),
        [fetchReportingBenchmarks]: (state, action) => apiActionHandler(
            state,
            action,
            {
                loading: 'clientReportingBenchmarksLoading',
                result: 'clientReportingBenchmarks',
                reader: p => _.map(p, readClientBenchmark),
            },
        ),
        [fetchSharedBenchmarks]: (state, action) => apiActionHandler(
            state,
            action,
            {
                loading: 'sharedBenchmarksLoading',
                result: 'sharedBenchmarks',
                reader: p => _.map(p.items, readSharedBenchmark),
            },
        ),
        [updateClientBenchmarks]: (state, action) => apiActionHandler(
            state,
            action,
            {
                loading: 'benchmarksUpdating',
                error: 'benchmarksUpdateError',
            },
        ),
        [clearLimitsUpdateError]: state => ({...state, limitsUpdateError: null}),
        [clearBenchmarksUpdateError]: state => ({...state, benchmarksUpdateError: null}),
        [fetchAllocatedAssets]: (state, action) => apiActionHandler(
            state,
            action,
            {
                loading: 'allocatedAssetsLoading',
                result: 'allocatedAssets',
                reader: result => result.items.map(item => item.assetAllocationRelation),
            },
        ),
        [fetchAllocationClasses]: (state, action) => apiActionHandler(
            state,
            action,
            {
                result: 'allocationClasses',
                reader: result => result,
            },
        ),
    },
    initialState,
);
