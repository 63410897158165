import React, {Component} from 'react';
import PropTypes from 'prop-types';
import qs from 'query-string';

import {connect} from 'store';
import {navigate} from 'store/navigate';

import Spinner from 'components/loading-spinner/loading-spinner';

import {login, signup} from 'store/auth/actions';
import {haveToken} from 'store/api';

@connect({haveToken}, {login, signup, navigate})
export default class LoginPage extends Component {
    static propTypes = {
        haveToken: PropTypes.bool,
        login: PropTypes.func.isRequired,
        signup: PropTypes.func.isRequired,
        navigate: PropTypes.func.isRequired,
        location: PropTypes.object,
    };

    componentWillMount() {
        this.checkAuth();
    }

    checkAuth = () => {
        const {proveo_invite_token: token} = qs.parse(this.props.location.hash);
        if (token) {
            return this.props.signup(token);
        }
        if (this.props.haveToken) {
            return this.props.navigate('/');
        }
        return this.props.login();
    };

    render() {
        return (
            <Spinner/>
        );
    }
}
