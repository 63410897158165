import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import _ from 'lodash';
import ApiError from 'store/api/error';

import styles from './panel.module.css';

const defaultMessage = 'Unknown error';

export default class ErrorPanel extends Component {
    static propTypes = {
        className: PropTypes.string,
        error: PropTypes.shape({
            message: PropTypes.string,
        }),
        timeout: PropTypes.number,
        onClear: PropTypes.func,
    };

    componentDidMount() {
        const {timeout, onClear} = this.props;
        if (timeout > 0 && onClear) {
            this.timer = setTimeout(() => {
                this.props.onClear();
            }, timeout);
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    getMessage = (error) => {
        let message;
        if (error instanceof ApiError) {
            const errors = error.errors.length ? error.errors : error.validationErrors;
            message = _.map(errors, err => <span>{err.message}</span>);
        } else if (error.message) {
            message = error.message;
        }
        return (!message || !message.length) ? defaultMessage : message;
    };

    render() {
        const {className, error, onClear} = this.props;
        if (!error) return null;
        const message = this.getMessage(error);
        return (
            <div
                className={cn(styles.panel, className)}
                onClick={onClear}
            >
                <p className={styles.message}>{message}</p>
            </div>
        );
    }
}

export const SuccessPanel = ({className, ...props}) => (
    <ErrorPanel className={cn(styles.successPanel, className)} {...props} />
);

SuccessPanel.propTypes = {
    className: PropTypes.string,
};
