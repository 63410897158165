import {setBoersenId, setCallbackUri, setLastAddedBank, setOnboardingFlow} from './actions';

function setBoersenIdFn({payload}) {
    if (window.sessionStorage) {
        if (payload) {
            window.sessionStorage.setItem('boersen_id', payload);
        } else {
            window.sessionStorage.removeItem('boersen_id');
        }
    }
}

function setCallbackUriFn({payload}) {
    if (window.sessionStorage) {
        if (payload) {
            window.sessionStorage.setItem('callback_uri', payload);
        } else {
            window.sessionStorage.removeItem('callback_uri');
        }
    }
}

function setLastAddedBankFn({payload}) {
    if (window.sessionStorage) {
        if (payload) {
            window.sessionStorage.setItem('last_added_bank', payload);
        } else {
            window.sessionStorage.removeItem('last_added_bank');
        }
    }
}

function setOnboardingFlowFn({payload}) {
    if (window.sessionStorage) {
        if (payload) {
            window.sessionStorage.setItem('onboarding_flow', payload);
        } else {
            window.sessionStorage.removeItem('onboarding_flow');
        }
    }
}

export default {
    [setBoersenId]: setBoersenIdFn,
    [setCallbackUri]: setCallbackUriFn,
    [setLastAddedBank]: setLastAddedBankFn,
    [setOnboardingFlow]: setOnboardingFlowFn,
};
