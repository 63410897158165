import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {
    ASSETS,
    CLIENT_BENCHMARKS,
    CLIENTS,
    LOGIN,
    MS_API,
    NEW_CLIENT,
    NOTIFICATIONS,
    PROFILE,
    RMS,
    ROOT,
    TRANSACTIONS,
} from '../config/pathnames';
import RoleRequired from '../scenes/auth/role-required';
import * as roles from '../config/roles';
import NewClientPage from '../scenes/new-client/new-client-page';
import NewClientEditPage from '../scenes/new-client/new-client-edit-page';
import ProfilePage from '../scenes/profile/profile-page';
import ClientsListPage from '../scenes/clients/clients-list-page';
import ClientCreatePage from '../scenes/clients/client-create-page';
import ClientPinner from '../scenes/clients/client-pinner';
import ClientProfileLayout from '../scenes/clients/client-profile-layout';
import InformationPage from '../scenes/clients/information/information-page';
import ClientAccountsPage from '../scenes/clients/account/client-account';
import ClientPortfoliosPage from '../scenes/clients/portfolios/portfolios-page';
import ClientTransactionsPage from '../scenes/clients/transactions/transactions-list-page';
import ClientAssetsPage from '../scenes/clients/assets/assets-list-page';
import ClientPerformancePage from '../scenes/clients/performance/performance-page';
import ClientRiskPage from '../scenes/clients/risk/risk-page';
import ClientStructurePage from '../scenes/clients/structure/StructurePage';
import RMsListPage from '../scenes/rms/rms-list-page';
import RMCreatePage from '../scenes/rms/rm-create-page';
import RMPinner from '../scenes/rms/rm-pinner';
import RMEditPage from '../scenes/rms/rm-edit-page';
import BenchmarksListPage from '../scenes/benchmarks/benchmarks-list-page';
import ClientBenchmarksListPage from '../scenes/client-benchmarks/client-benchmarks-list-page';
import NotificationsListPage from '../scenes/notifications';
import AssetsListPage from '../scenes/assets/assets-list-page';
import TransactionsListPage from '../scenes/transactions/transactions-list-page';
import ErrorPage from '../scenes/error-page';
import {LoggedLayout, LoggedNewClientLayout} from './logged-layouts';

export default (
    <Switch>
        <Route path={ROOT} exact />
        <Route path={LOGIN} />
        <Route
            path={NEW_CLIENT}
            render={() => (
                <LoggedNewClientLayout>
                    <Switch>
                        <Route path={NEW_CLIENT} exact>
                            <RoleRequired allowedRoles={[roles.RM]}>
                                <NewClientPage />
                            </RoleRequired>
                        </Route>
                        <Route
                            path={`${NEW_CLIENT}/:id/edit`}
                            component={NewClientEditPage}
                        />
                    </Switch>
                </LoggedNewClientLayout>
            )}
        />
        <Route
            path={PROFILE}
            render={() => (
                <LoggedLayout>
                    <ProfilePage />
                </LoggedLayout>
            )}
        />
        <Route
            path={CLIENTS}
            exact
            render={() => (
                <LoggedLayout>
                    <ClientsListPage />
                </LoggedLayout>
            )}
        />
        <Route
            path={`${CLIENTS}/create`}
            render={() => (
                <LoggedLayout>
                    <ClientCreatePage />
                </LoggedLayout>
            )}
        />
        <Route
            path={`${CLIENTS}/:id`}
            render={() => (
                <LoggedLayout>
                    <ClientPinner>
                        <ClientProfileLayout>
                            <Switch>
                                <Route path={`${CLIENTS}/:id/edit`} component={InformationPage}/>
                                <Route path={`${CLIENTS}/:id/accounts`} component={ClientAccountsPage}/>
                                <Route path={`${CLIENTS}/:id/portfolios`} component={ClientPortfoliosPage}/>
                                <Route
                                    path={`${CLIENTS}/:id/transactions`}
                                    component={ClientTransactionsPage}
                                />
                                <Route
                                    path={`${CLIENTS}/:id/allocation`}
                                    component={ClientAssetsPage}
                                />
                                <Route
                                    path={`${CLIENTS}/:id/performance`}
                                    component={ClientPerformancePage}
                                />
                                <Route path={`${CLIENTS}/:id/risk`} component={ClientRiskPage}/>
                                <Route
                                    path={`${CLIENTS}/:id/structure`}
                                    component={ClientStructurePage}
                                />
                            </Switch>
                        </ClientProfileLayout>
                    </ClientPinner>
                </LoggedLayout>
            )}
        />
        <Route
            path={RMS}
            exact
            render={() => (
                <LoggedLayout>
                    <RMsListPage />
                </LoggedLayout>
            )}
        />
        <Route
            path={`${RMS}/create`}
            render={() => (
                <LoggedLayout>
                    <RMCreatePage />
                </LoggedLayout>
            )}
        />
        <Route
            path={`${RMS}/:id`}
            render={() => (
                <LoggedLayout>
                    <RMPinner>
                        <Switch>
                            <Route path={`${RMS}/:id/edit`} component={RMEditPage}/>
                        </Switch>
                    </RMPinner>
                </LoggedLayout>
            )}
        />
        <Route
            path={MS_API}
            render={() => (
                <LoggedLayout>
                    <BenchmarksListPage />
                </LoggedLayout>
            )}
        />
        <Route
            path={CLIENT_BENCHMARKS}
            render={() => (
                <LoggedLayout>
                    <ClientBenchmarksListPage />
                </LoggedLayout>
            )}
        />
        <Route
            path={`${CLIENT_BENCHMARKS}/create`}
            render={() => (
                <LoggedLayout>
                    <ClientBenchmarksListPage />
                </LoggedLayout>
            )}
        />
        <Route
            path={NOTIFICATIONS}
            render={() => (
                <LoggedLayout>
                    <NotificationsListPage />
                </LoggedLayout>
            )}
        />
        <Route
            path={`${NOTIFICATIONS}/create`}
            render={() => (
                <LoggedLayout>
                    <NotificationsListPage />
                </LoggedLayout>
            )}
        />
        <Route
            path={`${NOTIFICATIONS}/create-new`}
            render={() => (
                <LoggedLayout>
                    <NotificationsListPage />
                </LoggedLayout>
            )}
        />
        <Route
            path={ASSETS}
            render={() => (
                <LoggedLayout>
                    <AssetsListPage />
                </LoggedLayout>
            )}
        />
        <Route
            path={TRANSACTIONS}
            render={() => (
                <LoggedLayout>
                    <TransactionsListPage />
                </LoggedLayout>
            )}
        />
        <Route path="*" component={ErrorPage}/>
    </Switch>
);
