import bind from '../helpers/bind';
import reducer, * as getters from './reducer';

const {binder, wrapAll} = bind(reducer);

export default binder;

export const {
    isLoading,
    isAddBankLoading,
    isClientExist,
    isCheckExistenceLoading,
    getCheckExistenceError,
    getClient,
    getBanks,
    getClientBanks,
    isClientBanksLoading,
    getClientBanksError,
    getError,
    getAddBankError,
    getLocalizationTerms,
    getLoginMessage,
    getLoginStatus,
    getLoginSubStatuses,
    getAgreements,
    getAuthMethods,
} = wrapAll(getters);
