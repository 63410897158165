import {PortfolioNode} from '../types';
import addPortfolioReducer from './addPortfolioReducer';
import expandPortfolioReducer from './expandPortfolioReducer';
import {StructureState} from '../reducer';
import {getParent} from '../helpers/structure';

const createPortfolioReducer = (
    state: StructureState,
    {payload}: {payload: PortfolioNode},
) => {
    // add portfolio and ensure parent node is expanded
    const newState = addPortfolioReducer(state, {payload});
    const parentNode = getParent(newState.tree, payload);
    return parentNode ? expandPortfolioReducer(newState, {payload: {node: parentNode, expanded: true}}) : newState;
};

export default createPortfolioReducer;
