import invariant from 'invariant';
import unionBy from 'lodash/unionBy';
import {
    AccountGroup,
    CustodyAccount,
    Level,
    PortfolioNode,
    StructureArray,
    StructureItem, StructureItemId,
    StructureItemState,
    AssetNode,
} from '../types';
import {getParentLevel, incrementLastIndex, isDirectChildren, isSameLevel, levelToString, parseLevel} from './level';
import {isAsset, isCashAccount, isPortfolio, isRootItem} from './assertion';
import {nameFieldComparator} from './sort';

/**
 * Parse structure returned by the backend into internal structure representation
 * @param rawStructure
 */
export const parseStructure = (rawStructure: StructureItem<string>[]): StructureItem[] =>
    rawStructure.map(node => ({...node, level: parseLevel(node.level)}));

/**
 * Parse obsolete assets returned by the backend into internal obsolete assets representation
 * @param assets
 */
export const parseObsoleteAssets = (assets: StructureItem<string>[]): {[key in StructureItemId]: StructureItem} =>
    assets
        .map(asset => ({...asset, level: parseLevel(asset.level)}))
        .reduce((result, asset) => Object.assign(result, {[asset.id]: asset}), {});

/**
 * Parse obsolete custody accounts returned by the backend into internal obsolete custody accounts representation
 * @param accounts
 */
export const parseObsoleteCustodyAccounts =
    (accounts: CustodyAccount[]): {[key in CustodyAccount['id']]: CustodyAccount} => accounts
        .reduce((result, account) => Object.assign(result, {[account.id]: account}), {});

/**
 * Convert internal structure representation to the format backend understands
 * @param structure
 */
export const exportStructure = (structure: StructureItem[]): StructureItem<string>[] =>
    structure.map(node => ({...node, level: levelToString(node.level)}));

/**
 * Finds and returns a root item of the structure. Or undefined when it's not found.
 * @param structure
 */
export const getRootItem = (structure: StructureArray) => structure.find(isRootItem);

/**
 * Returns a direct children nodes of the given item.
 * @param structure
 * @param item
 */
export const getChildrenNodes = (structure: StructureArray, item: StructureItem): StructureArray =>
    structure.filter(node => isDirectChildren(node.level, item.level));

/**
 * Returns all children nodes (including subchilden and deeper) of the given item.
 * @param structure
 * @param item
 */
export const getAllChildrenRecursively = (structure: StructureArray, item: StructureItem): StructureArray => {
    const directChildren = getChildrenNodes(structure, item);
    const subChildrenNodes = directChildren.map(children => getAllChildrenRecursively(structure, children)).flat();
    return [...directChildren, ...subChildrenNodes];
};

/**
 * Returns if given item has any children.
 * @param structure
 * @param item
 */
export const hasChildrenNodes = (structure: StructureArray, item: StructureItem): boolean =>
    structure.some(node => isDirectChildren(node.level, item.level));

/**
 * Returns if given item has any children portfolios.
 * @param structure
 * @param item
 */
export const hasChildrenPortfolios = (structure: StructureArray, item: StructureItem): boolean =>
    structure.some(node => isPortfolio(node) && isDirectChildren(node.level, item.level));


/**
 * Returns parent node for a given node.
 * @param structure
 * @param node
 */
export const getParent = (structure: StructureArray, node: StructureItem): StructureItem | undefined => {
    const parentLevel = getParentLevel(node.level);
    return structure.find(({level}) => isSameLevel(level, parentLevel));
};

/**
 * Finds and returns a node which is parent to the given item. Or undefined if not found.
 * @param structure
 * @param item
 */
export const getParentItem = (structure: StructureArray, item: StructureItem) => {
    const parentLevel = getParentLevel(item.level);
    return structure.find(({level}) => isSameLevel(level, parentLevel));
};

/**
 * Returns an array of parent nodes to the given item. From the top of the tree down to item, not including it.
 * @param structure
 * @param portfolio
 */
export const getParentPortfolios = (structure: StructureArray, portfolio: PortfolioNode): PortfolioNode[] => {
    const parentItem = getParentItem(structure, portfolio);
    return parentItem && isPortfolio(parentItem) ? [...getParentPortfolios(structure, parentItem), parentItem] : [];
};

/**
 * For the given parentNode returns what Level its new children would have
 * @param structure
 * @param parentNode
 */
export const getNextChildrenLevel = (structure: StructureArray, parentNode?: StructureItem): Level => {
    const parent = parentNode ?? getRootItem(structure);
    invariant(
        parent !== undefined,
        `Can't add portfolio, parent is not found: neither selected node nor root node: ${JSON.stringify(structure)}`,
    );
    const children = getChildrenNodes(structure, parent);
    return children.length ? incrementLastIndex(children[children.length - 1].level) : [...parent.level, 1];
};

export const CASH_ACCOUNT_GROUP_TEMPLATE: Omit<AccountGroup, 'assets'> = Object.freeze({
    id: 'Cash Accounts',
    name: 'Cash Accounts',
    isLinked: false,
    isCashAccountGroup: true,
});

/**
 * Groups assets by custody accounts. Also adds a group of Cash Accounts.
 *
 * @param assets
 * @param custodyAccounts
 * @param isPortfolioAssets
 */
export const createAccountGroups = (
    assets: StructureItem[],
    custodyAccounts: CustodyAccount[],
    isPortfolioAssets: boolean = false,
): AccountGroup[] => {
    let result: AccountGroup[] = custodyAccounts
        .map(account => ({...account, assets: [], isLinked: isPortfolioAssets}));
    const idToIndex = new Map(result.map(({id}, index) => [id, index]));

    // add assets into respective accounts. If there's no account in result yet, it means that it has been linked
    // somewhere
    assets.filter(isAsset).forEach(asset => {
        const index = idToIndex.get(asset.accountId);
        if (index !== undefined) {
            result[index].assets.push(asset);
        } else {
            result.push({id: asset.accountId, name: asset.accountName, assets: [asset], isLinked: !isPortfolioAssets});
            idToIndex.set(asset.accountId, result.length - 1);
        }
    });

    // sort results by account name and their assets by asset name
    result = result
        .map(account => Object.assign(account, {assets: account.assets.sort(nameFieldComparator)}))
        .sort(nameFieldComparator);

    // add a group of Cash Accounts
    const cashAccounts = assets.filter(isCashAccount).sort(nameFieldComparator);
    if (cashAccounts.length) {
        result.unshift({...CASH_ACCOUNT_GROUP_TEMPLATE, assets: cashAccounts});
    }

    return result;
};

/**
 * Finds index of portfolio which contains given custody account by accountId.
 * @param structure
 * @param accountId
 */
export const findCustodyAccountPortfolioIndex = (structure: StructureArray, accountId: CustodyAccount['id']) =>
    structure.findIndex(node => isPortfolio(node) && node.custodyAccounts.some(({id}) => id === accountId));

/**
 * Utility function to narrow down T|undefined to just T. Useful in Array.prototype.filter.
 * @param x
 */
const notUndefined = <T>(x: T | undefined): x is T => x !== undefined;

const areCustodyAccountsChanged = (oldPortfolio: PortfolioNode, newPortfolio: PortfolioNode) => {
    const oldAccounts = oldPortfolio.custodyAccounts;
    const allAccounts = unionBy(oldAccounts, newPortfolio.custodyAccounts, 'id');
    return allAccounts.length > oldAccounts.length || allAccounts.length > newPortfolio.custodyAccounts.length;
};

/**
 * Returns diff between old and new structures with node's state set properly.
 *
 * @param oldStructure
 * @param newStructure
 */
export const isStructureChanged = (oldStructure: StructureArray, newStructure: StructureArray): boolean => {
    if (oldStructure.length !== newStructure.length) {
        return true;
    }

    const idToOldNode = new Map(oldStructure.map(node => [node.id, node]));
    const newNodeIds = new Set(); // used to find deleted nodes

    const isAddedOrEdited = newStructure.some(node => {
        newNodeIds.add(node.id);
        const oldNode = idToOldNode.get(node.id);
        return !oldNode || oldNode.name !== node.name || !isSameLevel(oldNode.level, node.level)
            || (isPortfolio(oldNode) && isPortfolio(node) && areCustodyAccountsChanged(oldNode, node));
    });

    return isAddedOrEdited || oldStructure.some(({id}) => !newNodeIds.has(id));
};

/**
 * Returns diff between old and new structures with node's state set properly.
 *
 * @param oldStructure
 * @param newStructure
 */
export const structureDiff = (oldStructure: StructureArray, newStructure: StructureArray): StructureArray => {
    const idToOldNode = new Map(oldStructure.map(node => [node.id, node]));
    const newNodeIds = new Set(); // used to find deleted nodes

    return newStructure
        .map(node => {
            newNodeIds.add(node.id);
            const oldNode = idToOldNode.get(node.id);
            if (oldNode) {
                if (oldNode.name !== node.name
                    || (isPortfolio(oldNode) && isPortfolio(node) && areCustodyAccountsChanged(oldNode, node))
                ) {
                    return {...node, state: StructureItemState.MODIFIED};
                }
                if (!isSameLevel(oldNode.level, node.level)) {
                    return {...node, state: StructureItemState.MOVED};
                }
                return undefined; // to be filtered out
            }

            // TODO: ensure if CREATED is ok to send for both portfolios and assets
            return {...node, state: StructureItemState.CREATED};
        })
        .filter(notUndefined)
        .concat(oldStructure
            .filter(({id}) => !newNodeIds.has(id))
            .map(node => ({...node, state: StructureItemState.DELETED})),
        );
};

/**
 * Returns used but unlinked custody accounts
 *
 * @param structure
 * @param vacantAccountGroups
 */
export const getUsedButUnlinkedCustodyAccounts = (
    structure: StructureArray,
    vacantAccountGroups: AccountGroup[],
): AccountGroup[] => vacantAccountGroups
    .filter(acc => !acc.isLinked && structure.some(node => isAsset(node) && acc.id === node.accountId));
