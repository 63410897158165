import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {connectActions} from 'store';
import {setTitle} from 'store/breadcrumbs/actions';

const PageContentWrapper = ({className, children, title, setTitle}) => {
    useEffect(() => {
        setTitle(title);
    }, [title]);

    return (
        <div className={cn('prv-page-content-wrapper', className)}>
            {children}
        </div>
    );
};

PageContentWrapper.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
    ]),
    setTitle: PropTypes.func,
};

export default connectActions({setTitle})(PageContentWrapper);
