import React, {Component} from 'react';
import PropTypes from 'prop-types';
import invariant from 'invariant';
import {connect} from 'store';
import {collectionShape} from 'store/collections';
import buildActions from 'store/collections/actions_builder';

import Spinner from 'components/loading-spinner/loading-spinner';
import ErrorPanel from 'components/panel/panel';
import {withRouter} from 'react-router-dom';

const itemPinner = ({name, getCollectionState, getItemIdFromParams, getFetchParams = getItemIdFromParams}) => {
    invariant(typeof name === 'string' && name.length > 0, 'please provide collection name');
    invariant(typeof getCollectionState === 'function', 'please provide getCollectionState');
    invariant(typeof getItemIdFromParams === 'function', 'please provide getItemIdFromParams');
    invariant(typeof getFetchParams === 'function', 'please provide getFetchParams');

    const {pinItemById} = buildActions(name);

    @withRouter
    @connect({collection: getCollectionState}, {pinItemById})
    class CollectionItemPinner extends Component {
        static displayName = `CollectionItemPinner(${name})`;
        static propTypes = {
            collection: collectionShape.isRequired,
            match: PropTypes.object.isRequired,
            pinItemById: PropTypes.func.isRequired,
            children: PropTypes.node,
        };

        componentWillMount() {
            const fetchParams = getFetchParams(this.props.match.params);
            this.props.pinItemById(fetchParams);
        }

        render() {
            const {
                collection: {pinnedItem, pinnedError},
                match,
                children,
            } = this.props;
            const {params} = match;
            if (pinnedError) return <ErrorPanel error={pinnedError}/>;
            const itemId = getItemIdFromParams(params);
            const isPinned = pinnedItem && pinnedItem.id === itemId.id;
            return isPinned ? children : <Spinner/>;
        }
    }

    return CollectionItemPinner;
};
export default itemPinner;
