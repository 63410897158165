import React, {Component} from 'react';
import PropTypes from 'prop-types';
import * as pathnames from 'config/pathnames';
import * as role from 'config/roles';

import {connect} from 'store';
import {redirect} from 'store/navigate';

import HasRole, {IsCustomer, IsRM} from 'components/has-role/has-role';
import {profileShape} from '../../store/data/profile';
import {getProfile} from '../../store/auth';

class ChooseScenarioPage extends Component {
    static propTypes = {
        profile: profileShape,
        redirect: PropTypes.func.isRequired,
    };

    goToClients = () => {
        this.props.redirect(pathnames.CLIENTS);
    };

    goToClientInformation = () => {
        this.props.redirect(`${pathnames.CLIENTS}/${this.props.profile.id}/view`);
    };

    goToCreatingNewClient = () => {
        this.props.redirect(pathnames.NEW_CLIENT);
    };

    render() {
        return (
            <div className="prv-choose-scenario-page">
                <HasRole allowedRoles={[role.SA, role.RM]}>
                    <button
                        className="prv-choose-scenario-page__button"
                        onClick={this.goToClients}
                    >
                        Admin Panel
                    </button>
                </HasRole>
                <IsRM>
                    <button
                        className="prv-choose-scenario-page__button"
                        onClick={this.goToCreatingNewClient}
                    >
                        New Client / Update Client
                    </button>
                </IsRM>
                <IsCustomer>
                    <button
                        className="prv-choose-scenario-page__button"
                        onClick={this.goToClientInformation}
                    >
                        My Proveo
                    </button>
                </IsCustomer>
            </div>
        );
    }
}

export default connect({profile: getProfile}, {redirect})(ChooseScenarioPage);
