import typeReader from 'store/helpers/type_reader';
import typeShape, {required, optional} from 'store/helpers/type_shape';

const ClientType = {
    id: required(String),
    firstName: required(String),
    lastName: required(String),
    managerId: optional(String),
    proveoId: optional(String),
    managerFirstName: optional(String),
    managerLastName: optional(String),
    status: required(String),
    email: required(String),
    phone: required(String),
    birthday: optional(String),
    baseCurrency: optional(String),
    startMonth: required(Number),
    atlazId: optional(Number),
    lastSyncDate: optional(String),
};

export const readClient = typeReader(ClientType);
export const clientShape = typeShape(ClientType);

const LimitType = {
    id: required(String, 'typeId'),
    type: required(String, 'name'),
    value: required(String),
    range: optional(String, 'period'),
};

export const readLimit = typeReader(LimitType);
export const limitShape = typeReader(LimitType);

const BenchmarkType = {
    id: required(String),
    name: optional(String),
    morningstarId: optional(String),
    type: required(String),
};

const SubBenchmarkType = {
    id: required(String),
    name: optional(String),
    weight: required(Number),
};

const SharedBenchmarkType = {
    id: required(String),
    name: optional(String),
    ownerId: optional(String),
    ownerName: optional(String),
    isDefault: optional(Boolean),
    currency: optional(String),
    regionId: optional(String),
    assetClassId: optional(String),
    assetSubClassId: optional(String),
    benchmarks: [SubBenchmarkType],
};

const ClientBenchmarkType = {
    id: required(String),
    name: required(String),
    weight: required(Number),
    startDate: required(String),
};

export const readBenchmark = typeReader(BenchmarkType);
export const readSubBenchmark = typeReader(SubBenchmarkType);
export const readClientBenchmark = typeReader(ClientBenchmarkType);
export const readSharedBenchmark = typeReader(SharedBenchmarkType);
export const benchmarkShape = typeShape(BenchmarkType);
export const subBenchmarkShape = typeShape(SubBenchmarkType);
export const clientBenchmarkShape = typeShape(ClientBenchmarkType);
export const sharedBenchmarkShape = typeShape(SharedBenchmarkType);

export const editClient = client => (client && {
    id: client.id,
    proveoId: client.proveoId,
    firstName: client.firstName,
    lastName: client.lastName,
    status: client.status,
    email: client.email,
    phone: client.phone,
    birthday: client.birthday,
    managerId: client.managerId,
    baseCurrency: client.baseCurrency || 'DKK',
    startMonth: client.startMonth || 1,
});
