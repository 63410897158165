import React, {PureComponent} from 'react';
import {reduxForm} from 'components/forms';
import validate from 'components/forms/validations/validate';
import PropTypes from 'prop-types';
import EditingForm from 'components/editing/editing-form';
import EditingTable from 'components/editing/editing-table';

import {
    ErrorsRow,
    InputRow,
} from 'components/editing/editing-rows';

const fields = {
    firstName: {
        required: true,
        name: true,
        maxLength: 50,
    },
    lastName: {
        required: true,
        name: true,
        maxLength: 50,
    },
    email: {
        required: true,
        email: true,
    },
};

class ClientEditForm extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
        emailDisabled: PropTypes.bool,
        onValidateResult: PropTypes.func,
        valid: PropTypes.bool,
    };

    componentWillReceiveProps(nextProps) {
        if (this.props.valid !== nextProps.valid && this.props.onValidateResult) {
            this.props.onValidateResult(nextProps.valid);
        }
    }

    onTableRef = (table) => {
        this.table = table;
    };

    submit = event => this.table.onSubmit(event);

    render() {
        const {emailDisabled, className, ...props} = this.props;
        return (
            <EditingForm className={className} {...props}>
                <EditingTable
                    ref={this.onTableRef}
                    className="prv-client-props-form__form"
                    {...props}
                >
                    <InputRow name="firstName" label="First Name" required/>
                    <ErrorsRow name="firstName"/>

                    <InputRow name="lastName" label="Last Name" required/>
                    <ErrorsRow name="lastName"/>

                    <InputRow name="email" label="Email" disabled={emailDisabled}/>
                    <ErrorsRow name="email"/>

                </EditingTable>
            </EditingForm>
        );
    }
}

export default reduxForm({form: 'edit-new-client', ...validate(fields)})(ClientEditForm);
