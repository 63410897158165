/* eslint-disable class-methods-use-this */

export const BorsenEventCategories = Object.freeze({
    getStarted: 'Get_started',
    password: 'Password',
    chooseBank: 'Choose_bank',
    credentials: 'Credentials',
    success: 'Success',
});
export const BorsenEventActions = Object.freeze({
    getStartedEmailField: 'Get_started_email_field',
    getStartedNext: 'Get_started_next',
    getStartedBack: 'Get_started_back',
    getStartedHelp: 'Get_started_help',
    passwordField1: 'Password_field1',
    passwordField2: 'Password_field2',
    passwordNext: 'Password_next',
    passwordBack: 'Password_back',
    passwordHelp: 'Password_help',
    chooseBankAnybank: 'Choose_bank_anybank',
    chooseBankBack: 'Choose_bank_back',
    credentialsField1: 'Credentials_field1',
    credentialsField2: 'Credentials_field2',
    credentialsNext: 'Credentials_next',
    credentialsBack: 'Credentials_back',
    credentialsHelp: 'Credentials_help',
    credentialsMore: 'Credentials_more',
    successAppStore: 'Success_app_store',
    successGooglePlay: 'Success_google_play',
    successHelp: 'Success_help',
    successReadMore: 'Success_read_more',
});
export const actions = Object.freeze({
    [BorsenEventCategories.getStarted]: Object.freeze({
        [BorsenEventActions.getStartedEmailField]: BorsenEventActions.getStartedEmailField,
        [BorsenEventActions.getStartedNext]: BorsenEventActions.getStartedNext,
        [BorsenEventActions.getStartedBack]: BorsenEventActions.getStartedBack,
        [BorsenEventActions.getStartedHelp]: BorsenEventActions.getStartedHelp,
    }),
    [BorsenEventCategories.password]: Object.freeze({
        [BorsenEventActions.passwordField1]: BorsenEventActions.passwordField1,
        [BorsenEventActions.passwordField2]: BorsenEventActions.passwordField2,
        [BorsenEventActions.passwordNext]: BorsenEventActions.passwordNext,
        [BorsenEventActions.passwordBack]: BorsenEventActions.passwordBack,
        [BorsenEventActions.passwordHelp]: BorsenEventActions.passwordHelp,
    }),
    [BorsenEventCategories.chooseBank]: Object.freeze({
        [BorsenEventActions.chooseBankAnybank]: BorsenEventActions.chooseBankAnybank,
        [BorsenEventActions.chooseBankBack]: BorsenEventActions.chooseBankBack,
    }),
    [BorsenEventCategories.credentials]: Object.freeze({
        [BorsenEventActions.credentialsField1]: BorsenEventActions.credentialsField1,
        [BorsenEventActions.credentialsField2]: BorsenEventActions.credentialsField2,
        [BorsenEventActions.credentialsNext]: BorsenEventActions.credentialsNext,
        [BorsenEventActions.credentialsBack]: BorsenEventActions.credentialsBack,
        [BorsenEventActions.credentialsHelp]: BorsenEventActions.credentialsHelp,
        [BorsenEventActions.credentialsMore]: BorsenEventActions.credentialsMore,
    }),
    [BorsenEventCategories.success]: Object.freeze({
        [BorsenEventActions.successAppStore]: BorsenEventActions.successAppStore,
        [BorsenEventActions.successGooglePlay]: BorsenEventActions.successGooglePlay,
        [BorsenEventActions.successHelp]: BorsenEventActions.successHelp,
        [BorsenEventActions.successReadMore]: BorsenEventActions.successReadMore,
    }),
});

export class GA {
    get id() {
        let gtagId = 'UA-154023876-3';
        if (process.env.NODE_ENV === 'staging') {
            gtagId = 'UA-154023876-2';
        } else if (process.env.NODE_ENV === 'production') {
            gtagId = 'UA-154023876-1';
        }

        return gtagId;
    }

    sendEvent = (eventCategory, eventAction) => {
        if (!window.ga) return null;
        if (!actions[eventCategory][eventAction]) return null;
        const tracker = window.ga.getAll()[0];
        if (!tracker) return null;
        return tracker.send('event', eventCategory, actions[eventCategory][eventAction]);
    }
}

export default new GA();
