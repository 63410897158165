import {createVersion, deleteVersion, fetchVersions, selectVersion, renameVersion, makePrimary, resetState} from './actions';
import {apiActionHandler, handleActions} from '../helpers/actions';
import Version from '../types/Version';
import {Action, ResultAction} from '../helpers/types';

export interface StructureVersionState {
    error: Error | undefined,
    loading: boolean,
    loaded: boolean,
    selectedVersion: Version | undefined,
    defaultVersion: Version | undefined,
    versions: Version[],
}

const initialState: StructureVersionState = {
    error: undefined,
    loading: false,
    loaded: false,
    selectedVersion: undefined,
    defaultVersion: undefined,
    versions: [],
};

export const getVersions = ({versions}: StructureVersionState) =>
    (versions || []).map(v => ({...v, label: v.primary ? `${v.name} (primary)` : v.name}));
export const getSelectedVersion = ({selectedVersion}: StructureVersionState) => selectedVersion;
export const getDefaultVersion = ({defaultVersion}: StructureVersionState) => defaultVersion;
export const getLoading = ({loading}: StructureVersionState) => loading;
export const getLoaded = ({loaded}: StructureVersionState) => loaded;
export const getError = ({error}: StructureVersionState) => error;

export default handleActions({
    [selectVersion]: (state: StructureVersionState, action: Action<Version | undefined>) => ({
        ...state,
        selectedVersion: action.payload,
    }),
    [fetchVersions]: (state: StructureVersionState, action: Action<void> | ResultAction<Version[] | Error>) =>
        apiActionHandler(state, action, {
            result: 'versions',
            resultHandler: (payload: Version[], resultField: string, state: StructureVersionState) => ({
                ...state,
                [resultField]: payload,
                defaultVersion: payload.length > 0 ? payload.find(v => v.primary) : undefined,
                loading: false,
                loaded: true,
            }),
        }),
    [createVersion]: (state: StructureVersionState, action: Action<void> | ResultAction<Version | Error>) =>
        apiActionHandler(state, action),
    [renameVersion]: (state: StructureVersionState, action: Action<void> | ResultAction<Version | Error>) =>
        apiActionHandler(state, action),
    [deleteVersion]: (state: StructureVersionState, action: Action<void> | ResultAction<void | Error>) =>
        apiActionHandler(state, action),
    [makePrimary]: (state: StructureVersionState, action: Action<void> | ResultAction<Version | Error>) =>
        apiActionHandler(state, action, {result: 'selectedVersion'}),
    [resetState]: () => initialState,
}, initialState);
