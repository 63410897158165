import Error from 'es6-error';

export default class ApiError extends Error {
    constructor(message, errors = []) {
        super(message);
        this.errors = Array.isArray(errors) ? errors : [errors];
        this.validationErrors = Array.isArray(errors)
            ? errors.map(({key, ...error}) => ({...error, field: key}))
            : [];
    }
}
