import {handleActions, apiActionHandler} from 'store/helpers/actions';
import {readPerformanceGraph, readPerformanceInfo, readPortfolio} from 'store/data/client';

import {
    fetchGraph,
    fetchGraphForAccount,
    fetchGraphForPortfolio,
    fetchPerformanceInfo,
    fetchPerformanceInfoForAccount,
    fetchPerformanceInfoForPortfolio,
    fetchCustomerPortfolios,
} from './actions';

const initialState = {
    graph: null,
    graphLoading: false,
    info: null,
    infoLoading: false,
    portfolios: null,
    portfoliosLoading: false,
};

export const isLoading = ({graphLoading: l1, infoLoading: l2, portfoliosLoading: l3}) => l1 || l2 || l3;
export const isPortfoliosLoading = ({portfoliosLoading}) => portfoliosLoading;
export const getGraph = ({graph}) => graph;
export const getInfo = ({info}) => info;
export const getPortfolios = ({portfolios}) => portfolios;
const onFetchGraph = (state, action) => apiActionHandler(
    state,
    action,
    {loading: 'graphLoading', result: 'graph', reader: readPerformanceGraph},
);

const onFetchInfo = (state, action) => apiActionHandler(
    state,
    action,
    {loading: 'infoLoading', result: 'info', reader: readPerformanceInfo},
);

const onFetchPortfolios = (state, action) => apiActionHandler(
    state,
    action,
    {loading: 'portfoliosLoading', result: 'portfolios', reader: p => p.items.map(readPortfolio)},
);

export default handleActions(
    {
        [fetchGraph]: onFetchGraph,
        [fetchGraphForAccount]: onFetchGraph,
        [fetchGraphForPortfolio]: onFetchGraph,
        [fetchPerformanceInfo]: onFetchInfo,
        [fetchPerformanceInfoForAccount]: onFetchInfo,
        [fetchPerformanceInfoForPortfolio]: onFetchInfo,
        [fetchCustomerPortfolios]: onFetchPortfolios,
    },
    initialState,
);
