import React, {PureComponent} from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import indexOf from 'lodash/indexOf';
import without from 'lodash/without';
import Header, {columnProps as headerColumnProps} from './resource-table-header';
import Row, {columnProps as rowColumnProps} from './resource-table-row';

import styles from './resource-table.module.css';

// sizes in px
export const actionsProperties = {
    details: {text: 'Details', width: 76, icon: 'edit'},
    block: {text: 'Block', width: 67, icon: 'lock'},
    unblock: {text: 'Unblock', width: 82, icon: 'lock_open'},
    delete: {text: 'Delete', width: 71, icon: 'delete'},
    recalculate: {text: 'Recalculate Outdated', width: 110, icon: 'refresh'},
    forceRecalculate: {text: 'Recalculate All', width: 110, icon: 'refresh'},
    margin: 10,
};

export default class ResourceTable extends PureComponent {
    static propTypes = {
        actionView: PropTypes.string,
        tag: PropTypes.string,
        className: PropTypes.string,
        columns: PropTypes.arrayOf(PropTypes.shape({
            ...headerColumnProps,
            ...rowColumnProps,
        })).isRequired,
        items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
        activeItem: PropTypes.shape({}),
        selected: PropTypes.arrayOf(PropTypes.shape({})),
        sortField: PropTypes.string,
        sortOrder: PropTypes.string,
        onSort: PropTypes.func,
        onActivate: PropTypes.func,
        canSelect: PropTypes.func,
        onSelect: PropTypes.func,
        onEdit: PropTypes.func,
        canEdit: PropTypes.func,
        onDelete: PropTypes.func,
        canDelete: PropTypes.func,
        onToggleBlock: PropTypes.func,
        canBlock: PropTypes.func,
        onRecalculate: PropTypes.func,
        canRecalculate: PropTypes.func,
        canForceRecalculate: PropTypes.func,
        onForceRecalculate: PropTypes.func,
        wrapApiAction: PropTypes.func,
        getId: PropTypes.func,
        totalKey: PropTypes.string,
    };

    static defaultProps = {
        canEdit: () => false,
        canDelete: () => false,
        canSelect: () => false,
        canBlock: () => false,
        canRecalculate: () => false,
        getId: () => 'id',
    };

    onSelectItem = (item) => {
        const {selected = []} = this.props;
        const idx = indexOf(selected, item);
        if (idx >= 0) {
            this.props.onSelect(without(selected, item));
        } else {
            this.props.onSelect(selected.concat([item]));
        }
    };

    onActivateItem = (item) => {
        const {activeItem, onActivate} = this.props;
        onActivate(item === activeItem ? null : item);
    };

    render() {
        const {
            tag,
            className,
            columns,
            items,
            getId,
            onActivate,
            onEdit,
            onDelete,
            selected,
            onSelect,
            sortField,
            sortOrder,
            onSort,
            canEdit,
            canDelete,
            canSelect,
            canBlock,
            onToggleBlock,
            onRecalculate,
            canRecalculate,
            wrapApiAction,
            actionView,
            onForceRecalculate,
            canForceRecalculate,
            totalKey,
        } = this.props;

        const haveToSelectAny = onSelect && items.some(canSelect);
        const haveToEditAny = onEdit && items.some(canEdit);
        const haveToBlockAny = onToggleBlock && items.some(canBlock);
        const haveToDeleteAny = onDelete && items.some(canDelete);
        const haveToRecalculateAny = onRecalculate && items.some(canRecalculate);
        const [{field: totalField}] = columns;

        return (
            <table className={cn(styles.table, className)}>
                <Header
                    actionView={actionView}
                    tag={tag}
                    columns={columns}
                    sortField={sortField}
                    sortOrder={sortOrder}
                    onSort={onSort}
                    isTableHaveEditableItem={haveToEditAny}
                    isTableHaveBlockableItem={haveToBlockAny}
                    isTableHaveDeletableItem={haveToDeleteAny}
                    isTableHaveRecalculatableItem={haveToRecalculateAny}
                />
                <tbody>
                    {items.map((item, index) => (
                        <Row
                            actionView={actionView}
                            tag={tag}
                            // eslint-disable-next-line react/no-array-index-key
                            key={`${item[getId(item)]}${index}`}
                            getId={getId}
                            item={item}
                            columns={columns}
                            isActive={false}
                            isSelected={indexOf(selected, item) >= 0}
                            onActivate={onActivate ? this.onActivateItem : null}
                            canSelect={canSelect}
                            onSelect={haveToSelectAny ? this.onSelectItem : null}
                            canEdit={canEdit}
                            onEdit={(haveToEditAny || haveToBlockAny) ? onEdit : null}
                            canDelete={canDelete}
                            onDelete={onDelete}
                            canBlock={canBlock}
                            onToggleBlock={onToggleBlock}
                            canRecalculate={canRecalculate}
                            onRecalculate={onRecalculate}
                            canForceRecalculate={canForceRecalculate}
                            onForceRecalculate={onForceRecalculate}
                            wrapApiAction={wrapApiAction}
                        />
                    ))}
                    {totalKey && (
                        <Row
                            isTotal
                            item={{
                                [totalField]: 'Total',
                                [totalKey]: items.reduce((sum, nx) => sum + nx[totalKey], 0),
                            }}
                            columns={columns}
                        />
                    )}
                </tbody>
            </table>
        );
    }
}
