import typeReader from 'store/helpers/type_reader';
import typeShape, {required, optional} from 'store/helpers/type_shape';

const PortfolioType = {
    id: required(String),
    name: required(String),
    proveoPortfolioId: optional(String),
    benchmarks: [{
        id: required(String),
        name: required(String),
        weight: required(Number),
        startDate: required(String),
    }],
    limits: [{
        id: required(String, 'typeId'),
        type: required(String, 'name'),
        value: required(Number),
    }],
    invoicingAUM: required(Boolean),
    invoicingAUMLock: required(Boolean),
    invoicingReturn: required(Boolean),
    invoicingReturnLock: required(Boolean),
    invoicingFixed: required(Boolean),
    invoicingFixedLock: required(Boolean),
    proveoScoreCalculated: required(Boolean),
    reporting: required(Boolean),
    reportingLock: required(Boolean),
    createReport: required(Boolean),
    assetsManagerId: optional(String),
    isLegacy: optional(Boolean),
};

const AggregatedPortfolioType = {
    id: required(String),
    name: required(String),
    proveoPortfolioId: optional(String),
    benchmarks: [{
        id: required(String),
        name: required(String),
        weight: required(Number),
        startDate: required(String),
    }],
    limits: [{
        id: required(String, 'typeId'),
        type: required(String, 'name'),
        value: required(Number),
    }],
    value: required(Number),
    totalValueChange: required(Number),
    totalValueChangeForDay: required(Number),
    totalValueChangeForMonth: required(Number),
    totalValueChangeForYear: required(Number),
    invoicingAUM: required(Boolean),
    invoicingAUMLock: required(Boolean),
    invoicingReturn: required(Boolean),
    invoicingReturnLock: required(Boolean),
    invoicingFixed: required(Boolean),
    invoicingFixedLock: required(Boolean),
    proveoScoreCalculated: required(Boolean),
    reporting: required(Boolean),
    reportingLock: required(Boolean),
    createReport: required(Boolean),
    assetsManagerId: optional(String),
    isLegacy: optional(Boolean),
};

export const readPortfolio = typeReader(PortfolioType);
export const portfolioShape = typeShape(PortfolioType);
export const readAggregatedPortfolio = typeReader(AggregatedPortfolioType);
export const aggregatedPortfolioShape = typeShape(AggregatedPortfolioType);
