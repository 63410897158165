import {createApiCallAction} from '../helpers/actions';

export const fetchGraph = createApiCallAction('performance/FETCH_GRAPH', 'fetchPerformanceGraph');
export const fetchGraphForAccount =
    createApiCallAction('performance/FETCH_GRAPH_FOR_ACCOUNT', 'fetchPerformanceGraphForAccount');
export const fetchGraphForPortfolio =
    createApiCallAction('performance/FETCH_GRAPH_FOR_PORTFOLIO', 'fetchPerformanceGraphForPortfolio');
export const fetchPerformanceInfo = createApiCallAction('performance/FETCH_INFO', 'fetchPerformanceInfo');
export const fetchPerformanceInfoForAccount =
    createApiCallAction('performance/FETCH_INFO_FOR_ACCOUNT', 'fetchPerformanceInfoForAccount');
export const fetchPerformanceInfoForPortfolio =
    createApiCallAction('performance/FETCH_INFO_FOR_PORTFOLIO', 'fetchPerformanceInfoForPortfolio');
export const fetchCustomerPortfolios =
    createApiCallAction('performance/FETCH_CUSTOMER_PORTFOLIOS', 'fetchCustomerPortfolios');
