import React from 'react';
import SideDialog from '../../../components/dialogs/choose-items-side-dialog';

const renderItem = limit => limit.name;

const LimitsTypesDialog = props => (
    <SideDialog
        title="Limits types"
        uniqueKey="id"
        renderItem={renderItem}
        {...props}
    />
);
export default LimitsTypesDialog;

