import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import SelectField from 'react-md/lib/SelectFields/SelectField';
import cn from 'classnames';

export default class SubHeaderFilters extends PureComponent {
    static propTypes = {
        displayOptions: PropTypes.arrayOf(PropTypes.node),
        searchTerm: PropTypes.string,
        searchPlaceholder: PropTypes.string,
        searchDelay: PropTypes.number,
        onSearch: PropTypes.func,
        filtersConfig: PropTypes.objectOf(PropTypes.shape({
            className: PropTypes.string,
            title: PropTypes.string,
            options: PropTypes.objectOf(PropTypes.string),
            render: PropTypes.func,
            onChange: PropTypes.func,
            noEmptyOption: PropTypes.bool,
            emptyItem: PropTypes.string,
        })),
        filters: PropTypes.objectOf(PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.bool,
            PropTypes.array,
        ])),
        onFilterChange: PropTypes.func,
        children: PropTypes.node,
        createItemButtonTitle: PropTypes.string,
        onCreateItem: PropTypes.func,
    };

    static defaultProps = {
        searchPlaceholder: 'Table search',
        searchDelay: 300,
        createItemButtonTitle: 'Create',
    };

    componentWillUnmount = () => {
        clearTimeout(this.timeout);
    };

    onSearch = (e) => {
        const {value} = e.target;
        if (value != this.props.searchTerm) { // eslint-disable-line eqeqeq
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.props.onSearch(value);
            }, this.props.searchDelay);
        }
    };

    renderSearch = () => {
        const {searchTerm, searchPlaceholder} = this.props;
        return (
            <input
                type="text"
                className="prv-subheader-filters__search-field"
                onChange={this.onSearch}
                defaultValue={searchTerm}
                placeholder={searchPlaceholder}
            />
        );
    };

    renderFilters = () => {
        const {filters, filtersConfig, onFilterChange} = this.props;
        const filterTypes = Object.keys(filtersConfig || {});
        return (
            <div className="prv-subheader-filters__filters">
                {filterTypes.length !== 0 && <span>Choose Filters: </span>}
                <div>
                    {filterTypes.map((name) => {
                        const value = filters[name];
                        const {className, title, options = {}, render, onChange: onChangeCb,
                            noEmptyOption, emptyItem} = filtersConfig[name];
                        const onChange = v => {
                            const value = v === emptyItem ? null : v;
                            onFilterChange({[name]: v});
                            onChangeCb && onChangeCb(value);
                        };
                        if (render) {
                            return React.cloneElement(
                                render({name, value, onChange, filters, onFilterChange}),
                                {key: name},
                            );
                        }
                        // object.keys will cast any number keys to strings -> therefore we need to expicitly cast values to strings as well
                        // to ensure selection(value) in filter is of the same type - otherwise selection will be cleared
                        const menuItems = Object.keys(options).map(opt => ({value: opt, label: options[opt]}));
                        if (!noEmptyOption) {
                            menuItems.unshift({value: null, label: ''});
                        }
                        return (
                            <SelectField
                                className={cn('prv-subheader-filters__filter', className)}
                                id={`filter-${name}`}
                                key={name}
                                label={title}
                                menuItems={menuItems}
                                onChange={onChange}
                                simplifiedMenu={false}
                                value={(value && value.toString()) || ''}
                            />
                        );
                    })}
                </div>
            </div>
        );
    };

    renderCreateItemButton = title => (
        <button
            className="prv-subheader-filters__button-create"
            onClick={this.props.onCreateItem}
        >
            <span className="prv-subheader-filters__button-create-title">{title}</span>
        </button>
    );

    render() {
        const {displayOptions, onSearch, onFilterChange, children, onCreateItem, createItemButtonTitle} = this.props;

        return (
            <div className="prv-subheader-filters">
                {displayOptions}
                <div
                    className={cn('prv-subheader-filters__filter-group', {
                        'prv-subheader-filters__filter-group--long': !children,
                    })}
                >
                    <div
                        className={cn('prv-subheader-filters__block1', {
                            'prv-subheader-filters__block1--filters': !children,
                        })}
                    >
                        {children ? onSearch && this.renderSearch() : onFilterChange && this.renderFilters()}
                    </div>
                    <div className="prv-subheader-filters__block2">
                        {children ? onFilterChange && this.renderFilters() : this.renderSearch()}
                        {onCreateItem && this.renderCreateItemButton(createItemButtonTitle)}
                    </div>
                </div>
                {children}
            </div>
        );
    }
}
