import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {money, percent} from 'helpers/formatters';

import {connect} from 'store';
import {getReferenceData} from 'store/general-data';
import {fetchAccounts, fetchCustomerPortfolios} from '../../../store/accounts/actions';
import {fetchVersions} from '../../../store/structure-version/actions';
import {collectionShape} from '../../../store/collections';
import {getPinnedClient} from '../../../store/clients';
import {getCollectionState} from '../../../store/client-assets';
import {accountShape, clientShape, portfolioShape} from '../../../store/data/client';
import {getAccounts, getPortfolios} from '../../../store/accounts';
import {getVersions, getDefaultVersion, getLoaded as getVersionsLoaded} from '../../../store/structure-version';

import CollectionListPage from '../../../components/collections/list-page';

const columns = [
    {field: 'name', label: 'Asset Name'},
    {field: 'assetReportingCategoryName', label: 'Asset Allocation Type'},
    {field: 'isin', label: 'ISIN'},
    {field: 'share', label: 'Share', render: percent},
    {field: 'value', label: 'Value', render: money},
];

class AssetsPage extends Component {
    static propTypes = {
        client: clientShape,
        collection: collectionShape,
        accounts: PropTypes.arrayOf(accountShape),
        portfolios: PropTypes.arrayOf(portfolioShape),
        versions: PropTypes.arrayOf(PropTypes.object),
        defaultVersion: PropTypes.object,
        refenceData: PropTypes.arrayOf(PropTypes.object),
        fetchAccounts: PropTypes.func.isRequired,
        fetchCustomerPortfolios: PropTypes.func.isRequired,
    };

    componentWillMount() {
        const {client: {id}, defaultVersion} = this.props;
        this.props.fetchAccounts({id, type: ['deposit', 'cash']});
        this.props.fetchCustomerPortfolios({id, versionId: defaultVersion && defaultVersion.id});
    }

    getFiltersConfig = () => {
        const {accounts, portfolios, versions, fetchCustomerPortfolios, client: {id, atlazId}} = this.props;
        const accountsOptions = accounts.reduce((result, item) => Object.assign(result, {[item.id]: item.name}), {});
        const portfolioOptions = portfolios.reduce((result, item) => Object.assign(result, {[item.id]: item.name}), {});
        const versionOptions = versions.reduce((result, item) => Object.assign(result, {[item.id]: item.label}), {});

        const accountId = {
            title: 'Account',
            options: accountsOptions,
        };
        const portfolioId = {
            title: 'Portfolio',
            options: portfolioOptions,
        };

        if (!atlazId) {
            const {refenceData: {assetReportingCategories = []} = {}} = this.props;
            const categoryOptions = assetReportingCategories
                .reduce((result, item) => ({...result, [item.id]: item.assetCat}), {});
            return {
                accountId,
                categoryId: {
                    title: 'Asset Allocation Type',
                    options: categoryOptions,
                    className: 'prv-client-asset__version-filter',
                },
                portfolioId,
                versionId: {
                    title: 'Structure Version',
                    options: versionOptions,
                    className: 'prv-client-asset__version-filter',
                    onChange: versionId => fetchCustomerPortfolios({id, versionId}),
                },
            };
        }

        const {refenceData: {assetReportingCategoriesAtlaz = []} = {}} = this.props;
        const categoryOptionsAtlaz = assetReportingCategoriesAtlaz
            .reduce((result, item) => ({...result, [item.id]: item.assetCat}), {});
        return {
            accountId,
            categoryId: {
                title: 'Asset Allocation Type',
                options: categoryOptionsAtlaz,
                className: 'prv-client-asset__version-filter',
            },
            portfolioId,
        };
    };

    render() {
        const {collection, defaultVersion} = this.props;
        return (
            <div className="prv-client-asset">
                <CollectionListPage
                    className="prv-client-asset__collection"
                    collection={collection}
                    collectionTitle="Allocation"
                    columns={columns}
                    filters={this.getFiltersConfig()}
                    initialState={{
                        perPage: 1000000000,
                        filters: {versionId: defaultVersion && defaultVersion.id},
                    }}
                    name="clientAssets"
                    perPageOptions={[10, 25, 50, 100, 1000, {label: 'All', value: 1000000000}]}
                    totalKey="value"
                />
            </div>
        );
    }
}

export default connect(
    {
        collection: getCollectionState,
        accounts: getAccounts,
        portfolios: getPortfolios,
        versions: getVersions,
        client: getPinnedClient,
        defaultVersion: getDefaultVersion,
        versionsLoaded: getVersionsLoaded,
        refenceData: getReferenceData,
    },
    {
        fetchAccounts,
        fetchCustomerPortfolios,
    },
)(AssetsPage);
