import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import cn from 'classnames';

import * as pathnames from 'config/pathnames';

import {connect} from 'store';
import {collectionShape} from 'store/collections';
import {getCollectionState} from 'store/rms';

import {deleteRM, blockRM, unblockRM} from 'store/rms/actions';

import CollectionListPage from 'components/collections/list-page';
import {navigate, refresh} from 'store/navigate';

import {RmDialog as Dialog} from 'components/dialogs';

/**
 * @param {string} status
 * @return {*}
 */
const renderStatus = status => (
    <span className={cn('prv-rm-list__status', `prv-rm-list__status--${status.toLowerCase()}`)}>
        {status.toUpperCase()}
    </span>
);

const renderName = (item, items) => `${items.firstName} ${items.lastName}`;

const columns = [
    {field: 'firstName', label: 'RM Name', render: renderName},
    {field: 'status', label: 'Status', width: '58px', render: renderStatus},
    {field: 'email', label: 'Email', width: '18%'},
    {field: 'phone', label: 'Phone Number', width: '18%'},
];

const filtersConfig = {
    status: {
        title: 'Status',
        options: {
            active: 'Active',
            blocked: 'Blocked',
        },
    },
};

@withRouter
@connect(
    {collection: getCollectionState},
    {deleteRM, blockRM, unblockRM, navigate, refresh},
)
export default class RMsPage extends Component {
    static propTypes = {
        collection: collectionShape,
        location: PropTypes.shape({
            state: PropTypes.objectOf(PropTypes.bool),
        }),
        deleteRM: PropTypes.func.isRequired,
        blockRM: PropTypes.func.isRequired,
        unblockRM: PropTypes.func.isRequired,
        navigate: PropTypes.func.isRequired,
        refresh: PropTypes.func.isRequired,
    };

    onEdit = ({id}) => this.props.navigate(`${pathnames.RMS}/${id}/edit`);
    onDelete = item => this.props.deleteRM({id: item.id, assignTo: this.assignTo});
    onToggleBlock = ({id, status}) => (
        status === 'blocked' ? this.props.unblockRM(id) : this.props.blockRM({id, assignTo: this.assignTo})
    );


    getMessage = (item, msg) => (
        <div>
            {msg}
            <br/> {item.firstName} {item.lastName}?
        </div>
    );

    getDeleteMessage = item => this.getMessage(item, 'Do you want to delete');

    getBlockMessage = item => this.getMessage(
        item, `Do you want to ${item.status === 'blocked' ? 'un' : ''}block`,
    );

    getNotification = () => {
        const {created, updated, deleted} = this.props.location.state || {};
        if (created) {
            return `RM ${created} has been successfully created`;
        }
        if (updated) {
            return `RM ${updated} has been successfully updated`;
        }
        if (deleted) {
            return `RM ${deleted} has been successfully deleted`;
        }

        return null;
    };

    clearNotification = () => this.props.refresh(this.props.location, {});

    renderDialog = (action, {item, onSubmit, onCancel, onSubmitSuccess, onSubmitFail}) => (
        <Dialog
            item={item}
            onDelete={action === 'delete' ? onSubmit : null}
            onBlock={action === 'block' ? onSubmit : null}
            onCancel={onCancel}
            onSelectRM={(assignTo) => {
                this.assignTo = assignTo;
            }}
            onSubmitSuccess={onSubmitSuccess}
            onSubmitFail={onSubmitFail}
        />
    );

    render() {
        const {collection} = this.props;

        return (
            <CollectionListPage
                name="rms"
                title="RMs"
                collection={collection}
                columns={columns}
                filters={filtersConfig}

                canEdit={() => true}
                onEdit={this.onEdit}
                canDelete={() => true}
                onDelete={this.onDelete}
                canBlock={() => true}
                onToggleBlock={this.onToggleBlock}

                notification={this.getNotification()}
                clearNotification={this.clearNotification}

                getDeleteMessage={this.getDeleteMessage}
                getBlockMessage={this.getBlockMessage}

                renderDeleteDialog={params => this.renderDialog('delete', params)}
                renderBlockDialog={params => this.renderDialog('block', params)}
            />
        );
    }
}
