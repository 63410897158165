import {useMemo} from 'react';
import BankTerms from '../types/BankTerms.d';
import Language from '../../../../../config/Language';

const formatMessage = (lang: Language, texts: Partial<Record<Language, string>>): string =>
    texts[lang] ?? texts[Language.ENGLISH]!;

export const useCustomBankTerms = (lang: Language): BankTerms[] => useMemo<BankTerms[]>(
    () => [
        {
            bankId: [
                'ao',
                'asb',
                'bb',
                'bn',
                'bnf',
                'bos',
                'brs',
                'ds',
                'fas',
                'fls',
                'fos',
                'fros',
                'jak',
                'jut',
                'kls',
                'kronjylland',
                'lan_spar',
                'ls',
                'maba',
                'nos',
                'rfs',
                'ros',
                'sba',
                'sbr',
                'sdj',
                'sdlb',
                'shs',
                'sno',
                'sth',
                'sts',
                'vendsyssel',
            ],
            username: formatMessage(lang, {
                [Language.ENGLISH]: 'CPR number (without dash)',
                [Language.DANSKE]: 'CPR nummer (uden bindestreg)',
            }),
            password: formatMessage(lang, {
                [Language.ENGLISH]: 'Mobilebank code',
                [Language.DANSKE]: 'Mobilbank kode',
            }),
            mitIdUsername: formatMessage(lang, {
                [Language.ENGLISH]: 'Enter MitID User-ID',
                [Language.DANSKE]: 'Indtast MitID Bruger-ID',
            }),
            help: formatMessage(lang, {
                [Language.ENGLISH]: '1. Enter your CPR number and Mobile bank code\n2. Click Continue\n3. You receive a 4-digit code on your mobile, you must enter on next page',
                [Language.DANSKE]: '1. Indtast dit CPR nummer og Mobilbank kode\n2. Tryk på Fortsæt\n3. Du modtager en 4-cifret kode på din mobil, som indtastes på næste side',
            }),
            nemIdAppHelp: formatMessage(lang, {
                [Language.ENGLISH]: 'To connect {bankName}, you must enter the code you have received on your mobile device.',
                [Language.DANSKE]: 'For at tilknytte {bankName}, skal du indtaste den kodedu har modtaget på din mobil telefon.',
            }),
            nemIdAppField: formatMessage(lang, {
                [Language.ENGLISH]: 'Enter received code',
                [Language.DANSKE]: 'Indtast tilsendt kode',
            }),
        },
        {
            bankId: ['jyske_new'],
            username: formatMessage(lang, {
                [Language.ENGLISH]: 'NEM-ID login',
                [Language.DANSKE]: 'NEM-ID login',
            }),
            password: formatMessage(lang, {
                [Language.ENGLISH]: 'NEM-ID code ',
                [Language.DANSKE]: 'NEM-ID kode ',
            }),
            help: formatMessage(lang, {
                [Language.ENGLISH]: '1. Enter your NEM-ID Login and Continue\n2. Approve with your NEM-ID\n3. Enter your 6-digit mobile bank code from Jyske Bank',
                [Language.DANSKE]: '1. Indtast dit NEM-ID Login og tryk Fortsæt\n2. Godkend med dit NEM-ID\n3. Indtast din 6-cifret mobilbank kode fra Jyske Bank',
            }),
            nemIdKeycardHelp: formatMessage(lang, {
                [Language.ENGLISH]: 'Enter the code from your NemID key card. See which code to use further down.',
                [Language.DANSKE]: 'Indtast koden fra dit NemID nøglekort. Se hvilken kode du skal bruge nedenfor.',
            }),
            nemIdKeycardField: formatMessage(lang, {
                [Language.ENGLISH]: 'NemID Key card code',
                [Language.DANSKE]: 'NemID Nøglekort kode',
            }),
            nemIdAppHelp: formatMessage(lang, {
                [Language.ENGLISH]: 'Enter 6-digit mobile bank code',
                [Language.DANSKE]: 'Indtast 6-cifret mobilbank kode',
            }),
            nemIdAppField: formatMessage(lang, {
                [Language.ENGLISH]: 'Mobile bank code',
                [Language.DANSKE]: 'Mobilebank kode',
            }),
        },
        {
            bankId: ['nordea_new'],
            username: formatMessage(lang, {
                [Language.ENGLISH]: 'NEM-ID login',
                [Language.DANSKE]: 'NEM-ID login',
            }),
            password: formatMessage(lang, {
                [Language.ENGLISH]: 'NEM-ID code',
                [Language.DANSKE]: 'NEM-ID kode',
            }),
            help: formatMessage(lang, {
                [Language.ENGLISH]: '1. Enter your NEM-ID Login and Continue\n2. Approve with your NEM-ID\n3. Enter your personal code from Nordea\n4. Approve with your NEM-ID',
                [Language.DANSKE]: '1. Indtast dit NEM-ID Login og tryk Fortsæt\n2. Godkend med dit NEM-ID\n3. Indtast din personlige kode fra Nordea\n4. Godkend med dit NEM-ID',
            }),
            nemIdKeycardHelp: formatMessage(lang, {
                [Language.ENGLISH]: 'Enter your personal code from the Nordea mobile bank app.',
                [Language.DANSKE]: 'Indtast din personlige kode fra Nordea mobil bank appen.',
            }),
            nemIdKeycardField: formatMessage(lang, {
                [Language.ENGLISH]: 'Enter personal code',
                [Language.DANSKE]: 'Indtast personlig kode',
            }),
            nemIdAppHelp: formatMessage(lang, {
                [Language.ENGLISH]: 'Enter your personal code from the Nordea mobile bank app.',
                [Language.DANSKE]: 'Indtast din personlige kode fra Nordea mobil bank appen.',
            }),
            nemIdAppField: formatMessage(lang, {
                [Language.ENGLISH]: 'Enter personal code',
                [Language.DANSKE]: 'Indtast personlig kode',
            }),
        },
        {
            bankId: ['nordnet'],
            username: formatMessage(lang, {
                [Language.ENGLISH]: 'Username',
                [Language.DANSKE]: 'Brugernavn',
            }),
            password: formatMessage(lang, {
                [Language.ENGLISH]: 'Password',
                [Language.DANSKE]: 'Kodeord',
            }),
            help: formatMessage(lang, {
                [Language.ENGLISH]: 'To add Nordnet you must enter Username and Password to Nordnet, and not your Nem-ID.',
                [Language.DANSKE]: 'For at tilføje Nordnet, skal du indtaste brugernavn samt kodeord til Nordnet, ikke dit Nem-ID.',
            }),
        },
        {
            bankId: ['saxo'],
            username: formatMessage(lang, {
                [Language.ENGLISH]: 'User ID',
                [Language.DANSKE]: 'Bruger ID',
            }),
            password: formatMessage(lang, {
                [Language.ENGLISH]: 'Password',
                [Language.DANSKE]: 'Kodeord',
            }),
            help: formatMessage(lang, {
                [Language.ENGLISH]: 'To add Saxo Bank and Saxo Investor you must enter your User ID and Password.',
                [Language.DANSKE]: 'For at tilføje Saxo Bank og Saxo Investor skal du indtaste bruger ID samt kodeord.',
            }),
        },
        {
            bankId: ['dpa'],
            username: formatMessage(lang, {
                [Language.ENGLISH]: 'NEM-ID login',
                [Language.DANSKE]: 'NEM-ID login',
            }),
            password: formatMessage(lang, {
                [Language.ENGLISH]: 'NEM-ID code',
                [Language.DANSKE]: 'NEM-ID kode',
            }),
            help: formatMessage(lang, {
                [Language.ENGLISH]: 'To add Danica to Atlaz you must use your NEM-ID login and code.',
                [Language.DANSKE]: 'For at tilføje Danica til Atlaz, skal du anvende dit NEM-ID login og kode.',
            }),
        },
        {
            bankId: ['danske'],
            username: formatMessage(lang, {
                [Language.ENGLISH]: 'CPR number (without dash)',
                [Language.DANSKE]: 'CPR nummer (uden bindestreg)',
            }),
            password: formatMessage(lang, {
                [Language.ENGLISH]: 'Mobilebank code',
                [Language.DANSKE]: 'Mobilbank kode',
            }),
            help: formatMessage(lang, {
                [Language.ENGLISH]: '1. Enter your CPR number and Mobile bank code\n2. Click Continue\n3. Approve with NemID',
                [Language.DANSKE]: '1. Indtast dit CPR nummer og Mobilbank kode\n2. Tryk på Fortsæt\n3. Godkend med NemID',
            }),
            nemIdKeycardHelp: formatMessage(lang, {
                [Language.ENGLISH]: 'Enter the code from your NemID key card. See which code to use further down.',
                [Language.DANSKE]: 'Indtast koden fra dit NemID nøglekort. Se hvilken kode du skal bruge nedenfor.',
            }),
            nemIdKeycardField: formatMessage(lang, {
                [Language.ENGLISH]: 'NemID Key card code',
                [Language.DANSKE]: 'NemID Nøglekort kode',
            }),
        },
    ],
    [lang],
);

export const useDefaultBankTerms = (lang: Language): BankTerms => useMemo<BankTerms>(
    () => ({
        username: formatMessage(lang, {
            [Language.ENGLISH]: 'CPR number (without dash)',
            [Language.DANSKE]: 'CPR nummer (uden bindestreg)',
        }),
        usernamePlaceholder: formatMessage(lang, {
            [Language.ENGLISH]: 'CPR number (without dash)',
            [Language.DANSKE]: 'CPR nummer (uden bindestreg)',
        }),
        password: formatMessage(lang, {
            [Language.ENGLISH]: 'Mobilebank code',
            [Language.ENGLISH]: 'Mobilbank kode',
        }),
        passwordPlaceholder: formatMessage(lang, {
            [Language.ENGLISH]: 'Mobilebank code',
            [Language.ENGLISH]: 'Mobilbank kode',
        }),
        help: formatMessage(lang, {
            [Language.ENGLISH]: '1. Enter your CPR number\n2. Enter your Mobile bank code\n3. Click Continue',
            [Language.DANSKE]: '1. Indtast dit CPR nummer\n2. Indtast din Mobilbank kode\n3. Tryk på Fortsæt',
        }),
        nemIdKeycardHelp: formatMessage(lang, {
            [Language.ENGLISH]: 'To connect {bankName}, you must enter the code you have received on your mobile device.',
            [Language.DANSKE]: 'For at tilknytte {bankName}, skal du indtaste den kodedu har modtaget på din mobil telefon.',
        }),
        nemIdKeycardField: formatMessage(lang, {
            [Language.ENGLISH]: 'Security code',
            [Language.DANSKE]: 'Sikkerhedscode',
        }),
        nemIdAppHelp: formatMessage(lang, {
            [Language.ENGLISH]: 'To connect {bankName}, you must enter the code you have received on your mobile device.',
            [Language.DANSKE]: 'For at tilknytte {bankName}, skal du indtaste den kodedu har modtaget på din mobil telefon.',
        }),
        nemIdAppField: formatMessage(lang, {
            [Language.ENGLISH]: 'NemId code',
            [Language.DANSKE]: 'NemId kode',
        }),
        mitIdUsername: formatMessage(lang, {
            [Language.ENGLISH]: 'Enter MitID User-ID',
            [Language.DANSKE]: 'Indtast MitID Bruger-ID',
        }),
        personalCodeMitId: formatMessage(lang, {
            [Language.ENGLISH]: 'Enter your MitID password',
            [Language.DANSKE]: 'Indtast din MitID kode',
        }),
        personalCodeMitIdPlaceholder: formatMessage(lang, {
            [Language.ENGLISH]: 'Enter your MitID password',
            [Language.DANSKE]: 'Indtast din MitID kode',
        }),
    }),
    [lang],
);
