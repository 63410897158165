export const atlazColors = [
    '#82C8D7',
    '#ff6f61',
    '#42B498',
    '#000000',
];

export const proveoColors = [
    '#03AABE',
    '#0396F0',
    '#03C8FF',
    '#ED6D69',
    '#FF5A28',
    '#FF1E1E',
    '#BF4D4D',
    '#46b482',
    '#46b45e',
    '#83b446',
    '#1BFF9A',
    '#b2b2b2',
    '#000000',
];
