import React from 'react';
import {render} from 'react-dom';
import {Provider} from 'react-redux';
import {AppContainer} from 'react-hot-loader';
import {ConnectedRouter} from 'connected-react-router';
import {compose as reduxCompose} from 'redux';
import {renderHeader, renderGA} from 'components/head';
import {TITLE} from 'config/head';
import Routes from './routes';
import configureStore, {history} from './store/configureStore';
import './styles/global.css';
import './styles/index.scss';
import './module';

// eslint-disable-next-line no-underscore-dangle
const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
const compose = devToolsExtension ? devToolsExtension({name: 'AVEND'}) : reduxCompose;

const store = configureStore({}, {
    compose,
});

const renderApp = (history) => {
    const vdom = (
        <AppContainer>
            <React.Fragment>
                {renderHeader(TITLE.PROVEO)}
                <Provider store={store}>
                    <ConnectedRouter history={history}>
                        <Routes />
                    </ConnectedRouter>
                </Provider>
            </React.Fragment>
        </AppContainer>
    );

    render(vdom, document.getElementById('root'));
};

renderApp(history);

if (module.hot) {
    module.hot.accept('./routes', () => {
        renderApp(history);
    });
}
