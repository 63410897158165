import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import SelectField from 'react-md/lib/SelectFields/SelectField';
import TextField from 'react-md/lib/TextFields/TextField';
import SelectionControl from 'react-md/lib/SelectionControls/SelectionControl';

import {connect} from '../../../store';
import {clientBenchmarkShape} from '../../../store/data/client-benchmark';
import {getReferenceData} from '../../../store/general-data';

class BenchmarkProperties extends React.PureComponent {
    static propTypes = {
        benchmark: clientBenchmarkShape,
        canChangeDefault: PropTypes.bool,
        className: PropTypes.string,
        errors: PropTypes.object,
        onChange: PropTypes.func,
        referenceData: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.state = {};
        if (props.referenceData) {
            Object.assign(this.state, this._processReferenceData(props.referenceData));
        }
    }

    componentWillReceiveProps(nextProps) {
        const {referenceData} = nextProps;
        if (this.props.referenceData !== referenceData) {
            this.setState(this._processReferenceData(referenceData));
        }
    }

    _processReferenceData = (referenceData) => ({
        assetClass: this._createFromIdNameList(referenceData.assetClass),
        assetSubClass: this._createAssetSubClassMap(referenceData.assetClass),
        currency: this._createCurrencyList(referenceData.currency),
        region: this._createFromIdNameList(referenceData.region),
    });

    _createFromIdNameList = (idNameList) => idNameList.map(({id, name}) => ({value: id, label: name}));

    _createCurrencyList = (currencyReferenceData) => currencyReferenceData.map(({id, description}) => ({
        value: id,
        label: `${id} (${description})`,
    }));

    _createAssetSubClassMap = (assetClassReferenceData) => assetClassReferenceData.reduce(
        (result, item) => Object.assign(result, {[item.id]: this._createFromIdNameList(item.subClasses)}),
        {},
    );

    _handleNameChange = (name) => {
        const {benchmark, onChange} = this.props;
        onChange && onChange({...benchmark, name});
    };

    _handleCurrencyChange = (currency) => {
        const {benchmark, onChange} = this.props;
        onChange && onChange({...benchmark, currency});
    };

    _handleAssetClassIdChange = (assetClassId) => {
        const {benchmark, onChange} = this.props;
        onChange && onChange({...benchmark, assetClassId, assetSubClassId: undefined});
    };

    _handleAssetSubClassIdChange = (assetSubClassId) => {
        const {benchmark, onChange} = this.props;
        onChange && onChange({...benchmark, assetSubClassId});
    };

    _handleRegionIdChange = (regionId) => {
        const {benchmark, onChange} = this.props;
        onChange && onChange({...benchmark, regionId});
    };

    _handleDefaultChange = (isDefault) => {
        const {benchmark, onChange} = this.props;
        onChange && onChange({...benchmark, isDefault});
    };

    render() {
        const {benchmark, canChangeDefault, className, errors} = this.props;
        const {assetClass, assetSubClass, currency, region} = this.state;
        const benchmarkId = benchmark && benchmark.id ? benchmark.id : undefined;

        const assetSubClassList = benchmark && benchmark.assetClassId ? assetSubClass[benchmark.assetClassId] : [];

        return (
            <div className={cn('prv-benchmark-properties', className)}>
                <TextField
                    error={errors && errors.name}
                    fullWidth
                    id={`prv-benchmark-properties-${benchmarkId || 'new'}-name`}
                    label="Name"
                    onChange={this._handleNameChange}
                    required
                    value={(benchmark && benchmark.name) || ''}
                />
                <SelectField
                    error={errors && errors.currency}
                    id={`prv-benchmark-properties-${benchmarkId || 'new'}-currency`}
                    fullWidth
                    label="Currency"
                    menuItems={currency}
                    onChange={this._handleCurrencyChange}
                    required
                    simplifiedMenu={false}
                    value={benchmark ? benchmark.currency : ''}
                />
                <SelectField
                    error={errors && errors.assetClass}
                    id={`prv-benchmark-properties-${benchmarkId || 'new'}-asset-class`}
                    fullWidth
                    label="Asset Class"
                    menuItems={assetClass}
                    onChange={this._handleAssetClassIdChange}
                    required
                    simplifiedMenu={false}
                    value={benchmark ? benchmark.assetClassId : ''}
                />
                {!!(assetSubClassList && assetSubClassList.length) &&
                <SelectField
                    error={errors && errors.assetSubClass}
                    id={`prv-benchmark-properties-${benchmarkId || 'new'}-asset-sub-class`}
                    fullWidth
                    label="Asset Sub Class"
                    menuItems={assetSubClassList}
                    onChange={this._handleAssetSubClassIdChange}
                    required
                    simplifiedMenu={false}
                    value={benchmark ? benchmark.assetSubClassId : ''}
                />}
                <SelectField
                    error={errors && errors.region}
                    id={`prv-benchmark-properties-${benchmarkId || 'new'}-region`}
                    fullWidth
                    label="Region"
                    menuItems={region}
                    onChange={this._handleRegionIdChange}
                    required
                    simplifiedMenu={false}
                    value={benchmark ? benchmark.regionId : ''}
                />
                <SelectionControl
                    defaultChecked={benchmark ? benchmark.isDefault : false}
                    disabled={!canChangeDefault}
                    id={`prv-benchmark-properties-${benchmarkId || 'new'}-is-default`}
                    label="Default Benchmark"
                    name={`prv-benchmark-properties-${benchmarkId || 'new'}-is-default`}
                    onChange={this._handleDefaultChange}
                    type="switch"
                    value={benchmark ? benchmark.isDefault : false}
                />
            </div>
        );
    }
}

export default connect({referenceData: getReferenceData})(BenchmarkProperties);
