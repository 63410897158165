import {actionDispatchers} from '../collections/saga_helpers';
import {getFetchParams} from './index';

export default {
    ...actionDispatchers('client-benchmarks', {
        fetch: {
            apiCall: 'fetchClientBenchmarksList',
            getFetchParams,
        },
    }),
};
