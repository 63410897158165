import React, {PureComponent} from 'react';
import cn from 'classnames';
import Button from 'react-md/lib/Buttons/Button';
import FontIcon from 'react-md/lib/FontIcons/FontIcon';
import MenuButton from 'react-md/lib/Menus/MenuButton';
import PropTypes from 'prop-types';
import isArray from 'lodash/isArray';
import join from 'lodash/join';
import {actionsProperties} from './resource-table';
import styles from './resource-table.module.css';
import ActionViewOptions from './action-view-options';

export const columnProps = {
    field: PropTypes.string,
    render: PropTypes.func,
    isSingleLine: PropTypes.bool,
    className: PropTypes.string,
};

export default class ResourceTableRow extends PureComponent {
    static propTypes = {
        actionView: PropTypes.string,
        columns: PropTypes.arrayOf(PropTypes.shape(columnProps)).isRequired,
        getId: PropTypes.func,
        item: PropTypes.shape({status: PropTypes.string}).isRequired,
        isSelected: PropTypes.bool,
        isActive: PropTypes.bool,
        isTotal: PropTypes.bool,
        onSelect: PropTypes.func,
        canSelect: PropTypes.func,
        onEdit: PropTypes.func,
        canEdit: PropTypes.func,
        onDelete: PropTypes.func,
        canDelete: PropTypes.func,
        onToggleBlock: PropTypes.func,
        canBlock: PropTypes.func,
        onRecalculate: PropTypes.func,
        canRecalculate: PropTypes.func,
        onForceRecalculate: PropTypes.func,
        canForceRecalculate: PropTypes.func,
        wrapApiAction: PropTypes.func,
    };

    static defaultProps = {
        actionView: ActionViewOptions.Buttons,
    };

    onSelect = () => this.props.onSelect(this.props.item);
    onEdit = () => this.props.onEdit(this.props.item);

    renderValue = (item, field, render) => {
        const value = item[field];
        const {wrapApiAction, isTotal} = this.props;
        if (render) {
            if (isTotal && value == null) {
                return null;
            }
            return render(value, item, field, {wrapApiAction});
        }
        return isArray(value) ? join(value, ', ') : value;
    };

    renderButtonActions = (editable, deletable, blockable, recalculatable, forceRecalculatable) => {
        const {item, onEdit, onToggleBlock, onDelete, onRecalculate, onForceRecalculate} = this.props;
        return (
            <td
                className={cn(
                    styles.contentCell,
                    styles.actionsCell,
                )}
            >
                {editable &&
                /* eslint-disable-next-line jsx-a11y/click-events-have-key-events */
                <span
                    style={{
                        width: `${actionsProperties.details.width}px`,
                        marginRight: `${(blockable || deletable) ? actionsProperties.margin : 0}px`,
                    }}
                    className={styles.detailsAction}
                    onClick={() => onEdit(item)}
                >
                    {actionsProperties.details.text}
                </span>}
                {blockable &&
                /* eslint-disable-next-line jsx-a11y/click-events-have-key-events */
                <span
                    style={{
                        width: `${item.status === 'blocked'
                            ? actionsProperties.unblock.width
                            : actionsProperties.block.width}px`,
                        marginRight: `${deletable ? actionsProperties.margin : 0}px`,
                    }}
                    className={styles.blockAction}
                    onClick={() => onToggleBlock(item)}
                >
                    {item.status === 'blocked'
                        ? actionsProperties.unblock.text
                        : actionsProperties.block.text
                    }
                </span>}
                {recalculatable &&
                /* eslint-disable-next-line jsx-a11y/click-events-have-key-events */
                <span
                    style={{
                        width: `${actionsProperties.recalculate.width}px`,
                    }}
                    className={styles.recalculateAction}
                    onClick={() => onRecalculate(item)}
                >
                    {actionsProperties.recalculate.text}
                </span>}
                {forceRecalculatable &&
                /* eslint-disable-next-line jsx-a11y/click-events-have-key-events */
                <span
                    style={{
                        width: `${actionsProperties.forceRecalculate.width}px`,
                    }}
                    className={styles.forceRecalculateAction}
                    onClick={() => onForceRecalculate(item)}
                >
                    {actionsProperties.forceRecalculate.text}
                </span>}
                {deletable &&
                /* eslint-disable-next-line jsx-a11y/click-events-have-key-events */
                <span
                    style={{
                        width: `${actionsProperties.delete.width}px`,
                    }}
                    className={styles.deleteAction}
                    onClick={() => onDelete(item)}
                >
                    {actionsProperties.delete.text}
                </span>}
            </td>
        );
    };

    renderIconActions = (editable, deletable, blockable, recalculatable, forceRecalculatable) => {
        const {item, onEdit, onToggleBlock, onDelete, onRecalculate, onForceRecalculate} = this.props;
        return (
            <td
                className={cn(
                    styles.contentCell,
                    styles.actionsCell,
                )}
            >
                {editable &&
                <Button
                    icon
                    onClick={() => onEdit(item)}
                    tooltipLabel={actionsProperties.details.text}
                    tooltipPosition="top"
                >
                    {actionsProperties.details.icon}
                </Button>}
                {blockable &&
                <Button
                    icon
                    onClick={() => onToggleBlock(item)}
                    tooltipLabel={item.status === 'blocked'
                        ? actionsProperties.unblock.text
                        : actionsProperties.block.text}
                    tooltipPosition="top"
                >
                    {item.status === 'blocked'
                        ? actionsProperties.unblock.icon
                        : actionsProperties.block.icon
                    }
                </Button>}
                {recalculatable &&
                <Button
                    icon
                    onClick={() => onRecalculate(item)}
                    tooltipLabel={actionsProperties.recalculate.text}
                    tooltipPosition="top"
                >
                    {actionsProperties.recalculate.icon}
                </Button>}
                {forceRecalculatable &&
                <Button
                    icon
                    onClick={() => onForceRecalculate(item)}
                    tooltipLabel={actionsProperties.forceRecalculate.text}
                    tooltipPosition="top"
                >
                    {actionsProperties.forceRecalculate.icon}
                </Button>}
                {deletable &&
                <Button
                    icon
                    onClick={() => onDelete(item)}
                    tooltipLabel={actionsProperties.delete.text}
                    tooltipPosition="top"
                >
                    {actionsProperties.delete.icon}
                </Button>}
            </td>
        );
    };

    renderMenuActions = (
        editable,
        deletable,
        blockable,
        recalculatable,
    ) => {
        const {item, getId, onEdit, onToggleBlock, onDelete, onRecalculate} = this.props;
        const menuItems = [];
        if (editable) {
            menuItems.push({
                leftIcon: <FontIcon>{actionsProperties.details.icon}</FontIcon>,
                primaryText: actionsProperties.details.text,
                onClick: () => onEdit(item),
            });
        }
        if (blockable) {
            if (item.status === 'blocked') {
                menuItems.push({
                    leftIcon: <FontIcon>{actionsProperties.unblock.icon}</FontIcon>,
                    primaryText: actionsProperties.unblock.text,
                    onClick: () => onToggleBlock(item),
                });
            } else {
                menuItems.push({
                    leftIcon: <FontIcon>{actionsProperties.block.icon}</FontIcon>,
                    primaryText: actionsProperties.block.text,
                    onClick: () => onToggleBlock(item),
                });
            }
        }
        if (recalculatable) {
            menuItems.push({
                leftIcon: <FontIcon>{actionsProperties.recalculate.icon}</FontIcon>,
                primaryText: actionsProperties.recalculate.text,
                onClick: () => onRecalculate(item),
            });
        }
        if (deletable) {
            menuItems.push({
                leftIcon: <FontIcon>{actionsProperties.delete.icon}</FontIcon>,
                primaryText: actionsProperties.delete.text,
                onClick: () => onDelete(item),
            });
        }
        return (
            <td
                className={cn(
                    styles.contentCell,
                    styles.actionsCell,
                )}
            >
                <MenuButton
                    icon
                    id={`row-menu-${item[getId(item)]}`}
                    menuItems={menuItems}
                    listInline
                    centered
                    anchor={{
                        x: MenuButton.HorizontalAnchors.INNER_RIGHT,
                        y: MenuButton.VerticalAnchors.OVERLAP,
                    }}
                    simplifiedMenu={false}
                >
                    more_vert
                </MenuButton>
            </td>
        );
    };

    renderActions = (editable, deletable, blockable, recalculatable, forceRecalculatable) => {
        if (editable || deletable || blockable || recalculatable || forceRecalculatable) {
            switch (this.props.actionView) {
                case ActionViewOptions.Icons:
                    return this.renderIconActions(editable, deletable, blockable, recalculatable, forceRecalculatable);
                case ActionViewOptions.Menu:
                    return this.renderMenuActions(editable, deletable, blockable, recalculatable, forceRecalculatable);
                default:
                    return this.renderButtonActions(
                        editable,
                        deletable,
                        blockable,
                        recalculatable,
                        forceRecalculatable,
                    );
            }
        }
        return null;
    };

    render() {
        const {
            columns,
            item,
            isSelected,
            isActive,
            isTotal,
            onSelect,
            canSelect,
            canEdit,
            onEdit,
            canDelete,
            onDelete,
            canBlock,
            onToggleBlock,
            canRecalculate,
            onRecalculate,
            canForceRecalculate,
            onForceRecalculate,
        } = this.props;

        const editable = canEdit && canEdit(item) && onEdit;
        const deletable = canDelete && canDelete(item) && onDelete;
        const blockable = canBlock && canBlock(item) && onToggleBlock;
        const recalculatable = canRecalculate && canRecalculate(item) && onRecalculate;
        const forceRecalculatable = canForceRecalculate && canForceRecalculate(item) && onForceRecalculate;
        return (
            <tr
                className={
                    cn(styles.row, {[styles.active]: isActive, [styles.total]: isTotal}, 'prv-resource-table-row')
                }
            >
                {onSelect && canSelect(item) && (
                    <td className={cn(styles.selectRowAction, {[styles.selected]: isSelected})}>
                        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
                        <div onClick={this.onSelect}/>
                    </td>
                )}
                {columns.map(({field, render, isSingleLine, className}, idx) => (
                    <td
                        key={field || idx}
                        /* FIXME: wrong class names order, the outer one should be put at the end */
                        className={cn(className, styles.contentCell, {
                            [styles.singleLine]: isSingleLine,
                        })}
                    >
                        {this.renderValue(item, field, render)}
                    </td>
                ))}
                {this.renderActions(editable, deletable, blockable, recalculatable, forceRecalculatable)}
            </tr>
        );
    }
}
