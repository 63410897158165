import _ from 'lodash';
import {handleActions, apiActionHandler} from 'store/helpers/actions';
import {readLimit} from 'store/data/general-data';
import {readClientBenchmark, readSharedBenchmark} from 'store/data/client';

import {
    fetchLimits,
    fetchReferenceData,
    fetchClientBenchmarks,
    fetchSharedBenchmarks,
} from './actions';

const initialState = {
    limitsLoading: false,
    limits: null,
    benchmarksLoading: false,
    sharedBenchmarksLoading: false,
    benchmarks: null,
    sharedBenchmarks: [],
    error: null,
    referenceData: undefined,
    referenceDataLoading: false,
};

export const getError = ({error}) => error;

export const isLimitsLoading = ({limitsLoading}) => limitsLoading;
export const getLimits = ({limits}) => limits;

export const isReferenceDataLoading = ({referenceDataLoading}) => referenceDataLoading;
export const getReferenceData = ({referenceData}) => referenceData;

export const isBenchmarksLoading = ({benchmarksLoading, sharedBenchmarksLoading}) =>
    benchmarksLoading || sharedBenchmarksLoading;
export const getBenchmarks = ({benchmarks}) => benchmarks;
export const getSharedBenchmarks = ({sharedBenchmarks}) => sharedBenchmarks;

export default handleActions({
    [fetchLimits]: (state, action) => apiActionHandler(state, action, {
        loading: 'limitsLoading', result: 'limits', reader: p => _.map(p, readLimit),
    }),
    [fetchReferenceData]: (state, action) => apiActionHandler(state, action, {
        loading: 'referenceDataLoading',
        result: 'referenceData',
        reader: payload => payload,
    }),
    [fetchClientBenchmarks]: (state, action) => apiActionHandler(state, action, {
        loading: 'benchmarksLoading', result: 'benchmarks', reader: p => _.map(p.items, readClientBenchmark),
    }),
    [fetchSharedBenchmarks]: (state, action) => apiActionHandler(state, action, {
        loading: 'sharedBenchmarksLoading',
        result: 'sharedBenchmarks',
        reader: p => _.map(p.items, readSharedBenchmark),
    }),
}, initialState);
