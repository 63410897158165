import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './moire.module.css';

export default class Moire extends Component {
    static propTypes = {
        children: PropTypes.node,
        onCancel: PropTypes.func.isRequired,
        className: PropTypes.string,
    };

    componentDidMount() {
        window.addEventListener('keyup', this.onKeyUp);
    }

    componentWillUnmount() {
        window.removeEventListener('keyup', this.onKeyUp);
    }

    onKeyUp = (event) => {
        if (event.keyCode === 27) {
            this.props.onCancel();
        }
    };

    render() {
        return (
            <div className={cn(styles.moire, this.props.className)}>
                {this.props.children}
            </div>
        );
    }
}
