import bind from '../helpers/bind';
import reducer, * as getters from './reducer';

const {binder, wrapAll} = bind(reducer);
export default binder;

export const {
    // FIXME: fix to have proper types
    // @ts-ignore
    getAccountGroups, getExpandedPortfolioIds, getOriginalTree, getSelectedPortfolio, getTree, getError, getLoading,
    // @ts-ignore
    getPortfolioBeingRenamedId, getOriginalStartDate, getStartDate, getObsoleteAssets, getObsoleteCustodyAccounts,
} = wrapAll(getters);
