import {
    apiActionHandler,
    handleActions,
} from 'store/helpers/actions';
import {readCustomer} from 'store/data/notification';
// import {actionHandlers} from 'store/collections/reducer_helpers';

import {
    chooseNotificationType,
    chooseCustomers,
    fetchCustomers,
} from './actions';

export {
    getCollectionState,
    getFetchParams,
} from 'store/collections/reducer_helpers';

const initialState = {
    customers: [],
    selectedCustomers: [],
    notificationType: null,
    message: null,
    subject: null,
    id: null,
    isCreatingNotification: false,
    createNotificationError: null,
};

export const getCustomers = ({customers}) => customers;
export const getNotification = ({
    selectedCustomers,
    notificationType,
    message,
    subject,
    id,
}) => ({
    customers: selectedCustomers,
    type: notificationType,
    message,
    subject,
    id,
});
export const isCreatingNotification = ({creatingNotification}) => creatingNotification;
export const getCreateNotificationError = ({createNotificationError}) => createNotificationError;


export default handleActions({
    [fetchCustomers]: (state, action) => apiActionHandler(
        state,
        action,
        {
            result: 'customers',
            reader: customers =>
                customers.items.map(readCustomer),
        },
    ),
}, initialState);
