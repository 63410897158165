import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import _ from 'lodash';
import {connect} from 'store';
import {extrasMap} from 'store/api/api';

import {fetchAccounts} from 'store/accounts/actions';
import {getAccounts, isLoading as isAccountsLoading} from 'store/accounts';

import {fetchClientsAms} from 'store/clients/actions';
import {getPinnedClient, isClientsAmsLoading, getClientsAms} from 'store/clients';
import {accountShape, assetsManagerShape, clientShape} from 'store/data/client';

import {money, percentTrend} from 'helpers/formatters';

import Table from 'components/form-table';
import Spinner from 'components/loading-spinner/loading-spinner';
import AccountListHeader from '../account/account-list-header';

const renderReturn = (v) => {
    let color;
    if (v > 0) color = '#34a793';
    if (v === 0) color = '#e5ae60';
    if (v < 0) color = '#dd796b';
    return (<div style={{color}}>{percentTrend(v / 100.0)}</div>);
};

const getRiskLevelClassName = level => (level >= 1 && level <= 7 ? `prv-client-risk__risk-level--${level}` : '');

const renderRiskLevel = level => (
    <div className={cn('prv-client-risk__risk-level', getRiskLevelClassName(level))}>{level}</div>
);

const columns = [
    {field: 'bank', label: 'Bank'},
    {field: 'name', label: 'Account Name'},
    {field: 'totalRiskLevel', label: 'Risk Level', render: renderRiskLevel},
    {field: 'currency', label: 'Currency'},
    {field: 'value', label: 'Value', render: money},
    {field: 'return', label: 'Return', render: renderReturn},
];

class RiskPage extends Component {
    static propTypes = {
        accounts: PropTypes.arrayOf(accountShape),
        assetsManagers: PropTypes.arrayOf(assetsManagerShape),
        client: clientShape,
        accountsLoading: PropTypes.bool,
        amsLoading: PropTypes.bool,
        fetchAccounts: PropTypes.func.isRequired,
        fetchClientsAms: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {selectedAm: null};
    }

    componentWillMount() {
        const {id} = this.props.client;
        this.props.fetchAccounts({id, extras: [extrasMap.riskLevel]});
        this.props.fetchClientsAms({id, extras: [extrasMap.riskLevel]});
    }

    onSelectAm = (am) => {
        const {id} = this.props.client;
        this.props.fetchAccounts({id, amId: am && am.id, extras: [extrasMap.riskLevel]});
        this.setState({selectedAm: am});
    };

    renderManagers = () => (
        <div className="prv-client-risk__asset-manager-filter">
            <div
                className={cn('prv-client-risk__asset-manager-filter-item', {
                    'prv-client-risk__asset-manager-filter-item--selected': this.state.selectedAm === null,
                })}
                onClick={() => this.onSelectAm(null)}
            >
                All Managers
            </div>
            {
                // TODO: refactor to extract this as a separate component
                _.map(this.props.assetsManagers, am => (
                    <div
                        key={am.id}
                        className={cn('prv-client-risk__asset-manager-filter-item', {
                            'prv-client-risk__asset-manager-filter-item--selected':
                                this.state.selectedAm && this.state.selectedAm.id === am.id,
                        })}
                        onClick={() => this.onSelectAm(am)}
                    >
                        <div>{am.name}</div>
                        <div className={cn('prv-client-risk__risk-level', getRiskLevelClassName(am.totalRiskLevel))}>
                            {am.totalRiskLevel}
                        </div>
                    </div>
                ))
            }
        </div>
    );

    renderAggregatedRiskLevel = () => (
        <div className="prv-client-risk__aggregated-risk">
            <div className="prv-client-risk__aggregated-risk-title">Aggregated Risk Level</div>
            <div className="prv-client-risk__aggregated-risk-level-list">
                {_.times(7, i => (
                    <div
                        className={cn(
                            'prv-client-risk__risk-level',
                            getRiskLevelClassName(i + 1),
                            'prv-client-risk__aggregated-risk-level',
                            {
                                'prv-client-risk__aggregated-risk-level--selected':
                                this.state.selectedAm && this.state.selectedAm.totalRiskLevel === i + 1,
                            },
                        )}
                        key={i}
                    >
                        {i + 1}
                    </div>
                ))}
            </div>
        </div>
    );

    renderTitle = () => (
        <AccountListHeader
            filteredItemCount={this.state.filteredItemsCount}
            totalItemCount={(this.props.accounts && this.props.accounts.length) || 0}
        />
    );

    renderAccounts = () => (
        <Table
            className="prv-client-risk__account-table"
            title={this.renderTitle()}
            items={this.props.accounts}
            columns={columns}
            loading={this.props.accountsLoading}
            searchable={false}
        />
    );

    render() {
        const {amsLoading} = this.props;
        if (amsLoading) return (<Spinner/>);
        return (
            <div className="prv-client-risk">
                <div className="prv-client-risk__content">
                    {this.renderManagers()}
                    <div className="prv-client-risk__risk-list">
                        {this.renderAggregatedRiskLevel()}
                        {this.renderAccounts()}
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    {
        accounts: getAccounts,
        assetsManagers: getClientsAms,
        client: getPinnedClient,
        accountsLoading: isAccountsLoading,
        amsLoading: isClientsAmsLoading,
    },
    {fetchAccounts, fetchClientsAms},
)(RiskPage);
