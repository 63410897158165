import invariant from 'invariant';
import {CustodyAccount, PortfolioNode} from '../types';
import {StructureState} from '../reducer';
import {nameFieldComparator} from '../helpers/sort';
import {findCustodyAccountPortfolioIndex} from '../helpers/structure';

const unlinkAccountReducer = (state: StructureState, {payload: account}: {payload: CustodyAccount}) => {
    const {accountGroups, tree} = state;

    // ensure account is now present in groups and is marked as not linked
    const groupIndex = accountGroups.findIndex(({id, isCashAccountGroup}) => !isCashAccountGroup && id === account.id);
    const updatedGroups = groupIndex >= 0
        // update as not linked
        ? [
            ...accountGroups.slice(0, groupIndex),
            {...accountGroups[groupIndex], isLinked: false},
            ...accountGroups.slice(groupIndex + 1),
        ]
        // add to groups
        : [...accountGroups, {...account, isLinked: false, assets: []}].sort(nameFieldComparator);

    // unlink account from portfolio
    const portfolioIndex = findCustodyAccountPortfolioIndex(tree, account.id);
    invariant(
        portfolioIndex >= 0,
        `Can't unlink account (${JSON.stringify(account)}), linked portfolio not found: ${JSON.stringify(tree)}`,
    );
    const portfolio = tree[portfolioIndex] as PortfolioNode;
    const updatedPortfolio = {
        ...portfolio,
        custodyAccounts: portfolio.custodyAccounts.filter(({id}) => id !== account.id),
    };

    // update selected portfolio
    const selectedPortfolio = state.selectedPortfolio === portfolio ? updatedPortfolio : state.selectedPortfolio;

    // update tree
    return {
        ...state,
        accountGroups: updatedGroups,
        selectedPortfolio,
        tree: [
            ...tree.slice(0, portfolioIndex),
            updatedPortfolio,
            ...tree.slice(portfolioIndex + 1),
        ],
    };
};

export default unlinkAccountReducer;
