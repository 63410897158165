import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {handleSubmitError} from 'components/forms';
import styles from './editing-table.module.css';

export default class EditingTable extends Component {
    static propTypes = {
        className: PropTypes.string,
        naked: PropTypes.bool,
        children: PropTypes.node.isRequired,
        actionTitle: PropTypes.string,
        onSubmit: PropTypes.func.isRequired,
        onCancel: PropTypes.func,
        handleSubmit: PropTypes.func.isRequired,
        pristine: PropTypes.bool,
    };

    constructor(props) {
        super(props);
        this.state = {buttonWidth: void 0};
    }

    componentDidMount() {
        const saveBtnWidth = this.saveButton ? this.saveButton.offsetWidth : 0;
        const cancelBtnWidth = this.cancelButton ? this.cancelButton.offsetWidth : 0;
        const width = Math.max(saveBtnWidth, cancelBtnWidth);
        // eslint-disable-next-line react/no-did-mount-set-state
        this.setState({buttonWidth: width + 1});
    }

    onSubmit = (event) => {
        const {onSubmit, handleSubmit} = this.props;
        const submit = (...args) => handleSubmitError(onSubmit(...args));
        return handleSubmit(submit)(event);
    };

    render() {
        const {className, naked, children, actionTitle, pristine} = this.props;
        const {buttonWidth} = this.state;
        return (
            <table className={cn(styles.table, className)}>
                {naked
                    ? children
                    : (
                        <tbody>
                            {children}
                            {actionTitle &&
                            <Row>
                                <div className={styles.buttonsWrapper}>
                                    {
                                        this.props.onCancel &&
                                        <button
                                            className={styles.cancelButton}
                                            style={{width: buttonWidth}}
                                            onClick={this.props.onCancel}
                                            ref={(button) => {
                                                this.cancelButton = button;
                                            }}
                                        >
                                            Cancel
                                        </button>
                                    }
                                    <button
                                        className={styles.saveButton}
                                        style={{width: buttonWidth}}
                                        onClick={this.onSubmit}
                                        ref={(button) => {
                                            this.saveButton = button;
                                        }}
                                        disabled={pristine}
                                    >
                                        {actionTitle}
                                    </button>
                                </div>
                            </Row>
                            }
                        </tbody>
                    )
                }
            </table>
        );
    }
}

export const Row = ({className, name = '', label, required, children}) => (
    <tr className={cn(styles.row, className)}>
        <td className={styles.childWrapper}>
            <div>
                {label &&
                /* eslint-disable-next-line jsx-a11y/label-has-for */
                <label htmlFor={name}>
                    {label}
                    {required && '*'}
                </label>
                }
            </div>
            {children}
        </td>
    </tr>
);

Row.propTypes = {
    className: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
    children: PropTypes.node.isRequired,
};


export const RowSeparator = ({className}) => (
    <Row className={cn(styles.separatorRow, className)}>
        <div className={styles.separator}/>
    </Row>
);

RowSeparator.propTypes = {
    className: PropTypes.string,
};

