import {actionDispatchers} from '../collections/saga_helpers';
import {getFetchParams} from './index';

export default {
    ...actionDispatchers('clients', {
        fetch: {
            apiCall: 'fetchClients',
            getFetchParams,
        },
        pin: {
            apiCall: 'fetchClientById',
        },
    }),
};
