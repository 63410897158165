import invariant from 'invariant';
import groupBy from 'lodash/groupBy';
import {AnyAssetList} from '../types';
import {StructureState} from '../reducer';
import {isAsset, isCashAccount} from '../helpers/assertion';
import {groupComparator, nameFieldComparator, sortStructure} from '../helpers/sort';
import {CASH_ACCOUNT_GROUP_TEMPLATE} from '../helpers/structure';

const removeAssetsReducer = (state: StructureState, {payload: assets}: {payload: AnyAssetList}) => {
    if (!assets.length || !state.selectedPortfolio) {
        return state;
    }

    // first, add assets back to groups and reset levels for these assets
    const assetGroups = groupBy(assets.filter(isAsset).map(asset => ({...asset, level: []})), 'accountId');
    const accountGroups = state.accountGroups.map(group => {
        const assets = assetGroups[group.id];
        if (assets) {
            delete assetGroups[group.id];
            return {...group, assets: [...group.assets, ...assets].sort(nameFieldComparator)};
        }
        return group;
    });
    // now add groups that were not present in state.accountGroups (because they were empty and linked)
    // see logic for removing such groups in the addAssetsReducer
    Object.entries(assetGroups).forEach(([accountId, groupAssets]) => {
        invariant(groupAssets.length > 0, `Empty group for assets: ${JSON.stringify(assets)}`);
        const name = groupAssets[0].accountName;
        accountGroups.push({id: accountId, name, assets: groupAssets, isLinked: true});
    });

    // next, add cash accounts to groups and reset levels for these assets
    const cashAccounts = assets.filter(isCashAccount).map(cashAccount => ({...cashAccount, level: []}));
    if (cashAccounts.length) {
        let cashAccountGroupIndex = accountGroups
            .findIndex(({isCashAccountGroup}) => isCashAccountGroup);
        if (cashAccountGroupIndex < 0) {
            accountGroups.unshift({...CASH_ACCOUNT_GROUP_TEMPLATE, assets: []});
            cashAccountGroupIndex = 0;
        }

        const cashAccountGroup = accountGroups[cashAccountGroupIndex];
        accountGroups[cashAccountGroupIndex] = {
            ...cashAccountGroup,
            assets: [...cashAccountGroup.assets, ...cashAccounts].sort(nameFieldComparator),
        };
    }

    // next, remove these assets from the tree
    const assetIds = new Set(assets.map(({id}) => id));
    return {
        ...state,
        accountGroups: accountGroups.sort(groupComparator),
        tree: sortStructure(state.tree
            .filter(node => !(isAsset(node) || isCashAccount(node)) || !assetIds.has(node.id)),
        ),
    };
};

export default removeAssetsReducer;
