import invariant from 'invariant';
import {BASE_API_URL, TOKEN_KEY} from 'config/api.config';
import * as pathnames from 'config/pathnames';
import {norkonEnpoint, auth} from 'config/onboarding';
import {UserScopes} from 'config/userscope';
import ApiBase from './base';

export const UserScopeHeader = 'X-Application';

export const extrasMap = {
    valueChange: 'valuechange',
    share: 'share',
    piechart: 'piechart',
    transations: 'transations',
    benchmark: 'benchmark',
    outperformance: 'outperformance',
    sharpeRatio: 'sharpeRatio',
    riskLevel: 'risklevel',
    riskReturn: 'riskreturn',
};

const parseExtras = (extras) => {
    invariant(extras && extras instanceof Array, 'extras must be array');
    if (extras.length === 0) return '';
    const extracted = extras.reduce((prev, cur) => `${prev},${cur}`);
    return `extras=${extracted}`;
};

const sortKeysToMake$TypeFirst = (keys) => keys.sort((a, b) => {
    if (a === '$type') {
        return -1;
    }
    return b === '$type' ? 1 : 0;
});

const serializeStructureToGuaranteePropertyOrder = (structure) =>
    `[${structure.map(node => JSON.stringify(node, sortKeysToMake$TypeFirst(Object.keys(node)))).join(',')}]`;

export default class Api extends ApiBase {
    constructor() {
        super({
            baseURL: BASE_API_URL,
        });
    }

    signup = params => this.post('/customers/selfregister', params, {
        headers: {
            [UserScopeHeader]: UserScopes.BORSEN_WEB,
        },
        auth,
    });

    checkUser = params => this.post('/customers/check_selfregister', params, {
        headers: {
            [UserScopeHeader]: UserScopes.BORSEN_WEB,
        },
        auth,
    })

    getAuthToken = () => window.sessionStorage.getItem(TOKEN_KEY);

    requestFileUpload = fileName => this.post(`/users/resources/upload_token/${encodeURIComponent(fileName)}`);

    signedUp = token => this.patch(`/invites/accept?id=${token}`);
    fetchProfile = ({...config}) => this.get('/users/me', config);

    fetchLimitsList = () => this.get('/limits');

    fetchClients = params => this.get('/customers', {params});
    createClient = params => this.post('/customers', {
        ...params,
        url: `${window.location.origin}${pathnames.LOGIN}#proveo_invite_token=`,
    });
    updateClient = ({id, ...params}) => this.put(`customers/${id}`, params);
    fetchClientById = ({id, ...params}) => this.get(`/customers/${id}`, params);
    deleteClient = id => this.delete(`/customers/${id}`);
    blockClient = id => this.patch(`/customers/${id}/block`);
    unblockClient = id => this.patch(`/customers/${id}/unblock`);

    checkClientExistence = email => this.post('/customers/check_existence', {email});

    fetchClientAssets = id => this.get(`/customers/${id}/aggregated?extras=piechart`);
    fetchAccountsProfit = ({id, versionId}) => {
        const versionPart = versionId ? `/versions/${versionId}` : '';
        return this.get(`/customers/${id}${versionPart}/aggregated?extras=valuechange`);
    };

    fetchClientAllocatedAssets = id => this.get(`/customers/${id}/assets?allocations=assetallocation`)
    fetchAllocationClasses = id => this.get(`/customers/${id}/assets/allocation`);

    fetchTransactions = ({id, ...params}) => this.get('/transactions', {params});
    fetchClientTransactions = ({id, ...params}) => this.get(`/customers/${id}/transactions`, {params});
    fetchTransactionById = id => new Promise(res => res(id));

    deleteTransaction = ({transactionId}) =>
        this.delete(`/transactions/${transactionId}`);
    updateTransaction = ({transactionId, ...params}) => (
        this.patch(`/transactions/${transactionId}`, params)
    );

    addClientTransaction = ({clientId, ...transaction}) =>
        this.post(`/customers/${clientId}/transactions`, transaction);
    deleteClientTransaction = ({clientId, transactionId}) =>
        this.delete(`/customers/${clientId}/transactions/${transactionId}`);
    updateClientTransaction = ({clientId, transactionId, ...params}) => (
        this.patch(`/customers/${clientId}/transactions/${transactionId}/set_details`, params)
    );

    fetchAssetsManagers = () => this.get('/assetsmanagers');
    fetchCustomersAssetsManagers = ({id, extras = [], ...params}) => (
        this.get(`/customers/${id}/assetsmanagers?${parseExtras(extras)}`, {params})
    );

    fetchCustomerPortfolios = ({id, extras = [], versionId, ...params}) => {
        const versionPart = versionId ? `/versions/${versionId}` : '';
        return this.get(`/customers/${id}/portfolios${versionPart}?${parseExtras(extras)}`, {params});
    };
    fetchCustomerAggregatedPortfolios = ({id, extras = ['valuechange'], versionId, ...params}) => {
        const versionPart = versionId ? `/versions/${versionId}` : '';
        return this.get(`/customers/${id}/portfolios${versionPart}/aggregated?${parseExtras(extras)}`, {params});
    };

    fetchAccounts = ({id, extras = [], amId, ...params}) => {
        const extractedExtras = parseExtras([...extras, extrasMap.valueChange]);
        return amId ?
            this.get(`/customers/${id}/assetsmanagers/${amId}/accounts?${extractedExtras}`, params) :
            this.get(`/customers/${id}/accounts?${extractedExtras}`, params);
    };
    deleteAccount = ({clientId, id, ...config}) => this.delete(`customers/${clientId}/accounts/${id}`, config);

    fetchBanks = (params) => this.get('/banks', params);
    fetchBanksForClient = ({id, ...config}) => this.get(`/banks/customer/${id}`, config);
    bankSignIn = ({data, ...config}) => this.post(`/customers/${data.id}/import`, data, config);
    fetchBankAuthMethods = ({bankId, ...config}) => this.get(`banks/${bankId}/auth_methods`, config);
    updateBankCredentials = ({bankUserId, data, ...config}) =>
        this.put(`/bankusers/${bankUserId}/credentials`, data, config);
    updateBankDescription = ({bankUserId, description, ...config}) =>
        this.put(`/bankusers/${bankUserId}/description`, {Description: description}, config);
    importAccounts = ({id, customerId, accounts, ...config}) => this.put(
        `/customers/${customerId}/import/${id}`, accounts, config,
    );
    fetchAccountsForBank = ({id, ...config}) => this.get(`/banks/${id}/accounts`, config);
    deleteBank = ({id, ...cofnig}) => this.delete(`/banks/${id}`, cofnig);
    updateAccount = ({id, clientId, ...params}) => this.put(`/customers/${clientId}/accounts/${id}`, params);

    fetchAllocationsForCustomer = ({id, versionId, ...params}) => {
        const path = versionId ? `/versions/${versionId}` : '';
        return this.get(`/customers/${id}/allocations${path}?extras=share`, {params});
    };
    fetchAllocationsForCustomerPortfolio = ({id, portfolioId, ...params}) =>
        this.get(`/customers/${id}/portfolios/${portfolioId}/allocations?extras=share`, {params});
    fetchAllocationsForCustomerAccount = ({id, accountId, ...params}) =>
        this.get(`/customers/${id}/accounts/${accountId}/assets?extras=share`, {params});
    fetchAllocationsForCustomerPortfolioAccount = ({id, portfolioId, accountId, ...params}) => (
        this.get(`/customers/${id}/portfolios/${portfolioId}/accounts/${accountId}/assets?extras=share`, {params}));

    fetchPerformanceGraph = ({clientId, versionId, ...params}) => {
        const path = versionId ? `/versions/${versionId}` : '';
        return this.get(`/customers/${clientId}${path}/graph/percentage?extras=benchmark`, {params});
    }
    fetchPerformanceGraphForAccount = ({clientId, id, ...params}) => (
        this.get(`/customers/${clientId}/accounts/${id}/graph/percentage?extras=benchmark`, {params})
    );
    fetchPerformanceGraphForPortfolio = ({clientId, versionId, id, ...params}) =>
        this.get(`/customers/${clientId}/portfolios/${id}/graph/percentage?extras=benchmark`, {params});
    fetchPerformanceInfo = ({clientId, versionId, period}) => {
        const path = versionId ? `/versions/${versionId}` : '';
        const periodParam = period ? `&period=${period}` : '';
        return this.get(`/customers/${clientId}${path}/aggregated?extras=portfolio,benchmark,outperformance${periodParam}`);
    }
    fetchPerformanceInfoForAccount = ({clientId, id}) =>
        this.get(`/customers/${clientId}/accounts/${id}?extras=portfolio,benchmark,outperformance`);
    fetchPerformanceInfoForPortfolio = ({clientId, id}) =>
        this.get(`/customers/${clientId}/portfolios/${id}?extras=portfolio,benchmark,outperformance`);

    fetchRMs = params => this.get('/managers', {params});
    fetchRMClients = id => this.get(`managers/${id}/customers`);
    fetchActiveRMs = () => this.get('/managers/active');
    fetchRMById = ({id}) => this.get(`/managers/${id}`);
    createRM = (data, ...config) => this.post('/managers', {
        ...data,
        url: `${window.location.origin}${pathnames.LOGIN}#proveo_invite_token=`,
    }, config);
    updateRM = ({id, ...params}) => this.put(`managers/${id}`, params);
    deleteRM = ({id, assignTo}) => this.delete(`/managers/${id}?assignTo=${assignTo}`);
    blockRM = ({id, assignTo}) => this.patch(`/managers/${id}/block?assignTo=${assignTo}`);
    unblockRM = id => this.patch(`/managers/${id}/unblock`);

    updateAssetsManager = ({clientId, amId, ...params}) =>
        this.put(`/customers/${clientId}/assetsmanagers/${amId}`, params);

    fetchPortfolio = ({clientId, portfolioId, isLegacy = true, ...params}) =>
        this.get(`/customers/${clientId}/portfolios/${portfolioId}`, {params: {isLegacy}, ...params});
    updatePortfolio = ({clientId, portfolioId, ...params}) =>
        this.put(`/customers/${clientId}/portfolios/${portfolioId}`, params);
    deletePortfolio = ({clientId, id}) => this.delete(`customers/${clientId}/portfolios/${id}`);

    fetchBenchmarksList = params => this.get('/benchmarks', {params});
    fetchSharedBenchmarks = params => this.get('/shared_benchmarks', {params});

    fetchClientBenchmarksList = params => this.get('/shared_benchmarks', {params});
    createClientBenchmark = params => this.post('/shared_benchmarks', params);

    updateClientBenchmark = ({id, formData, ...params}) => {
        const data = formData || params;
        return this.post(`/shared_benchmarks/${id}`, data);
    };
    deleteClientBenchmark = ({id, assignTo}) => this.delete(`/shared_benchmarks/${id}?assignTo=${assignTo}`);

    fetchClientLimits = id => this.get(`/customers/${id}/limits`);
    fetchClientBenchmarks = id => this.get(`/customers/${id}/benchmarks`);

    updateClientBenchmarks = ({id, benchmarks}) => this.post(`/customers/${id}/benchmarks`, benchmarks);
    updateClientLimits = ({id, limits}) => this.post(`/customers/${id}/limits`, limits);

    fetchNotificationCustomers = params => this.get('/notifications/recipients', {params});

    fetchNotificationsList = ({...params}) => this.get('/notifications', {params});
    createNotification = ({...params}) => this.post('/notifications', params);
    updateNotification = ({id, ...params}) =>
        this.put(`/notifications/${id}`, params);
    deleteNotification = ({id, ...params}) => this.delete(`/notifications/${id}`, params);

    fetchAssetList = params => this.get('/assets', {params});

    updateAsset = ({assetId, ...params}) => (
        this.patch(`/assets/${assetId}`, params)
    );

    fetchReferenceData = () => this.get('/reference_data');

    recalculateEverything = ({force = false}) => this.post(`/schedule/performance/all${force ? '?force=true' : ''}`);
    recalculateClient = ({clientId, force = false}) =>
        this.post(`/schedule/performance/customers/${clientId}${force ? '?force=true' : ''}`);
    recalculateAccount = ({clientId, accountId}) =>
        this.post(`/schedule/performance/customers/${clientId}/accounts/${accountId}`);
    recalculatePortfolio = ({clientId, portfolioId}) =>
        this.post(`/schedule/performance/customers/${clientId}/portfolios/${portfolioId}`);
    recalculateBenchmark = ({benchmarkId}) => this.post(`/schedule/performance/benchmarks/${benchmarkId}`);

    syncClientStart = id => this.post(`/customers/${id}/sync_start`);

    syncClientCheck = syncId => this.get(`/sync/${syncId}/status`);

    completeOnboarding = ({bid, ...config}) => this.post(`/boersen/${bid}/complete_onboarding/`, {}, config);
    patchOnboarding = ({customerId, ...config}) =>
        this.patch(`/customers/${customerId}/complete_onboarding`, {}, config);

    norkonRegister = ({aid}) => this.post(`${norkonEnpoint}?aid=${aid}`);

    sendOffboarding = ({customerId, ...config}) => this.delete(`/boersen/${customerId}`, config);
    // softDelete = ({customerId, ...config}) => this.delete(`/boersen/${customerId}`, config)

    fetchTinkUrl = ({bankId, callback, ...config}) =>
        this.get(`/tink/auth/logon_url/${bankId}?callback=${window.encodeURIComponent(callback)}`, config);

    // sendOffboarding = ({customerId, ...config}) => this.delete(`/boersen/${customerId}`, config)

    fetchCustomerTinkUrl = ({bankId, callback, customerId, ...config}) =>
        this.get(`/tink/auth/logon_url/${bankId}/${customerId}?callback=${window.encodeURIComponent(callback)}`);

    fetchLocalizationTerms = async (config) => (await this.get('/localization/terms', config)).terms;

    getInvoices = ({customerId, portfolioId, ...config}) =>
        this.get(`/customers/${customerId}/portfolios/${portfolioId}/invoices`, config);
    updateInvoices = ({customerId, portfolioId, data, ...config}) =>
        this.post(`/customers/${customerId}/portfolios/${portfolioId}/invoices`, data, config);
    checkExistence = ({data, ...config}) =>
        this.post('customers/check_existence', data, {...config, auth})
    simpleSignin = ({data, ...config}) =>
        this.post('customers/selfregister?simplified=true', data, {...config, auth})

    fetchStructureVersions = async ({customerId}) => this.get(`/sub_portfolios/${customerId}/versions`);
    createStructureVersion = async ({customerId, name, templateVersionId}) =>
        this.post(`/sub_portfolios/${customerId}/versions`, {name, templateVersionId});
    updateStructureVersion = async ({customerId, versionId, name, primary}) =>
        this.patch(`/sub_portfolios/${customerId}/versions/${versionId}`, {name, primary});
    deleteStructureVersion = async ({customerId, versionId}) =>
        this.delete(`/sub_portfolios/${customerId}/versions/${versionId}`);

    fetchStructure = async ({customerId, versionId}) => {
        const structure = await this.get(`/sub_portfolios/${customerId}/versions/${versionId}`);
        const assets = await this.fetchStructureAssets({customerId, versionId, date: structure.date});
        return {...assets, structure};
    };

    fetchStructureAssets = ({customerId, versionId, date}) =>
        this.get(`/sub_portfolios/${customerId}/versions/${versionId}/diff?startDate=${date}`);

    saveStructure = ({customerId, versionId, payload: {structure, date}}) => this.post(
        `/sub_portfolios/${customerId}/versions/${versionId}`,
        {structure: serializeStructureToGuaranteePropertyOrder(structure), date},
    );
    fetchCustomerReportingBenchmarks = ({customerId, ...config}) =>
        this.get(`customers/${customerId}/reporting_benchmarks`, config)
    updateCustomerReportingBenchmarks = ({id, benchmarks, ...data}) =>
        this.post(`customers/${id}/reporting_benchmarks`, benchmarks)
    deleteCustomerReportingBenchmarks = ({customerId, date, ...config}) =>
        this.delete(`customers/${customerId}/reporting_benchmarks${date}`, config)
}
