import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

export default class ButtonPanel extends React.PureComponent {
    static propTypes = {
        buttonsDisabled: PropTypes.bool,
        className: PropTypes.string,
        onFinishButtonClick: PropTypes.func,
        onNextButtonClick: PropTypes.func,
    };

    render() {
        const {buttonsDisabled, className, onFinishButtonClick, onNextButtonClick} = this.props;

        return (
            <div className={cn('prv-button-panel', className)}>
                {onNextButtonClick &&
                <button
                    className="prv-button-panel__button-next"
                    onClick={onNextButtonClick}
                    disabled={buttonsDisabled}
                >
                    Save
                </button>}
                {onFinishButtonClick &&
                <button
                    className="prv-button-panel__button-finish"
                    onClick={onFinishButtonClick}
                    disabled={buttonsDisabled}
                >
                    <span>Finish with this client</span>
                </button>}
            </div>
        );
    }
}
