import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connectActions} from 'store';
import {redirect as refresh} from 'store/navigate';
import {clearSubmitError} from 'components/forms';
import cn from 'classnames';

import PageContentWrapper from 'components/layout/page-content-wrapper';
import Spinner from 'components/loading-spinner/loading-spinner';
import ErrorPanel from 'components/panel/panel';
import {SuccessDialog, Dialog} from 'components/dialogs';

// TODO: refactor top action buttons
class EditingForm extends Component {
    static propTypes = {
        children: PropTypes.node.isRequired,
        className: PropTypes.string,

        title: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.arrayOf(PropTypes.string),
        ]),

        loading: PropTypes.bool,
        loadingError: PropTypes.shape({
            message: PropTypes.string,
        }),
        clearLoadingError: PropTypes.func,

        error: PropTypes.shape({
            message: PropTypes.string,
        }),
        submitting: PropTypes.bool,

        panel: PropTypes.node,

        notification: PropTypes.string,
        clearNotification: PropTypes.func,

        refresh: PropTypes.func,

        blocked: PropTypes.bool,
        onToggleBlock: PropTypes.func,
        onDelete: PropTypes.func,
        getDeleteMessage: PropTypes.func,
        getBlockMessage: PropTypes.func,
        renderDeleteDialog: PropTypes.func,
        renderBlockDialog: PropTypes.func,
        onSuccessDelete: PropTypes.func,

        topView: PropTypes.node,
        bottomView: PropTypes.any,
        rightView: PropTypes.node,
    };

    constructor(props) {
        super(props);
        this.state = {showDeleteDialog: false, showBlockDialog: false};
    }

    onClearError = () => clearSubmitError(this.props);

    onAskDeleteItem = () => this.setState({showDeleteDialog: true, deleteError: null});
    onPerformDelete = () => this.props.onDelete();
    onCancelDelete = () => this.setState({showDeleteDialog: false});
    onCompletedDelete = () => {
        this.setState({showDeleteDialog: false});
        this.props.onSuccessDelete();
    };
    onFailedDelete = ({_error: error}) => {
        this.setState({showDeleteDialog: false, deleteError: error});
    };

    onAskBlockItem = () => this.setState({showBlockDialog: true, blockError: null});
    onPerformBlock = () => this.props.onToggleBlock();
    onCompleteBlock = () => {
        this.setState({showBlockDialog: false});
        this.refresh();
    };
    onFailedBlock = ({_error: error}) => {
        this.setState({showBlockDialog: false, blockError: error});
    };
    onCancelBlock = () => this.setState({showBlockDialog: false});

    clearDeleteError = () => this.setState({deleteError: null});
    clearBlockError = () => this.setState({blockError: null});

    refresh = () => {
        this.props.refresh({pathname: window.location.pathname, state: {update: true}});
    };

    renderDeleteDialog = () => {
        const {
            getDeleteMessage = () => 'Delete item?',
            renderDeleteDialog: renderer,
        } = this.props;
        const {showDeleteDialog} = this.state;
        if (!showDeleteDialog) return null;

        return renderer ?
            renderer({
                onSubmit: this.onPerformDelete,
                onCancel: this.onCancelDelete,
                onSubmitSuccess: this.onCompletedDelete,
                onSubmitFail: this.onFailedDelete,
            }) :
            (
                <Dialog
                    message={getDeleteMessage()}
                    onDelete={this.onPerformDelete}
                    onCancel={this.onCancelDelete}
                    onSubmitSuccess={this.onCompletedDelete}
                    onSubmitFail={this.onFailedDelete}
                />
            );
    };

    renderBlockDialog = () => {
        const {
            getBlockMessage = () => 'Block selected items?',
            renderBlockDialog: renderer,
            blocked,
        } = this.props;
        const {showBlockDialog} = this.state;
        if (!showBlockDialog) return null;

        return renderer && !blocked ?
            renderer({
                onSubmit: this.onPerformBlock,
                onCancel: this.onCancelBlock,
                onSubmitSuccess: this.onCompleteBlock,
                onSubmitFail: this.onFailedBlock,
            }) :
            (
                <Dialog
                    message={getBlockMessage()}
                    onBlock={!blocked ? this.onPerformBlock : null}
                    onUnblock={blocked ? this.onPerformBlock : null}
                    onCancel={this.onCancelBlock}
                    onSubmitSuccess={this.onCompleteBlock}
                    onSubmitFail={this.onFailedBlock}
                />
            );
    };

    render() {
        const {
            panel, children, className,
            submitting, error, title,
            notification, clearNotification,
            loading, loadingError, clearLoadingError,
            blocked, onToggleBlock, onDelete,
            bottomView, rightView, topView,
        } = this.props;

        const {deleteError, blockError} = this.state;

        return (
            <PageContentWrapper
                title={title}
                className={cn('prv-editing-form', className)}
            >
                {(submitting || loading) && !notification && <Spinner/>}
                {notification && <SuccessDialog message={notification} onOk={clearNotification}/>}
                {error &&
                <ErrorPanel
                    className="prv-editing-form__error-panel"
                    error={error}
                    onClear={this.onClearError}
                />
                }
                {loadingError &&
                <ErrorPanel
                    className="prv-editing-form__error-panel"
                    error={loadingError}
                    onClear={clearLoadingError}
                />
                }
                {deleteError &&
                <ErrorPanel
                    className="prv-editing-form__error-panel"
                    error={deleteError}
                    onClear={this.clearDeleteError}
                />
                }
                {blockError &&
                <ErrorPanel
                    className="prv-editing-form__error-panel"
                    error={blockError}
                    onClear={this.clearBlockError}
                />
                }
                {this.renderDeleteDialog()}
                {this.renderBlockDialog()}
                {panel}
                {topView}
                {(onToggleBlock || onDelete) &&
                <div className="prv-editing-form__actions">
                    {onToggleBlock &&
                    <div
                        className={cn({
                            'prv-editing-form__action-block': !blocked,
                            'prv-editing-form__action-unblock': blocked,
                        })}
                        onClick={this.onAskBlockItem}
                    >
                        {blocked ? 'Unblock' : 'Block'}
                    </div>}
                    {onDelete &&
                    <div className="prv-editing-form__action-delete" onClick={this.onAskDeleteItem}>Delete</div>}
                </div>}
                <div className="prv-editing-form__content">
                    <div className="prv-editing-form__table-container">
                        {children}
                    </div>
                    {rightView}
                </div>
                {bottomView}
            </PageContentWrapper>
        );
    }
}

export default connectActions({refresh})(EditingForm);
