import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from '../../store';
import {haveToken} from '../../store/api';
import {getProfile, getRole} from '../../store/auth';
import {redirect} from '../../store/navigate';

// this class is pretty much same as the HasRole, but when role is not found it redirects to the beginning
// instead of rendering null
@connect({haveToken, profile: getProfile, role: getRole}, {redirect})
class RoleRequired extends PureComponent {
    static propTypes = {
        // eslint-disable-next-line react/no-unused-prop-types
        allowedRoles: PropTypes.arrayOf(PropTypes.string),
        children: PropTypes.node,
        // eslint-disable-next-line react/no-unused-prop-types
        haveToken: PropTypes.bool.isRequired,
        // eslint-disable-next-line react/no-unused-prop-types
        role: PropTypes.string.isRequired,
        // eslint-disable-next-line react/no-unused-prop-types
        profile: PropTypes.shape({}),
        // eslint-disable-next-line react/no-unused-prop-types
        redirect: PropTypes.func.isRequired,
    };

    componentDidMount() {
        this.handle(this.props);
    }

    componentWillReceiveProps(props) {
        this.handle(props);
    }

    handle = (props) => {
        if (props.haveToken && !props.profile) return;
        if (!this.hasAllowedRole(props)) {
            props.redirect('/');
        }
    };

    hasAllowedRole = ({allowedRoles, role}) => allowedRoles.includes(role);

    render() {
        if (!this.hasAllowedRole(this.props)) return null;
        return this.props.children;
    }
}

export default RoleRequired;
