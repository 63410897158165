import {AccountGroup, CustodyAccount, PortfolioNode, StructureArray, StructureItem, StructureItemId} from './types';
import {
    addAssets,
    addPortfolio,
    changeStartDate,
    createPortfolio,
    disablePortfolioRenaming,
    enablePortfolioRenaming,
    expandPortfolio,
    fetchAssets,
    fetchPortfolio,
    fetchStructure,
    linkAccount,
    movePortfolio,
    removeAssets,
    removePortfolio,
    renamePortfolio,
    saveStructure,
    selectPortfolio,
    unlinkAccount,
} from './actions';
import {
    addAssetsReducer,
    addPortfolioReducer,
    changeStartDateReducer,
    createPortfolioReducer,
    disablePortfolioRenamingReducer,
    enablePortfolioRenamingReducer,
    expandPortfolioReducer,
    fetchAssetsReducer,
    fetchPortfolioReducer,
    fetchStructureReducer,
    linkAccountReducer,
    movePortfolioReducer,
    removeAssetsReducer,
    removePortfolioReducer,
    renamePortfolioReducer,
    saveStructureReducer,
    selectPortfolioReducer,
    unlinkAccountReducer,
} from './reducers';
import {handleActions} from '../helpers/actions';
import Portfolio from '../types/Portfolio';

export interface StructureState {
    accountGroups: AccountGroup[],
    // whether we should delete the portfolio when renaming is cancelled. Used when creating new portfolio.
    deleteOnCancelRenaming: boolean,
    error?: Error,
    expandedPortfolioIds: Set<StructureItemId>,
    loading: boolean,
    portfolioBeingEdited: Portfolio | undefined,
    portfolioBeingRenamedId: StructureItemId | undefined,
    obsoleteAssets: {[key in StructureItemId]: StructureItem},
    obsoleteCustodyAccounts: {[key in CustodyAccount['id']]: CustodyAccount},
    originalStartDate: Date,
    originalTree: StructureArray,
    selectedPortfolio: PortfolioNode | undefined,
    startDate: Date,
    tree: StructureArray,
}

const initialState: StructureState = {
    accountGroups: [],
    deleteOnCancelRenaming: false,
    error: undefined,
    expandedPortfolioIds: new Set(),
    loading: false,
    obsoleteAssets: {},
    obsoleteCustodyAccounts: {},
    originalStartDate: new Date(),
    originalTree: [],
    portfolioBeingEdited: undefined,
    portfolioBeingRenamedId: undefined,
    selectedPortfolio: undefined,
    startDate: new Date(),
    tree: [],
};

export const getAccountGroups = ({accountGroups}: StructureState) => accountGroups;
export const getError = ({error}: StructureState) => error;
export const getExpandedPortfolioIds = ({expandedPortfolioIds}: StructureState) => expandedPortfolioIds;
export const getLoading = ({loading}: StructureState) => loading;
export const getObsoleteAssets = ({obsoleteAssets}: StructureState) => obsoleteAssets;
export const getObsoleteCustodyAccounts = ({obsoleteCustodyAccounts}: StructureState) => obsoleteCustodyAccounts;
export const getOriginalStartDate = ({originalStartDate}: StructureState) => originalStartDate;
export const getOriginalTree = ({originalTree}: StructureState) => originalTree;
export const getPortfolioBeingRenamedId = ({portfolioBeingRenamedId}: StructureState) => portfolioBeingRenamedId;
export const getSelectedPortfolio = ({selectedPortfolio}: StructureState) => selectedPortfolio;
export const getStartDate = ({startDate}: StructureState) => startDate;
export const getTree = ({tree}: StructureState) => tree;

export default handleActions({
    [addPortfolio]: addPortfolioReducer,
    [createPortfolio]: createPortfolioReducer,
    [disablePortfolioRenaming]: disablePortfolioRenamingReducer,
    [enablePortfolioRenaming]: enablePortfolioRenamingReducer,
    [expandPortfolio]: expandPortfolioReducer,
    [movePortfolio]: movePortfolioReducer,
    [removePortfolio]: removePortfolioReducer,
    [renamePortfolio]: renamePortfolioReducer,
    [selectPortfolio]: selectPortfolioReducer,

    [addAssets]: addAssetsReducer,
    [linkAccount]: linkAccountReducer,
    [removeAssets]: removeAssetsReducer,
    [unlinkAccount]: unlinkAccountReducer,

    [fetchStructure]: fetchStructureReducer,
    [saveStructure]: saveStructureReducer,
    [fetchAssets]: fetchAssetsReducer,

    [changeStartDate]: changeStartDateReducer,

    [fetchPortfolio.type]: fetchPortfolioReducer,
}, initialState);
