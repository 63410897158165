import typeReader from '../helpers/type_reader';
import typeShape, {required, optional} from '../helpers/type_shape';

const RMType = {
    id: required(String),
    firstName: required(String),
    lastName: required(String),
    birthday: optional(String),
    status: required(String),
    email: required(String),
    phone: required(String),
};

export const readRM = typeReader(RMType);
export const rmShape = typeShape(RMType);

export const editRM = rm => (rm && {
    id: rm.id,
    firstName: rm.firstName,
    lastName: rm.lastName,
    birthday: rm.birthday,
    status: rm.status,
    email: rm.email,
    phone: rm.phone,
});
