import React from 'react';
import {Doughnut} from 'react-chartjs-2';
import {atlazColors, proveoColors} from './colors';
import Client from '../../../store/types/Client';
import AllocationClass from '../../../store/types/AllocationClass';

const ALLOCATION_CLASSES_CHART_OPTIONS = Object.freeze({
    responsive: true,
    maintainAspectRatio: false,
    cutoutPercentage: 65,
    tooltips: {
        enabled: false,
    },
});

interface Props {
    allocationClasses: AllocationClass[],
    client: Client,
}

const AllocationClasses: React.FC<Props> = ({allocationClasses, client}) => {
    const colors = client.atlazId ? atlazColors : proveoColors;
    const hasAllocationClasses = Array.isArray(allocationClasses);
    const chartAllocations = (allocationClasses || []).filter(({allocation}) => allocation >= 0);
    const chartColors = chartAllocations.map(({id}) => colors[id - 1]);

    return (
        <div className="client-information-square client-information-diagram">
            <div className="client-information-legend">
                <div className="client-information-legend-header">
                    Client&#39;s total asset allocation
                </div>
                <div className="client-information-legend-detalization">
                    {hasAllocationClasses &&
                    allocationClasses.map((cls) => (
                        <div key={`${cls.id}`} className="client-information-legend-row">
                            <div
                                style={{backgroundColor: colors[cls.id - 1]}}
                                className="legend-square"
                            />
                            <div className="row-text">
                                {cls.name}
                            </div>
                            <div className="row-text">
                                {cls.allocation}%
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="client-information-graphic">
                {hasAllocationClasses && <Doughnut
                    data={{
                        datasets: [{
                            data: chartAllocations.map(cls => cls.allocation),
                            backgroundColor: chartColors,
                            hoverBackgroundColor: chartColors,
                            hoverBorderWidth: 0,
                            borderWidth: 0,
                        }],
                    }}
                    options={ALLOCATION_CLASSES_CHART_OPTIONS}
                />}
            </div>
        </div>
    );
};

export default AllocationClasses;
