import React, {memo, useCallback} from 'react';
import cn from 'classnames';
import Button from 'react-md/lib/Buttons/Button';
import Tooltipped from 'react-md/lib/Tooltips/Tooltipped';
import {
    AnyAssetList,
    AssetNode,
    CashAccountNode,
    StructureItem,
    StructureItemId,
} from '../../../../store/structure/types';
import {connectActions} from '../../../../store';
import {addAssets, removeAssets} from '../../../../store/structure/actions';
import {isSameLevel} from '../../../../store/structure/helpers/level';

interface DispatchProps {
    addAssets?: (payload: AnyAssetList) => void,
    removeAssets?: (payload: AnyAssetList) => void,
}

interface Props extends DispatchProps {
    asset: AssetNode | CashAccountNode,
    className?: string,
    isAdded?: boolean,
    obsoleteAssets: {[key in StructureItemId]: StructureItem},
}

const AssetItem: React.FC<Props> = memo(({
    asset,
    className,
    isAdded,
    obsoleteAssets,

    addAssets,
    removeAssets,
}) => {
    const handleAddRemoveClick = useCallback(
        () => (isAdded ? removeAssets && removeAssets([asset]) : addAssets && addAssets([asset])),
        [asset, addAssets, removeAssets],
    );
    const isObsolete = obsoleteAssets[asset.id] && isSameLevel(obsoleteAssets[asset.id].level, asset.level);
    return (
        <div className={cn('prv-asset-item', className)}>
            <Tooltipped
                delay={500}
                label={asset.name}
                position="top"
                setPosition
            >
                <div className="prv-asset-item__title">
                    <div
                        className={cn(
                            'prv-asset-item__title-label',
                            {'prv-asset-item__title-label--obsolete': isObsolete},
                        )}
                    >
                        {asset.name}
                    </div>
                </div>
            </Tooltipped>
            <div className="prv-asset-item__actions">
                <Button
                    icon
                    onClick={handleAddRemoveClick}
                    tooltipLabel={isAdded ? 'Remove asset from Portfolio' : 'Add asset to Portfolio'}
                    tooltipPosition="left"
                >
                    {isAdded ? 'remove' : 'add'}
                </Button>
            </div>
        </div>
    );
});

// FIXME: figure out how to type the connect function
// @ts-ignore
export default connectActions({addAssets, removeAssets})(AssetItem);
