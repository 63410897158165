import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import _ from 'lodash';
import Table from 'components/resource-table/resource-table';
import ErrorPanel, {SuccessPanel} from 'components/panel/panel';
import {Dialog} from 'components/dialogs';
import Spinner from 'components/loading-spinner/loading-spinner';
import Select from 'components/select/select';
import styles from './form-table.module.css';

// TODO: refactor render title
export default class FormTable extends Component {
    static propTypes = {
        actionView: PropTypes.string,
        className: PropTypes.string,
        displayOptions: PropTypes.arrayOf(PropTypes.node),
        title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        items: PropTypes.arrayOf(PropTypes.object),
        columns: PropTypes.arrayOf(PropTypes.object),
        searchable: PropTypes.bool,
        searchFields: PropTypes.arrayOf(PropTypes.string),
        filters: PropTypes.object,
        loading: PropTypes.bool,
        canEdit: PropTypes.func,
        onEdit: PropTypes.func,
        canDelete: PropTypes.func,
        onDelete: PropTypes.func,
        canBlock: PropTypes.func,
        canRecalculate: PropTypes.func,
        onBlock: PropTypes.func,
        onFilter: PropTypes.func,
        onRecalculate: PropTypes.func,
        renderTableEmpty: PropTypes.func,
        actionButton: PropTypes.node,
        notification: PropTypes.string,
        onClearNotification: PropTypes.func,
        error: PropTypes.object,
        onClearError: PropTypes.func,
        getId: PropTypes.func,

        getDeleteMessage: PropTypes.func,
        refresh: PropTypes.func,
    };

    static defaultProps = {
        filters: {},
        searchable: true,
        refresh: () => undefined,
    };

    constructor(props) {
        super(props);
        this.state = {items: props.items, searchTerm: ''};
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.items !== nextProps.items) {
            this.setState({items: nextProps.items});
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.items && this.props.items.length > 0
            && this.props.items !== prevProps.items) {
            this.search(this.state.searchTerm);
        }
    }

    onAskDeleteItem = item => this.setState({itemToDelete: item, deleteError: null});
    onPerformDelete = () => this.props.onDelete(this.state.itemToDelete);
    onCancelDelete = () => this.setState({itemToDelete: null});
    onCompletedDelete = () => {
        this.setState({itemToDelete: null});
        this.props.refresh();
    };
    onFailedDelete = ({_error: error}) => {
        this.setState({itemToDelete: null, deleteError: error});
        this.props.refresh();
    };

    onSearch = (e) => {
        const {value: searchTerm} = e.target;
        this.search(searchTerm);
        this.setState({searchTerm});
    };

    onSort = ({sortField, sortOrder}) => {
        this.sort({sortField, sortOrder, items: this.state.items});
    };

    onFilter = (confName, value) => {
        this.setState({[confName]: value === '' ? null : value}, () => this.search(this.state.searchTerm));
    };

    clearDeleteError = () => this.setState({deleteError: null});

    search = (searchTerm) => {
        const {columns, filters, items, searchable} = this.props;

        const {
            searchFields = columns.filter(col => (!col.id && !col.uid)).map(v => v.field),
        } = this.props;

        const {sortField, sortOrder} = this.state;
        const filteredItems = !searchable ?
            items :
            items.filter((item) => {
                const containsSearchTerm = searchFields.reduce(
                    (prev, cur) => (
                        prev || (item[cur] || '').toString().toLowerCase().includes(searchTerm.toLowerCase())
                    ),
                    false,
                );
                let satisfiesFilters = true;
                Object.keys(filters).forEach((key) => {
                    if (this.state[key] && item[key] !== this.state[key]) {
                        satisfiesFilters = false;
                    }
                });
                return containsSearchTerm && satisfiesFilters;
            });

        if (sortField && sortOrder) {
            this.sort({sortField, sortOrder, items: filteredItems});
        } else {
            this.setState({items: filteredItems});
        }

        if (this.props.onFilter) {
            this.props.onFilter(searchTerm === '' ? null : filteredItems);
        }
    };

    sort = ({sortField, sortOrder, items}) => {
        const sortedItems = _.orderBy(
            items,
            (item) => {
                const value = item[sortField];

                if (_.isNumber(value)) return value;
                if (_.isString(value)) return value.toLowerCase();

                return '';
            },
            sortOrder,
        );

        this.setState({items: sortedItems, sortField, sortOrder});
    };

    renderTitle = () => (
        <div className={styles.formTableClientsTitle}>
            <span>{this.props.title}</span>
            <div className={styles.formTableClientsSeparator}/>
            <span className={styles.formTableClientsCount}>
                {
                    this.state.items == null ?
                        this.props.items && this.props.items.length :
                        `Found ${this.state.filteredItemsCount}`
                }
            </span>
        </div>
    );

    renderFilters = () => this.props.filters && Object.keys(this.props.filters).map(key => (
        <Select
            key={key}
            className={styles.filtersSelect}
            onChange={v => this.onFilter(key, v)}
            placeholder={this.props.filters[key].title}
            value={this.state[key] || ''}
            options={Object.keys(this.props.filters[key].options).map(opt => ({
                value: opt,
                label: this.props.filters[key].options[opt],
            }))}
            prefix="In"
        />
    ));

    renderHeader = () => (
        <div className={styles.header}>
            <div className={styles.filtersWrapper}>
                <div className={styles.title}>
                    {this.props.title instanceof String ? this.renderTitle() : this.props.title}
                </div>
                <div className={styles.filters}>
                    {Object.keys(this.props.filters).length !== 0 && <span>Choose Filters: </span>}
                    <div>{this.renderFilters()}</div>
                </div>
            </div>
            {
                this.props.searchable &&
                <input
                    disabled={this.props.loading}
                    type="text"
                    className={styles.search}
                    style={this.props.actionButton && {marginRight: '10px'}}
                    onChange={this.onSearch}
                    defaultValue=""
                    placeholder="Search"
                />
            }
            {this.props.actionButton}
        </div>
    );

    renderTable = () => {
        const {
            columns, canEdit, onEdit,
            canDelete, onDelete, canBlock, onBlock,
            loading, getId, actionView,
            canRecalculate, onRecalculate,
        } = this.props;

        const {items, sortField, sortOrder} = this.state;

        return loading ?
            (<div className={styles.spinnerWrapper}><Spinner/></div>) :
            (<Table
                actionView={actionView}
                items={items || []}
                columns={columns}
                getId={getId}
                sortField={sortField}
                sortOrder={sortOrder}
                onSort={this.onSort}
                canEdit={canEdit}
                onEdit={onEdit}
                canDelete={canDelete}
                onDelete={onDelete ? this.onAskDeleteItem : null}
                canBlock={canBlock}
                onBlock={onBlock}
                canRecalculate={canRecalculate}
                onRecalculate={onRecalculate}
            />);
    };

    renderDeleteDialog = () => {
        const {
            getDeleteMessage = () => 'Delete selected item?',
        } = this.props;

        const {itemToDelete} = this.state;
        return (
            <Dialog
                message={getDeleteMessage(itemToDelete)}
                onDelete={this.onPerformDelete}
                onCancel={this.onCancelDelete}
                onSubmitSuccess={this.onCompletedDelete}
                onSubmitFail={this.onFailedDelete}
            />
        );
    };

    render() {
        const {className, displayOptions, items, loading, renderTableEmpty, notification, error} = this.props;
        const {deleteError, itemToDelete} = this.state;

        return (
            <div className={cn(className, styles.wrapper)}>
                {notification &&
                <SuccessPanel
                    error={{message: notification}}
                    onClear={this.props.onClearNotification}
                />}
                {error && <ErrorPanel error={error} onClear={this.props.onClearError}/>}
                {deleteError && <ErrorPanel error={deleteError} onClear={this.clearDeleteError}/>}
                {itemToDelete && this.renderDeleteDialog()}

                {this.renderHeader()}
                {displayOptions &&
                <div className={styles['display-options']}>
                    {displayOptions}
                </div>
                }
                {((items && items.length > 0) || loading) && this.renderTable()}
                {!((items && items.length > 0) || loading) && renderTableEmpty &&
                <div className={styles.tableEmpty}>
                    {renderTableEmpty && renderTableEmpty()}
                </div>
                }
            </div>
        );
    }
}
