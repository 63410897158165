import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {Row} from './editing-table';
import ErrorField, {ErrorGuard} from '../forms/error-field';
import InputField from '../forms/input-field';
import SelectField from '../forms/select-field';
import TextAreaField from '../forms/text-area-field';
import TimeField from '../forms/time-field';
import PictureField from '../forms/picture-field';
import DateField from '../forms/date-field';
import AnyFileField from '../forms/any-file-field';
import styles from './editing-table.module.css';

export const ErrorsRow = ({className, name}) => (
    <ErrorGuard name={name}>
        <Row className={cn(styles.errorRow, className)}>
            <ErrorField name={name}/>
        </Row>
    </ErrorGuard>
);
ErrorsRow.propTypes = {
    className: PropTypes.string,
    name: PropTypes.string.isRequired,
};

export const HintRow = ({className, children}) => (
    <Row className={cn(className, styles.hintRow)}>
        <div>{children}</div>
    </Row>
);
HintRow.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
};

export const InputRow = ({className, name, label, required, ...props}) => (
    <Row className={className} name={name} label={label} required={required}>
        <InputField name={name} {...props} />
    </Row>
);
InputRow.propTypes = {
    className: PropTypes.string,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    required: PropTypes.bool,
};


export const SelectRow = ({className, name, label, required, selectClassName, ...props}) => (
    <Row className={className} name={name} label={label} required={required}>
        <SelectField name={name} className={selectClassName} {...props} />
    </Row>
);
SelectRow.propTypes = {
    className: PropTypes.string,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    required: PropTypes.bool,
    selectClassName: PropTypes.string,
};

export const TextAreaRow = ({className, name, label, required, textAreaClassName, ...props}) => (
    <Row className={className} name={name} label={label} required={required}>
        <TextAreaField name={name} className={textAreaClassName} {...props} />
    </Row>
);
TextAreaRow.propTypes = {
    className: PropTypes.string,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    textAreaClassName: PropTypes.string,
    required: PropTypes.bool,
};

export const TimeRow = ({className, name, label, required, ...props}) => (
    <Row className={className} name={name} label={label} required={required}>
        <TimeField name={name} {...props} />
    </Row>
);
TimeRow.propTypes = {
    className: PropTypes.string,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    required: PropTypes.bool,
};

export const PictureRow = ({className, name, label, required, ...props}) => (
    <Row className={className} name={name} label={label} required={required}>
        <PictureField name={name} {...props} />
    </Row>
);
PictureRow.propTypes = {
    className: PropTypes.string,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    required: PropTypes.bool,
};

export const DateRow = ({className, name, label, required, ...props}) => (
    <Row className={className} name={name} label={label} required={required}>
        <DateField name={name} {...props} />
    </Row>
);
DateRow.propTypes = {
    className: PropTypes.string,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    required: PropTypes.bool,
};

export const AnyFileRow = ({className, name, label, required, ...props}) => (
    <Row className={className} name={name} label={label} required={required}>
        <AnyFileField name={name} {...props} />
    </Row>
);
AnyFileRow.propTypes = {
    className: PropTypes.string,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    required: PropTypes.bool,
};
