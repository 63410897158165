import {FLOWS, STEPS} from '../../config/onboarding';
import {handleActions} from '../../store/helpers/actions';
import {
    completeOnboarding,
    selectAccounts,
    selectBank,
    setAdditionalStep,
    setBoersenId,
    setCallbackUri,
    setLastAddedBank,
    setOnboardingFlow,
    setStep,
    signup,
} from './actions';
import {isApiResultAction} from '../api/helpers';

const boersenId = window.sessionStorage ? window.sessionStorage.getItem('boersen_id') : FLOWS.ONBOARDING;
const onboardingFlow = () => (window.sessionStorage
    ? window.sessionStorage.getItem('onboarding_flow')
    : FLOWS.ONBOARDING
);
const callbackUri = window.sessionStorage ? window.sessionStorage.getItem('callback_uri') : window.location.host;
const lastAddedBank = () => {
    if (window.sessionStorage) {
        return window.sessionStorage.getItem('last_added_bank');
    }
    return null;
};

const initialState = {
    currentStep: 1,
    totalSteps: Object.keys(STEPS).length,
    additionalStep: undefined,
    selectedBankId: undefined,
    selectedAccounts: [],
    loading: false,
    error: undefined,
    boersenId,
    callbackUri,
    onboardingFlow: onboardingFlow(),
    lastAddedBank: lastAddedBank(),
    onboardingResult: undefined,
};

export const getCurrentStep = ({currentStep}) => currentStep;
export const getAdditionalStep = ({additionalStep}) => additionalStep;
export const getTotalSteps = ({totalSteps}) => totalSteps;
export const getSelectedBankId = ({selectedBankId}) => selectedBankId;
export const getSelectedAccounts = ({selectedAccouns}) => selectedAccouns;
export const getBoersenId = ({boersenId}) => boersenId;
export const getCallbackUri = ({callbackUri}) => callbackUri;
export const getOnboardingFlow = () => onboardingFlow();
export const getLastAddedBank = () => lastAddedBank();
export const getOnboardingResult = ({onboardingResult}) => onboardingResult;

const onSelectBank = (state, action) => ({
    ...state,
    selectedBankId: action.payload,
});

const onSelectAccounts = (state, action) => ({
    ...state,
    selectedAccounts: action.payload,
});

const onSetStep = (state, action) => ({
    ...state,
    currentStep: action.payload,
});

const onSignUp = (state, action) => {
    if (isApiResultAction(action)) {
        const {error, payload} = action;
        if (error) {
            return {...state, error: payload, loading: false};
        }
        return {...state, error: undefined, loading: false};
    }
    return {...state, error: undefined, loading: true};
};

const onSetBoersenId = (state, action) => ({
    ...state,
    boersenId: action.payload || undefined,
});

const onCompleteOnboarding = (state, action) => {
    if (isApiResultAction(action)) {
        const {error, payload} = action;
        if (error) {
            return {...state, error: payload, loading: false};
        }
        return {...state, onboardingResult: payload, error: undefined, loading: false};
    }
    return {...state, error: undefined, loading: true};
};

const onSetCallbackUri = (state, action) => ({
    ...state,
    callbackUri: action.payload || window.location.host,
});

const onSetOnboardingFlow = (state, action) => ({
    ...state,
    onboardingFlowFlag: action.payload || true,
});

const onSetLastAddedBank = (state, action) => ({
    ...state,
    lastAddedBank: action.payload,
});

const onSetAdditionalStep = (state, action) => ({
    ...state,
    additionalStep: action.payload,
});

export default handleActions({
    [setStep]: onSetStep,
    [signup]: onSignUp,
    [selectBank]: onSelectBank,
    [selectAccounts]: onSelectAccounts,
    [setBoersenId]: onSetBoersenId,
    [setCallbackUri]: onSetCallbackUri,
    [setOnboardingFlow]: onSetOnboardingFlow,
    [setLastAddedBank]: onSetLastAddedBank,
    [setAdditionalStep]: onSetAdditionalStep,
    [completeOnboarding]: onCompleteOnboarding,
}, initialState);
