import {handleActions, apiActionHandler} from 'store/helpers/actions';
import {actionHandlers} from 'store/collections/reducer_helpers';
import {readAsset} from 'store/data/assets';
import {
    clearUpdateAssetError,
    updateAsset,
} from './actions';

export {
    getCollectionState,
    getFetchParams,
} from 'store/collections/reducer_helpers';

const initialState = {
    updateAssetError: null,
    updateAssetLoading: false,
};

export const isUpdateAssetLoading = ({updateAssetLoading}) => updateAssetLoading;
export const getUpdateAssetError = ({updateAssetError}) => updateAssetError;

export default handleActions({
    [updateAsset]: (state, action) => apiActionHandler(
        state,
        action,
        {loading: 'updateAssetLoading', error: 'updateAssetError'},
    ),
    [clearUpdateAssetError]: state => ({...state, updateAssetError: null}),
    ...actionHandlers('assets', readAsset),
}, initialState);
