import {createApiCallAction, createAction} from '../helpers/actions';

export const chooseNotificationType =
    createAction('newNotifications/CHOOSE_TYPE');

export const fetchCustomers =
    createApiCallAction('newNotifications/FETCH_CUSTOMERS', 'fetchNotificationCustomers');

export const chooseCustomers =
    createAction('newNotifications/CHOOSE_CUSTOMERS');

export const setMessage =
    createAction('newNotifications/SET_MESSAGE');

export const updateNotification = createApiCallAction('newNotifications/UPDATE_NOTIFICATION', 'updateNotification');
export const createNotification = createApiCallAction('newNotifications/CREATE_NOTIFICATION', 'createNotification');
export const deleteNotification = createApiCallAction('newNotifications/DELETE_NOTIFICATION', 'deleteNotification');
export const clearCreateNotificationError = createAction('transactions/CLEAR_CREATE_NOTIFICATION_ERROR');
