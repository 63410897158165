import React from 'react';
import PropTypes from 'prop-types';
import Header from './header/new-client-header';

const Layout = ({children}) => (
    <div className="prv-new-client-layout">
        <Header/>
        <div className="prv-new-client-layout__content">
            {children}
        </div>
    </div>
);
export default Layout;

Layout.propTypes = {
    children: PropTypes.node,
};
