import React, {memo, useCallback, useMemo, useState} from 'react';
import cn from 'classnames';
import moment from 'moment';
import Button from 'react-md/lib/Buttons/Button';
import DatePicker from 'react-md/lib/Pickers/DatePickerContainer';
import {Prompt, useHistory} from 'react-router-dom';
import useChangeLocationPrompt from '../../../../components/hooks/useChangeLocationPrompt';
import useBoolean from '../../../../components/hooks/useBoolean';
import {fetchAssets, fetchStructure, saveStructure} from '../../../../store/structure/actions';
import ConfirmationDialog from '../../../../components/dialogs/ConfirmationDialog';
import {
    getObsoleteAssets,
    getOriginalStartDate,
    getOriginalTree,
    getStartDate,
    getTree,
    getAccountGroups,
} from '../../../../store/structure';
import {makePrimary} from '../../../../store/structure-version/actions';
import {getSelectedVersion} from '../../../../store/structure-version';
import {StructureArray, StructureItem, StructureItemId, AccountGroup} from '../../../../store/structure/types';
import {isStructureChanged, getUsedButUnlinkedCustodyAccounts} from '../../../../store/structure/helpers/structure';
import {connect} from '../../../../store';
import Version from '../../../../store/types/Version';
import VersionSelector from './VersionSelector';
import MessageBox from '../../../../components/dialogs/message-box';

type CanChangeResolver = ((value: boolean) => void);

interface StateProps {
    obsoleteAssets: {[key in StructureItemId]: StructureItem},
    originalStartDate: Date,
    originalTree: StructureArray,
    startDate: Date,
    tree: StructureArray,
    accountGroups: AccountGroup[],
}

interface DispatchProps {
    fetchAssets?: (payload: {startDate: Date, versionId: Version['id']}) => void,
    fetchStructure?: (versionId: Version['id']) => void,
    saveStructure?: (versionId: Version['id']) => void,
    makePrimary: (payload: {versionId: Version['id']}) => void,
}

interface Props extends StateProps, DispatchProps {
    className?: string,
    showButtons: boolean,
    version: Version,
}

const Footer = memo<Props>(({
    fetchAssets,
    className,
    obsoleteAssets,
    originalStartDate,
    originalTree,
    accountGroups,
    fetchStructure,
    saveStructure,
    makePrimary,
    showButtons,
    startDate,
    tree,
    version,
}) => {
    const isTreeModified = useMemo(() => isStructureChanged(originalTree, tree), [originalTree, tree]);
    const unlinkedCustodyAccounts = useMemo(
        () => getUsedButUnlinkedCustodyAccounts(tree, accountGroups),
        [tree, accountGroups],
    );
    const [resetConfirmationVisible, showResetConfirmation, hideResetConfirmation] = useBoolean();
    const prompt = useChangeLocationPrompt(useHistory(), isTreeModified);

    const handleChangeDate = useCallback(
        (formattedDate: string, date: Date) => fetchAssets
            && fetchAssets({versionId: version.id, startDate: date}),
        [fetchAssets, version],
    );
    const startDateDiff = moment(startDate).diff(originalStartDate, 'day');

    const [primaryConfirmationVisible, showPrimaryConfirmation, hidePrimaryConfirmation] = useBoolean();
    const handlePrimaryConfirmation = useCallback(
        () => makePrimary({versionId: version.id}),
        [makePrimary, version],
    );

    const [unlinkedAccountsMessageVisible, showUnlinkedAccountsMessage, hideUnlinkedAccountsMessage] = useBoolean();
    const [obsoleteConfirmationVisible, showObsoleteConfirmation, hideObsoleteConfirmation] = useBoolean();
    const handleSaveStructure = useCallback(
        () => saveStructure && saveStructure(version.id),
        [saveStructure, version],
    );
    const handleSaveClick = useCallback(
        () => {
            if (unlinkedCustodyAccounts.length > 0) {
                showUnlinkedAccountsMessage();
            } else if (Object.keys(obsoleteAssets).length) {
                showObsoleteConfirmation();
            } else {
                handleSaveStructure();
                if (!version.primary) {
                    showPrimaryConfirmation();
                }
            }
        },
        [
            handleSaveStructure,
            obsoleteAssets,
            showObsoleteConfirmation,
            showPrimaryConfirmation,
            showUnlinkedAccountsMessage,
            unlinkedCustodyAccounts,
            version,
        ],
    );

    const handleResetStructure = useCallback(
        () => fetchStructure && fetchStructure(version.id),
        [fetchStructure, version],
    );

    const [canChangeVersionResolver, setCanChangeVersionResolver] = useState<CanChangeResolver | undefined>();
    const handleBeforeVersionChange = useCallback(
        (): Promise<boolean> => (isTreeModified
            ? new Promise<boolean>(resolve => setCanChangeVersionResolver(() => resolve))
            : Promise.resolve(true)
        ),
        [isTreeModified, setCanChangeVersionResolver],
    );
    const handleConfirmChange = useCallback(
        () => {
            canChangeVersionResolver && canChangeVersionResolver(true);
            setCanChangeVersionResolver(undefined);
        },
        [canChangeVersionResolver, setCanChangeVersionResolver],
    );
    const handleCancelChange = useCallback(
        () => {
            canChangeVersionResolver && canChangeVersionResolver(false);
            setCanChangeVersionResolver(undefined);
        },
        [canChangeVersionResolver, setCanChangeVersionResolver],
    );

    return (
        <div className={cn('prv-structure-footer', className)}>
            <div className="prv-structure-footer__date-container">
                <VersionSelector
                    id="structure-version"
                    onSetAsPrimary={showPrimaryConfirmation}
                    onBeforeChange={handleBeforeVersionChange}
                />
                <DatePicker
                    className="prv-structure-footer__date-picker"
                    id="structure-date-picker"
                    label="Start Date"
                    onChange={handleChangeDate}
                    value={startDate}
                />
            </div>
            {showButtons && (
                <div>
                    <Button
                        flat
                        onClick={showResetConfirmation}
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={!isTreeModified && startDateDiff === 0}
                        flat
                        onClick={handleSaveClick}
                        primary
                    >
                        Save
                    </Button>
                </div>
            )}
            <ConfirmationDialog
                confirmButtonLabel="Reset"
                focusOnConfirm={false}
                id="structure-reset-confirmation"
                onConfirm={handleResetStructure}
                onHide={hideResetConfirmation}
                text="All your changes will be lost. You can not cancel this operation."
                title="Do you really want to reset structure to its original state?"
                visible={resetConfirmationVisible}
            />

            <Prompt
                message={prompt.handlePromptMessage}
                when={isTreeModified && !prompt.visible}
            />
            <ConfirmationDialog
                confirmButtonLabel="Leave"
                focusOnConfirm={false}
                hideOnConfirm={false}
                id="structure-leave-confirmation"
                onConfirm={prompt.confirmLeave}
                onHide={prompt.hide}
                text="All unsaved changes will be lost."
                title="Do you really want to leave Structure page?"
                visible={prompt.visible}
            />

            <ConfirmationDialog
                confirmButtonLabel="Proceed"
                focusOnConfirm={false}
                hideOnConfirm={false}
                id="version-change-confirmation"
                onConfirm={handleConfirmChange}
                onHide={handleCancelChange}
                text="All unsaved changes will be lost."
                title="Do you really want to change version?"
                visible={!!canChangeVersionResolver}
            />

            <ConfirmationDialog
                cancelButtonLabel="No"
                confirmButtonLabel="Yes"
                focusOnConfirm={false}
                id="structure-obsolete-confirmation"
                onConfirm={handleSaveStructure}
                onHide={hideObsoleteConfirmation}
                text="There are portfolios with obsolete assets in a current structure. Do you want to save?"
                visible={obsoleteConfirmationVisible}
            />

            <ConfirmationDialog
                confirmButtonLabel="Yes"
                cancelButtonLabel="No"
                focusOnConfirm={false}
                id="structure-primary-confirmation"
                title="Make Primary?"
                onConfirm={handlePrimaryConfirmation}
                onHide={hidePrimaryConfirmation}
                visible={primaryConfirmationVisible}
            />

            <MessageBox
                onHide={hideUnlinkedAccountsMessage}
                text={(
                    <div className="prv-structure-footer__unlinked-accounts-container">
                        Papers from these Custody Accounts are used, but accounts are not linked:
                        <ul className="prv-structure-footer__unlinked-accounts-list">
                            {unlinkedCustodyAccounts.map(account => <li key={account.id}>{account.name}</li>)}
                        </ul>
                        Please link them to any portfolio before saving.
                    </div>
                )}
                visible={unlinkedAccountsMessageVisible}
            />
        </div>
    );
});

// FIXME: figure out how to type the connect function
// @ts-ignore
export default connect(
    {
        obsoleteAssets: getObsoleteAssets,
        originalStartDate: getOriginalStartDate,
        originalTree: getOriginalTree,
        startDate: getStartDate,
        tree: getTree,
        accountGroups: getAccountGroups,
        version: getSelectedVersion,
    },
    {
        fetchAssets,
        fetchStructure,
        saveStructure,
        makePrimary,
    },
)(Footer);
