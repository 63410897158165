import typeReader from 'store/helpers/type_reader';
import typeShape, {required, optional} from 'store/helpers/type_shape';

const AssetsType = {
    accountId: optional(String),
    totalValue: required(Number, 'assetsValue'),
    equities: required(Number),
    bonds: required(Number),
    cash: required(Number),
    realEstates: required(Number),
    alternatives: required(Number),
};

const AssetsAllocationType = {
    accountId: required(String),
    customerId: required(String),
    accountName: required(String),
    id: required(String),
    assetId: required(String),
    name: required(String),
    type: required(String),
    assetReportingCategoryName: required(String),
    region: optional(String),
    currency: required(String),
    value: required(Number),
    share: required(Number),
    isin: optional(String),
};

export const readAssets = typeReader(AssetsType);
export const assetsShape = typeShape(AssetsType);

export const readAssetsAllocation = typeReader(AssetsAllocationType);
export const assetsAllocationShape = typeShape(AssetsAllocationType);
