import {actionDispatchers} from '../collections/saga_helpers';
import {getFetchParams} from './index';
import {callApi, dispatch, fromStore} from '../helpers/sagas';
import {createApiCallResultAction} from '../helpers/actions';

import {
    fetchAssets,
} from './actions';

const assetsFetched = createApiCallResultAction(fetchAssets);

function* fetcher({payload}) {
    const params = yield fromStore(getFetchParams, payload);
    try {
        const result = yield callApi('fetchAssetList', {...params});
        yield dispatch(assetsFetched(result));
    } catch (error) {
        yield dispatch(assetsFetched(error));
    }
}

export default {
    [fetchAssets]: fetcher,
    ...actionDispatchers('assets'),
};
