import _ from 'lodash';
import {handleActions} from 'store/helpers/actions';
import {
    showToast,
    hideToast,
} from './actions';
import ApiError from '../api/error';

const initialState = {
    toasts: [],
};

const DEFAULT_ERROR = 'Unknown error';
const DEFAULT_TOAST_PROPS = {autohide: true};
const processText = (text) => {
    if (_.isEmpty(text)) {
        return '';
    }

    let message = text;
    if (message instanceof ApiError) {
        const errors = text.errors.length ? text.errors : text.validationErrors;
        message = _.map(errors, err => err.message);
        if (!message.length) {
            message = DEFAULT_ERROR;
        }
    } else if (message.message) {
        message = message.message || DEFAULT_ERROR;
    }
    return message;
};

export const getToasts = ({toasts}) => toasts;

export default handleActions({
    [showToast]: (state, {payload: toast}) => ({
        ...state,
        toasts: [...state.toasts, {...DEFAULT_TOAST_PROPS, ...toast, text: processText(toast.text)}],
    }),
    [hideToast]: state => ({...state, toasts: state.toasts.slice(1)}),
}, initialState);
