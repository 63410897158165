import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import SelectField from 'react-md/lib/SelectFields/SelectField';
import {connect} from '../../../store';

import {
    fetchPortfolios,
    updatePortfolio,
    deletePortfolio,
    clearUpdatePortfolioError,
    recalculatePortfolio,
} from '../../../store/client-portfolios/actions';
import {getCollectionState, isUpdatePortfolioLoading, getUpdatePortfolioError} from '../../../store/client-portfolios';
import {collectionShape} from '../../../store/collections';

import {getPinnedClient} from '../../../store/clients';
import {getAssetsManagers, isLoading} from '../../../store/accounts';
import {fetchAssetsManagers} from '../../../store/accounts/actions';
import {assetsManagerShape, clientShape} from '../../../store/data/client';
import {showToast} from '../../../store/snackbar/actions';
import {getVersions, getDefaultVersion} from '../../../store/structure-version';

import CollectionListPage from '../../../components/collections/list-page';
import EditAccountDialog from '../edit-account-dialog/edit-account-dialog';
import {money} from '../../../helpers/formatters';
import ActionViewOptions from '../../../components/resource-table/action-view-options';
import MessageBox from '../../../components/dialogs/message-box';

const yes = () => true;
const VALUE_CHANGE_TYPE_OPTIONS = {
    totalValueChange: 'All',
    totalValueChangeForDay: 'Daily',
    totalValueChangeForMonth: 'MtD',
    totalValueChangeForYear: 'YtD',
};

class PortfoliosPage extends Component {
    static propTypes = {
        assetsManagers: PropTypes.arrayOf(assetsManagerShape),
        clearUpdatePortfolioError: PropTypes.func.isRequired,
        client: clientShape,
        collection: collectionShape,
        deletePortfolio: PropTypes.func.isRequired,
        fetchPortfolios: PropTypes.func.isRequired,
        portfolioUpdateError: PropTypes.bool,
        portfolioUpdating: PropTypes.bool,
        showToast: PropTypes.func.isRequired,
        updatePortfolio: PropTypes.func.isRequired,
        recalculatePortfolio: PropTypes.func.isRequired,
        fetchAssetsManagers: PropTypes.func.isRequired,
        amsLoading: PropTypes.bool,
        versions: PropTypes.array,
        defaultVersion: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.state = {
            portfolioBeingEdited: null,
            recalculationDialog: undefined,
            valueChangeType: 'totalValueChange',
        };
    }

    componentWillMount() {
        this.props.fetchAssetsManagers();
    }

    onEdit = portfolio => {
        this.setState({portfolioBeingEdited: portfolio});
    };

    onDelete = async ({id}) => {
        const {client, deletePortfolio, showToast} = this.props;
        await deletePortfolio({clientId: client.id, id})
            .catch((e) => {
                const isUsed = e && Array.isArray(e.errors) && e.errors.some(error =>
                    error.message.contains('cannot delete one of following portfolios at it used'));
                showToast({text: isUsed
                    ? 'Portfolio is in use, you can\'t delete'
                    : 'Failed to delete portfolio. Please try again later.',
                });
            });
    };

    onRecalculate = async (item) => {
        const {client, recalculatePortfolio} = this.props;
        try {
            await recalculatePortfolio({clientId: client.id, portfolioId: item.id});
            this.setState({recalculationDialog:
                    `Recalculation has started for Portfolio "${item.name}".\nIt will take some time.`});
        } catch (e) {
            this.setState({recalculationDialog:
                    `Failed to start recalculation for Portfolio "${item.name}".\nPlease try again later.`});
        }
    };

    onPortfolioUpdate = async ({accountName: name, benchmarks, invoices, selectedAMs}) => {
        const {portfolioBeingEdited} = this.state;
        const data = {
            clientId: this.props.client.id,
            portfolioId: portfolioBeingEdited.id,
            benchmarks: _.map(benchmarks, ({id, weight, startDate, deleted}) => ({id, weight, startDate, deleted})),
            name,
            assetsManagerId: (selectedAMs && selectedAMs.length) ? selectedAMs[0].id : undefined,
            isLegacy: portfolioBeingEdited.isLegacy,
            ...invoices,
        };
        this.onEditPortfolioDialogClose();
        await this.props.updatePortfolio(data);
        this.props.fetchPortfolios();
    };

    onEditPortfolioDialogClose = () => this.setState({portfolioBeingEdited: null});

    getColumns = () => ([
        {field: 'name', label: 'Name'},
        {
            field: 'value',
            label: 'Value',
            render: money,
            className: 'prv-client-portfolio-collection-item--right-aligned',
            headerClassName: 'prv-client-portfolio-collection-item--right-aligned',
            width: '100px',
        },
        {
            field: 'totalValueChange',
            label: 'Value Change',
            render: (value, row) => money(row[this.state.valueChangeType]),
            className: 'prv-client-portfolio-collection-item--right-aligned',
            headerClassName: 'prv-client-portfolio-collection-item--right-aligned',
            width: '100px',
        },
    ]);

    getFiltersConfig = () => {
        const {versions, client} = this.props;
        const versionOptions = versions.reduce((result, item) => Object.assign(result, {[item.id]: item.label}), {});

        const filtersConfig = {};

        if (!client.atlazId) {
            filtersConfig.versionId = {
                title: 'Structure Version',
                options: versionOptions,
                className: 'prv-client-portfolio__version-filter',
            };
        }

        return filtersConfig;
    };

    _handleValueChangeTypeChange = (valueChangeType) => {
        this.setState({valueChangeType});
    };

    _handleHideRecalculationDialog = () => this.setState({recalculationDialog: undefined});

    render() {
        const {
            amsLoading,
            assetsManagers,
            collection,
            portfolioUpdating,
            portfolioUpdateError,
            defaultVersion,
        } = this.props;
        const {portfolioBeingEdited, valueChangeType, recalculationDialog} = this.state;

        const selectedAm = portfolioBeingEdited
            ? assetsManagers.find(({id}) => id === portfolioBeingEdited.assetsManagerId)
            : undefined;

        return (
            <div>
                <CollectionListPage
                    actionView={ActionViewOptions.Icons}
                    canEdit={yes}
                    canDelete={yes}
                    canRecalculate={yes}
                    className="prv-client-portfolio__collection"
                    collection={collection}
                    collectionTitle="Portfolios"
                    initialState={{filters: {versionId: defaultVersion && defaultVersion.id}}}
                    columns={this.getColumns()}
                    filters={this.getFiltersConfig()}
                    displayOptions={[
                        <SelectField
                            className="prv-subheader-filters__option prv-client-portfolio__value-change-field"
                            id="filter-valueChangeType"
                            key="filter-valueChangeType"
                            label="Value Change"
                            menuItems={Object.keys(VALUE_CHANGE_TYPE_OPTIONS)
                                .map(opt => ({value: opt, label: VALUE_CHANGE_TYPE_OPTIONS[opt]}))}
                            onChange={this._handleValueChangeTypeChange}
                            simplifiedMenu={false}
                            value={valueChangeType}
                        />,
                    ]}
                    name="clientPortfolios"
                    onEdit={this.onEdit}
                    onDelete={this.onDelete}
                    onRecalculate={this.onRecalculate}
                />
                {portfolioBeingEdited &&
                <EditAccountDialog
                    addCustomAssetManager={false}
                    assetsManagers={assetsManagers}
                    selectedAMs={selectedAm ? [selectedAm] : []}
                    account={{name: portfolioBeingEdited.name}}
                    renderHeaderTitle={() => <div>Edit</div>}
                    limits={portfolioBeingEdited.limits || []}
                    benchmarks={portfolioBeingEdited.benchmarks || []}
                    invoicingAUMLock={portfolioBeingEdited.invoicingAUMLock}
                    invoicingReturnLock={portfolioBeingEdited.invoicingReturnLock}
                    invoicingFixedLock={portfolioBeingEdited.invoicingFixedLock}
                    reportingLock={portfolioBeingEdited.reportingLock}
                    confirmationButtonTitle="Set"
                    onSetAccount={this.onPortfolioUpdate}
                    onClose={this.onEditPortfolioDialogClose}

                    loading={portfolioUpdating || amsLoading}
                    error={portfolioUpdateError}
                    onClearError={this.props.clearUpdatePortfolioError}

                    shouldBeRendered={{
                        accountName: true,
                        ams: true,
                        limits: false,
                        benchmarks: true,
                        invoices: true,
                        endDate: true,
                    }}
                    invoices={{
                        invoicingAUM: portfolioBeingEdited.invoicingAUM,
                        invoicingReturn: portfolioBeingEdited.invoicingReturn,
                        invoicingFixed: portfolioBeingEdited.invoicingFixed,
                        proveoScoreCalculated: portfolioBeingEdited.proveoScoreCalculated,
                        reporting: portfolioBeingEdited.reporting,
                        createReport: portfolioBeingEdited.createReport,
                    }}
                />
                }
                {recalculationDialog &&
                <MessageBox
                    onHide={this._handleHideRecalculationDialog}
                    title="Recalculation"
                    text={<p>{recalculationDialog}</p>}
                    visible={!!recalculationDialog}
                />}
            </div>
        );
    }
}

export default connect(
    {
        assetsManagers: getAssetsManagers,
        collection: getCollectionState,
        portfolioUpdating: isUpdatePortfolioLoading,
        portfolioUpdateError: getUpdatePortfolioError,
        client: getPinnedClient,
        amsLoading: isLoading,
        versions: getVersions,
        defaultVersion: getDefaultVersion,
    },
    {
        fetchAssetsManagers,
        fetchPortfolios,
        updatePortfolio,
        deletePortfolio,
        clearUpdatePortfolioError,
        showToast,
        recalculatePortfolio,
    },
)(PortfoliosPage);
