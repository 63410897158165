import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import qs from 'query-string';

import * as pathnames from 'config/pathnames';

import {connect} from 'store';
import {navigate} from 'store/navigate';
import {collectionShape} from 'store/collections';

import {getCollectionState} from 'store/client-benchmarks';

import {fetchClientBenchmarks, deleteClientBenchmark, recalculateBenchmark} from 'store/client-benchmarks/actions';

import CollectionListPage from 'components/collections/list-page';
import {DeleteDialog} from 'components/dialogs';
import EditBenchmarkDialog from './edit-benchmark-dialog/edit-benchmark-dialog';

import {isSA} from '../../store/auth';
import ActionViewOptions from '../../components/resource-table/action-view-options';
import MessageBox from '../../components/dialogs/message-box';

const columns = [
    {
        field: 'name',
        label: 'Name',
        render: (name, benchmark) => (
            <span>
                {name}
                {benchmark.isDefault &&
                <span className="prv-client-benchmarks__default-label">default</span>}
            </span>
        ),
    },
    {field: 'ownerName', label: 'Owner', width: '18%'},
];

const yes = () => true;

class ClientBenchmarksPage extends Component {
    static propTypes = {
        collection: collectionShape,
        isSA: PropTypes.bool,
        location: PropTypes.shape({
            hash: PropTypes.string,
            pathname: PropTypes.string,
        }),
        fetchClientBenchmarks: PropTypes.func.isRequired,
        deleteClientBenchmark: PropTypes.func.isRequired,
        navigate: PropTypes.func.isRequired,
        recalculateBenchmark: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            recalculationDialog: undefined,
        };
    }

    onEdit = item => this.props.navigate(`${pathnames.CLIENT_BENCHMARKS}#edit=${item.id}`);

    onDelete = ({id}, {assignTo}) => this.props.deleteClientBenchmark({id, assignTo});

    onRecalculate = async (item) => {
        try {
            await this.props.recalculateBenchmark({benchmarkId: item.id});
            this.setState({recalculationDialog:
                    `Recalculation has started for Benchmark "${item.name}".\nIt will take some time.`});
        } catch (e) {
            this.setState({recalculationDialog:
                    `Failed to start recalculation for Benchmark "${item.name}".\nPlease try again later.`});
        }
    };

    onSubmitSuccess = () => {
        this.props.fetchClientBenchmarks();
        this.props.navigate(pathnames.CLIENT_BENCHMARKS);
    };
    getDeleteMessage = item => `Do you want to delete ${item.name}`;

    closeDialog = () => this.props.navigate(pathnames.CLIENT_BENCHMARKS);

    _canDeleteBenchmark = benchmark => (!benchmark.isDefault || this.props.isSA);

    _handleHideRecalculationDialog = () => this.setState({recalculationDialog: undefined});

    renderDeleteDialog = ({item, onSubmit, onCancel, onSubmitSuccess, onSubmitFail}) => {
        const {collection} = this.props;
        const message = collection.items.some(collectionItem => collectionItem.id !== item.id)
            ? 'Choose a replacement benchmark'
            : 'You have no opportunity to remove singular benchmark. Add one more benchmark or remove all links to this one.'; // eslint-disable-line max-len

        return (
            <DeleteDialog
                message={message}
                selectPlaceholder="Choose benchmark"
                itemToDelete={item}
                items={collection.items}
                onSubmit={onSubmit}
                onCancel={onCancel}
                onSubmitSuccess={onSubmitSuccess}
                onSubmitFail={onSubmitFail}
                renderItem={({name}) => name}
                selectOptionsMapper={({id, name}) => ({value: id, label: name})}
            />
        );
    };

    render() {
        const {collection, location} = this.props;
        const {recalculationDialog} = this.state;

        const hash = qs.parse(location.hash);
        const items = this.props.collection.items;

        const updatedBenchmark = hash.edit && items && items.find(x => x.id === hash.edit);
        const shouldRenderBenchmarkDialog = location.pathname.endsWith('create') || updatedBenchmark;

        return (
            <div>
                <CollectionListPage
                    actionView={ActionViewOptions.Icons}
                    name="client-benchmarks"
                    title="Benchmarks"
                    collection={collection}
                    columns={columns}

                    canEdit={yes}
                    onEdit={this.onEdit}
                    canDelete={this._canDeleteBenchmark}
                    onDelete={this.onDelete}
                    canRecalculate={yes}
                    onRecalculate={this.onRecalculate}

                    renderDeleteDialog={this.renderDeleteDialog}
                    getDeleteMessage={this.getDeleteMessage}
                />
                {
                    shouldRenderBenchmarkDialog &&
                    <EditBenchmarkDialog
                        benchmark={updatedBenchmark}
                        onCancel={this.closeDialog}
                        onSubmitSuccess={this.onSubmitSuccess}
                    />
                }
                {recalculationDialog &&
                <MessageBox
                    onHide={this._handleHideRecalculationDialog}
                    title="Recalculation"
                    text={<p>{recalculationDialog}</p>}
                    visible={!!recalculationDialog}
                />}
            </div>
        );
    }
}

export default withRouter(connect(
    {collection: getCollectionState, isSA},
    {fetchClientBenchmarks, deleteClientBenchmark, navigate, recalculateBenchmark},
)(ClientBenchmarksPage));
