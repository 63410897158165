import React, {memo} from 'react';
import cn from 'classnames';
import FontIcon from 'react-md/lib/FontIcons/FontIcon';
import IconSeparator from 'react-md/lib/Helpers/IconSeparator';
import Tooltipped from 'react-md/lib/Tooltips/Tooltipped';
import Badge from 'react-md/lib/Badges/Badge';

interface Props {
    className?: string,
    expanded: boolean,
    hasAssets: boolean,
    markObsolete?: boolean,
    onClick: (event: React.MouseEvent<HTMLElement>) => void,
    title: string,
}

const AssetGroupTitle: React.FC<Props> = memo(({className, expanded, hasAssets, markObsolete, onClick, title}) => (
    <Tooltipped
        delay={500}
        label={title}
        position="top"
        setPosition
    >
        <IconSeparator
            className={cn('prv-account-group-title', className)}
            iconBefore
            label={title}
            labelClassName={cn(
                'prv-account-group-title__label',
                {'prv-account-group-title__label--obsolete': markObsolete},
            )}
            onClick={onClick}
        >
            <FontIcon
                className={cn(
                    'prv-account-group-title__icon',
                    'md-collapser',
                    {'md-collapser--flipped': expanded},
                )}
                inherit
            >
                {hasAssets ? 'expand_more' : ' '}
            </FontIcon>
        </IconSeparator>
    </Tooltipped>
));

export default AssetGroupTitle;
