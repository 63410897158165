import difference from 'lodash/difference';
import {AnyAssetList} from '../types';
import {StructureState} from '../reducer';
import {getNextChildrenLevel} from '../helpers/structure';
import {getLastIndex, updateLevelIndex} from '../helpers/level';
import {sortStructure} from '../helpers/sort';

const addAssetsReducer = (state: StructureState, {payload: assets}: {payload: AnyAssetList}) => {
    if (!assets.length || !state.selectedPortfolio) {
        return state;
    }

    // first, update groups to exclude assets being added
    const accountGroups = state.accountGroups
        .map(group => {
            const filteredAssets = difference(group.assets, assets);
            return filteredAssets.length < group.assets.length
                ? {...group, assets: filteredAssets}
                : group;
        })
        // remove groups without assets if they're linked or if that's the Cash Account group
        .filter(group => group.assets.length || (!group.isCashAccountGroup && !group.isLinked));

    // next, add these assets to the tree
    const {tree} = state;
    const baseLevel = getNextChildrenLevel(tree, state.selectedPortfolio);
    const baseIndex = getLastIndex(baseLevel);
    return {
        ...state,
        accountGroups,
        tree: sortStructure([
            ...tree,
            ...assets.map((asset, index) => ({
                ...asset,
                level: updateLevelIndex(baseLevel, baseIndex + index),
            })),
        ]),
    };
};

export default addAssetsReducer;
