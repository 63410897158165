import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {Button, FontIcon} from 'react-md';

import {connect} from '../../../store';
import {getPinnedClient} from '../../../store/clients';
import {getGraph, getInfo, getPortfolios, isLoading as performanceIsLoading, isPortfoliosLoading} from '../../../store/performance';
import {getTree, getLoading} from '../../../store/structure';
import {fetchStructure} from '../../../store/structure/actions';

import {
    fetchCustomerPortfolios,
    fetchGraph,
    fetchGraphForPortfolio,
    fetchPerformanceInfo,
    fetchPerformanceInfoForPortfolio,
} from '../../../store/performance/actions';
import {clearPortfolios} from '../../../store/client-portfolios/actions';

import {
    clientShape,
    performanceGraphShape,
    performanceInfoShape,
    portfolioShape,
} from '../../../store/data/client';

import {percentTrend} from '../../../helpers/formatters';

import Spinner from '../../../components/loading-spinner/loading-spinner';
import Switch from '../../../components/switch/switch';
import Plot from '../../../components/plotly/plot';
import VersionSelector from './components/VersionSelector';
import {levelComparator} from '../../../store/structure/helpers/sort';
import {recalculateClient} from '../../../store/clients/actions';
import {showToast} from '../../../store/snackbar/actions';
import {getDefaultVersion} from '../../../store/structure-version';

const formatClientName = (client) => (client.firstName || client.lastName
    ? `${client.firstName || ''} ${client.lastName || ''}`
    : 'client');

class PerformancePage extends Component {
    static propTypes = {
        client: clientShape,
        graph: performanceGraphShape,
        info: performanceInfoShape,
        graphLoading: PropTypes.bool,
        fetchCustomerPortfolios: PropTypes.func.isRequired,
        fetchGraph: PropTypes.func.isRequired,
        fetchGraphForPortfolio: PropTypes.func.isRequired,
        fetchPerformanceInfo: PropTypes.func.isRequired,
        fetchPerformanceInfoForPortfolio: PropTypes.func.isRequired,
        portfolios: PropTypes.arrayOf(portfolioShape),
        clearPortfolios: PropTypes.func.isRequired,
        fetchStructure: PropTypes.func.isRequired,
        tree: PropTypes.array.isRequired,
        treeLoading: PropTypes.bool.isRequired,
        portfoliosLoading: PropTypes.bool.isRequired,
        recalculateClient: PropTypes.func.isRequired,
        showToast: PropTypes.func.isRequired,
        defaultVersion: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.dateFilters = [
            {label: 'Month', value: 'MtD'},
            {label: 'Year', value: 'YtD'},
            {label: 'All', value: 'all'},
        ];

        this.state = {
            dateFilter: this.dateFilters[0],
            previousSelectedPortfolios: {},
            firstRender: true,
            hideData: false,
            unfolded: [],
        };
    }

    componentDidMount() {
        // eslint-disable-next-line react/no-did-mount-set-state
        this.setState({firstRender: false});
        if (this.props.defaultVersion) {
            this.fetchTree(this.props.defaultVersion.id);
        } else {
            this.props.fetchCustomerPortfolios(this.getPortfoliosFetchParams());
            this.fetchPerformanceData(true);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (!nextProps.graphLoading && this.state.hideData) {
            this.setState({hideData: false});
        }
    }

    componentWillUnmount() {
        this.props.clearPortfolios();
    }

    onChooseDatePeriod = (item) => {
        this.shouldRedraw = false;
        this.setState({dateFilter: item}, () => {
            this.shouldRedraw = true;
            this.fetchPerformanceData();
        });
    };

    onChooseEntity = (id) => {
        this.shouldRedraw = false;
        this.setState({entityFilter: id}, () => {
            this.shouldRedraw = true;
            this.fetchPerformanceData();
        });
    };

    getPortfoliosFetchParams = () => {
        const {client} = this.props;
        const {versionId} = this.state;
        return {id: client.id, versionId, hasUsages: true, perPage: 9999};
    };

    getGraphFetchParams = () => {
        const {client} = this.props;
        const {entityFilter, dateFilter, versionId} = this.state;

        return {clientId: client.id, id: entityFilter, versionId, period: dateFilter.value};
    };

    getChartHeaderInfo = () => {
        const {info} = this.props;
        if (!info) {
            return {
                portfolio: 0,
                benchmark: 0,
                outperformance: 0,
            };
        }
        switch (this.state.dateFilter.value) {
            case 'MtD':
                return {
                    portfolio: info.totalPortfolioForMonth,
                    benchmark: info.totalBenchmarkForMonth,
                    outperformance: info.totalOutperformanceForMonth,
                };

            case 'YtD':
                return {
                    portfolio: info.totalPortfolioForYear,
                    benchmark: info.totalBenchmarkForYear,
                    outperformance: info.totalOutperformanceForYear,
                };

            default:
                return {
                    portfolio: info.totalPortfolio,
                    benchmark: info.totalBenchmark,
                    outperformance: info.totalOutperformance,
                };
        }
    };

    getNode = item => (this.props.tree ?? []).find(({id}) => id === item.id) || {level: []};

    handleRecalculateOutdatedClick = async () => {
        const {client, recalculateClient, showToast} = this.props;
        try {
            await recalculateClient({clientId: client.id});
            showToast({
                text: `Recalculation of outdated data has started for ${(formatClientName(client))}. It will take some time.`,
            });
        } catch (e) {
            showToast({
                text: `Failed to start recalculation of outdated data for ${formatClientName(client)}. Please try again later.`,
            });
        }
    };
    handleRecalculateAllClick = async () => {
        const {client, recalculateClient, showToast} = this.props;
        try {
            await recalculateClient({clientId: client.id, force: true});
            showToast({
                text: `Recalculation of all data has started for ${(formatClientName(client))}. It will take some time.`,
            });
        } catch (e) {
            showToast({
                text: `Failed to start recalculation of all data for ${formatClientName(client)}. Please try again later.`,
            });
        }
    }

    fetchPerformanceData = (hide = false) => {
        this.setState({hideData: hide});
        this.fetchInfo();
        this.fetchGraph();
    };

    fetchInfo = () => {
        const {entityFilter, dateFilter, versionId} = this.state;
        const fetchParams = {clientId: this.props.client.id, versionId, id: entityFilter, period: dateFilter.value};

        if (!entityFilter) {
            return this.props.fetchPerformanceInfo(fetchParams);
        }

        return this.props.fetchPerformanceInfoForPortfolio(fetchParams);
    };

    fetchGraph = () => {
        const fetchParams = this.getGraphFetchParams();

        if (!this.state.entityFilter) {
            return this.props.fetchGraph(fetchParams);
        }

        return this.props.fetchGraphForPortfolio(fetchParams);
    };

    fetchTree = (versionId) => {
        this.setState({
            versionId,
            unfolded: [],
            entityFilter: this.state.previousSelectedPortfolios[versionId] || null,
            previousSelectedPortfolios: {
                ...this.state.previousSelectedPortfolios,
                ...(this.state.versionId ? {[this.state.versionId]: this.state.entityFilter} : undefined),
            },
        }, () => {
            this.shouldRedraw = true;
            this.fetchPerformanceData();
            this.props.fetchCustomerPortfolios(this.getPortfoliosFetchParams());
            this.props.fetchStructure(versionId);
        });
    }

    toggleFolding = id => () => {
        const node = this.getNode({id});
        const isUnfolded = this.isUnfolded(node);
        if (!isUnfolded) {
            this.setState({
                unfolded: [...this.state.unfolded, node.level[1]],
            });
        } else {
            this.setState({
                unfolded: this.state.unfolded.filter(lvl => lvl !== node.level[1]),
            });
        }
    }

    isUnfolded = item => this.state.unfolded.includes(item.level[1]);

    renderEntityFilter = () => {
        const {portfolios, client, graphLoading, treeLoading, portfoliosLoading} = this.props;
        const {dateFilter, entityFilter, versionId, hideData} = this.state;
        const getMargin = (item) => {
            const margin = 10 * (this.getNode(item).level.length - 2);
            return margin > 0 ? margin : 0;
        };
        const visiblePortfolios = portfolios && portfolios
            .filter(item => {
                if (!versionId) {
                    return item && item.id;
                }

                const node = this.getNode(item);
                if (node.level.length <= 2) return true;
                return this.isUnfolded(node);
            })
            .sort((nodeA, nodeB) => levelComparator(this.getNode(nodeA).level, this.getNode(nodeB).level));
        const isPortfolioSelected = item => entityFilter === item.id;
        const loading = treeLoading || graphLoading || portfoliosLoading;

        return (
            <div className={cn('prv-client-performance__entity-filter')}>
                {!client.atlazId && <VersionSelector
                    id="perfomance-structure-version"
                    key="filter-structureVersion"
                    selectedVersionId={versionId}
                    onChange={this.fetchTree}
                    disabled={loading}
                />}
                <Switch
                    activeItem={dateFilter}
                    className="prv-client-performance__date-filter"
                    idKey="value"
                    itemClassName="prv-client-performance__date-filter-item"
                    items={this.dateFilters}
                    onItemClick={this.onChooseDatePeriod}
                    renderItem={item => item.label}
                />
                <div
                    className={cn('prv-client-performance__entity-filter-item', {
                        'prv-client-performance__entity-filter-item--active': !entityFilter,
                    })}
                    onClick={() => this.onChooseEntity()}
                >
                    All Portfolios
                </div>
                {
                    !hideData && !treeLoading && !portfoliosLoading && visiblePortfolios && visiblePortfolios
                        .map(item => {
                            const node = this.getNode(item);
                            return (
                                <div
                                    className={cn('prv-client-performance__entity-filter-item', {
                                        'prv-client-performance__entity-filter-item--active': isPortfolioSelected(item),
                                    })}
                                    style={{
                                        marginLeft: `${getMargin(item)}px`,
                                        width: `calc(100% - ${getMargin(item)}px)`,
                                        minWidth: '60%',
                                    }}
                                    key={item.id}
                                    onClick={() => this.onChooseEntity(item.id)}
                                >
                                    <div>
                                        {item.name}
                                    </div>
                                    {versionId && node.level.length === 2 && (
                                        <div onClick={this.toggleFolding(item.id)}>
                                            {this.isUnfolded(node)
                                                ? (
                                                    <FontIcon
                                                        style={{color: isPortfolioSelected(item) && 'white'}}
                                                    >
                                                        expand_less
                                                    </FontIcon>
                                                ) : (
                                                    <FontIcon
                                                        style={{color: isPortfolioSelected(item) && 'white'}}
                                                    >
                                                        expand_more
                                                    </FontIcon>
                                                )}
                                        </div>
                                    )}
                                </div>
                            );
                        })
                }
                <div className="prv-client-performance__entity-filter-spacer">&nbsp;</div>
                <div className="prv-client-performance__entity-filter-buttons">
                    <Button
                        flat
                        iconChildren="refresh"
                        onClick={this.handleRecalculateOutdatedClick}
                        tooltipLabel="Recalculate Outdated"
                        tooltipPosition="top"
                    >
                        Outdated
                    </Button>
                    <Button
                        flat
                        iconChildren="refresh"
                        onClick={this.handleRecalculateAllClick}
                        tooltipLabel="Recalculate All"
                        tooltipPosition="top"
                    >
                        All
                    </Button>
                </div>
            </div>
        );
    };

    renderChartHeader = () => {
        const {portfolio = 0, benchmark = 0, outperformance = 0} = this.getChartHeaderInfo();

        const getColorClassName = (value) => ({
            'prv-client-performance__percent-value--up': value > 0,
            'prv-client-performance__percent-value--flat': value === 0 || !value,
            'prv-client-performance__percent-value--down': value < 0,
        });

        return (
            <div className="prv-client-performance__chart-header">
                <div>
                    <div className="prv-client-performance__percent-title">Portfolio</div>
                    <div
                        className={cn(
                            'prv-client-performance__percent-value',
                            getColorClassName(Number(portfolio.toFixed(2))),
                        )}
                    >
                        {percentTrend(portfolio)}
                    </div>
                </div>
                <div>
                    <div className="prv-client-performance__percent-title">Benchmark</div>
                    <div
                        className={cn(
                            'prv-client-performance__percent-value',
                            getColorClassName(Number(benchmark.toFixed(2))),
                        )}
                    >
                        {percentTrend(benchmark)}
                    </div>
                </div>
                <div>
                    <div className="prv-client-performance__percent-title">Outperformance</div>
                    <div
                        className={cn(
                            'prv-client-performance__percent-value',
                            getColorClassName(Number(outperformance.toFixed(2))),
                        )}
                    >
                        {percentTrend(outperformance)}
                    </div>
                </div>
            </div>
        );
    };

    renderChartFooter = () => (
        <div className="prv-client-performance__chart-legend">
            <div className="prv-client-performance__chart-legend-item">
                <div className="prv-client-performance__legend-color prv-client-performance__legend-color--portfolio"/>
                <span>Portfolio</span>
            </div>
            <div className="prv-client-performance__chart-legend-item">
                <div className="prv-client-performance__legend-color prv-client-performance__legend-color--benchmark"/>
                <span>Benchmark</span>
            </div>
        </div>
    );

    renderChart = () => {
        const {graph, graphLoading} = this.props;

        const portfolio = {
            name: 'portfolio',
            x: [],
            y: [],
            marker: {
                color: '#7CB1D6',
            },
        };
        const benchmark = {
            name: 'benchmark',
            x: [],
            y: [],
            marker: {
                color: '#9874B6',
            },
        };

        if (graph && !this.state.hideData) {
            if (!graph.returns.length || !graph.benchmark.length) {
                return null;
            }

            graph.returns.forEach((r) => {
                portfolio.x.push(r.date);
                portfolio.y.push(r.value * 100);
            });
            graph.benchmark.forEach((b) => {
                benchmark.x.push(b.date);
                benchmark.y.push(b.value * 100);
            });
        }

        return (
            <div>
                {this.renderChartHeader()}
                <div className="prv-client-performance__chart-graph">
                    {graphLoading && <Spinner/>}
                    <Plot
                        data={[portfolio, benchmark]}
                        layout={{
                            autosize: true,
                            width: 890,
                            height: 500,
                            margin: {
                                l: 40,
                                r: 5,
                                t: 30,
                            },
                            dragmode: 'pan',
                            xaxis: {
                                rangeslider: {},
                            },
                            yaxis: {
                                fixedrange: true,
                                zerolinecolor: '#B3B6B8',
                                hoverformat: '.2r',
                                ticksuffix: '%',
                            },
                            showlegend: false,
                        }}
                        config={{
                            scrollZoom: true,
                            displayModeBar: true,
                            displaylogo: false,
                            modeBarButtonsToRemove: [
                                'toImage',
                                'sendDataToCloud',
                                'zoom2d',
                                'pan2d',
                                'select2d',
                                'lasso2d',
                                'autoScale2d',
                                'resetScale2d',
                                'hoverClosestCartesian',
                                'hoverCompareCartesian',
                                'toggleSpikelines',
                            ],
                        }}
                    />
                </div>
                {this.renderChartFooter()}
            </div>
        );
    };

    render() {
        if (this.state.firstRender) return <Spinner/>;

        return (
            <div className="prv-client-performance">
                <div className="prv-client-performance__content-wrapper">
                    <div className="prv-client-performance__content">
                        {this.renderEntityFilter()}
                        {this.renderChart()}
                    </div>
                </div>
            </div>
        );
    }
}
export default connect(
    {
        portfolios: getPortfolios,
        client: getPinnedClient,
        graphLoading: performanceIsLoading,
        treeLoading: getLoading,
        portfoliosLoading: isPortfoliosLoading,
        graph: getGraph,
        info: getInfo,
        tree: getTree,
        defaultVersion: getDefaultVersion,
    },
    {
        fetchCustomerPortfolios,
        fetchGraph,
        fetchGraphForPortfolio,
        fetchPerformanceInfo,
        fetchPerformanceInfoForPortfolio,
        clearPortfolios,
        fetchStructure,
        recalculateClient,
        showToast,
    },
)(PerformancePage);
