import React, {Component} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ReactDatePicker from 'react-datepicker';
import cn from 'classnames';

import 'styles/react-datepicker.css';
import styles from './date-picker.module.css';

export default class DatePicker extends Component {
    static propTypes = {
        className: PropTypes.string,
        date: PropTypes.any,
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
        startDate: PropTypes.any,
        endDate: PropTypes.any,
        selectsStart: PropTypes.bool,
        selectsEnd: PropTypes.bool,
        disableInput: PropTypes.bool,
    };

    componentDidMount() {
        if (this.props.disableInput) {
            const inputs = document.getElementsByClassName(styles.disableInput);
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < inputs.length; i++) {
                inputs[i].disabled = true;
            }
        }
    }

    convertDate = date => {
        let parsedDate = date;
        if (moment.isDate(date)) {
            parsedDate = moment(date);
        } else if (!moment.isMoment(date)) {
            parsedDate = moment.utc(date);
        }

        return parsedDate && parsedDate.isValid() ? parsedDate : null;
    };

    filterDate = (date) => {
        const {selectsStart, selectsEnd, startDate, endDate} = this.props;

        if (selectsStart && endDate) {
            return date <= endDate;
        }

        if (selectsEnd && startDate) {
            return date >= startDate;
        }

        return true;
    };

    render() {
        const {
            className,
            onChange,
            onBlur = () => {},
            date,
            startDate,
            endDate,
            ...props
        } = this.props;
        const convertedDate = this.convertDate(date);
        const convertedStartDate = this.convertDate(startDate);
        const convertedEndDate = this.convertDate(endDate);
        return (
            <ReactDatePicker
                selected={convertedDate ? convertedDate.toDate() : null}
                onChange={onChange}
                filterDate={this.filterDate}
                className={cn(styles.datePickerInput, styles.disableInput, className)}
                onBlur={() => onBlur(date)}
                startDate={convertedStartDate ? convertedStartDate.toDate() : null}
                endDate={convertedEndDate ? convertedEndDate.toDate() : null}
                {...props}
            />
        );
    }
}
