import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import _ from 'lodash';

import styles from './select.module.css';

export default class Select extends Component {
    static propTypes = {
        className: PropTypes.string,
        placeholder: PropTypes.string,
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
        onShow: PropTypes.func,
        onClose: PropTypes.func,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        options: PropTypes.arrayOf(PropTypes.oneOfType([
            PropTypes.shape({
                value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            }),
        ])).isRequired,
        prefix: PropTypes.string,
        clearable: PropTypes.bool,
    };

    static defaultProps = {
        clearable: true,
        placeholder: 'Select Item',
    };

    constructor(props) {
        super(props);
        this.state = {dropdownOpen: false};
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.onShow && this.state.dropdownOpen
            && this.state.dropdownOpen !== prevState.dropdownOpen) {
            this.props.onShow(this.wrapper, this.menu);
        }

        if (this.props.onClose && !this.state.dropdownOpen
            && this.state.dropdownOpen !== prevState.dropdownOpen) {
            this.props.onClose(this.wrapper);
        }
    }

    onChange = (value) => {
        this.props.onChange(value);
        this.setState({dropdownOpen: false});
    };

    onBlur = () => {
        this.setState({dropdownOpen: false});
        if (this.props.onBlur) this.props.onBlur(this.props.value);
    };

    onClear = (e) => {
        e.stopPropagation();
        this.setState({dropdownOpen: false});
        this.props.onChange(null);
    };

    toggleDropdown = () => this.setState({dropdownOpen: !this.state.dropdownOpen});

    renderButton = () => {
        const {prefix, clearable, options, value, placeholder} = this.props;
        const optionObj = _.find(options, opt => opt.value === value);
        const label = optionObj ? optionObj.label : placeholder;

        return (
            <div
                className={styles.button}
                onClick={this.toggleDropdown}
            >
                {prefix && <span className={styles.prefix}>{prefix}</span>}
                <span className={styles.buttonLabel}>{label}</span>
                {clearable && value &&
                <span className={styles.cleanIcon} onClick={this.onClear}/>
                }
                {(!clearable || !value) && <span className={styles.dropdownIcon}/>}
            </div>
        );
    };

    renderMenu = () => {
        const {options, value} = this.props;
        const {dropdownOpen} = this.state;

        if (!options.length) {
            return (
                <div
                    className={cn(styles.noOptions, {[styles.show]: dropdownOpen})}
                >
                    <span>No items to select</span>
                </div>
            );
        }

        return (
            <ul
                ref={(ref) => {
                    this.menu = ref;
                }}
                className={cn(styles.menu, {[styles.show]: dropdownOpen})}
            >
                {options.map(opt => (
                    <li
                        key={opt.value}
                        onClick={() => this.onChange(opt.value)}
                        className={cn({
                            [styles.selectedItem]: opt.value === value,
                        })}
                    >
                        {opt.label}
                    </li>
                ))}
            </ul>
        );
    };

    render() {
        const {className} = this.props;

        return (
            <div
                tabIndex={0}
                ref={(ref) => {
                    this.wrapper = ref;
                }}
                className={cn(styles.select, className)}
                onBlur={this.onBlur}
            >
                {this.renderButton()}
                {this.renderMenu()}
            </div>
        );
    }
}
