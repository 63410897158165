import React, {memo, useCallback, useMemo} from 'react';
import cn from 'classnames';
import TreeElementHeader from './TreeElementHeader';
import {BaseFlatTreeProps, NodeRenderer, TreeNode} from './tree';

interface Props extends BaseFlatTreeProps {
    getNodeLevel: (node: TreeNode) => number,
    node: TreeNode,
}

export const defaultNodeRenderer: NodeRenderer<BaseFlatTreeProps> = (node, props: BaseFlatTreeProps) => (
    <FlatTreeElement {...props} node={node}/> // node prop should be the last one otherwise it could be overridden
);

// eslint-disable-next-line prefer-arrow-callback
const FlatTreeElement: React.FC<Props> = memo((props) => {
    const {
        canSelect,
        getActions,
        getName,
        getNodeClass,
        getNodeId,
        getNodeLevel,
        hasChildren,
        isEditing,
        isExpanded,
        isSelected,
        isVisible,
        node,
        onCancelRename,
        onExpand,
        onRename,
        onSelect,
    } = props;
    const actions = getActions ? getActions(node) : undefined;
    const handleCancelRename = useCallback(
        (cancelled) => onCancelRename && onCancelRename(node, cancelled),
        [node, onSelect],
    );
    const handleRename = useCallback((newName) => onRename && onRename(node, newName), [node, onRename]);
    const handleSelect = useCallback(() => onSelect && onSelect(node), [node, onSelect]);
    const editing = useMemo(() => (isEditing ? isEditing(node) : false), [isEditing, node]);
    const expandable = hasChildren(node);
    const expanded = useMemo(() => (isExpanded ? isExpanded(node) : true), [isExpanded, node]);
    const visible = useMemo(() => (isVisible ? isVisible(node) : true), [isVisible, node]);
    const nodeId = getNodeId(node);

    const handleToggleExpand = useCallback(() => onExpand && onExpand(node, !expanded), [expanded, node, onExpand]);

    return (
        <div
            className={cn(
                'prv-tree-view-element',
                'prv-tree-view-element--flat',
                {
                    'prv-tree-view-element--has-children': expandable,
                    'prv-tree-view-element--selected': isSelected && isSelected(node),
                    'prv-tree-view-element--expanded': expanded,
                    'prv-tree-view-element--hidden': !visible,
                },
                getNodeClass ? getNodeClass(node) : undefined,
            )}
            style={{['--level' as any]: getNodeLevel(node)}}
        >
            <TreeElementHeader
                actions={actions}
                canExpand={expandable}
                canSelect={(canSelect && canSelect(node)) ?? false}
                className="prv-tree-view-element__header"
                editing={editing}
                expanded={expanded}
                name={getName(node)}
                nodeId={nodeId}
                onCancelRename={handleCancelRename}
                onRename={handleRename}
                onSelect={handleSelect}
                onToggleExpand={handleToggleExpand}
            />
        </div>
    );
});

export default FlatTreeElement;
