// Prop types checking still disabled in this file assuming that we'll move to a better type checking soon
/* eslint-disable react/prop-types */
import React from 'react';
import {ErrorMessage, Field, Form, Formik} from 'formik';
import * as yup from 'yup';
import cn from 'classnames';
import DatePicker from 'react-datepicker';
import CircularProgress from 'react-md/lib/Progress/CircularProgress';
import moment from 'moment';
import {connectActions} from '../../store';
import {showToast} from '../../store/snackbar/actions';

const SUBMIT_BUTTON_PROPS = Object.freeze({type: 'submit'});

const schema = yup.object().shape({
    firstName: yup.string()
        .trim()
        .required('Required'),
    lastName: yup.string()
        .trim()
        .required('Required'),
    birthday: yup.string()
        .nullable()
        .required('Required'),
    email: yup.string()
        .email('Should be email')
        .required('Required'),
    phone: yup.string()
        .matches(/^\+\d{6,}$/, 'Phone has incorrect format')
        .required('Required'),
    baseCurrency: yup.string()
        .required('Required'),
    startMonth: yup.string()
        .required('Required'),
});

const Title = React.memo(({title}) => (
    <div className="form-label">
        <span className="">{title}</span>
    </div>
));

const Button = React.memo(({message, onClick, className = '', buttonProps, id, loading, disabled, ...props}) => (
    <div className={cn('form-button', className)} {...props}>
        <button id={id} onClick={onClick} disabled={disabled} {...buttonProps}>
            {loading && <CircularProgress id={`${id}-loading`} scale={0.5} />}
            {message}
        </button>
    </div>
));

const formatDate = (date) => `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}T00:00:00Z`;

const DatePickerField = React.memo(({name, value, onChange, ...props}) => {
    let selectedDate = moment(value, 'YYYY-MM-DD');
    selectedDate = selectedDate.isValid() ? selectedDate.toDate() : new Date();
    return (
        <DatePicker
            selected={selectedDate}
            onChange={val => {
                if (val && moment.isDate(val)) {
                    onChange(name, val);
                } else {
                    onChange(name, null);
                }
            }}
            maxDate={new Date()}
            dateFormat="yyyy-MM-dd"
            {...props}
        />
    );
});

const ClientEditForm = (props) => {
    const [loading, setLoading] = React.useState(false);
    const {showToast} = props;
    return (
        <div>
            <Formik
                validationSchema={schema}
                initialValues={props.initialValues}
                onSubmit={({firstName, lastName, birthday, ...values}, {setErrors}) => {
                    if (!loading) {
                        setLoading(true);
                        props.updateClient({
                            ...values,
                            firstName: firstName.trim(),
                            lastName: lastName.trim(),
                            birthday: formatDate(new Date(birthday)),
                        })
                            .then(() => {
                                setLoading(false);
                                showToast({text: 'Client has been updated'});
                            })
                            .catch((response) => {
                                setLoading(false);

                                if (!response || !response.message) {
                                    showToast({text: 'Unknown error, please try again later'});
                                    return;
                                }

                                if (Array.isArray(response.validationErrors) && response.validationErrors.length) {
                                    setErrors(response.validationErrors.reduce(
                                        (result, {field, message}) => Object.assign(result, {[field]: message}),
                                        {},
                                    ));
                                } else {
                                    showToast({text: `Failed to save changes: ${response.message}`});
                                }
                            });
                    }
                }}
            >
                {({
                    errors,
                    values,
                    setFieldValue,
                    resetForm,
                    handleSubmit,
                }) => (
                    <Form onSubmit={handleSubmit}>
                        {props.managers &&
                        <div>
                            <Title title="Relationship manager*" />
                            <Field
                                disabled={loading}
                                className={cn('form-field', {'form-field__invalid': errors.managerId})}
                                name="managerId"
                                as="select"
                            >
                                {[{value: null, label: ''}, ...props.managers].map((manager) =>
                                    <option value={manager.value} key={manager.value}>{manager.label}</option>,
                                )}
                            </Field>
                            <ErrorMessage name="managerId">
                                {(message) => <div className="form-error">{message}</div>}
                            </ErrorMessage>
                        </div>}

                        <div>
                            <Title title="First name*" />
                            <Field
                                disabled={loading}
                                className={cn('form-field', {'form-field__invalid': errors.firstName})}
                                name="firstName"
                            />
                            <ErrorMessage name="firstName">
                                {(message) => <div className="form-error">{message}</div>}
                            </ErrorMessage>
                        </div>

                        <div>
                            <Title title="Last name*" />
                            <Field
                                disabled={loading}
                                className={cn('form-field', {'form-field__invalid': errors.lastName})}
                                name="lastName"
                            />
                            <ErrorMessage name="lastName">
                                {(message) => <div className="form-error">{message}</div>}
                            </ErrorMessage>
                        </div>

                        <div>
                            <Title title="Birthday*" />
                            <DatePickerField
                                disabled={loading}
                                name="birthday"
                                value={
                                    moment(values.birthday).isValid
                                    && moment(values.birthday).format('YYYY-MM-DD')
                                }
                                onChange={setFieldValue}
                                className={cn('form-field', {'form-field__invalid': errors.birthday})}
                            />
                            {Object.keys(errors).includes('birthday') &&
                                <div className="form-error">{errors.birthday}</div>
                            }
                        </div>

                        <div>
                            <Title title="Email*" />
                            <Field
                                disabled={loading}
                                className={cn('form-field', {'form-field__invalid': errors.email})}
                                name="email"
                            />
                            <ErrorMessage name="email">
                                {(message) => <div className="form-error">{message}</div>}
                            </ErrorMessage>
                        </div>

                        <div>
                            <Title title="Phone number*" />
                            <Field
                                disabled={loading}
                                className={cn('form-field', {'form-field__invalid': errors.phone})}
                                name="phone"
                            />
                            <ErrorMessage name="phone">
                                {(message) => <div className="form-error">{message}</div>}
                            </ErrorMessage>
                        </div>

                        <div>
                            <Title title="proVeo ID" />
                            <Field
                                disabled={loading}
                                className={cn('form-field', {'form-field__invalid': errors.proveoId})}
                                name="proveoId"
                            />
                            <ErrorMessage name="proveoId">
                                {(message) => <div className="form-error">{message}</div>}
                            </ErrorMessage>
                        </div>

                        <div>
                            <Title title="Base Currency*" />
                            <Field
                                disabled={loading}
                                className={cn('form-field', {'form-field__invalid': errors.baseCurrency})}
                                value={values.baseCurrency}
                                name="baseCurrency"
                                as="select"
                            >
                                {props.currencies && props.currencies.map(currency =>
                                    <option value={currency.value} key={currency.value}>{currency.label}</option>)}
                            </Field>
                            <ErrorMessage name="baseCurrency">
                                {(message) => <div className="form-error">{message}</div>}
                            </ErrorMessage>
                        </div>

                        <div>
                            <Title title="Start Month*" />
                            <Field
                                disabled={loading}
                                className={cn('form-field', {'form-field__invalid': errors.startMonth})}
                                value={values.startMonth}
                                name="startMonth"
                                as="select"
                            >
                                {moment.months().map((month, index) =>
                                    <option value={index + 1} key={month}>{month}</option>)}
                            </Field>
                            <ErrorMessage name="startMonth">
                                {(message) => <div className="form-error">{message}</div>}
                            </ErrorMessage>
                        </div>

                        <div className="form-buttons">
                            <Button
                                disabled={loading}
                                message="Cancel"
                                onClick={() => resetForm(props.initialState)}
                                className="secondary"
                            />
                            <Button
                                disabled={loading}
                                message="Save changes"
                                className="primary"
                                buttonProps={SUBMIT_BUTTON_PROPS}
                                id="client-edit-form-save-button"
                                loading={loading}
                            />
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default connectActions({showToast})(ClientEditForm);
/* eslint-enable react/prop-types */
