import buildActions from '../collections/actions_builder';
import {createApiCallAction, createAction} from '../helpers/actions';

const collection = buildActions('clientTransactions');

export const fetchTransactions = collection.fetchItems;
export const pinTransactionById = collection.pinItemById;

export const addTransaction = createApiCallAction('clientTransactions/ADD_TRANSACTION', 'addClientTransaction');
export const updateTransaction =
    createApiCallAction('clientTransactions/UPDATE_TRANSACTION', 'updateClientTransaction');
export const deleteTransaction =
    createApiCallAction('clientTransactions/DELETE_TRANSACTION', 'deleteClientTransaction');
export const clearAddTransactionError = createAction('clientTransactions/CLEAR_ADD_TRANSACTION_ERROR');
export const clearUpdateTransactionError = createAction('clientTransactions/CLEAR_UPDATE_TRANSACTION_ERROR');
