import React from 'react';
import PropTypes from 'prop-types';

export default class AccountListHeader extends React.PureComponent {
    static propTypes = {
        filteredItemCount: PropTypes.number,
        totalItemCount: PropTypes.number.isRequired,
    };

    render() {
        const {filteredItemCount, totalItemCount} = this.props;

        return (
            <div className="prv-client-account-list-header">
                <span>Accounts</span>
                <div className="prv-client-account-list-header__separator"/>
                <span className="prv-client-account-list-header__account-count">
                    {filteredItemCount == null ? totalItemCount : `Found ${filteredItemCount}`}
                </span>
            </div>
        );
    }
}
