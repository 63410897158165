import {handleActions} from 'store/helpers/actions';
import {actionHandlers} from 'store/collections/reducer_helpers';
import {readBenchmark} from 'store/data/benchmark';

export {
    getCollectionState,
    getFetchParams,
} from 'store/collections/reducer_helpers';

const initialState = {};

export default handleActions({
    ...actionHandlers('benchmarks', readBenchmark),
}, initialState);
