import typeReader from 'store/helpers/type_reader';
import typeShape, {required, optional} from 'store/helpers/type_shape';

const TransactionType = {
    id: required(String),
    accountId: required(String),
    customerFirstName: optional(String),
    customerLastName: optional(String),
    customerId: required(String),
    custodyAccountId: optional(String),
    assetId: required(String),
    originalId: required(String),
    accountName: required(String),
    assetName: required(String),
    type: required(String),
    currency: required(String),
    price: required(Number),
    value: required(Number),
    originalValue: required(Number),
    tradeDate: required(String),
    settlementDate: optional(String),
    message: optional(String),
};

export const readTransaction = typeReader(TransactionType);
export const transactionShape = typeShape(TransactionType);
