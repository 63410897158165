import typeReader from '../helpers/type_reader';
import typeShape, {required, optional} from '../helpers/type_shape';

const infoType = {
    type: {
        id: required(String),
        birthday: optional(String),
        creationDate: required(String),
        email: required(String),
        firstName: required(String),
        lastName: required(String),
        phone: optional(String),
        status: required(String),
    },
    from: 'profile',
    required: false,
};

const ProfileType = {
    id: required(String),
    azureId: required(String),
    role: required(String),
    info: infoType,
    scope: required(String),
};

export const readProfile = typeReader(ProfileType);
export const profileShape = typeShape(ProfileType);

export const editProfile = profile => profile && {
    birthday: profile.birthday,
    creationDate: profile.creationDate,
    email: profile.email,
    firstName: profile.firstName,
    lastName: profile.lastName,
    phone: profile.phone,
};
