import React from 'react';
import cn from 'classnames';
import {money} from '../../../helpers/formatters';
import Client from '../../../store/types/Client';
import AccountProfit from '../../../store/types/AccountProfit';

interface Props {
    accountsProfit: AccountProfit,
    client: Client,
}

const ProfitInformation: React.FC<Props> = ({accountsProfit, client}) => (
    <div className="client-information-square client-information-total">
        <div className="client-information-value-changes">
            <div className="client-information-value-changes-header">Total Values</div>
            {accountsProfit &&
            <div className="client-information-value-changes-total">
                {money(accountsProfit.totalValue)}&nbsp;{client.baseCurrency}
            </div>
            }
        </div>
        <div className="client-information-value-changes">
            <div className="client-information-value-changes-header">
                Total Value Changes
            </div>
            {accountsProfit &&
            <div
                className={cn(
                    'client-information-value-changes-total',
                    parseFloat('' + accountsProfit.totalValueChange) >= 0
                        ? 'client-information-value-changes-total--green'
                        : 'client-information-value-changes-total--red',
                )}
            >
                {money(accountsProfit.totalValueChange)}&nbsp;{client.baseCurrency}
            </div>
            }
        </div>
    </div>
);

export default ProfitInformation;
