import React, {useCallback, useState} from 'react';
import DialogContainer from 'react-md/lib/Dialogs/DialogContainer';
import {PortfolioNode, StructureItem} from '../../../../../store/structure/types';
import FlatTreeView from '../../../../../components/TreeView/FlatTreeView';
import {isPortfolio, isRootItem} from '../../../../../store/structure/helpers/assertion';
import {getParentPortfolios, hasChildrenNodes} from '../../../../../store/structure/helpers/structure';
import {BaseFlatTreeProps, NodeRenderer} from '../../../../../components/TreeView/tree';
import PortfolioElement from './PortfolioElement';
import {FAKE_ROOT_PORTFOLIO} from '../../../../../store/structure/reducers/movePortfolioReducer';

const getPortfolioName = (item: StructureItem) => (isPortfolio(item) ? item.name : '');
const getNodeId = ({id}: StructureItem) => `${id}`;
const getNodeLevel = ({level}: StructureItem) => level.length;

export const nodeRenderer: NodeRenderer<BaseFlatTreeProps> = (node, props: BaseFlatTreeProps) => (
    <PortfolioElement {...props} node={node}/> // node prop should be the last one otherwise it could be overridden
);

interface Props {
    id: string,
    onHide: () => void,
    onSelectPortfolio?: (portfolio: PortfolioNode) => void,
    portfolios: PortfolioNode[],
    text?: string,
    title?: string,
    visible: boolean,
}

const SelectPortfolioDialog: React.FC<Props> = ({
    id,
    onHide,
    onSelectPortfolio,
    portfolios,
    text = 'Click to select portfolio',
    title = 'Select Portfolio',
    visible,
}) => {
    const hasChildren = useCallback(
        (node: StructureItem) => hasChildrenNodes(portfolios, node),
        [hasChildrenNodes, portfolios],
    );

    const [expandedPortfolioIds, setExpandedPortfolioIds]
        = useState<Set<PortfolioNode['id']>>(new Set([FAKE_ROOT_PORTFOLIO.id])); // fake root is always expanded
    const isExpanded = useCallback(({id}: StructureItem) => expandedPortfolioIds.has(id), [expandedPortfolioIds]);
    const handleExpand = useCallback(
        (node: StructureItem, expanded: boolean) => {
            setExpandedPortfolioIds(expandedIds => {
                const newExpandedIds = new Set(expandedIds);
                if (newExpandedIds.has(node.id)) {
                    if (!expanded) {
                        newExpandedIds.delete(node.id);
                    }
                } else if (expanded) {
                    newExpandedIds.add(node.id);
                }
                return newExpandedIds.size !== expandedIds.size ? newExpandedIds : expandedIds;
            });
        },
        [setExpandedPortfolioIds],
    );
    const isVisible = useCallback(
        (node: PortfolioNode) => isRootItem(node)
            || getParentPortfolios(portfolios, node).every(({id}) => expandedPortfolioIds.has(id)),
        [expandedPortfolioIds, portfolios],
    );

    return (
        <DialogContainer
            actions={[
                {
                    children: 'Cancel',
                    className: 'prv-select-portfolio-dialog__cancel-button',
                    onClick: onHide,
                    secondary: true,
                },
            ]}
            dialogClassName="prv-select-portfolio-dialog"
            focusOnMount={false}
            id={id}
            modal={false}
            onHide={onHide}
            renderNode={document.body}
            title={title}
            visible={visible}
        >
            {text && <p>{text}</p>}

            <FlatTreeView
                className="prv-portfolio-tree__tree"
                getName={getPortfolioName}
                getNodeId={getNodeId}
                getNodeLevel={getNodeLevel}
                hasChildren={hasChildren}
                isExpanded={isExpanded}
                isVisible={isVisible}
                renderNode={nodeRenderer}
                onExpand={handleExpand}
                onSelect={onSelectPortfolio}
                tree={portfolios}
            />
        </DialogContainer>
    );
};

export default SelectPortfolioDialog;
