import {createAction, createApiCallAction} from '../helpers/actions';

export const setStep = createAction('onboarding/SET_STEP');

export const signup = createApiCallAction('onboarding/SIGNUP', 'signup');

export const checkUser = createApiCallAction('onboarding/CHECK_USER', 'checkUser');

export const selectBank = createAction('onboarding/SELECT_BANK');

export const selectAccounts = createAction('onboarding/SELECT_ACCOUNTS');

export const completeOnboarding = createApiCallAction('onboarding/COMPLETE_ONBOARDING', 'completeOnboarding');

export const setBoersenId = createAction('onboarding/SET_BOERSEN_ID');

export const norkonRegister = createApiCallAction('onboarding/NORKON_REGISTER', 'norkonRegister');

export const setCallbackUri = createAction('onboarding/SET_CALLBACK');

export const setOnboardingFlow = createAction('onboarding/SET_ONBOARDING_FLOW');

export const setLastAddedBank = createAction('onboarding/SET_LAST_ADDED_BANK');

export const patchOnboarding = createApiCallAction('onboarding/PATCH_ONBOARDING', 'patchOnboarding');

export const checkExistence = createApiCallAction('onboarding/CHECK_EXISTENCE', 'checkExistence');

export const simpleSignin = createApiCallAction('onboarding/SIMPLE_SIGNIN', 'simpleSignin');

export const setAdditionalStep = createAction('onboarding/SET_ADDITIONAL_STEP');
