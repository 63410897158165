import invariant from 'invariant';
import qs from 'query-string';
import {
    getLoginUrl,
    getSignUpUrl,
    getResetPasswdUrl,
} from '../../helpers/adal';

import {TOKEN_KEY, LOGIN_POPUP_BLOCKED} from '../../config/api.config';

import {
    login,
    signup,
    logout,
    loggedIn,
    signedUp,
    fetchProfile,
    resetPassword,
} from './actions';

import {
    dispatchApiCall,
} from '../helpers/sagas';

import {
    createApiCallResultAction,
} from '../helpers/actions';
import POLICIES from '../../config/policies';

const LOGIN_POPUP_HEIGHT = 600;
const LOGIN_POPUP_WIDTH = 800;

const signedUpResponse = createApiCallResultAction(signedUp);
const fetchProfileResponse = createApiCallResultAction(fetchProfile);

function onResetPassword({payload = {}}) {
    const {policy, redirectUri = `${window.location.origin}/`} = payload;

    invariant(policy, 'poolicy should be defined');

    const params = {
        redirectUri,
        policy,
    };

    sessionStorage.removeItem(TOKEN_KEY);
    window.location = getResetPasswdUrl(params);
}

function onLogin({payload = {}}) {
    const {policy = POLICIES.LOGIN.PROVEO, redirectUri = `${window.location.origin}/`, withPopup = false} = payload;

    const params = {
        redirectUri,
    };

    if (policy) {
        params.policy = policy;
    }

    const fallback = (url) => {
        window.sessionStorage.setItem(LOGIN_POPUP_BLOCKED, JSON.stringify(true));
        window.location = url;
    };

    sessionStorage.removeItem(TOKEN_KEY);
    const url = getLoginUrl(params);
    try {
        if (withPopup) {
            const popup = window.open(
                url,
                'ADAL AUTHENTICATION',
                `width=${LOGIN_POPUP_WIDTH},height=${LOGIN_POPUP_HEIGHT}`,
            );
            if (popup) {
                const interval = setInterval(() => {
                    try {
                        if (popup.closed) {
                            clearInterval(interval);
                            return;
                        }
                        if (popup.location.href.split('#')[0] === redirectUri && popup.location.hash) {
                            const {id_token: token} = qs.parse(popup.location.hash);
                            sessionStorage.setItem(TOKEN_KEY, token);
                            popup.close();
                            clearInterval(interval);
                            window.location.reload();
                            return;
                        }
                    } catch (error) {
                        if (popup.closed) {
                            clearInterval(interval);
                        }
                    }
                }, 100);
            } else {
                fallback(url);
            }
        } else {
            fallback(url);
        }
    } catch (error) {
        fallback(url);
    }
}

function* onLoggedIn({payload: {token, ...config}}) {
    sessionStorage.setItem(TOKEN_KEY, token);
    yield dispatchApiCall(fetchProfileResponse, 'fetchProfile', config);
}

function onSignup({payload: token, policy = POLICIES.SIGNUP.PROVEO}) {
    const params = {
        state: token,
    };

    if (policy) {
        params.policy = policy;
    }

    window.location = getSignUpUrl(params);
}

function* onSignedUp({payload}) {
    const {token, inviteToken} = payload;
    sessionStorage.setItem(TOKEN_KEY, token);

    yield dispatchApiCall(signedUpResponse, 'signedUp', inviteToken);
}

function onLogout({payload: location}) {
    sessionStorage.removeItem(TOKEN_KEY);
    window.location = location || window.location.origin;
}

export default {
    [loggedIn]: onLoggedIn,
    [signedUp]: onSignedUp,
    [login]: onLogin,
    [signup]: onSignup,
    [logout]: onLogout,
    [resetPassword]: onResetPassword,
};
