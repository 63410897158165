import buildActions from '../collections/actions_builder';
import {createApiCallAction, createAction} from '../helpers/actions';

const collection = buildActions('clients');

export const fetchClients = collection.fetchItems;
export const refreshPinnedClient = collection.pinItemById;
export const clearFetchClientsError = collection.clearFetchError;
export const changeClientsPerPage = collection.changePerPage;
export const clearClientsCollection = collection.clearCollection;
export const createClient = createApiCallAction('clients/CREATE', 'createClient');
export const updateClient = createApiCallAction('clients/EDIT', 'updateClient');
export const deleteClient = createApiCallAction('clients/DELETE', 'deleteClient');
export const blockClient = createApiCallAction('clients/BLOCK', 'blockClient');
export const unblockClient = createApiCallAction('clients/BLOCK', 'unblockClient');
export const syncClientStart = createApiCallAction('clients/SYNC/start', 'syncClientStart');
export const syncClientCheck = createApiCallAction('clients/SYNC/check', 'syncClientCheck');
export const fetchActiveRMs = createApiCallAction('clients/FETCH_ACTIVE_RMS', 'fetchActiveRMs');

export const fetchAssets = createApiCallAction('clients/FETCH_ASSETS', 'fetchClientAssets');
export const fetchAccountsProfit = createApiCallAction('clients/FETCH_ACCOUNTS_PROFIT', 'fetchAccountsProfit');
export const fetchClientsAms =
    createApiCallAction('clients/FETCH_CUSTOMERS_ASSETS_MANAGERS', 'fetchCustomersAssetsManagers');
export const fetchAllocatedAssets = createApiCallAction('clients/FETCH_ALLOCATED_ASSETS', 'fetchClientAllocatedAssets');
export const fetchAllocationClasses = createApiCallAction('clients/FETCH_ALLOCATION_CLASSES', 'fetchAllocationClasses');

export const fetchSharedBenchmarks = createApiCallAction('clients/FETCH_SHARED_BENCHMARKS', 'fetchSharedBenchmarks');
export const fetchClientBenchmarks = createApiCallAction('clients/FETCH_CLIENT_BENCHMARKS', 'fetchClientBenchmarks');
export const fetchLimits = createApiCallAction('clients/FETCH_LIMITS', 'fetchClientLimits');
export const updateClientBenchmarks = createApiCallAction('clients/UPDATE_BENCHMARKS', 'updateClientBenchmarks');
export const updateLimits = createApiCallAction('clients/UPDATE_LIMITS', 'updateClientLimits');

export const clearError = createAction('clients/CLEAR_ERROR');
export const clearLimitsUpdateError = createAction('clients/CLEAR_LIMITS_UPDATE_ERROR');
export const clearBenchmarksUpdateError = createAction('clients/CLEAR_BENCHMARKS_UPDATE_ERROR');

export const recalculateClient = createApiCallAction('clients/recalculate', 'recalculateClient');
export const recalculateEverything = createApiCallAction('clients/recalculateAll', 'recalculateEverything');

export const fetchClientBanks = createApiCallAction('clients/FETCH_BANKS', 'fetchBanksForClient');
export const fetchReportingBenchmarks =
    createApiCallAction('clients/FETCH_CUSTOMER_REPORTING_BENCHMARKS', 'fetchCustomerReportingBenchmarks');
export const updateReportingBenchmarks =
    createApiCallAction('clients/UPDATE_CUSTOMER_REPORTING_BENCHMARKS', 'updateCustomerReportingBenchmarks');
export const deleteReportingBenchmarks =
    createApiCallAction('clients/DELETE_CUSTOMER_REPORTING_BENCHMARKS', 'deleteCustomerReportingBenchmarks');
