import React, {memo, SyntheticEvent, useCallback, useMemo} from 'react';
import cn from 'classnames';
import Button from 'react-md/lib/Buttons/Button';
import {BaseFlatTreeProps, TreeNode} from '../../../../../components/TreeView/tree';

interface Props extends BaseFlatTreeProps {
    getNodeLevel: (node: TreeNode) => number,
    node: TreeNode,
}

const PortfolioElement: React.FC<Props> = memo((props) => {
    const {
        getName,
        getNodeLevel,
        hasChildren,
        isExpanded,
        isVisible,
        node,
        onExpand,
        onSelect,
    } = props;

    const expandable = hasChildren(node);
    const expanded = useMemo(() => (isExpanded ? isExpanded(node) : true), [isExpanded, node]);
    const visible = useMemo(() => (isVisible ? isVisible(node) : true), [isVisible, node]);

    const handleExpand = useCallback(
        (e: SyntheticEvent) => {
            e.stopPropagation();
            onExpand && onExpand(node, !expanded);
        },
        [expanded, node, onExpand],
    );

    const handleSelect = useCallback(() => onSelect && onSelect(node), [node, onSelect]);

    return (
        <div
            className={cn(
                'prv-select-portfolio-element',
                'prv-select-portfolio-element--flat',
                {
                    'prv-select-portfolio-element--has-children': expandable,
                    'prv-select-portfolio-element--expanded': expanded,
                    'prv-select-portfolio-element--hidden': !visible,
                },
            )}
            onClick={handleSelect}
            style={{['--level' as any]: getNodeLevel(node)}}
        >
            <div className="prv-select-portfolio-element__header">
                {expandable && (
                    <Button
                        className="prv-select-portfolio-element__header-expand-button"
                        icon
                        onClick={handleExpand}
                    >
                        {expanded ? 'expand_less' : 'expand_more'}
                    </Button>
                )}
                <div className="prv-select-portfolio-element__header-name">{getName(node)}</div>
            </div>
        </div>
    );
});

export default PortfolioElement;
