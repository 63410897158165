import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import {reduxForm} from '../../components/forms';
import validate from '../../components/forms/validations/validate';

import EditingForm from '../../components/editing/editing-form';
import EditingTable from '../../components/editing/editing-table';

import {
    ErrorsRow,
    InputRow,
    DateRow,
} from '../../components/editing/editing-rows';

const fields = {
    firstName: {
        required: true,
        name: true,
        maxLength: 50,
    },
    lastName: {
        required: true,
        name: true,
        maxLength: 50,
    },
    email: {
        required: true,
        email: true,
    },
    phone: {
        required: true,
        phone: true,
    },
};

const RMEditForm = ({blocked, disabled, ...props}) => (
    <div>
        <EditingForm {...props} blocked={blocked}>
            <EditingTable
                className={cn('prv-rm-edit-form__form', {'prv-rm-edit-form__form--blocked': blocked})}
                {...props}
            >
                <InputRow name="firstName" label="First Name" disabled={disabled} required/>
                <ErrorsRow name="firstName"/>

                <InputRow name="lastName" label="Last Name" disabled={disabled} required/>
                <ErrorsRow name="lastName"/>

                <DateRow name="birthday" label="Birthdate" disabled={disabled}/>
                <ErrorsRow name="birthday"/>

                <InputRow
                    normalize={value => value && value.toLowerCase()}
                    name="email"
                    label="Email"
                    disabled={blocked != null}
                    required
                />
                <ErrorsRow name="email"/>

                <InputRow name="phone" label="Phone number" disabled={disabled} required/>
                <ErrorsRow name="phone"/>
            </EditingTable>
        </EditingForm>
    </div>
);

RMEditForm.propTypes = {
    blocked: PropTypes.bool,
    disabled: PropTypes.bool,
};

export default reduxForm({
    form: 'edit-rm',
    ...validate(fields),
})(RMEditForm);
