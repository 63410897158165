import React, {ReactNode} from 'react';

interface Props {
    title: string,
    children: ReactNode,
}

const Field: React.FC<Props> = ({children, title}) => (
    <div className="prv-client-information-field">
        <div className="prv-client-information-field__title">{title}</div>
        <div className="prv-client-information-field__value">{children}</div>
    </div>
);

export default Field;
