import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Language from 'config/Language';
import {Theme} from 'config/onboarding';
import translations from 'scenes/onboarding/translations';

import AuthMethods from './AuthMethods';
import styles from './choose-auth-method.module.css';

export default class ChooseAuthMethod extends React.Component {
    static propTypes = {
        onChoose: PropTypes.func,
        authMethod: PropTypes.string,
        authMethods: PropTypes.array,
    };

    onAuthClick = (country) => {
        const {onChoose, authMethod} = this.props;
        onChoose && onChoose(authMethod === country.id ? null : country);
    };

    render() {
        const {authMethod, authMethods} = this.props;
        const language = window.navigator.language.includes('da') ? Language.DANSKE : Language.ENGLISH;
        const translation = translations[language];

        return (
            <div className={styles.choosingAuthMethodWrapper}>
                <ul>
                    {authMethods.includes(AuthMethods.NemId) &&
                        <li
                            key={AuthMethods.NemId}
                            onClick={() => this.onAuthClick(AuthMethods.NemId)}
                            className={authMethod === AuthMethods.NemId ? styles.selected : null}
                        >
                            {translation[Theme.ATLAZ].authChoosing.NemId}
                        </li>
                    }
                    {authMethods.includes(AuthMethods.MitId) &&
                        <li
                            key={AuthMethods.MitId}
                            onClick={() => this.onAuthClick(AuthMethods.MitId)}
                            className={authMethod === AuthMethods.MitId ? styles.selected : null}
                        >
                            {translation[Theme.ATLAZ].authChoosing.MitId}
                        </li>
                    }
                    {authMethods.includes(AuthMethods.Basic) &&
                        <li
                            key={AuthMethods.Basic}
                            onClick={() => this.onAuthClick(AuthMethods.Basic)}
                            className={authMethod === AuthMethods.Basic ? styles.selected : null}
                        >
                            {translation[Theme.ATLAZ].authChoosing.Basic}
                        </li>
                    }
                </ul>
            </div>
        );
    }
}
