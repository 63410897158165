import browserLocale from 'browser-locale';
import numeral from 'numeral';
import 'numeral/locales';

const ensureNumeralLocale = function ensureNumeralLocale() {
    let locale = browserLocale();
    if (locale) {
        locale = locale.toLowerCase();
    }
    if (!numeral.locales[locale]) {
        // If not exists, also try converting xx_yy to xx-yy or xx
        // and failover to 'en' if still not found
        locale = [locale.replace('_', '-'), locale.split('_')[0], 'en']
            .find(locale => !!numeral.locales[locale]);
    }

    if (numeral.locale() !== locale) {
        numeral.locale(locale);
    }
};

export const money = (num) => {
    ensureNumeralLocale();
    return numeral(num).format('0,0.00');
};

export const percent = (num) => {
    ensureNumeralLocale();
    return numeral(num).format('0.00%');
};

export const percentTrend = (num, failoverText = 'N/A') => {
    // numeral library has problem formatting small numbers https://github.com/adamwdraper/Numeral-js/issues/512
    if (Math.abs(num) < 10e-8) {
        return 0;
    }
    if (Number.isNaN(num)) {
        return failoverText;
    }
    ensureNumeralLocale();
    return numeral(num).format('+0.00%');
};
