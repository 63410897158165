import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
// TODO: Refactor the component
import _ from 'lodash';
import {toggle} from 'components/helpers/select';
import Moire from 'components/moire/moire';
import Spinner from 'components/loading-spinner/loading-spinner';

import styles from './choose-items-side-dialog.module.css';

export default class SideDialog extends Component {
    static propTypes = {
        className: PropTypes.string,
        title: PropTypes.string,
        items: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.string])),
        selectedItems: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.string])),
        onConfirm: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
        multiSelect: PropTypes.bool,
        buttonTitle: PropTypes.string,
        uniqueKey: PropTypes.string,
        disableOnBlur: PropTypes.bool,
        renderItem: PropTypes.func,
        loading: PropTypes.bool,
        addCustom: PropTypes.bool,
        onAddCustom: PropTypes.func,
        itemName: PropTypes.string,
    };

    static defaultProps = {
        itemName: 'item',
    };

    constructor(props) {
        super(props);
        this.state = {
            selectedItems: props.selectedItems || [],
            showAddCustom: false,
            customNameValue: '',
            doesCustomNameDuplicatesExistingAM: false,
        };
    }

    componentDidMount() {
        this.wrapper.focus();
    }

    onConfirm = () => this.props.onConfirm(this.state.selectedItems);
    onSelectItem = (item) => {
        const {multiSelect, buttonTitle} = this.props;
        const {selectedItems} = this.state;

        const newItems = multiSelect ? toggle(selectedItems, item) : [item];
        if (buttonTitle) {
            this.setState({selectedItems: newItems});
        } else {
            this.props.onConfirm(newItems);
        }
    };

    onClose = (e) => {
        if (e.relatedTarget === this.confirmationButton) {
            return null;
        }
        return this.props.onClose(e);
    };

    onShowAddCustom = (e) => {
        e.stopPropagation();
        const newItem = this.props.items.find(item => item.isNew);
        this.setState({
            showAddCustom: true,
            customNameValue: newItem ? newItem.name : '',
            doesCustomNameDuplicatesExistingAM: false,
        });
    };
    onCustomNameChange = (e) => {
        const
            {renderItem} = this.props,
            newValue = e.target.value,
            newValueUpperCase = newValue.toUpperCase(),
            hasDuplicates = this.props.items.some((item) => newValueUpperCase === renderItem(item).toUpperCase());

        this.setState({customNameValue: newValue, doesCustomNameDuplicatesExistingAM: hasDuplicates});
    };
    onCloseAddCustom = () => {
        this.setState({showAddCustom: false});
    };

    onAddCustom = () => {
        const {onAddCustom} = this.props;
        const value = this.state.customNameValue.trim();
        if (!value) {
            this.onCloseAddCustom();
        } else {
            onAddCustom(value);
        }
    };

    renderAddCustomDialog = () => {
        const {itemName} = this.props;
        const {customNameValue, doesCustomNameDuplicatesExistingAM} = this.state;
        return (
            <Moire
                onClose={this.onCloseAddCustom}
                onCancel={this.onCloseAddCustom}
            >
                <div className={styles.addCustomDialog}>
                    <input
                        className={styles.addCustomInput}
                        onChange={this.onCustomNameChange}
                        ref={(input) => {input && input.focus();}}
                        type="text"
                        value={customNameValue}
                    />
                    {doesCustomNameDuplicatesExistingAM &&
                    <div className={styles.duplicatesWarning}>
                        {itemName} with such name already exists. Please choose another name.
                    </div>
                    }
                    <div className={styles.addCustomButtonBar}>
                        <button
                            className={styles.saveCustomButton}
                            disabled={doesCustomNameDuplicatesExistingAM || !customNameValue.trim()}
                            onClick={this.onAddCustom}
                        >
                            Ok
                        </button>
                        <button
                            className={styles.cancelCustomButton}
                            onClick={this.onCloseAddCustom}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </Moire>
        );
    };

    render() {
        const {
            className, items, buttonTitle, title,
            uniqueKey: key, renderItem, disableOnBlur, loading, addCustom,
        } = this.props;

        const hasNew = items.some(item => item.isNew);

        const {selectedItems, showAddCustom} = this.state;

        if (loading) {
            return (
                <div
                    ref={(wrapper) => {
                        this.wrapper = wrapper;
                    }}
                    className={cn(styles.wrapper, className)}
                    tabIndex={-1}
                    onBlur={disableOnBlur ? undefined : this.onClose}
                >
                    <Spinner/>
                </div>
            );
        }

        return (
            <div
                ref={(wrapper) => {
                    this.wrapper = wrapper;
                }}
                className={cn(styles.wrapper, className)}
                tabIndex={-1}
                onBlur={disableOnBlur ? undefined : this.onClose}
            >
                {showAddCustom && this.renderAddCustomDialog()}
                <div className={styles.header}>
                    <div>{title}</div>
                    {
                        addCustom && !hasNew &&
                        <button className={styles.addButton} onClick={this.onShowAddCustom}>Add</button>
                    }
                    <div className={styles.closeIcon} onClick={this.onClose}/>
                </div>
                <ul
                    className={styles.content}
                    style={{height: buttonTitle ? 'calc(100% - 50px - 70px)' : 'calc(100% - 50px)'}}
                >
                    {
                        _.map(items, item => (
                            <li
                                key={key ? item[key] : item}
                                className={
                                    selectedItems.some(obj => (key ? item[key] === obj[key] : item === obj))
                                        ? styles.selected
                                        : null
                                }
                                onClick={() => this.onSelectItem(item)}
                            >
                                {renderItem ? renderItem(item) : item}
                                {addCustom && hasNew && item.isNew &&
                                <button className={styles.addButton} onClick={this.onShowAddCustom}>Edit</button>
                                }
                            </li>
                        ))
                    }
                </ul>
                {buttonTitle &&
                <div className={styles.footer}>
                    <button
                        ref={(button) => {
                            this.confirmationButton = button;
                        }}
                        onClick={this.onConfirm}
                    >
                        {buttonTitle}
                    </button>
                </div>}
            </div>
        );
    }
}
