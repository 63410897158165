import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import * as pathnames from 'config/pathnames';

import {connectActions} from 'store';
import {redirect} from 'store/navigate';

import {createRM} from 'store/rms/actions';

import {UserScopes} from 'config/userscope';
import {UserScopeHeader} from 'store/api/api';

import RMEditForm from './rm-edit-form';

@withRouter
@connectActions({createRM, redirect})
export default class CreateRMPage extends Component {
    static propTypes = {
        redirect: PropTypes.func.isRequired,
        createRM: PropTypes.func.isRequired,
    };

    onCreate = data => this.props.createRM({...data,
        headers: {
            [UserScopeHeader]: UserScopes.PROVEO,
        },
    });
    onCancel = () => this.props.redirect(pathnames.RMS);

    onSuccess = (a, b, {values: rm}) => this.props.redirect({
        pathname: pathnames.RMS,
        state: {created: `${rm.firstName} ${rm.lastName}`},
    });

    render() {
        return (
            <RMEditForm
                form="create-rm"
                title={['RMs', 'New RM']}
                actionTitle="Send Invitation"
                onSubmit={this.onCreate}
                onCancel={this.onCancel}
                onSubmitSuccess={this.onSuccess}
            />
        );
    }
}
